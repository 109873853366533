import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// config file
class LogoutModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    logout = () => {
        this.props.closeLogoutModal('close');
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Modal isOpen={this.props.openLogoutModal}
                    className={`modal-dialog-centered modal-dialog--primary m-auto`}
                    style={{ maxWidth: 400 }}>
                    <ModalBody className="mt-2" style={{ margin: "auto", }} >
                        <div style={{ marginBottom: 10 }}>{t('Are you sure want to Logout')}?</div>
                        <Button color="primary" outline onClick={this.props.closeLogoutModal}
                            className='deleteModalBtn marginRight'>{t('No')}</Button>
                        <Button color="primary" outline onClick={this.logout}
                            className='deleteModalBtn'>{t('Yes')}</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('common')(LogoutModal);
