import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import { useState } from 'react';

const DeleteFieldModal = (props) => {
    const [displayMod, setDisplayMod] = useState(props.displayFieldModal);

    const handleNo = (cIndex,itemObj,fIndex) => {
        props.deleteFieldsFromController(cIndex,itemObj,fIndex,'no');
        
    };

    const handleYes = (cIndex,itemObj,fIndex) => {
        props.deleteFieldsFromController(cIndex,itemObj,fIndex);
    };

    return (
        
        <div>
            <Modal centered={true} isOpen={displayMod}>
                <ModalHeader className='progresBarClr text-white' style={{ textAlign: "center" }}>
                    Are you sure you want to delete this field/column?
                </ModalHeader>
                <ModalBody>
                    <Row className="pt-4">
                        <Col sm={12} className=" text-center">
                            
                            <button type="button" className="btn btn-success"
                                onClick={() => handleNo(props.cIndex,props.itemObj,props.fIndex)}>
                                No
                            </button>
                            <button type="button" className="btn btn-success"
                                onClick={() => handleYes(props.cIndex,props.itemObj,props.fIndex)}>
                                Yes
                            </button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
};
export default DeleteFieldModal;