import React, { useState, useEffect, useRef } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { load as loadAccount } from "../../redux/reducers/commonReducer";
import Checkbox from "../../shared/components/form/CheckBox"
import Select from "../../shared/components/form/Select"
import validate from '../Form/validate';
import DefaultInput from '../../shared/components/form/DefaultInput';
import configMessage from '../../config/configMessages';
import config from '../../config/config'
let newpassval = value => (value ? config.passwordRegex.test(value) ? undefined : 'Not a secure paswword, hint:User1234$' : configMessage.fillField)
const newpassvalLength = value => (value ? value.length >= 8 ? undefined : 'Password length should be atleast 8' : configMessage.fillField)
const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

// hook forms
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { InputText } from "primereact/inputtext";
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';


const AddAdminModal = (props)=> {
    const defaultValues = {
        name: '',
        email: '',
        password: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register, trigger, setError, setValue
    } = useForm({ defaultValues });

    useEffect(()=> {
        if(props && props.isEdit) {
            const { name,email,password } = props.details;
            setValue('name', name);
            setValue('email', email);
            setValue('password', password);
        } else {
            if (props.activeTab && props.activeTab == "Admin") {
                setValue('name', 'Admin');
                setValue('email', "admin@yopmail.com");
                setValue('password', "Admin1234$");
            } else {
                setValue('name', props.activeTab);
                setValue('email', `${this.props.activeTab}@yopmail.com`);
                setValue('password', "Admin1234$");
            }
        }
    }, [props]);  

    const onSubmit = (data) => {
        let values = {};
        values = data;
        values.index = props.index;
        values.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
        values.isEdit = props.isEdit ? props.isEdit : false;
        props.setNewUserDetails(values);
        props.closeAddCreateUser();
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };
    
    return(
        <>
            <Modal style={{ width: "40%" }} isOpen={props.openCreateUserModal} centered={true}>
                <ModalHeader className='progresBarClr text-white'>
                  <span className='h5'>{props.isEdit ? `Edit Admin` : `Create Admin`}</span>  
                    <span className='float-right'>
                        < FontAwesomeIcon
                            className='editIcon text-white'
                            color='#ff00008c'
                            icon='times-circle'
                            data-toggle="tool-tip"
                            title="Close"
                            onClick={() => props.closeAddCreateUser()}
                        />
                    </span>
                </ModalHeader>
                <ModalBody>
                    <Card className='m-0 p-0' style={{ border: "none" }}>
                        <CardBody className='m-0 p-0'>
                            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                                <div className='row mb-1'>
                                    <div className='form-group'>
                                        <label htmlFor="username" className='mb-1'>Name</label>
                                        <Controller
                                            name='name'
                                            control={control}
                                            rules={{ required: 'Name is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>  
                                                    <InputText id="username" value={field.value} 
                                                    className={classNames({ 'p-invalid': fieldState.error },'w-full')} 
                                                    onChange={(e) => field.onChange(e.target.value)} />
                                                    {getFormErrorMessage(field.name)}   
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-1'>
                                    <div className='form-group'>
                                        <label htmlFor="email_field" className='mb-1'>Email</label>
                                        <Controller
                                            name='email'
                                            control={control}
                                            rules={{ required: 'Email is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>  
                                                    <InputText id="email_field" value={field.value} 
                                                    className={classNames({ 'p-invalid': fieldState.error },'w-full')} 
                                                    onChange={(e) => field.onChange(e.target.value)} />
                                                    {getFormErrorMessage(field.name)}   
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-1'>
                                    <div className='form-group'>
                                        <label htmlFor="password_field" className='mb-1'>Password</label>
                                        <Controller
                                            name='password'
                                            placeholder="Password"
                                            control={control}
                                            rules={{ required: 'Password is required.' }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <Password id="password_field" {...field} inputRef={field.ref} 
                                                    className={classNames({ 'p-invalid': fieldState.error },'w-full')}
                                                    toggleMask
                                                    
                                                    feedback={false} />
                                                        {getFormErrorMessage(field.name)}  
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className='col-sm-12 mt-2'>
                                    <ButtonToolbar className="justify-content-center ms-auto">
                                        < Button type="button" outline style={{ backgroundColor: "#4ce1b6" }}
                                            onClick={() => props.closeAddCreateUser()}>Cancel</Button>
                                        <Button
                                            style={{ backgroundColor: "#4ce1b6" }}
                                            outline
                                            type="submit">Save</Button>

                                    </ButtonToolbar>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>
        </>
    )
}

export default AddAdminModal;
     