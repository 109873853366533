import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import NewProject from '../NoCode/NewProject/newproject';
import { useState } from 'react';

const DeleteUIModal = (props) => {
    console.log("in DeleteUIModal COMPONENT");
    const [displayMod, setDisplayMod] = useState(props.displayUIModal);

    const handleNo = (ind) => {
        props.RemoveATab(ind,'no');
        
    };

    const handleYes = (ind) => {
        props.RemoveATab(ind);
    };

    return (
        
        <div>
            <Modal centered={true} isOpen={displayMod} style={{ "--bs-backdrop-opacity": "0.3" }}
>
                <ModalHeader className='progresBarClr text-white' style={{ textAlign: "center" }}>
                    Are you sure you want to Delete ?
                </ModalHeader>
                <ModalBody>
                    <Row className="pt-4">
                        <Col sm={12} className=" text-center">
                            {/* <button type="button" className="btn btn-success"
                                onClick={() => this.props.closeInputModal()}>
                                No
                            </button> */}
                            <button type="button" className="btn btn-success"
                                onClick={() => handleNo(props.index)}>
                                No
                            </button>
                            <button type="button" className="btn btn-success"
                                onClick={() => handleYes(props.index)}>
                                Yes
                            </button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
};
export default DeleteUIModal;