import { Fields } from "redux-form";

export const FieldTypes = [
    { value: "text", label: "Text" },
    { value: "fileUpload", label: "File Upload" },
    { value: "email", label: "Email" },
    { value: "textarea", label: "Textarea" },
    { value: "dropDown", label: "DropDown" },
    { value: "date", label: "Date" },
    { value: "checkbox", label: "Checkbox" },
    { value: "webSite", label: "WebSite" },
    { value: "uniqueField", label: "Unique Field" },
    { value: "uniqueID", label: "Unique ID" },
    { value: "encryptedField", label: "Encrypted Field" },
    { value: "relateAutoComplete", label: "RelateAutoComplete" },
    { value: "derivedFields", label: "Derived Fields" },
    { value: "multiSelect", label: "MultiSelect" },
    { value: "radio", label: "Radio" },
    { value: "number", label: "Number" },
    { value: "time", label: "Time" },
    { value: "password", label: "Password" }
];
export const Badgecolors = [
    { value: "primary", label: "primary" },
    { value: "secondary", label: "secondary" },
    { value: "success", label: "success" },
    { value: "danger", label: "danger" },
    { value: "warning", label: "warning" },
    { value: "info", label: "info" },
    { value: "light", label: "light" },
    { value: "dark", label: "dark" }
];
export const DateFormats =  [
    { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
    { value: "YYYY-MM-DD", label: "YYYY-MM-DD" },
    { value: "MM-DD-YYYY", label: "MM-DD-YYYY" },
    { value: "YYYY-MM-DD HH:mm:ss", label: "YYYY-MM-DD HH:mm:ss" },
    { value: "MM/DD/YYYY HH:mm", label: "MM/DD/YYYY HH:mm" },
];
export const TableOptions =  [
    { value: "True", label: "True" },
    { value: "false", label: "False" }
];
export const basicFormFields = [
    {
        required: true,
        value: '',
        type: 'text',
        name: 'fieldName',
        label: 'Field Name',
        id: 'fieldName',
        placeholder: 'Field Name',
        help: 'Name of the field you want to create,will allow alphabets,numbers and “_” '
    },
    {
        required: false,
        value: "",
        type: 'text',
        name: 'label',
        label: 'Label',
        id: 'label',
        placeholder: 'Label',
        help: 'Label will be automatically given based on the Name,if you want change you can change manually.It will be used as Header'
    },
    {
        required: false,
        value: "",
        type: 'text',
        name: 'placeholder',
        label: 'Placeholder',
        id: 'Placeholder',
        placeholder: 'Placeholder',
        help: 'PlaceHolder will be automatically given based on the Name,if you want change you can change manually'
    },
    {
        required: false,
        value: "",
        type: "text",
        name: 'value',
        label: 'DefaultValue',
        id: 'value',
        placeholder: 'DefaultValue',
        help: 'Default value of the field'

    },
    {
        required: false,
        value: "",
        type: "text",
        name: 'width',
        label: 'Field Width',
        id: 'width',
        placeholder: 'Width',
        help: 'This width is used to set the width of the column in the table while displaying.can be given in "px" or "%".Default will be “90px”'
    }
]
// export const basicAutocompleteFields = [
//     {
//         required: false,
//         value: "",
//         type: "text",
//         name: 'searchApi',
//         label: 'Search Api',
//         id: 'searchApi',
//         placeholder: 'search Api'
//     },
//     {
//         required: false,
//         value: "",
//         type: "text",
//         name: 'searchField',
//         label: 'search Field',
//         id: 'search Field',
//         placeholder: 'search Field'
//     },
// ];
export const basicAutocompleteFields = [
    {
        required: false,
        type: "checkbox",
        name: "isMultiple",
        label: 'Multiple Selection Required',
        id: 'multipleSelectionReq',
        help:"Check this if you want to allow the selection of multiple options"
    },
    {
        required: false,
        type: "radio",
        name: "hasDependency",
        label: 'Has Dependency',
        id: 'radio_hasDependency',
        help: 'Form field value on which this field value is dependent'
      },
  {
    required: true,
    type: "dropdown",
    name: "controllerName",
    label: 'Select Controller',
    id: 'controllerName',
    help: 'Select the required controller/screen'
  },
  {
    required: true,
    type: "dropdown",
    name: 'searchField',
    label: 'Search Field',
    id: 'search Field',
    help: 'Controller field with which you wish to search'
  },
  {
    required: false,
    type: "multiselect",
    name: "displayFields",
    label: 'Select Display Fields',
    id: 'displayFields',
    help: 'Fields to display'
  },
  {
    required: true,
    type: "dropdown",
    name: 'hasDependencyField',
    label: 'Has dependency Field',
    id: 'Has dependency Field',
    help:'Form Field with which the current field being created has a dependency',
    sh:false
  },
  {
    required: true,
    type: "dropdown",
    name: "fieldForKey",
    label: 'Field for Key',
    id: 'Field for Key',
    help: 'Form Field with which the current field being created has a dependency',
    sh:false
  }
]
export const Methods = [
    { value: "SUM", label: "Sum" }, { value: "MULTIPLE", label: "Multiple" }, { value: "AVG", label: "Average" }, { value: "MAX", label: "Max" }, { value: "MIN", label: "Min" }
]

export const normalFields = [
    {
        required: true,
        value: '',
        type: 'text',
        name: 'fieldName',
        label: 'Field Name',
        id: 'fieldName',
        placeholder: 'Field Name',
        help: 'Name of the field you want to create,will allow alphabets,numbers and “_” '
    },
    {
        required: false,
        value: "",
        type: 'text',
        name: 'label',
        label: 'Label',
        id: 'label',
        placeholder: 'Label',
        help: 'Label will be automatically given based on the Name,if you want change you can change manually.It will be used as Header'
    },
    {
        required: false,
        value: "",
        type: 'text',
        name: 'placeholder',
        label: 'Placeholder',
        id: 'Placeholder',
        placeholder: 'Placeholder',
        help: 'PlaceHolder will be automatically given based on the Name,if you want change you can change manually'
    },
    {
        required: false,
        value: "",
        type: "text",
        name: 'value',
        label: 'DefaultValue',
        id: 'value',
        placeholder: 'DefaultValue',
        help: 'Default value of the field'

    },
    {
        required: false,
        value: "",
        type: "text",
        name: 'width',
        label: 'Field Width',
        id: 'width',
        placeholder: 'Width',
        help: 'This width is used to set the width of the column in the table while displaying.can be given in "px" or "%".Default will be “90px”'
    },
     {
        required: false,
        type: "number",
        name: 'maxFileSize',
        value: "",
        label: 'Max File Size',
        id: 'maxFileSize_id',
        placeholder: 'Max File Size',
        help: "Maximum filesize for the uploded files, provide expected filesize in MB's"
    },
    {
        required: true,
        type: "number",
        name: 'addFormOrder',
        value: "",
        label: 'Add Form Order',
        id: 'addFormOrder_id',
        placeholder: 'Add Form Order',
        help: 'To display in which position this field will be placed in Add Form'
    },
    {
        required: true,
        type: "number",
        name: 'editFormOrder',
        value: "",
        label: 'Edit Form Order',
        id: 'editFormOrder_id',
        placeholder: 'Edit Form Order',
        help: 'To display in which position this field will be placed in Edit Form'
    },
  
];
export const radioFields = [
    {
        required: false,
        type: "radio",
        name: 'displayinaddForm',
        value: "",
        label: 'Display In Add Form',
        id: 'radio_DIAF',
        help: 'If you want the field in the Add form select true if not select false'
    },
    {
        required: false,
        type: "radio",
        name: 'displayineditForm',
        value: "",
        label: 'Display In Edit Form',
        id: 'radio_DIEF',
        help: 'If you want the field in the Edit form select true if not select false'
    },
    {
        required: false,
        type: "radio",
        name: 'displayinlist',
        value: "",
        label: 'Display In List',
        id: 'radio_DIL',
        help: 'If you want the field in the table List select true if not select false.'
    },
    {
        required: false,
        type: "radio",
        name: 'isFieldRequired',
        value: "",
        label: 'Required Field',
        id: 'radio_RF',
        help: 'In the add or edit form if you want the field to be required field select true if not select false.'
    },
    {
        required: false,
        type: "radio",
        name: 'globalSearchField',
        value: "",
        label: 'Global Search',
        id: 'radio_GS',
        help: 'If you want the field to be searchable in the Global search select true if not false.'
    },
    {
        required: false,
        type: "radio",
        name: 'isAddToIndex',
        value: "",
        label: 'Add To Index',
        id: 'radio_IID',
        help: 'If you want the field to be searchable in the Global search select true if not false.'
    },
    {
        required: false,
        type: "radio",
        name: 'isBulkUploadField',
        value: "",
        label: 'Bulk Upload',
        id: 'radio_BU',
        help: 'If you want the field to be uploaded in Bulk select true if not false.'
    },
    {
        required: false,
        type: "radio",
        name: 'displayinregisterForm',
        value: "",
        label: 'Display In Register Form',
        id: 'radio_DIRG',
        help: 'If you want the field in the Register form select true if not select false.'
    },
    {
        required: false,
        type: "radio",
        name: 'isMultipleRequired',
        value: "",
        label: 'Multiple Required',
        id: 'radio_MR',
        help: 'If you want multiple file uploads select true if not select false.'
    },
];
export const checkBoxFields = [
    {
        required: false,
        type: "checkbox",
        name: 'capitalizeTableText',
        value: "",
        label: 'Capitalize the Table Text',
        id: 'checkbox_CTT',
        help: 'Capitalize the first letter in the text which is displayed in the table.'
    },
    {
        required: false,
        type: "checkbox",
        name: 'sortable',
        value: "",
        label: 'Sorting for Field',
        id: 'checkbox_SF',
        help: 'If you want the sorting option in the table for this field check this option'
    },
    {
        required: false,
        type: "checkbox",
        name: 'filter',
        value: "",
        label: 'Filter Required',
        id: 'checkbox_FR',
        help: 'If you want an inline search filter in the datatable for this particular field column check this option.'
    },
    {
        required: false,
        type: "checkbox",
        name: 'isClickable',
        value: "",
        label: 'Clickable Field',
        id: 'checkbox_CL',
        help: 'If you want this field to be clickable in the table to view the record data'
    },
    {
        required: false,
        type: "checkbox",
        name: 'displayOptionsInActions',
        value: "",
        label: 'Display Options In Actions',
        id: 'checkbox_DOIA',
        help: 'Check the box if you want the options appearing in the Actions dropdown'
    },
]
export const passwordFields = [
    {
        required: true,
        value: '',
        type: 'text',
        name: 'fieldName',
        label: 'Field Name',
        id: 'fieldName',
        placeholder: 'Field Name',
        help: 'Name of the field you want to create,will allow alphabets,numbers and “_” '
    },
    {
        required: false,
        value: "",
        type: 'text',
        name: 'label',
        label: 'Label',
        id: 'label',
        placeholder: 'Label',
        help: 'Label will be automatically given based on the Name,if you want change you can change manually.it will be used as Header'
    },
    {
        required: false,
        value: "",
        type: 'text',
        name: 'placeholder',
        label: 'Placeholder',
        id: 'Placeholder',
        placeholder: 'Placeholder',
        help: 'PlaceHolder will be automatically given based on the Name,if you want change you can change manually'
    },
    {
        required: false,
        type: "radio",
        name: 'displayinaddForm',
        value: "",
        label: 'Display In Add Form',
        id: 'radio_DIAF',
        help: 'If you want the field in the Add form select true if not select false'
    },
    {
        required: false,
        type: "radio",
        name: 'isFieldRequired',
        value: "",
        label: 'Required Field',
        id: 'radio_RF',
        help: 'In the add or edit form if you want the field to be required field select true if not select false.'
    },
]
export const fileTypeField = [
    {
        required: false,
        type: "radio",
        name: 'fileType',
        value: true,
        label: 'File Type',
        options: [
            { value: 'img/video', label: "Img/Video" },
            { value: 'other', label: "Other's" }
        ],
        id: 'radio_FT',
        help: 'Please select which file types you wanted to upload'
    }
]
export const defaultCheckBox = [
    {
        required: false,
        value: "",
        type: "radio",
        name: 'value',
        label: 'DefaultValue',
        options: [
            { value: true, label: "Checked" },
            { value: false, label: "UnChecked" }
        ],
        id: 'value',
        help: 'Default value of the field'
    }
]
