import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// config file
class DeleteRowModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    deleteSelectedRow = () => {
        this.props.deleteSelectedRow();
    }

    render() {
        const { t, confirmModalText } = this.props;
        return (
            <div>
                <Modal isOpen={this.props.openDeleteModal}
                    className='modal-dialog-centered modal-dialog--primary m-auto'
                >
                    <ModalBody className='deleteModalBody' style={{ margin: "auto", }}>
                        <div style={{ marginBottom: 10 }}>{t(confirmModalText)}?</div>
                        <Button color="primary" outline
                            onClick={this.props.closeDeleteModal}
                            className='deleteModalBtn marginRight'>{t('No')}</Button>
                        <Button color="primary" outline onClick={this.deleteSelectedRow}
                            className='deleteModalBtn'>{t('Yes')}</Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation('common')(DeleteRowModal);
