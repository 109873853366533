import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Captcha from './Captcha';



class ForgotStaticForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
        }

    }
    render() {
        return (
            <div>                
                <div className='text-center h5 pb-2 font-weight-bold'>
                    Forgot Password
                </div>
                <form>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Enter Registered Email Address</label>
                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" />

                    </div>
                    {this.state.captcha || this.props.googleCaptcha ?
                        <div className='my-2'>
                            <Captcha></Captcha>
                        </div>
                        : null
                    }
                    <button type="submit" class="btn btn-sm btn-custom btn-block" 
                    style={{
                        backgroundColor: (this.props.buttonType === 'regular' ? this.props.buttonColour : 'transparent'),
                        color: (this.props.buttonType === 'regular' ? this.props.buttonTextColour : this.props.buttonColour),
                         borderColor: this.props.buttonColour,
                        borderRadius: (this.props.buttonRounded ? '20px' : '4px')                        
                    }}
                    >Submit</button>
                </form>
            </div>
        )
    }
}
export default withTranslation('common')(ForgotStaticForm);