import React from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Collapse } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import SelectDropdown from "react-select";

//Components
import DefaultInput from "../../../shared/components/form/DefaultInput";
import CheckBox from '../../../shared/components/form/CheckBox';
import Select from '../../../shared/components/form/Select';
import config from '../../../config/config';
import FileInput from '../../../shared/components/form/FileInput'
import MultiSelect from '../../../shared/components/form/MultiSelectDropDown';
import RadioInput from '../../../shared/components/form/RadioButton';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          height: 47,
          top: -2,
        },
        "&$focused $notchedOutline": {
          borderColor: config.floatingFieldsColor,
        },
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: config.floatingFieldsColor,
          fontWeight: '500'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$contained": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        "&$marginDense": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        margin: 0,
        marginTop: 0,
      }
    },
  }
});

class NewProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controllers: [],
      showFilterElement: false,
      selectdFilterIndex: 0,
      controllerFields: [],
      selectedControllerIndex: 0,
      selectedServerControllerIndex: 0,
      selectedFormIndex: 0,
      selectedControllerCheckBoxIndex: -1,
      showApiUrlDropdown: false,
      typeOptions: [
        { value: "center", label: "center" },
        { value: "left", label: "left" },
        { value: "right", label: "right" },
      ],
      fieldTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "dropDown", label: "dropDown" },
        { value: "date", label: "date" },
        { value: "checkbox", label: "checkbox" },
        { value: "autoComplete", label: "autoComplete" },
        { value: "multiSelect", label: "multiSelect" },
        { value: "radio", label: "radio" },
      ],
      fieldProfileTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "date", label: "date" },
        { value: "state", label: "state" },
      ],
      fieldProfileType: [
        { value: "email", label: "email" },
        { value: "fullName", label: "fullName" },
        { value: "lastName", label: "lastName" },
        { value: "dateOfBirth", label: "dateOfBirth" },
        { value: "address", label: "address" },
      ],
      actionsType: [
        { value: "add", label: "add" },
        { value: "edit", label: "edit" },
        { value: "delete", label: "delete" },
        { value: "view", label: "view" },
      ],
      selectedProfileIndex: 0,
      showProfileTypeDropdown: false,
      showControllers: false,
      apiUrlTypes: [],
      loginCheck: false,
      uploadCheck: false,
      jsonDownloadLink: '#',
      tabOneActive: true,
      tabTwoActive: false,
      tabThreeActive: false,
      activeOneTab: true,
      activeTwoTab: false,
      activeThreeTab: false,
      setMainTab: 'one',
      projectFileValue: '',
      emailService: "no",
      mailServices: [
        { value: 1, label: "Node Mailer" },
        { value: 2, label: "Send Grid" },
      ],
      selectedMailService: null,
      controllerCol: false,
      emailServiceCol: false,
      selectedControllerIndex: 0,
      selectedControllerCheckBoxIndex: -1,
      typeServerOptions: [
        { value: "String", label: "String" },
        { value: "Boolean", label: "Boolean" },
        { value: "Number", label: "Number" },
      ],
      actionsServerType: [
        { value: "get", label: "get" },
        { value: "list", label: "list" },
        { value: "update", label: "update" },
        { value: "create", label: "create" },
        { value: "remove", label: "remove" },
      ],
      serverControler: [],
      sampleFileJson: 'http://nocode.api.dosystemsinc.com/json/reactServer',
      showSampleFile: false,
      favIconState: false,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }

  showErrorForApiUrl = async () => {
    await this.setState({ showApiUrlDropdown: !this.state.showApiUrlDropdown })
  }

  //emptyController
  emptyController = async () => {
    await this.setState({
      controllers: [],
      controllerFields: [],
      showControllers: false,
      loginCheck: false,
      selectdFilterIndex: 0,
      selectedProfileIndex: 0,
      showProfileTypeDropdown: false,
      showFilterElement: false,
      selectedControllerIndex: 0,
      selectedFormIndex: 0,
      uploadCheck: false,
      favIconState: false,
    })
  }

  //addFields
  addControllersFields = async (type, e) => {
    this.setState({ showProfileTypeDropdown: false })
    let { selectedControllerIndex, controllers } = this.state;
    let controllerData = controllers;
    if (type === 'table') {
      let controllerFields =
        controllers[selectedControllerIndex].controllerFields;
      let controllerFieldsLength = controllerFields.length;
      controllerFields.push({
        textAlign: `controller_${selectedControllerIndex +
          1}.textAlign${controllerFieldsLength + 1}`,
        width: `controller_${selectedControllerIndex +
          1}.width${controllerFieldsLength + 1}`,
        field: `controller_${selectedControllerIndex +
          1}.field${controllerFieldsLength + 1}`,
        header: `controller_${selectedControllerIndex +
          1}.header${controllerFieldsLength + 1}`,
        filter: `controller_${selectedControllerIndex +
          1}.filter${controllerFieldsLength + 1}`,
        sortable: `controller_${selectedControllerIndex +
          1}.sortable${controllerFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFields = controllerFields;
      await this.setState({
        selectdFilterIndex: controllerFields.length && controllerFields.length !== 1 ?
          controllerFields.length - 1 : 0,
        controllers: controllerData,
        showFilterElement: false,
      });
    } else if (type === 'form') {
      let controllerFormFields =
        controllers[selectedControllerIndex].controllerFormFields;
      let controllerFormFieldsLength = controllerFormFields.length;
      controllerFormFields.push({
        value: `controller_${selectedControllerIndex +
          1}.value${controllerFormFieldsLength + 1}`,
        type: `controller_${selectedControllerIndex +
          1}.type${controllerFormFieldsLength + 1}`,
        name: `controller_${selectedControllerIndex +
          1}.name${controllerFormFieldsLength + 1}`,
        label: `controller_${selectedControllerIndex +
          1}.label${controllerFormFieldsLength + 1}`,
        id: `controller_${selectedControllerIndex +
          1}.id${controllerFormFieldsLength + 1}`,
        placeholder: `controller_${selectedControllerIndex +
          1}.placeholder${controllerFormFieldsLength + 1}`,
        required: `controller_${selectedControllerIndex +
          1}.required${controllerFormFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFormFields = controllerFormFields;

      await this.setState({
        selectedFormIndex: controllerFormFields.length && controllerFormFields.length !== 1 ?
          controllerFormFields.length - 1 : 0,
        controllers: controllerData,
      });
    } else if (type === 'controller') {
      controllers.push({
        controllerFields: [],
        controllerFormFields: [],
      });

      await this.setState({
        controllers: controllers,
        selectedControllerIndex: controllers.length && controllers.length !== 1 ?
          controllers.length - 1 : 0,
        showProfileTypeDropdown: false,
        tabThreeActive: true,
        tabOneActive: false,
        activeThreeTab: true,
        setMainTab: 'three'
      });
      await this.props.changeprogressWidth(80)
    } else if (type === 'profile') {
      let controllerProfileFields = controllerData && controllerData.length &&
        controllerData[0]['controllerProfileFields'] ? controllerData[0]['controllerProfileFields'] : [];
      let controllerProfileFieldsLength = controllerProfileFields.length ? controllerProfileFields.length : 0;
      let tempOptions = this.state.fieldProfileType;
      let flag = false;
      for (let j = 0; j < controllerProfileFields.length; j++) {
        if (controllerProfileFields[j]["profileName"] === e) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        controllerProfileFields.push({
          value: `controllerprofile_${0 +
            1}.value${controllerProfileFieldsLength + 1}`,
          type: `controllerprofile_${0 +
            1}.type${controllerProfileFieldsLength + 1}`,
          name: `controllerprofile_${0 +
            1}.name${controllerProfileFieldsLength + 1}`,
          label: `controllerprofile_${0 +
            1}.label${controllerProfileFieldsLength + 1}`,
          id: `controllerprofile_${0 +
            1}.id${controllerProfileFieldsLength + 1}`,
          placeholder: `controllerprofile_${0 +
            1}.placeholder${controllerProfileFieldsLength + 1}`,
          required: `controllerprofile_${0 +
            1}.required${controllerProfileFieldsLength + 1}`,
          [`name${controllerProfileFieldsLength == 0 ? 0 : controllerProfileFieldsLength == 1 ? controllerProfileFieldsLength : controllerProfileFieldsLength}`]: e,
          [`profileName`]: e,
        });
        if (controllerData && controllerData.length) {
          controllerData[0]['controllerProfileFields'] = controllerProfileFields;
        } else {
          controllerData.push({ 'controllerProfileFields': '' })
          controllerData[0]['controllerProfileFields'] = controllerProfileFields;
        }
        this.setState({
          selectedProfileIndex: controllerProfileFields.length && controllerProfileFields.length !== 1 ?
            controllerProfileFields.length - 1 : 0,
          controllers: controllerData,
          showProfileTypeDropdown: false,
          fieldProfileType: tempOptions
        });
      } else {
        this.setState({
          showProfileTypeDropdown: true,
        });
      }
      if (this.props.yesJsonUploaded) {
        await this.props.changeprogressWidth(100)
      }
    }
  };

  //addFilterElement
  addFilterElement = async (index, type) => {
    let { selectedControllerIndex, controllers } = this.state;
    let controllerData = controllers;
    if (type === 'filter') {
      let filterElementFields =
        controllers[selectedControllerIndex].controllerFields[index].filterElement ?
          controllers[selectedControllerIndex].controllerFields[index].filterElement :
          [];
      let filterElementFieldsLength = filterElementFields.length;
      filterElementFields.push({
        label: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.label${filterElementFieldsLength + 1}`,
        value: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.value${filterElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFields[index].filterElement = filterElementFields;
      await this.setState({ selectdFilterIndex: index, controllers: controllerData, });
    } else if (type === 'options') {
      let optionsElementFields =
        controllers[selectedControllerIndex].controllerFormFields[index].options ?
          controllers[selectedControllerIndex].controllerFormFields[index].options :
          [];
      let optionsElementFieldsLength = optionsElementFields.length;
      optionsElementFields.push({
        label: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.label${optionsElementFieldsLength + 1}`,
        value: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.value${optionsElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFormFields[index].options = optionsElementFields;
      await this.setState({ selectedFormIndex: index, controllers: controllerData, });
    }
  }

  //returnController
  returnControllerArray() {

    return {
      reactControllers: this.state.controllers,
      serverControllers: this.state.serverControler
    };
  }

  //changeControllerName
  changeContrllerName = async (e, selectedControllerIndex) => {
    let controllers = this.state.controllers;
    let tempUrlTypes = [];
    if (e && e.target.value) {
      controllers[selectedControllerIndex].name = e.target.value
      for (let i = 0; i < controllers.length; i++) {
        Object.keys(controllers[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers[i].name, value: controllers[i].name })
          }
        }
        )
      }
      await this.setState({
        controllers: this.state.controllers,
        apiUrlTypes: tempUrlTypes
      })
    } else {
      let i = selectedControllerIndex;
      i += 1;
      controllers[selectedControllerIndex].name = ''
      await this.setState({ controllers: this.state.controllers })
    }
  }
  //change controller name
  changeServerContrllerName = async (e, selectedControllerIndex) => {
    let controllers = this.state.serverControler;
    if (e && e.target.value) {
      controllers[selectedControllerIndex].name = e.target.value
      await this.setState({ serverControler: controllers })
    } else {
      let i = selectedControllerIndex;
      i += 1;
      controllers[selectedControllerIndex].name = ''
      await this.setState({ serverControler: this.state.serverControler })
    }
  }

  //handleControllerTableFields
  handleControllerFields = async (e, index, fieldName, type, fieldsType, name) => {
    let { selectedControllerIndex, controllers } = this.state;
    if (fieldsType === 'table') {
      if (type === 'checkbox') {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e;
        controllers[selectedControllerIndex]["controllerFields"][index][name] = e;
        await this.setState({ controllers: controllers })
        if (e == true && fieldName == `filter${index}`) {
          let filterElementFields =
            controllers[selectedControllerIndex].controllerFields[index].filterElement ?
              controllers[selectedControllerIndex].controllerFields[index].filterElement :
              [];
          let filterElementFieldsLength = filterElementFields.length;
          filterElementFields.push({
            label: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.label${filterElementFieldsLength + 1}`,
            value: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.value${filterElementFieldsLength + 1}`,
          })
          controllers[selectedControllerIndex].controllerFields[index].filterElement = filterElementFields;
          await this.setState({ selectdFilterIndex: index, controllers: controllers, });
        } else if (e == false && fieldName == `filter${index}`) {
          controllers[selectedControllerIndex]["controllerFields"][index]["filterElement"] = []
          await this.setState({ showFilterElement: false, controllers: controllers })
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e.target.value;
          controllers[selectedControllerIndex]["controllerFields"][index][name] = e.target.value;
          await this.setState({ controllers: controllers })
        } else {
          controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = '';
          controllers[selectedControllerIndex]["controllerFields"][index][name] = '';
          await this.setState({ controllers: controllers })
        }
      }
    } else if (fieldsType === 'form') {
      if (type === 'checkbox') {
        if (e === 'dropDown' || e === 'radio') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e === 'autoComplete') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index]['options'];
        } else if (e === 'multiSelect') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = 'dropDown';
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`] = true;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e && `type${index}` === fieldName) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index]['options'];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e.target.value;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e.target.value;
        } else {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = '';
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = '';
        }
      }
      await this.setState({ controllers: controllers })
    } else if (fieldsType === 'profile') {
      if (type === 'checkbox') {
        if (e) {
          controllers[0]["controllerProfileFields"][index][fieldName] = e;
          controllers[0]["controllerProfileFields"][index][name] = e;
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[0]["controllerProfileFields"][index][fieldName] = e.target.value;
          controllers[0]["controllerProfileFields"][index][name] = e.target.value;
        } else {
          controllers[0]["controllerProfileFields"][index][fieldName] = '';
          controllers[0]["controllerProfileFields"][index][name] = '';
        }
      }
      await this.setState({ controllers: controllers })
    }
  }

  //handleactions
  handleControllerActions = async (e, fieldName) => {
    let controllers = this.state.controllers;
    if (fieldName === 'actions') {
      if (e) {
        controllers[this.state.selectedControllerIndex][fieldName] = e

      } else {
        controllers[this.state.selectedControllerIndex][fieldName] = e
      }
    } else if ('login') {
      controllers[this.state.selectedControllerIndex][fieldName] = e
    }
    await this.setState({ controllers: controllers })
  }

  //handleactions
  handleControllerServerActions = async (e, fieldName) => {
    let controllers = this.state.serverControler;
    if (fieldName === 'methods') {
      if (e) {
        controllers[this.state.selectedServerControllerIndex][fieldName] = e

      } else {
        controllers[this.state.selectedServerControllerIndex][fieldName] = e
      }
    }
    await this.setState({ serverControler: controllers })
  }

  //handleControllerFilterFields
  handleControllerFilterFields = async (e, index, fieldName, filterIndex, type, name) => {
    let controllerData = this.state.controllers;
    if (type === 'table') {
      if (e && e.target.value) {
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][fieldName] = e.target.value;
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][name] = e.target.value;
      } else {
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][fieldName] = '';
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][name] = '';
      }
    } else if (type === 'form') {
      if (e && e.target.value) {
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][fieldName] = e.target.value;
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][name] = e.target.value;
      } else {
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][fieldName] = '';
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][name] = '';
      }
    }
    await this.setState({ controllers: controllerData })
  }

  //removeFormFields
  removeFormFields = async (index, type) => {
    let controllers = this.state.controllers;
    if (type === 'table') {
      controllers[this.state.selectedControllerIndex]["controllerFields"].splice(index, 1);
      await this.setState({
        selectdFilterIndex:
          index > 0 ? index - 1 : 0,
      })
    } else if (type === 'form') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"].splice(index, 1);
      await this.setState({
        selectedFormIndex:
          index > 0 ? index - 1 : 0,
      })
    } else if (type === 'profile') {
      controllers[0]["controllerProfileFields"].splice(index, 1);
      await this.setState({
        showProfileTypeDropdown: false,
      })
    } else if (type === 'controller') {
      let tempUrlTypes = [];
      controllers.splice(index, 1);
      for (let i = 0; i < controllers.length; i++) {
        Object.keys(controllers[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers[i].name, value: controllers[i].name })
          }
        }
        )
      }
      if (index === 0) {
        await this.setState({
          selectedControllerIndex: 0,
          apiUrlTypes: tempUrlTypes
        })
      } else {
        await this.setState({
          selectedControllerIndex: index - 1,
          apiUrlTypes: tempUrlTypes
        })
      }
    }
    await this.setState({
      controllers: controllers,
    })
  }

  //activeTabs
  activeTabs = async (index, fieldName) => {
    let controllers = this.state.controllers;
    let valueType = controllers[this.state.selectedControllerIndex]["controllerFields"][index][fieldName] ? controllers[this.state.selectedControllerIndex]["controllerFields"][index][fieldName] : '';
    if (valueType) {
      if (valueType == false) {
        await this.setState({ showFilterElement: false, selectdFilterIndex: index })
      } else {
        await this.setState({ showFilterElement: true, selectdFilterIndex: index })
      }
    } else {
      await this.setState({ showFilterElement: false, selectdFilterIndex: index })
    }
  }

  //uploadValidate
  uploadExits = async () => {
    await this.setState({ uploadCheck: true })
    // this.handleProgressBarWidth();
  }


  handleTabs = async (type) => {
    if (type === 'one') {
      await this.setState({
        setMainTab: 'one',
        tabOneActive: true,
        tabTwoActive: false,
        tabThreeActive: false
      })
    } else if (type === 'two') {
      await this.setState({
        setMainTab: 'two',
        tabOneActive: false,
        tabTwoActive: true,
        tabThreeActive: false
      })
    } else if (type === 'three') {
      await this.setState({
        setMainTab: 'three',
        tabOneActive: false,
        tabTwoActive: true,
        tabThreeActive: true
      })
    }
  }

  //add controllers
  addControllersServer = async (type) => {
    if (type === 'controller') {
      let controllers = this.state.serverControler;
      controllers.push({
        controllerFields: [],
      });
      await this.setState({
        serverControler: controllers,
        emailServiceCol: false,
        controllerCol: true,
        tabOneActive: false,
        tabTwoActive: true,
        tabThreeActive: true,
        activeTwoTab: true,
        setMainTab: 'three',
        activeOneTab: false,
        selectedServerControllerIndex: controllers.length && controllers.length !== 1 ?
          controllers.length - 1 : 0
      });
      await this.props.changeprogressWidth(100)
    } else if (type === 'fields') {
      let { selectedServerControllerIndex, serverControler } = this.state;
      let controllerData = serverControler;
      let controllerFields =
        serverControler[selectedServerControllerIndex].controllerFields;
      let controllerFieldsLength = controllerFields.length;
      controllerFields.push({
        fieldName: `controllerserver_${selectedServerControllerIndex +
          1}.fieldName${controllerFieldsLength + 1}`,
        type: `controllerserver_${selectedServerControllerIndex +
          1}.type${controllerFieldsLength + 1}`,
      });
      controllerData[selectedServerControllerIndex].controllerFields = controllerFields;
      this.setState({
        serverControler: controllerData,
        tabThreeActive: true,
        tabTwoActive: true,
        tabOneActive: true,
        activeThreeTab: true,
        setMainTab: 'three'
      });
    }
  };

  //remove controller
  removeServerController = async (index, type) => {
    if (type === 'controller') {
      let controllers = this.state.serverControler;
      controllers.splice(index, 1);
      if (index === 0) {
        await this.setState({
          serverControler: controllers,
          selectedServerControllerIndex: 0
        })
      } else {
        await this.setState({
          serverControler: controllers,
          selectedServerControllerIndex: index - 1
        })
      }
    } else if (type === 'fields') {
      let controllers = this.state.serverControler;
      controllers[this.state.selectedServerControllerIndex]["controllerFields"].splice(index, 1);
      await this.setState({
        serverControler: controllers,
      })
    }
  }


  //handleControllerTableFields
  handleControllerServerFields = async (e, index, fieldName, type, name) => {
    let { selectedServerControllerIndex, serverControler } = this.state;
    if (type === 'radio') {
      if (e == false) {
        delete serverControler[selectedServerControllerIndex]['entity'];
        delete serverControler[selectedServerControllerIndex]['entityValue'];
      }
      serverControler[selectedServerControllerIndex][fieldName] = e;
    } else if (type === 'entity') {
      if (e && e.target.value) {
        serverControler[selectedServerControllerIndex][fieldName] = e.target.value;
        serverControler[selectedServerControllerIndex][name] = e.target.value;
      } else {
        serverControler[selectedServerControllerIndex][fieldName] = '';
        serverControler[selectedServerControllerIndex][name] = '';
      }
    } else if (type === 'input') {
      if (e && e.target.value) {
        serverControler[selectedServerControllerIndex]["controllerFields"][index][fieldName] = e.target.value;
        serverControler[selectedServerControllerIndex]["controllerFields"][index][name] = e.target.value;
      } else {
        serverControler[selectedServerControllerIndex]["controllerFields"][index][fieldName] = '';
        serverControler[selectedServerControllerIndex]["controllerFields"][index][name] = '';
      }
    } else if (type === 'checkbox') {
      serverControler[selectedServerControllerIndex]["controllerFields"][index][fieldName] = e;
      serverControler[selectedServerControllerIndex]["controllerFields"][index][name] = e;
    }
    await this.setState({ serverControler: serverControler })
  }

  //handleProgressBarWidth
  handleProgressBarWidth = async () => {
    await this.setState({
      loginCheck: true,
      tabOneActive: false,
      tabTwoActive: true,
      activeTwoTab: true,
      setMainTab: 'two',
    })
    if (this.props.yesJsonUploaded) {
      await this.props.changeprogressWidth(80)
    } else {
      await this.props.changeprogressWidth(60)
    }
  }

  //handle showControlers 
  handleShowControllers = async () => {
    await this.setState({ showControllers: true })
  }

  //toggleUploadSample
  toggleUploadSample = async () => {
    await this.setState({ showSampleFile: !this.state.showSampleFile })
    if (this.props.yesJsonUploaded) {
      this.handleShowControllers();
    }
  }
  //removeFilterFields
  removeFilterFields = async (index, type, filterIndex) => {
    let controllers = this.state.controllers;
    if (type == 'tableFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'].splice(filterIndex, 1);
    } else if (type == 'formFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'].splice(filterIndex, 1);
    }
    await this.setState({ controllers: controllers })
  }

  //favIconSubmitted
  favIconSubmitted = async () => {
    await this.setState({ favIconState: true })
    if (this.props.yesJsonUploaded) {
      await this.setState({ showControllers: true })
    }
    this.handleProgressBarWidth();
  }

  //handleyesExistingProject
  handleExistingProject = (e) => {
    if (e && e.target.value) {
      this.setState({ showControllers: true })
      this.props.handleyesExistingProject(true);
    } else {
      this.setState({ showControllers: false })
      this.props.handleyesExistingProject(false);
    }
  }
  render() {

    const {
      controllers,
      selectedControllerIndex,
      typeOptions,
      fieldTypeOptions,
      fieldProfileTypeOptions,
      fieldProfileType,
      actionsType,
      apiUrlTypes,
      tabOneActive,
      tabTwoActive,
      tabThreeActive,
      activeOneTab,
      activeThreeTab,
      activeTwoTab,
      setMainTab,
      emailService,
      emailServiceCol,
      mailServices,
      selectedMailService,
      controllerCol,
      typeServerOptions,
      actionsServerType,
      serverControler,
      showSampleFile,
      selectedServerControllerIndex
    } = this.state;
    let controllerFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFields"] &&
        controllers[selectedControllerIndex]["controllerFields"].length
        ? controllers[selectedControllerIndex]["controllerFields"]
        : [];
    let controllerProfileFields =
      controllers &&
        controllers.length &&
        controllers[0] &&
        controllers[0]["controllerProfileFields"] &&
        controllers[0]["controllerProfileFields"].length
        ? controllers[0]["controllerProfileFields"]
        : [];
    let controllerFormFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFormFields"] &&
        controllers[selectedControllerIndex]["controllerFormFields"].length
        ? controllers[selectedControllerIndex]["controllerFormFields"]
        : [];
    let controllerServerFieldsArray =
      serverControler &&
        serverControler.length &&
        serverControler[selectedServerControllerIndex] &&
        serverControler[selectedServerControllerIndex]["controllerFields"] &&
        serverControler[selectedServerControllerIndex]["controllerFields"].length
        ? serverControler[selectedServerControllerIndex]["controllerFields"]
        : [];
    return (
      <Row>
        <Col sm={12}>
          <div >
            <h4 className='ps-2 addIcon pb-2 float-right'>
              < FontAwesomeIcon
                className='editIcon text-info'
                color='#ff00008c'
                icon='upload'
                data-toggle="tool-tip"
                title="Upload Json File"
                onClick={this.toggleUploadSample}
              />
            </h4>
            <Collapse isOpen={showSampleFile}>

              <h5 className='pt-3 pb-3'>Sample File <a href={this.state.sampleFileJson} download target="_blank" >
                <span className='ps-2 addIcon'>
                  < FontAwesomeIcon
                    className='editIcon text-info'
                    color='#ff00008c'
                    icon='download'
                    data-toggle="tool-tip"
                    title="Sample File"
                  />
                </span>
              </a> </h5>
              <Field
                name="jsonFile"
                label={'Upload Json File'}
                component={FileInput}
                type="text"
                placeholder="eg:var/www/html"
                className="form-control  d-none"
                onChange={this.props.jsonUploadFile}
                accept={'.json'}
              />
              <h5 className=' text-secondary mb-3 mt-2'>
                Note: If you upload JSON file please fill the fields which displays below.</h5>
              <hr></hr>
            </Collapse>
            {showSampleFile && <h4 className='text-center mb-2 '> OR</h4>}
          </div>
        </Col>
        <Col sm={12} >
          <div className='row'>
            <div className='col-12  col-lg-12 d-flex tabCenter'>
              <div className={setMainTab == 'one' ? 'tabRootFirst' : 'setMainTabFirst'} role='button' onClick={() => this.handleTabs('one')}>
                <h4 className='text-white'>
                  1
                    </h4>
              </div>
              <div className="progress progressCustom">
                <div className="progress-bar progresBarClr" role="progressbar"
                  style={this.state.showControllers && this.state.uploadCheck &&
                    this.state.favIconState &&
                    this.state.loginCheck ? { width: '100%' } : { width: '0' }}
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className={this.state.showControllers &&
                this.state.loginCheck && this.state.uploadCheck && this.state.favIconState &&
                activeTwoTab ? (setMainTab == 'two' ? 'tabRootFirst' : 'setMainTabFirst') : ' bg-inactiveTab'} role='button'
                onClick={() => this.state.showControllers &&
                  this.state.loginCheck && this.state.uploadCheck && this.state.favIconState && activeTwoTab && this.handleTabs('two')}>
                <h4 className='text-white'>
                  2
                    </h4>
              </div>
              <div className="progress progressCustom">
                <div className="progress-bar progresBarClr" role="progressbar"
                  style={
                    this.state.showControllers &&
                      this.state.loginCheck && this.state.favIconState &&
                      activeThreeTab ? { width: '100%' } : { width: '0' }}
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className={this.state.showControllers &&
                this.state.loginCheck && this.state.uploadCheck && this.state.favIconState &&
                activeThreeTab ? (setMainTab == 'three' ? 'tabRootFirst' : 'setMainTabFirst') : ' bg-inactiveTab'} role='button'
                onClick={() => this.state.showControllers &&
                  this.state.loginCheck && this.state.uploadCheck && this.state.favIconState && activeThreeTab && this.handleTabs('three')}>
                <h4 className='text-white'>
                  3
                    </h4>
              </div>
            </div>
          </div>
          {tabOneActive && <div>
            <div>
              <div className='row pt-3'>
                {!this.props.yesJsonUploaded && <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                  <label className='labelMargin'>May I have your Project Name?</label>
                  <Field
                    name="projectName"
                    component={DefaultInput}
                    type="text"
                    placeholder="Project Name"
                    className="form-control"
                    onChange={(e) => this.handleExistingProject(e)}
                  />
                </div>}
                <div className='col-12 col-sm-12 col-md-6 col-lg-4 logoStyle pt-4'>
                  <Field
                    name="projectFile"
                    label={'Upload Project Logo'}
                    component={FileInput}
                    type="text"
                    placeholder="eg:var/www/html"
                    className="form-control  d-none"
                    onChange={this.props.projectFile}
                    accept={'.jpg,.jpeg,.png'}
                  />
                </div>
                <div className='col-12 col-sm-12 col-md-6 col-lg-4 logoStyle pt-4'>
                  <Field
                    name="favicon"
                    label={'Upload Favicon'}
                    component={FileInput}
                    type="text"
                    placeholder="eg:var/www/html"
                    className="form-control  d-none"
                    onChange={this.props.projectFav}
                    accept={'.jpg,.jpeg,.png,.ico'}
                  />
                </div>
              </div>
            </div>
          </div>}
          {this.state.showControllers && this.state.loginCheck && this.state.favIconState && this.state.uploadCheck ?
            <div className='pt-4'>
              {!this.props.yesJsonUploaded &&
                <div className='row'>
                  {tabTwoActive &&
                    <div className='col-lg-4 col-md-12'>
                      <label className='labelMargin headingsFontC '>Add React JS Controllers Data
            <span className='ps-3 addIcon'>
                          < FontAwesomeIcon
                            className='editIcon text-info'
                            color='#ff00008c'
                            icon='plus-circle'
                            data-toggle="tool-tip"
                            title="Add"
                            onClick={(e) => this.addControllersFields('controller')}
                          />
                        </span>
                      </label>
                    </div>}
                  {tabTwoActive && <div className='col-lg-4 col-md-12  ps-4 pr-2'>
                    <div className=' pt-2 pb-2'>
                      <Field
                        name="loginApi"
                        component={Select}
                        type="text"
                        placeholder="Select Api Url"
                        className="form-control"
                        options={apiUrlTypes}
                        onChange={() => this.setState({ showApiUrlDropdown: false })}
                      />
                    </div>
                    <div style={{ height: 13 }}>
                      {this.state.showApiUrlDropdown ? <span className="form__form-group-error">Please select api.</span> : null}
                    </div>
                  </div>}
                </div>}
              {this.props.yesJsonUploaded &&
                <div className='row'>
                  <div className='col-lg-4 col-md-12'>
                    <div className=' pt-2 pb-2'>
                      <Field
                        name="loginApi"
                        component={Select}
                        type="text"
                        placeholder="Select Api Url"
                        className="form-control"
                        options={this.props.apiUrlTypes}
                        onChange={() => this.setState({ showApiUrlDropdown: false, tabThreeActive: true, setMainTab: 'three', activeThreeTab: true, })}
                      />
                    </div>
                    <div style={{ height: 13 }}>
                      {this.state.showApiUrlDropdown ? <span className="form__form-group-error">Please select api.</span> : null}
                    </div>
                  </div>
                </div>}
              {tabTwoActive &&
                <div className='row ps-2'>
                  {!this.props.yesJsonUploaded && controllers && controllers.length
                    ? controllers.map((item, index) => {
                      return (
                        <div
                          className='col-12 col-sm-12 col-md-6 col-lg-4'
                          key={index}
                        >
                          <div className={selectedControllerIndex == index ?
                            'text-info  controller_label borderContollerColor'
                            : 'controller_label'}>
                            <span
                              style={{ textTransform: 'capitalize' }}
                              onClick={(e) =>
                                this.setState({
                                  selectedControllerIndex: index,
                                  selectdFilterIndex: 0,
                                  selectedFormIndex: 0,
                                })
                              }>
                              <MuiThemeProvider theme={theme}>
                                <TextField
                                  autoFocus={true}
                                  margin="dense"
                                  variant="outlined"
                                  id="custom-css-outlined-input"
                                  size='small'
                                  type="text"
                                  helperText={'Please Enter Singular Values, Ex:employee'}
                                  className={selectedControllerIndex == index ? 'float-left text-info textFieldsWidth'
                                    : 'float-left textFieldsWidth'}
                                  style={{ textTransform: 'capitalize' }}
                                  name={`controller_${index + 1}.name`}
                                  label={`Controller${index + 1} Name`}
                                  onChange={(e) => {
                                    this.changeContrllerName(e, selectedControllerIndex)
                                  }}
                                  value={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                                  defaultValue={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                                />
                              </MuiThemeProvider>
                              <span className='text-white'>
                                {`controller_${index + 1}.name`}
                              </span>
                            </span>
                            <span className='float-right iconSize controllerDelteIConPos'><FontAwesomeIcon
                              className='editIcon'
                              color='#ff00008c'
                              icon='trash-alt'
                              data-toggle="tool-tip"
                              title="Delete"
                              onClick={() => this.removeFormFields(index, 'controller')}
                            /></span>
                          </div>
                        </div>
                      );
                    })
                    : null}
                  {!this.props.yesJsonUploaded &&
                    <Col
                      sm={12}
                      className={controllers && controllers.length ? " pt-2" : "d-none"}
                    >
                      <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                          <label className='labelMargin headingsFont'>
                            {controllers && controllers.length && controllers[selectedControllerIndex].name
                              ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`} Table Fields
                  <span className='ps-3 addIcon'>
                              < FontAwesomeIcon
                                className='editIcon text-info'
                                color='#ff00008c'
                                icon='plus-circle'
                                data-toggle="tool-tip"
                                title="Add"
                                onClick={(e) => this.addControllersFields('table')}
                              />
                            </span>
                          </label>
                        </div>
                        <div className='col-lg-4 col-md-12'>
                          <div>
                            <label className='mb-0'>
                              Actions
                    </label>
                            <Field
                              name={`controller_${selectedControllerIndex}.act`}
                              component={MultiSelect}
                              type="text"
                              placeholder="select"
                              className="form-control"
                              valueMulti={controllers && controllers.length > 0 && controllers[selectedControllerIndex]['actions'] && controllers[selectedControllerIndex]['actions']}
                              options={actionsType}
                              onChange={(e) => { e ? this.handleControllerActions(e, 'actions') : null }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {controllerFieldsArray && controllerFieldsArray.length
                          ? controllerFieldsArray.map((item, index) => {
                            return (
                              <div key={index} className='col-12 col-sm-12 col-md-6 col-lg-4'>
                                <div key={index}>
                                  <div className='controller_labelForFields'>
                                    <span className='float-left' style={{ textTransform: 'capitalize' }}>
                                      {item[`fieldValue`] ? item[`fieldValue`] : `Table Fields ${index + 1}`}
                                    </span>
                                    <span className='float-right iconSize' ><FontAwesomeIcon
                                      className='editIcon'
                                      color='#ff00008c'
                                      icon='trash-alt'
                                      data-toggle="tool-tip"
                                      title="Delete"
                                      onClick={() => this.removeFormFields(index, 'table')}
                                    /></span>
                                  </div>
                                  <div className='card cardShadow'>
                                    <div className='card-body px-3 py-2'>
                                      <div className="row" key={index}>
                                        <div className="col-12 pb-2 ">
                                          <MuiThemeProvider theme={theme}>
                                            <TextField
                                              type="text"
                                              className='textFieldsWidth'
                                              margin="dense"
                                              name={item.field}
                                              label="Field Name"
                                              variant="outlined"
                                              id="custom-css-outlined-input"
                                              size='small'
                                              defaultValue={item[`fieldValue`] ? item[`fieldValue`] : ''}
                                              value={item[`fieldValue`] ? item[`fieldValue`] : ''}
                                              onChange={(e) => this.handleControllerFields(e, index, `field${index}`, 'input', 'table', 'fieldValue')}
                                            />
                                          </MuiThemeProvider>
                                        </div>
                                        <div className="col-12 pt-2 pb-3">
                                          <MuiThemeProvider theme={theme}>
                                            <TextField
                                              type="text"
                                              className='textFieldsWidth'
                                              margin="dense"
                                              name={item.header}
                                              label="Header Name"
                                              variant="outlined"
                                              size='small'
                                              id="custom-css-outlined-input"
                                              defaultValue={item[`headerValue`] ? item[`headerValue`] : ''}
                                              value={item[`headerValue`] ? item[`headerValue`] : ''}
                                              onChange={(e) => this.handleControllerFields(e, index, `header${index}`, 'input', 'table', 'headerValue')}
                                            />
                                          </MuiThemeProvider>
                                        </div>
                                        <div className="col-12 dropDownPadiingAlign ">
                                          <Field
                                            name={item.textAlign}
                                            component={Select}
                                            type="text"
                                            placeholder="Text Align"
                                            valueExists={{ label: item[`textAlignValue`], value: item[`textAlignValue`] }}
                                            className="form-control"
                                            options={typeOptions}
                                            onChange={(e) => this.handleControllerFields(e, index, `textAlign${index}`, 'checkbox', 'table', 'textAlignValue')}
                                          />
                                        </div>
                                        <div className="col-12 pb-2">
                                          <Field
                                            name={item.filter}
                                            component={CheckBox}
                                            label='Filter'
                                            className="form-control"
                                            type="checkbox"
                                            onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `filter${index}`, 'checkbox', 'table') : null }}
                                          />
                                        </div>
                                        {item.filterElement && item.filterElement.length > 0 && index == index ?
                                          <div className='col-12'>
                                            {item.filterElement && item.filterElement.length > 0 ?
                                              item.filterElement.map((itemsFilter, filterIndex) => (
                                                <div className='row' key={filterIndex}>
                                                  <div className="col-6 pb-3 pr-lg-1">
                                                    <MuiThemeProvider theme={theme}>
                                                      <TextField
                                                        type="text"
                                                        className='textFieldsWidth'
                                                        margin="dense"
                                                        name={itemsFilter.label}
                                                        label="Label"
                                                        variant="outlined"
                                                        size='small'
                                                        id="custom-css-outlined-input"
                                                        defaultValue={itemsFilter[`labelValue`] ? itemsFilter[`labelValue`] : ''}
                                                        value={itemsFilter[`labelValue`] ? itemsFilter[`labelValue`] : ''}
                                                        onChange={(e) => this.handleControllerFilterFields(e, index, `label${filterIndex}`, filterIndex, 'table', 'labelValue')}
                                                      />
                                                    </MuiThemeProvider>
                                                  </div>
                                                  <div className="col-6 pb-3 ps-lg-1">
                                                    <MuiThemeProvider theme={theme}>
                                                      <TextField
                                                        type="text"
                                                        className='textFieldsWidth'
                                                        margin="dense"
                                                        name={itemsFilter.label}
                                                        label="Value"
                                                        variant="outlined"
                                                        size='small'
                                                        id="custom-css-outlined-input"
                                                        defaultValue={itemsFilter[`valueValue`] ? itemsFilter[`valueValue`] : ''}
                                                        value={itemsFilter[`valueValue`] ? itemsFilter[`valueValue`] : ''}
                                                        onChange={(e) => this.handleControllerFilterFields(e, index, `value${filterIndex}`, filterIndex, 'table', 'valueValue')}
                                                      />
                                                    </MuiThemeProvider>
                                                    <span className='float-right iconSize' ><FontAwesomeIcon
                                                      className='editIconField'
                                                      color='#ff00008c'
                                                      icon='trash-alt'
                                                      data-toggle="tool-tip"
                                                      title="Delete"
                                                      onClick={() => this.removeFilterFields(index, 'tableFilter', filterIndex)}
                                                    /></span>
                                                  </div>
                                                </div>
                                              ))
                                              : null}
                                            <p
                                              onClick={(e) => this.addFilterElement(index, 'filter')}
                                              className="text-primary py-2 mt-0"
                                              style={{ textDecoration: "underline", cursor: "pointer" }}
                                            >
                                              Add Filter Element Fields +
                        </p>
                                          </div> : null}
                                        <div className="col-12 ">
                                          <Field
                                            name={item.sortable}
                                            component={CheckBox}
                                            label='Sortable'
                                            className="form-control"
                                            type="checkbox"
                                            onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `sortable${index}`, 'checkbox', 'table') : null }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </Col>}
                  {!this.props.yesJsonUploaded &&
                    <Col
                      sm={12}
                      className={controllers && controllers.length ? "pt-3" : "d-none"}
                    >
                      <label className='labelMargin headingsFont pt-1'>
                        {controllers && controllers.length && controllers[selectedControllerIndex].name
                          ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`} Form Fields
                  <span className='ps-3 addIcon'>
                          < FontAwesomeIcon
                            className='editIcon text-info'
                            color='#ff00008c'
                            icon='plus-circle'
                            data-toggle="tool-tip"
                            title="Add"
                            onClick={(e) => this.addControllersFields('form')}
                          />
                        </span>
                      </label>
                      <div className="row" >
                        {controllerFormFieldsArray && controllerFormFieldsArray.length
                          ? controllerFormFieldsArray.map((item, index) => {
                            return (
                              <div
                                className='col-12 col-sm-12 col-md-6 col-lg-4'
                                key={index}
                              >
                                <div className='controller_labelForFields '>
                                  <span className='float-left' style={{ textTransform: 'capitalize' }}>
                                    {item[`formName`] ? item[`formName`] : `Form Fields ${index + 1}`}
                                  </span>
                                  <span className='float-right iconSize'><FontAwesomeIcon
                                    className='editIcon'
                                    color='#ff00008c'
                                    icon='trash-alt'
                                    data-toggle="tool-tip"
                                    title="Delete"
                                    onClick={() => this.removeFormFields(index, 'form')}
                                  /></span>
                                </div>
                                <div className='card cardShadowForm'>
                                  <div className='card-body px-3 py-2'>
                                    <div className="row" key={index}>
                                      <div className="col-12 pb-2">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.value}
                                            label="Value"
                                            id="outlined-search"
                                            variant="outlined"
                                            size='sm'
                                            defaultValue={item[`formValue`] ? item[`formValue`] : ''}
                                            value={item[`formValue`] ? item[`formValue`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'form', 'formValue')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12 py-2">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.name}
                                            label="Name"
                                            id="outlined-margin-dense"
                                            variant="outlined"
                                            size='sm'
                                            defaultValue={item[`formName`] ? item[`formName`] : ''}
                                            value={item[`formName`] ? item[`formName`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'form', 'formName')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12  py-2">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.label}
                                            label="Label"
                                            id="outlined-margin-dense"
                                            variant="outlined"
                                            size='sm'
                                            value={item[`formLabel`] ? item[`formLabel`] : ''}
                                            defaultValue={item[`formLabel`] ? item[`formLabel`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'form', 'formLabel')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12  py-2">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.id}
                                            label="Id"
                                            id="outlined-margin-dense"
                                            variant="outlined"
                                            size='small'
                                            value={item[`formId`] ? item[`formId`] : ''}
                                            defaultValue={item[`formId`] ? item[`formId`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'form', 'formId')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12  py-2">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.placeholder}
                                            label="Placeholder"
                                            id="outlined-margin-dense"
                                            variant="outlined"
                                            size='small'
                                            value={item[`formPlaceholder`] ? item[`formPlaceholder`] : ''}
                                            defaultValue={item[`formPlaceholder`] ? item[`formPlaceholder`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'form', 'formPlaceholder')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12 dropDownPadiingFields">
                                        <Field
                                          name={item.type}
                                          component={Select}
                                          type="text"
                                          placeholder="Field Type"
                                          valueExists={{ label: item[`formType`], value: item[`formType`] }}
                                          className="form-control"
                                          options={fieldTypeOptions}
                                          onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'form', 'formType')}
                                        />
                                      </div>
                                      {(item[`formType`] === 'dropDown' || item[`formType`] === 'radio'
                                        || item[`formType`] === 'multiSelect') && index == index ?
                                        <div className='col-12 pb-3'>
                                          {item.options && item.options.length > 0 ?
                                            item.options.map((itemsOptions, optionsIndex) => (
                                              <div className='row' key={optionsIndex}>
                                                <div className="col-6 pb-3 pr-lg-1">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsOptions.label}
                                                      label="Label"
                                                      id="outlined-margin-dense"
                                                      variant="outlined"
                                                      size='small'
                                                      value={itemsOptions[`formOptionLabel`] ? itemsOptions[`formOptionLabel`] : ''}
                                                      defaultValue={itemsOptions[`formOptionLabel`] ? itemsOptions[`formOptionLabel`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `label${optionsIndex}`, optionsIndex, 'form', 'formOptionLabel')}
                                                    />
                                                  </MuiThemeProvider>
                                                </div>
                                                <div className="col-6 pb-3 ps-lg-1">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsOptions.value}
                                                      label="Value"
                                                      id="outlined-margin-dense"
                                                      variant="outlined"
                                                      size='small'
                                                      value={itemsOptions[`formOptionValue`] ? itemsOptions[`formOptionValue`] : ''}
                                                      defaultValue={itemsOptions[`formOptionValue`] ? itemsOptions[`formOptionValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `value${optionsIndex}`, optionsIndex, 'form', 'formOptionValue')}
                                                    />
                                                  </MuiThemeProvider>
                                                  <span className='float-right iconSize' ><FontAwesomeIcon
                                                    className='editIconField'
                                                    color='#ff00008c'
                                                    icon='trash-alt'
                                                    data-toggle="tool-tip"
                                                    title="Delete"
                                                    onClick={() => this.removeFilterFields(index, 'formFilter', optionsIndex)}
                                                  /></span>
                                                </div>
                                              </div>
                                            ))
                                            : null}
                                          <p
                                            onClick={(e) => this.addFilterElement(index, 'options')}
                                            className="text-primary  mt-0 pt-2"
                                            style={{ textDecoration: "underline", cursor: "pointer" }}
                                          >
                                            Add Options Fields +
                        </p>
                                        </div> : null}
                                      {item[`formType`] === 'autoComplete' ?
                                        <div className='col-12'>
                                          <div className='row'>
                                            <div className="col-6 pb-3 pr-lg-1">
                                              <MuiThemeProvider theme={theme}>
                                                <TextField
                                                  type="text"
                                                  className='textFieldsWidth'
                                                  margin="dense"
                                                  name={`controller_${selectedControllerIndex +
                                                    1}.searchF${index + 1}`}
                                                  label="Search Field"
                                                  id="outlined-margin-dense"
                                                  variant="outlined"
                                                  size='small'
                                                  value={item[`searchF`] ? item[`searchF`] : ''}
                                                  defaultValue={item[`searchF`] ? item[`searchF`] : ''}
                                                  onChange={(e) => this.handleControllerFields(e, index, `searchField${index}`, 'input', 'form', 'searchF')}
                                                />
                                              </MuiThemeProvider>
                                            </div>
                                            <div className="col-6 pb-3 ps-lg-1">
                                              <MuiThemeProvider theme={theme}>
                                                <TextField
                                                  type="text"
                                                  className='textFieldsWidth'
                                                  margin="dense"
                                                  name={`controller_${selectedControllerIndex +
                                                    1}.searchA${index + 1}`}
                                                  label="Search Api"
                                                  id="outlined-margin-dense"
                                                  variant="outlined"
                                                  size='small'
                                                  value={item[`searchA`] ? item[`searchA`] : ''}
                                                  defaultValue={item[`searchA`] ? item[`searchA`] : ''}
                                                  onChange={(e) => this.handleControllerFields(e, index, `searchApi${index}`, 'input', 'form', 'searchA')}
                                                />
                                              </MuiThemeProvider>
                                            </div>
                                          </div>
                                        </div>
                                        : null}
                                      <div className="col-12 pt-2">
                                        <Field
                                          name={item.required}
                                          component={CheckBox}
                                          label='Requried'
                                          className="form-control"
                                          type="checkbox"
                                          onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'form') : null }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </Col>}
                </div>}
            </div>
            : null}
          {this.state.showControllers && this.state.loginCheck &&
            this.state.uploadCheck && <hr className='mt-4'></hr>}
        </Col>
        {this.state.showControllers && this.state.loginCheck &&
          this.state.uploadCheck && !this.props.yesJsonUploaded ?
          <Col
            sm={12}
            className={controllers && controllers.length ? "" : "d-none"}
          >
            <label className=' headingsFontC pt-2 mb-0'> Profile Fields
          </label>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 ps-0 dropDownPadiing">
              <Field
                name='profile'
                component={Select}
                label='Field Type'
                type="text"
                placeholder="Field Type"
                className="form-control"
                options={fieldProfileType}
                onChange={(e) => this.addControllersFields('profile', e)}
              />
            </div>
            <div style={{ height: 13 }}>
              {this.state.showProfileTypeDropdown ? <span className="form__form-group-error">Already exists please select another one.</span> : null}
            </div>
            <div className="row" >
              {
                controllerProfileFields && controllerProfileFields.length
                  ? controllerProfileFields.map((item, index) => {
                    return (
                      <div
                        className='col-12 col-sm-12 col-md-6 col-lg-4'
                        key={index}
                      >
                        <div className='controller_labelForFields '>
                          <span className='float-left' style={{ textTransform: 'capitalize' }}>
                            {item[`profileName`] ? item[`profileName`] : `Profile Fields ${index + 1}`}
                          </span>
                          <span className='float-right iconSize'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeFormFields(index, 'profile')}
                          /></span>
                        </div>
                        <div className='card cardShadowForm'>
                          <div className='card-body px-3 py-2'>
                            <div className="row" key={index}>
                              <div className="col-12 pb-2 ">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.value}
                                    label="Value"
                                    id="outlined-search"
                                    variant="outlined"
                                    size='sm'
                                    defaultValue={item[`profileValue`] ? item[`profileValue`] : ''}
                                    value={item[`profileValue`] ? item[`profileValue`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'profile', 'profileValue')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.name}
                                    label="Name"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='sm'
                                    defaultValue={item[`profileName`] ? item[`profileName`] : ''}
                                    value={item[`profileName`] ? item[`profileName`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'profile', 'profileName')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    name={item.label}
                                    margin="dense"
                                    label="Label"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='sm'
                                    value={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                    defaultValue={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'profile', 'profileLabel')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    name={item.id}
                                    margin="dense"
                                    label="Id"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='small'
                                    value={item[`profileId`] ? item[`profileId`] : ''}
                                    defaultValue={item[`profileId`] ? item[`profileId`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'profile', 'profileId')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 pb-3 pt-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.placeholder}
                                    label="Placeholder"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='small'
                                    value={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                    defaultValue={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'profile', 'profilePlaceholder')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 dropDownPadiing">
                                <Field
                                  name={item.type}
                                  component={Select}
                                  type="text"
                                  placeholder="Field Type"
                                  valueExists={{ label: item[`profileType`], value: item[`profileType`] }}
                                  className="form-control"
                                  options={fieldProfileTypeOptions}
                                  onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'profile', 'profileType')}
                                />
                              </div>
                              <div className="col-12 pt-2">
                                <Field
                                  name={item.required}
                                  component={CheckBox}
                                  label='Requried'
                                  className="form-control"
                                  type="checkbox"
                                  onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'profile') : null }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}
            </div>
            <hr className='mt-3'></hr>
          </Col>

          : null}
        {this.state.showControllers && this.state.loginCheck &&
          this.state.uploadCheck && this.props.yesJsonUploaded ?
          <Col
            sm={12}
          >
            <label className=' headingsFontC pt-2 mb-0'> Profile Fields
          </label>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 ps-0 dropDownPadiing">
              <Field
                name='profile'
                component={Select}
                label='Field Type'
                type="text"
                placeholder="Field Type"
                className="form-control"
                options={fieldProfileType}
                onChange={(e) => this.addControllersFields('profile', e)}
              />
            </div>
            <div style={{ height: 13 }}>
              {this.state.showProfileTypeDropdown ? <span className="form__form-group-error">Already exists please select another one.</span> : null}
            </div>
            <div className="row" >
              {
                controllerProfileFields && controllerProfileFields.length
                  ? controllerProfileFields.map((item, index) => {
                    return (
                      <div
                        className='col-12 col-sm-12 col-md-6 col-lg-4'
                        key={index}
                      >
                        <div className='controller_labelForFields '>
                          <span className='float-left' style={{ textTransform: 'capitalize' }}>
                            {item[`profileName`] ? item[`profileName`] : `Profile Fields ${index + 1}`}
                          </span>
                          <span className='float-right iconSize'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeFormFields(index, 'profile')}
                          /></span>
                        </div>
                        <div className='card cardShadowForm'>
                          <div className='card-body px-3 py-2'>
                            <div className="row" key={index}>
                              <div className="col-12 pb-2 ">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.value}
                                    label="Value"
                                    id="outlined-search"
                                    variant="outlined"
                                    size='sm'
                                    defaultValue={item[`profileValue`] ? item[`profileValue`] : ''}
                                    value={item[`profileValue`] ? item[`profileValue`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'profile', 'profileValue')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.name}
                                    label="Name"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='sm'
                                    defaultValue={item[`profileName`] ? item[`profileName`] : ''}
                                    value={item[`profileName`] ? item[`profileName`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'profile', 'profileName')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    name={item.label}
                                    margin="dense"
                                    label="Label"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='sm'
                                    value={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                    defaultValue={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'profile', 'profileLabel')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    name={item.id}
                                    margin="dense"
                                    label="Id"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='small'
                                    value={item[`profileId`] ? item[`profileId`] : ''}
                                    defaultValue={item[`profileId`] ? item[`profileId`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'profile', 'profileId')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 pb-3 pt-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.placeholder}
                                    label="Placeholder"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='small'
                                    value={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                    defaultValue={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'profile', 'profilePlaceholder')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 dropDownPadiing">
                                <Field
                                  name={item.type}
                                  component={Select}
                                  type="text"
                                  placeholder="Field Type"
                                  valueExists={{ label: item[`profileType`], value: item[`profileType`] }}
                                  className="form-control"
                                  options={fieldProfileTypeOptions}
                                  onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'profile', 'profileType')}
                                />
                              </div>
                              <div className="col-12 pt-2">
                                <Field
                                  name={item.required}
                                  component={CheckBox}
                                  label='Requried'
                                  className="form-control"
                                  type="checkbox"
                                  onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'profile') : null }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}
            </div>
            <hr className='mt-3'></hr>
          </Col>
          : null}
        <Col sm={12}>
          <div>
            {!this.props.yesJsonUploaded && tabTwoActive &&
              this.state.showControllers &&
              this.state.loginCheck && this.state.uploadCheck &&
              <div className='d-lg-flex d-md-flex d-sm-block d-block pt-3'>
                <label className='labelMargin headingsFontC '>Add Node Js Controllers Data
               <span className='ps-3 addIcon'>
                    < FontAwesomeIcon
                      className='editIcon text-info'
                      color='#ff00008c'
                      icon='plus-circle'
                      data-toggle="tool-tip"
                      title="Add"
                      onClick={(e) => this.addControllersServer('controller')}
                    />
                  </span>
                </label>
              </div>}
            {tabThreeActive && !this.props.yesJsonUploaded &&
              <div className='row ps-2'>
                {serverControler && serverControler.length
                  ? serverControler.map((item, index) => {
                    return (
                      <div
                        className={selectedServerControllerIndex == index ?
                          'controller_labelS text-info col-lg-4 col-md-6 col-sm-12 col-12'
                          : 'controller_labelS col-lg-4 col-md-6 col-sm-12 col-12 '}
                        key={index}
                      >
                        <div className={selectedServerControllerIndex == index ?
                          'text-info  controller_labelS borderContollerColor'
                          : 'controller_labelS'}>
                          <span
                            style={{ textTransform: 'capitalize' }}
                            onClick={(e) =>
                              this.setState({
                                selectedServerControllerIndex: index,
                                controllerCol: true,
                                emailServiceCol: false
                              })
                            }>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                autoFocus={true}
                                margin="dense"
                                variant="outlined"
                                id="custom-css-outlined-input"
                                size='small'
                                type="text"
                                className={selectedServerControllerIndex == index ? 'float-left text-info textFieldsWidth'
                                  : 'float-left textFieldsWidth'}
                                style={{ textTransform: 'capitalize' }}
                                name={`controllerserver_${index + 1}.name`}
                                label={`Controller${index + 1} Name`}
                                onChange={(e) => {
                                  this.changeServerContrllerName(e, selectedServerControllerIndex)
                                }}
                                value={serverControler && serverControler.length > 0 && serverControler[index]["name"] ? serverControler[index]["name"] : ''}
                                defaultValue={serverControler && serverControler.length > 0 && serverControler[index]["name"] ? serverControler[index]["name"] : ''}
                              />
                            </MuiThemeProvider>
                            <span className='text-white'>
                              {`controllerserver_${index + 1}.name`}
                            </span>
                          </span>
                          <span className='float-right iconSize controllerDelteIConPosNode'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeServerController(index, 'controller')}
                          /></span>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>}
          </div>

        </Col>
        {!this.props.yesJsonUploaded && tabThreeActive && <Col
          sm={{ size: 12 }}
          className={serverControler && serverControler.length && controllerCol ? "" : "d-none"}
        >
          <h3 className='fieldsHeadingServer'>
            <span className='float-left' style={{ textTransform: 'capitalize' }}>
              {serverControler && serverControler.length && serverControler[selectedServerControllerIndex][`name`] ? serverControler[selectedServerControllerIndex][`name`] : `
              Controller${selectedServerControllerIndex + 1}`} Fields
            </span>
          </h3>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-4 pt-3 ps-3'>
              <div className="form__form-group">
                <Field
                  name={`controllerserver_${selectedServerControllerIndex}.act`}
                  component={MultiSelect}
                  type="text"
                  placeholder="Actions"
                  className="form-control"
                  valueMulti={serverControler && serverControler.length > 0 && serverControler[selectedServerControllerIndex]['methods'] && serverControler[selectedServerControllerIndex]['methods']}
                  options={actionsServerType}
                  onChange={(e) => { e ? this.handleControllerServerActions(e, 'methods') : null }}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <label>Authentication Required</label>
              <div>
                <Field
                  name={`controllerserver_${selectedServerControllerIndex + 1}.isAuth`}
                  component={RadioInput}
                  label={"Yes"}
                  radioValue={true}
                  onClick={(e) => this.handleControllerServerFields(true, selectedServerControllerIndex, `isAuth`, 'radio', "isAuthValue")}
                />
                <Field
                  name={`controllerserver_${selectedServerControllerIndex + 1}.isAuth`}
                  component={RadioInput}
                  label={"No"}
                  radioValue={false}
                  onClick={(e) => this.handleControllerServerFields(false, selectedServerControllerIndex, `isAuth`, 'radio', "isAuthValue")}
                />
              </div>
            </div>
          </div>
          <div className='d-lg-flex d-md-flex d-sm-block d-block '>
            <label className='labelMargin headingsFontC '>Add New Fields
            <span className='ps-3 addIcon'>
                < FontAwesomeIcon
                  className='editIcon text-info'
                  color='#ff00008c'
                  icon='plus-circle'
                  data-toggle="tool-tip"
                  title="Add"
                  onClick={(e) => this.addControllersServer('fields')}
                />
              </span>
            </label>
          </div>
          <div className="row pt-3">
            {controllerServerFieldsArray && controllerServerFieldsArray.length
              ? controllerServerFieldsArray.map((item, index) => {
                return (
                  <div key={index} className='col-lg-4 col-md-6 col-sm-12'>
                    <div className="pb-3">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          type="text"
                          className='textFieldsWidth'
                          margin="dense"
                          name={item.fieldName}
                          label="Field Name"
                          variant="outlined"
                          id="custom-css-outlined-input"
                          size='small'
                          defaultValue={item[`fieldvalue`] ? item[`fieldvalue`] : ''}
                          value={item[`fieldvalue`] ? item[`fieldvalue`] : ''}
                          onChange={(e) => this.handleControllerServerFields(e, index, `name${index}`, 'input', "fieldvalue")}
                        />
                      </MuiThemeProvider>
                    </div>
                    <div className=" pb-3 pt-3">
                      <Field
                        name={item.type}
                        component={Select}
                        type="text"
                        placeholder="Field Type"
                        className="form-control"
                        options={typeServerOptions}
                        valueExists={{ label: item[`fieldType`], value: item[`fieldType`] }}
                        onChange={(e) => this.handleControllerServerFields(e, index, `type${index}`, 'checkbox', "fieldType")}
                      />
                      {controllerServerFieldsArray && controllerServerFieldsArray.length > 0 ? <span>
                        <FontAwesomeIcon
                          className='editIconField'
                          color='#ff00008c'
                          icon='trash-alt'
                          data-toggle="tool-tip"
                          title="Delete"
                          onClick={() => this.removeServerController(index, 'fields')}
                        />
                      </span> : null}
                    </div>
                  </div>
                );
              })
              : null}
          </div>
          {!this.props.yesJsonUploaded ? <div className='py-3 ps-2'>
            <label>Do you like to have Email Service?</label>
            <div>
              <Field
                name="emailService"
                component={RadioInput}
                label={"Yes"}
                radioValue={"yes"}
                onChange={(e) => this.setState({ emailService: e })}
              />
              <Field
                name="emailService"
                component={RadioInput}
                label={"No"}
                radioValue={"no"}
                onChange={(e) => this.setState({
                  emailService: e,
                  emailServiceCol: false,
                  controllerCol: true,
                  selectedMailService: '',
                })}
              />
            </div>
          </div> : null}
          {emailService == "yes" ? (
            <div className='py-3 ps-2'>
              <label>Which type of Mail Service Do you want</label>
              <div>
                <div className='row'>
                  <div className='col-sm-12 col-md-6 col-lg-4'>
                    <SelectDropdown
                      value={selectedMailService}
                      onChange={this.handleSelect}
                      options={mailServices}
                      className="react-select"
                      placeholder="Mail Service"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Col>}
        {this.props.yesJsonUploaded && this.state.showControllers &&
          <Col sm={12}>
            <div className='py-3 ps-2'>
              <label>Do you like to have Email Service?</label>
              <div>
                <Field
                  name="emailService"
                  component={RadioInput}
                  label={"Yes"}
                  radioValue={"yes"}
                  onChange={(e) => this.setState({
                    emailService: e,
                    activeTwoTab: true, setMainTab: 'two',
                  })}
                />
                <Field
                  name="emailService"
                  component={RadioInput}
                  label={"No"}
                  radioValue={"no"}
                  onClick={() => this.props.changeprogressWidth(100)}
                  onChange={(e) => this.setState({
                    emailService: e,
                    emailServiceCol: false,
                    controllerCol: true,
                    selectedMailService: '',
                    activeTwoTab: true, setMainTab: 'two', tabThreeActive: true,
                    activeThreeTab: true, setMainTab: 'three'
                  })}
                />
              </div>
            </div>
            {emailService == "yes" ? (
              <div className='py-3 ps-2'>
                <label>Which type of Mail Service Do you want</label>
                <div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                      <SelectDropdown
                        value={selectedMailService}
                        onChange={this.handleSelectWithUpload}
                        options={mailServices}
                        className="react-select"
                        placeholder="Mail Service"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        }
        <Col
          sm={12} md={6} lg={4}
          className={
            selectedMailService &&
              selectedMailService.value == 1 &&
              emailService == "yes"
              ? ""
              : "d-none"
          }
        >

          <h3 className='py-3'>Mail Service Details</h3>
          <div>

            <label>Your Node Mailer Service</label>
            <div>
              <Field
                name="nodeMailerService"
                component={DefaultInput}
                type="text"
                placeholder="Mail Service"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Host</label>
            <div>
              <Field
                name="nodeMailerHost"
                component={DefaultInput}
                type="text"
                placeholder="Host"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Port</label>
            <div>
              <Field
                name="nodeMailerPort"
                component={DefaultInput}
                type="text"
                placeholder="Port"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Email</label>
            <div>
              <Field
                name="nodeMailerEmail"
                component={DefaultInput}
                type="text"
                placeholder="Mail"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Password</label>
            <div>
              <Field
                name="nodeMailerPassword"
                component={DefaultInput}
                type="password"
                placeholder="Password"
                className="form-control"
              />
            </div>
          </div>
        </Col>
        <Col
          sm={12} md={6} lg={4}
          className={
            selectedMailService &&
              selectedMailService.value == 2 && emailServiceCol &&
              emailService == "yes"
              ? ""
              : "d-none"
          }
        >
          <h3 className='pt-3'>Send Grid Details</h3>
          <div>
            <label>Your Send Grid Email</label>
            <div>
              <Field
                name="sendGridMail"
                component={DefaultInput}
                type="text"
                placeholder="Mail Service"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label>Your Send Grid API key</label>
            <div>
              <Field
                name="sendGridApiKey"
                component={DefaultInput}
                type="text"
                placeholder="Host"
                className="form-control"
              />
            </div>
          </div>
        </Col>
      </Row >
    );
  }
}
export default NewProject;


