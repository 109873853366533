import React, { useEffect, useState, useRef } from "react";
//Components
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { Progress } from 'reactstrap';
import { ProgressBar } from 'primereact/progressbar';
import { Dialog } from 'primereact/dialog';


import RSExistingProject from "../NoCodeRS/RSExistingProject/rsexistingproject";
import RSNewProject from "../NoCode/NewProject/newproject";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faDownload, faUpload, faSave, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

import RadioButton from '../../shared/components/form/RadioButton';
import Loader from '../App/Loader';
import ConfirmModal from '../ConfirmModals/ConfirmModal';
import SelectModal from '../ConfirmModals/SelectModal';

//styles
import { Row, Col, Collapse } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import socket from '../../config/Socket';


//service.js
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apicalls';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

import LogoutTime from "../../config/logoutTime";
import SessionExpiryModal from '../Cruds/CommonModals/SessionexpiryModal';

const ClientCode = (props) => {

	const newProjectRef = useRef(null);
	const existingProjectRef = useRef(null);

	const projects = [
		{ value: "new", label: "New" },
		{ value: "existing", label: "Existing" },
	];
	const [selectedProject, setSelectedProject] = useState("new");
	const [selectedTech, setSelectedTech] = useState(null);
	const [showProgressBarDialog, setShowProgressBarDialog] = useState(false);
	const [hideTabTwo, setHideTabTwo] = useState(true);
	const [fileName, setFileName] = useState(null);
	const [favName, setFavName] = useState(null);
	const [bgName, setBgName] = useState(null);
	const [downloadProjectLink, setDownloadProjectLink] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [yesJsonUploaded, setYesJsonUploaded] = useState(false);
	const [jsonUploadFile, setJsonUploadFile] = useState('');
	const [apiUrlTypes, setApiUrlTypes] = useState([]);
	const [redColor, setRedColor] = useState("#FF0000");
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [selectModalShow, setSelectModalShow] = useState(false);
	const [selectedModalValue, setSelectedModalValue] = useState('');
	const [saveButton, setSaveButton] = useState(false);
	const [downloadButton, setDownloadButton] = useState(true);
	const [deployButton, setDeployButton] = useState(false);
	const [stopButton, setStopButton] = useState(true);
	const [projectId, setProjectId] = useState('');
	const [projectFileName, setProjectFileName] = useState('');
	const [projectname, setProjectname] = useState('');
	const [startButton, setStartButton] = useState(true);
	const [yesExistingProject, setYesExistingProject] = useState(false);
	const [sessionExpiryModal, setSessionExpiryModal] = useState(false);
	const [previewProject, setPreviewProject] = useState(true);
	const [loginSideImage, setLoginSideImage] = useState(null);
	const [services, setServices] = useState(["Admin"]);
	const [progressValue, setProgressValue] = useState(40);
	const [projectData, setProjectData] = useState({});
	const [isProjectDeployedToServer, setIsProjectDeployedToServer] = useState(false);
	const [jsonObject, setJsonObject] = useState({});
	const [saveProjectName, setSaveProjectName] = useState("");
	const [color, setColor] = useState('');
	const [projectType, setProjectType] = useState(null);
	const [textcolor, setTextcolor] = useState(null);
	const [logo, setLogo] = useState(null);
	const [fav, setFav] = useState(null);
	const [bg, setBg] = useState(null);
	const [s_g_project_Id, setS_g_project_Id] = useState(null);
	const [loginApi, setLoginApi] = useState(null);
	const [progressWidth, setProgressWidth] = useState(null);
	const [progressBarPer, setProgressBarPer] = useState(null);
	const [progressBarColor, setProgressBarColor] = useState(null);
	const [currentUI, setCurrentUI] = useState("");

	//not used
	const [apiIndex, setApiIndex] = useState();

	useEffect(() => {
		if (props.editProject && props.editProjectId) {
			getProjectInfo(props.editProjectId)
		}
	}, [])

	//not used
	const setApiDetails = (values) => {
		setApiIndex(values);
	}

	useEffect(() => {
		let loginCredentials1 = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
		socket.emit('userConnected', {
			userId: loginCredentials1._id,
		})

		//not used
		socket.on('DeployStatus', async (res) => {
			if (res.deployStatus == "ui files deploying done! and server files deploying started") {
				setProgressBarColor("warning");
				setProgressBarPer(40);
				setProgressValue(40 / services.length);
				// this.setState({ progressBarColor: "warning", progressBarPer: 40, progressValue: 40 / this.state.services.length });
			}
			if (res.deployStatus == "files deployed in server and setup done") {
				setIsLoading(false);
				setProgressBarColor("info");
				setProgressBarPer(60);
				setProgressValue(40 / services.length);
			}
			if (services.includes(res.serviceName)) {
				let progressBarPerValue = parseInt(progressValue) + parseInt(progressBarPer)
				progressBarPerValue = Math.round(progressBarPerValue)
				progressBarPerValue > 97 ? progressBarPerValue = 100 : null
				setPreviewProject(false);
				setDeployButton(false);
				setProgressBarColor("success");
				setProgressBarPer(progressBarPerValue);
				if (services[services.length - 1] && services[services.length - 1] == res.serviceName) {
					setTimeout(() => {
						setShowProgressBarDialog(false)
					}, 5000);
				}
			}
			if (res.deployStatus == "Please contact admin some issue") {
				setShowProgressBarDialog(false);
				// this.setState({ showProgressBarDialog: false })
				showToasterMessage("Please contact admin some issue or else please re deploy", 'error');
				setDeployButton(false);
				// this.setState({ deployButton: false })
			}
		})

		if (!fileName) {
			if (newProjectRef?.current) {
				newProjectRef.current.uploadExits();
			}
			setFileName('default_project_logo.png');
		}
		if (!favName) {
			if (newProjectRef?.current) {
				newProjectRef.current.favIconSubmitted();
			}
			setFavName('default_fav_icon.png');
		}
		if (!loginSideImage) {
			if (newProjectRef) {
				newProjectRef.current.uploadExits();
			}
			setLoginSideImage('default_LoginSideImage.webp')
		}
	}, [])

	const getProjectInfo = async (id) => {
		await setIsLoading(true);
		let filterCriteria = { "limit": "30", "page": 1, "criteria": [{ "key": "_id", "value": id, "type": "eq" }], "sortfield": "created", "direction": "desc" }
		let apiUrl = `${apiCalls.Project}?filter=${JSON.stringify(filterCriteria)}`;
		return fetchMethodRequest('GET', apiUrl)
			.then(async (response) => {
				setProjectData(response.projects[0])

				let sessionexpired = await localStorage.getItem('sessionexpired')
				if (sessionexpired == "true") {
					await setSessionExpiryModal(true);
				}
				if (response) {
					if (response.projects && response.projects.length > 0) {
						let projectData = response.projects[0];
						console.log("projectData", projectData)
						//keep
						// this.newProjectRef.setEditProjectdata(projectData);
						// await this.setState({
						// 	projectData: projectData
						// })
						// console.log("this.newProjectRef",this.newProjectRef)
						// await this.newProjectRef.setEditProjectdata(projectData)
						setProjectData(projectData);
						console.log("this.newProjectRef", newProjectRef)
						await newProjectRef.current.setEditProjectdata(projectData)

					}
				}
				await setIsLoading(false);
			}).catch((err) => {
				setIsLoading(false);
				return err;
			});

	}

	const setButtonValues = (save, deploy) => {
		setDeployButton(false);
		setSaveButton(false);
	}

	//submit values
	const submit = (values) => {
		LogoutTime.logoutTime()
		let temp = '';
		let AllControllersData
		let yesContiue = false;
		let profileFields = [];
		let selectedControllers = newProjectRef.current.returnSelectedControllers();
		let api = values.api
		let emailSourceType = newProjectRef.current.returnMailServiceType()
		let sendGridApiKey = newProjectRef.current.returnsendgridApiKey()
		let nodeMailerEmail = newProjectRef.current.returnnodeMailerEmail()
		let smtphost = newProjectRef.current.returnsmtphost()
		let smtpuser = newProjectRef.current.returnsmtpuser()
		let smtppassword = newProjectRef.current.returnsmtppassword()
		let emailService = newProjectRef.current.returnEmailService()
		let googleData = newProjectRef.current.returnisGoogleLoginRequired()
		let sendGridEmail = newProjectRef.current.returnsendgridEmail()
		let projectType = newProjectRef.current.returnProjectType();
		let selectedLoginScreen = newProjectRef.current.returnGetSelectedTemplate();
		let s_g_project_Id = newProjectRef.current.returns_g_project_Id();
		let multiUIData = newProjectRef.current.returnMultiUiData();
		let servicesAndLoginApi = newProjectRef.current.returnServicesAndLoginApi();

		if (!api) {
			api = newProjectRef.current.returnApi();

		}
		let allControllers = newProjectRef.current.returnAllControllers();
		AllControllersData = [...newProjectRef.current.returnControllerArray()]

		if (newProjectRef && selectedProject == 'new') {
			temp = newProjectRef.current.returnControllerArray();
		}
		if (existingProjectRef && selectedProject == 'existing') {
			temp = existingProjectRef.current.returnControllerArray();
		}

		// console.log("enteresssss", temp, temp[0], temp[0].controllerFields)
		if (temp && temp[0].controllerFields.length >= 0) {
			var loginController = temp.filter(function (el) {
				return el.isAuth === true
			});

			let isUpdate = props.editProject && projectData && projectData._id ? projectData : JSON.parse(localStorage.getItem("jsonObject"));
			if (isUpdate) {
				let logoDetails = JSON.parse(isUpdate.jsonObject);
				setFileName(logoDetails.logo);
				setFavName(logoDetails.fav);
				setBgName(logoDetails.bg);
				setLoginSideImage(logoDetails.loginSideImage);

				localStorage.removeItem("jsonObject");
			}
			let loginControllerAPiData = loginController[0] && loginController[0].name ? loginController[0].name : "controller"
			multiUIData = newProjectRef.current.returnMultiUiData();
			let jsonObjectbody = {
				projectName: values.projectName,
				projectType: projectType,
				logo: fileName,
				fav: favName,
				bg: bgName,
				api: api,
				controllers: AllControllersData,
				loginApi: loginControllerAPiData,
				multipleListDummy: selectedControllers,
				multipleList: allControllers,
				services: servicesAndLoginApi.services,
				loginApi: servicesAndLoginApi.loginApi,
				newUIDetails: multiUIData.newUIDetails,
				s_g_project_Id: s_g_project_Id,
				multiTabsData: multiUIData.multiTabsData,
				// color: values.color
			}

			setSaveButton(false);
			setDeployButton(false);
			setJsonObject(jsonObjectbody);
			setSaveProjectName(values.projectName ? values.projectName : saveProjectName ? saveProjectName : values.projectName);
			setProjectname(values.projectName);
			setColor(values.color);
			setProjectType(projectType);
			setTextcolor(values.textcolor);
			setLogo(fileName);
			setFav(favName);
			setServices(servicesAndLoginApi.services);
			setBg(bgName);
			setS_g_project_Id(s_g_project_Id);
			setLoginApi(loginControllerAPiData);

		}
		if (values && values.loginApi) {
			yesContiue = true;
		} else {
			if (newProjectRef?.current) {
				newProjectRef.current.showErrorForApiUrl();
			}
			if (existingProjectRef?.current && selectedProject == 'existing') {
				yesContiue = true;
			}
		}
		if (yesContiue) {
			setDeployButton(true);
			if (values) {
				if (values.projectNameNew) {
					values.projectName = values.projectNameNew;
					delete values['projectNameNew']
				}
				if (values['projectFile'] && values['projectFile']['name']) {
					values['projectFile'] = values['projectFile']['name']
				}
			}
			if (temp) {
				for (let j = 0; j < temp.length; j++) {
					if (selectedControllers.includes(temp[j].name)) {
						const index = temp[j]['controllerFields'].findIndex(object => object.field === "Actions");
						if (index === -1) {

							if (temp[j].actions) {
								// temp[j]['controllerFields'].push({
								// 	"textAlign": "left",
								// 	"width": 70,
								// 	"field": "Actions",
								// 	"header": "Actions",
								// 	"filter": false,
								// 	"sortable": false
								// })
							}
						}
						const index2 = temp[j]['controllerFields'].findIndex(object => object.field === "Sno");

						if (index2 === -1) {
							temp[j]['controllerFields'].unshift(
								{
									"textAlign": "center",
									"width": 47,
									"field": "Check Box",
									"label": "Check Box",
									"fieldType": 'multiple',
									"header": "",
									'selectionMode': 'multiple',
									"show": true,
									"mobile": true,
									"displayInSettings": true,
								}, {
								"textAlign": 'center',
								"width": 47,
								"field": 'Sno',
								"label": "SNo",
								"header": 'SNo',
								"filter": false,
								"sortable": false,
								"placeholder": 'Search'
							},

							)
						}
					} else {
						temp.splice(j, 1)
					}
				}
			}

			if (projectType) {
				values.projectType = projectType;
			}
			// this.loginUrl()
			let isUpdate = props.editProject && projectData && projectData._id ? projectData : localStorage.getItem("jsonObject");
			if (isUpdate) {
				let jsonObject = isUpdate;
				let editRecord = JSON.parse(jsonObject.jsonObject)
				console.log("rsNewProject", editRecord)
				setFavName(editRecord.fav);
				setFileName(editRecord.logo);
				setColor(editRecord.color);
				setTextcolor(editRecord.textcolor);
				setBgName(editRecord.bg);

			}
			setServices(servicesAndLoginApi.services);

			multiUIData = newProjectRef.current.returnMultiUiData();
			let uiSettings = {}
			let uiMenuList = {}
			let selectedFrontEnd = ''
			let selectedBackEnd = ''
			for (let ob of servicesAndLoginApi.services) {
				uiSettings[ob] = multiUIData.newUIDetails[ob].settings
				uiMenuList[ob] = multiUIData.newUIDetails[ob].menuList

				selectedFrontEnd = uiSettings['Admin'].selectedFrontEnd
				selectedBackEnd = uiSettings['Admin'].selectedBackEnd

			}
			uiSettings['Admin'].selectedLoginTemplate = selectedLoginScreen
			let body = {
				projectName: values.projectName,
				emailSourceType: values.emailServiceType,
				sendGridApiKey: values.sendgridApiKey,
				nodeMailerEmail: values.nodeMailerEmail,
				smtphost: values.smtphost,
				smtpuser: values.smtpuser,
				smtppassword: values.smtppassword,
				sendGridEmail: values.sendgridEmail,
				displayProjectLogoOrName: values.displayProjectLogoOrName,
				projectType: projectType,
				logo: fileName,
				selectedLoginScreen: selectedLoginScreen,
				fav: favName,
				bg: values.bgColor ? null : bgName,
				bgColor: values.bgColor,
				selectedBackground: values.bgColor ? 'colour' : "image",
				controllers: temp,
				s_g_project_Id: s_g_project_Id,
				// loginApi: this.state.loginApi,
				color: values.color ? values.color : color,
				doNotListInDuplicateScreenDropDown: values.doNotListInDuplicateScreenDropDown,
				textcolor: values.textcolor ? values.textcolor : color,
				services: servicesAndLoginApi.services,
				loginApi: servicesAndLoginApi.loginApi,
				controllerId: servicesAndLoginApi.controllerId,
				newUIDetails: multiUIData.newUIDetails,
				multiTabsData: multiUIData.multiTabsData,
				uiSettings: uiSettings,
				selectedFrontEnd: uiSettings['Admin'].selectedFrontEnd,
				selectedBackEnd: uiSettings['Admin'].selectedBackEnd,
				uiMenuList: uiMenuList,
			}
			let id = props.editProject && props.editProjectId ? props.editProjectId : localStorage.getItem("projectId");
			projectType == "existing" && id ? body.projectId = id : null
			let savedjsondata = {
				projectName: values.projectName,
				projectType: projectType,
				logo: fileName,
				fav: favName,
				selectedLoginScreen: selectedLoginScreen,
				bg: values.bgColor ? null : bgName,
				selectedBackground: values.bgColor ? 'colour' : "image",
				bgColor: values.bgColor,
				loginSideImage: loginSideImage,
				controllers: AllControllersData,
				// loginApi: this.state.loginApi,
				emailSourceType: emailSourceType,
				sendGridApiKey: sendGridApiKey,
				displayProjectLogoOrName: values.displayProjectLogoOrName,
				nodeMailerEmail: nodeMailerEmail,
				smtphost: smtphost,
				smtpuser: smtpuser,
				s_g_project_Id: s_g_project_Id,
				smtppassword: smtppassword,
				sendGridEmail: sendGridEmail,
				emailService: emailService,
				isGoogleLoginRequired: googleData.isGoogleLoginRequired,
				googleClientID: googleData.googleClientID,
				isGoogleRecaptchaRequired: googleData.isGoogleRecaptchaRequired,
				googleRecaptchaSiteKey: googleData.googleRecaptchaSiteKey,
				api: api,
				services: servicesAndLoginApi.services,
				loginApi: servicesAndLoginApi.loginApi,
				controllerId: servicesAndLoginApi.controllerId,
				newUIDetails: multiUIData.newUIDetails,
				multiTabsData: multiUIData.multiTabsData,
				uiSettings: uiSettings,
				selectedFrontEnd: uiSettings['Admin'].selectedFrontEnd,
				selectedBackEnd: uiSettings['Admin'].selectedBackEnd,
				uiMenuList: uiMenuList,
				color: values.color,
				doNotListInDuplicateScreenDropDown: values.doNotListInDuplicateScreenDropDown,
				multipleListDummy: selectedControllers,
				multipleList: allControllers,
				textcolor: values.textcolor ? values.textcolor : color
			}
			projectType == "existing" && id ? savedjsondata.projectId = id : null
			body.jsonObject = savedjsondata
			body.type = values.type

			if (existingProjectRef?.current && selectedProject == 'existing') {
				delete body.logo;
				delete body.fav;
				delete body.loginApi;
			}
			if (yesJsonUploaded) {
				delete body.controllers;
				delete body.projectName;
				body['reaactJsonFile'] = jsonUploadFile
			}
			console.log("................SRCreate....Submit Body..............", body)

			sendDataToServer(body)
		}
	};

	const yesSubmittedData = async () => {
		await setProgressWidth(20);
	}

	const setLoadingStatus = (e) => {
		setIsLoading(e);
	}

	const sendDataToServer = async (values) => {
		let submitDataValue = false;
		if (!yesJsonUploaded && values.projectName) {
			submitDataValue = true;
		} else if (yesJsonUploaded) {
			submitDataValue = true;
		}
		if (submitDataValue) {
			setShowProgressBarDialog(true);
			setIsProjectDeployedToServer(false);
			setIsLoading(false);
			setSubmitted(true);
			setSaveButton(true);

			// Your API call
			let response = await fetchMethodRequest('POST', apiCalls.ReactNode, values);
			if (response && response.respCode && response.respMessage) {
				setDownloadProjectLink(response.downloadLink);
				setJsonUploadFile('');
				setYesJsonUploaded(false);
				setTimeout(() => {
					setHideTabTwo(false);
				}, 500);
				if (selectedProject && selectedProject === 'existing' && existingProjectRef) {
					setSelectedProject('');
					reset();
					yesSubmittedData();
					existingProjectRef.current.emptyController();
				} else if (selectedProject && selectedProject === 'new' && newProjectRef) {
					showToasterMessage(response.respMessage, 'success');
					setSelectedProject('new');
					setSubmitted(false);
					setProgressBarPer(100);
					setProgressBarColor('danger');
					setShowProgressBarDialog(false);
					setIsProjectDeployedToServer(true);
				}
				setDownloadButton(false);
				setSaveButton(false);
				setProjectId(response.Id);
				setProjectFileName(response.fileName);
				setProjectname(response.projectName);
			} else {
				if (response.errorMessage && response.errorMessage.includes('Session expired')) {
					setSessionExpiryModal(true);
					LogoutTime.resetLocalStorage();
				} else {
					if (response && response.errorCode && response.errorMessage) {
						showToasterMessage(response.errorMessage, 'error');
					}
					setIsLoading(false);
					setShowProgressBarDialog(false);
					setIsProjectDeployedToServer(false);
				}
			}
		} else {
			setIsLoading(false);
		}
	}

	const projectFile = (file) => {
		newProjectRef.current.changeLabel("projectFile");
		fetchMethodRequest('POST', apiCalls.ReactLogo, file.file, 'upload').then((res) => {
			if (res && res.logo) {
				if (newProjectRef) {
					newProjectRef.current.uploadExits(res.logo, "logo");
				}
				setFileName(res.logo);
			} else if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
				// Handle session expiry
				setSessionExpiryModal(true);
				LogoutTime.resetLocalStorage();
			}
		});
	};

	const projectFav = (file) => {
		newProjectRef.current.changeLabel("projectFav");
		fetchMethodRequest('POST', apiCalls.ReactFav, file.file, 'upload').then((res) => {
			if (res && res.fav) {
				if (newProjectRef.current) {
					newProjectRef.current.favIconSubmitted(res.fav);
				}
				setFavName(res.fav);
			} else if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
				setSessionExpiryModal(true);
				LogoutTime.resetLocalStorage();
			}
		});
	};

	const projectLoginSideImage = (file) => {
		newProjectRef.current.changeLabel("loginSideImage");
		fetchMethodRequest('POST', apiCalls.loginSideImage, file.file, 'upload').then((res) => {
			if (res && res.loginSideImage) {
				if (newProjectRef.current) {
					newProjectRef.current.uploadExits(res.loginSideImage, "loginSideImage");
				}
				setLoginSideImage(res.loginSideImage);
			} else if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
				setSessionExpiryModal(true);
				LogoutTime.resetLocalStorage();
			}
		});
	};

	const projectBg = (file) => {
		newProjectRef.current.changeLabel("projectBg");
		fetchMethodRequest('POST', apiCalls.ReactBg, file.file, 'upload').then((res) => {
			if (res && res.bg) {
				if (newProjectRef.current) {
					newProjectRef.current.uploadExits(res.bg, "bg");
				}
				setBgName(res.bg);
				return res.bg;
			} else if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
				setSessionExpiryModal(true);
				LogoutTime.resetLocalStorage();
			}
		});
	};

	const jsonUploadFileHandler = (file) => {
		fetchMethodRequest('POST', 'build/jsonUpload?type=reactAndServer', file.file, 'upload').then(async (res) => {
			if (res && res.fileName) {
				await changeprogressWidth(60);
				setJsonUploadFile(res.fileName);
				setYesJsonUploaded(true);
				setApiUrlTypes(res.loginApi);
				setYesExistingProject(true);
				if (newProjectRef) {
					newProjectRef.current.toggleUploadSample();
					if (existingProjectRef && selectedProject === 'existing') {
						setYesJsonUploaded(true);
						existingProjectRef.current.toggleUploadSample();
						await changeprogressWidth(80);
					}
				} else if (existingProjectRef) {
					setYesJsonUploaded(true);
					await changeprogressWidth(80);
					existingProjectRef.current.toggleUploadSample();
				}
			} else if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
				setSessionExpiryModal(true);
				LogoutTime.resetLocalStorage();
			}
		});
	};

	const handleRadioChanges = async (type, event) => {
		if (type === 'new') {
			if (!yesJsonUploaded) {
				changeprogressWidth(40);
			}
			if (selectedProject === 'existing') {
				setSelectModalShow(true);
			} else {
				setSelectedProject('new');
				setDownloadProjectLink('');
				setHideTabTwo(true);
				setYesExistingProject(false);
			}
		} else if (type === 'exi') {
			if (!yesJsonUploaded) {
				changeprogressWidth(40);
			}
			if (selectedProject === 'new') {
				setSelectModalShow(true);
			} else {
				setSelectedProject('existing');
				setDownloadProjectLink('');
				setYesExistingProject(false);
				setHideTabTwo(true);
			}
		}
	};

	const handleSelectModal = async (type) => {
		await setSelectedModalValue(type);
		setSelectModalShow(false);

		if (selectedProject === 'existing') {
			let res = selectedModalValue;
			if (res === 'no') {
				setSelectedProject('existing');
				setDownloadProjectLink('');
				setHideTabTwo(true);
			} else {
				await props.reset();
				await changeprogressWidth(110);
				setYesExistingProject(false);
				setFileName('');
				setFavName('');
				setSelectedProject('new');
				setYesJsonUploaded(false);
				setDownloadProjectLink('');
				setHideTabTwo(true);
			}
		} else if (selectedProject === 'new') {
			let res = selectedModalValue;
			if (res === 'no') {
				setSelectedProject('new');
				setDownloadProjectLink('');
				setHideTabTwo(true);
			} else {
				await props.reset();
				await changeprogressWidth(110);
				setYesExistingProject(false);
				setFileName('');
				setFavName('');
				setSelectedProject('existing');
				setYesJsonUploaded(false);
				setDownloadProjectLink('');
				setHideTabTwo(true);
			}
		} else {
			setSelectedProject(selectedProject);
			setDownloadProjectLink('');
			setHideTabTwo(true);
		}
	};

	const handleProgressWidth = async () => {
		if (selectedProject) {
			setShowConfirmModal(true);
		} else {
			await handleTabs('one');
			setShowConfirmModal(false);
		}
	};

	const confirmModalOpened = async (type) => {
		if (type === 'yes') {
			setSelectedProject('');
			setYesExistingProject(false);
			setYesJsonUploaded(false);
			setHideTabTwo(false);
			setShowConfirmModal(false);
			await props.reset();
			setTimeout(() => {
				handleTabs('one');
			}, 500);
		} else if (type === 'no') {
			setShowConfirmModal(false);
		}
	};

	const handleyesExistingProject = async (type, projectName) => {
		setYesExistingProject(type);
		setSaveProjectName(projectName);
	};

	const changeprogressWidth = async (widthValue) => {
		let x = progressWidth;
		if (widthValue !== 110) {
			if (widthValue !== 0) {
				if (x !== 100) {
					if (x > widthValue) {
						x = x - 20;
					} else if (x < widthValue) {
						x = x + 20;
					} else if (widthValue === 20) {
						x = 0;
					}
					setProgressWidth(x);
				} else {
					setProgressWidth(100);
				}
			} else {
				setProgressWidth(0);
			}
		} else {
			setProgressWidth(40);
		}
	};

	const handleTabs = async (type) => {
		if (type === 'one') {
			setSetMainTab('one');
			setHideTabOne(false);
			setHideNextScreen(true);
			changeprogressWidth(0);
		} else if (type === 'two') {
			setSetMainTab('two');
			setActiveTwoTab(true);
			setHideTabOne(true);
			setHideNextScreen(false);
		} else if (type === 'three') {
			setSetMainTab('three');
			setActiveThreeTab(true);
		} else if (type === 'four') {
			setSetMainTab('four');
			setActiveFourTab(true);
		}
	};

	const deployProject = async () => {
		setIsLoading(true);
		let userBody = {
			projectName: projectname,
			fileName: projectFileName,
			Id: projectId
		};
		try {
			const response = await fetchMethodRequest('POST', apiCalls.serverCreate, userBody);
			if (response && response.respCode && response.respCode === 200) {
				showToasterMessage(response.respMessage, 'success');
			} else if (response && response.errorMessage) {
				showToasterMessage(response.errorMessage, 'error');
			}
		} catch (err) {
			console.error(err);
		}
		setIsLoading(false);
	};

	const saveProject = (e) => {
		let api = newProjectRef.current.returnApi();
		let color = newProjectRef.current.returnColor();
		let textcolor = newProjectRef.current.returnTextColor()
		let emailSourceType = newProjectRef.current.returnMailServiceType()
		let sendGridApiKey = newProjectRef.current.returnsendgridApiKey()
		let nodeMailerEmail = newProjectRef.current.returnnodeMailerEmail()
		let smtphost = newProjectRef.current.returnsmtphost()
		let smtpuser = newProjectRef.current.returnsmtpuser()
		let smtppassword = newProjectRef.current.returnsmtppassword()
		let emailService = newProjectRef.current.returnEmailService()
		let googleData = newProjectRef.current.returnisGoogleLoginRequired()
		let sendGridEmail = newProjectRef.current.returnsendgridEmail()
		let displayProjectLogoOrName = newProjectRef.current.returnDisplayProjectLogoOrName()
		let doNotListInDuplicateScreenDropDown = newProjectRef.current.returnDoNotListInDuplicateScreenDropDown()
		let AllControllersData = [...newProjectRef.current.returnControllerArray()]
		let selectedControllers = newProjectRef.current.returnSelectedControllers();
		let allControllers = newProjectRef.current.returnAllControllers();
		let projectType = newProjectRef.current.returnProjectType();
		let selectedLoginScreen = newProjectRef.current.returnGetSelectedTemplate();
		let s_g_project_Id = newProjectRef.current.returns_g_project_Id();
		let projectSavedName = props.editProject && projectData && projectData.name ? projectData.name : localStorage.getItem("projectSavedName");

		let jsonObject = {
			controllers: AllControllersData,
			loginApi: AllControllersData && AllControllersData[api] && AllControllersData[api].name ? AllControllersData[api].name : AllControllersData[0].name,
			multipleListDummy: selectedControllers,
			multipleList: allControllers,
			projectName: saveProjectName ? saveProjectName : projectSavedName,
			projectType: projectType,
		};
		if (jsonObject && jsonObject.controllers && jsonObject.controllers.length > 0) {
			for (let i in jsonObject.controllers) {
				if (jsonObject.controllers[i].controllerFields && jsonObject.controllers[i].controllerFields.length > 0) {
					let showOrHideFields = []
					for (let ob of jsonObject.controllers[i].controllerFields) {
						if (ob.showOrHideFields && ob.showOrHideFields.length > 0) {
							showOrHideFields = [...showOrHideFields, ...ob.showOrHideFields]
						}
					}
					//removing the Duplicate Names
					jsonObject.controllers[i].hideFields = [...new Set(showOrHideFields)];
				}
			}
		}
		let uiSettings = {}, uiMenuList = {}, selectedFrontEnd = '', selectedBackEnd = '';
		let multiUIData = newProjectRef.current.returnMultiUiData();
		let servicesAndLoginApi = newProjectRef.current.returnServicesAndLoginApi();

		for (let ob of servicesAndLoginApi.services) {
			uiSettings[ob] = multiUIData.newUIDetails[ob].settings
			uiMenuList[ob] = multiUIData.newUIDetails[ob].menuList
			selectedFrontEnd = uiSettings['Admin'].selectedFrontEnd
			selectedBackEnd = uiSettings['Admin'].selectedBackEnd
		}
		uiSettings['Admin'].selectedLoginTemplate = selectedLoginScreen

		jsonObject.services = servicesAndLoginApi.services
		jsonObject.newUIDetails = multiUIData.newUIDetails
		jsonObject.multiTabsData = multiUIData.multiTabsData
		jsonObject.api = api
		jsonObject.s_g_project_Id = s_g_project_Id
		jsonObject.controllerId = servicesAndLoginApi.controllerId
		jsonObject.loginApi = servicesAndLoginApi.loginApi
		jsonObject.uiSettings = uiSettings
		jsonObject.selectedFrontEnd = uiSettings['Admin'].selectedFrontEnd
		jsonObject.selectedBackEnd = uiSettings['Admin'].selectedBackEnd
		jsonObject.uiMenuList = uiMenuList
		jsonObject.emailSourceType = emailSourceType
		jsonObject.sendGridApiKey = sendGridApiKey
		jsonObject.nodeMailerEmail = nodeMailerEmail
		jsonObject.smtphost = smtphost
		jsonObject.smtpuser = smtpuser
		jsonObject.smtppassword = smtppassword
		jsonObject.sendGridEmail = sendGridEmail
		jsonObject.emailService = emailService
		jsonObject.isGoogleLoginRequired = googleData.isGoogleLoginRequired
		jsonObject.googleClientID = googleData.googleClientID
		jsonObject.isGoogleRecaptchaRequired = googleData.isGoogleRecaptchaRequired
		jsonObject.googleRecaptchaSiteKey = googleData.googleRecaptchaSiteKey
		jsonObject.color = color
		jsonObject.textcolor = textcolor
		jsonObject.doNotListInDuplicateScreenDropDown = doNotListInDuplicateScreenDropDown
		jsonObject.fav = favName
		jsonObject.displayProjectLogoOrName = displayProjectLogoOrName
		jsonObject.selectedLoginScreen = selectedLoginScreen
		jsonObject.loginSideImage = loginSideImage
		jsonObject.logo = fileName

		let bgColor = newProjectRef.current.returnBgColor()
		if (bgColor) {
			setBgName(null);
			jsonObject.bgColor = bgColor
			jsonObject.bg = null
			jsonObject.selectedBackground = 'colour'
		} else {
			jsonObject.selectedBackground = "image"
			jsonObject.bgColor = null
			jsonObject.bg = bgName
		}
		jsonObject.projectName = saveProjectName ? saveProjectName : projectSavedName
		jsonObject.projectType = projectType
		let id = props.editProject && props.editProjectId ? props.editProjectId : localStorage.getItem("projectId");
		projectType == "existing" && id ? jsonObject.projectId = id : null

		let userBody = {
			controllers: jsonObject,
			projectType: projectType,
			selectedLoginScreen: selectedLoginScreen,
			projectName: saveProjectName ? saveProjectName : projectSavedName,
			loginApi: servicesAndLoginApi.loginApi,
			fav: fav,
			doNotListInDuplicateScreenDropDown: doNotListInDuplicateScreenDropDown,
			logo: logo,
			selectedBackground: jsonObject.selectedBackground,
			bgColor: bgColor,
			uiSettings: uiSettings,
			selectedFrontEnd: uiSettings['Admin'].selectedFrontEnd,
			selectedBackEnd: uiSettings['Admin'].selectedBackEnd,
			s_g_project_Id: s_g_project_Id,
			uiMenuList: uiMenuList,
			controllerId: servicesAndLoginApi.controllerId,
			services: servicesAndLoginApi.services,
			newUIDetails: multiUIData.newUIDetails,
			multiTabsData: multiUIData.multiTabsData,
			displayProjectLogoOrName: displayProjectLogoOrName,
			bg: jsonObject.bg
		};
		setServices(servicesAndLoginApi.services);

		projectType == "existing" && id ? userBody.projectId = id : null
		return fetchMethodRequest('POST', apiCalls.saveProject, userBody)
			.then((response) => {
				if (response && response.respCode && response.respCode === 200) {
					// display message
					// Get the project ID from the response
					const projectId = response.record._id;
					// this.newProjectRef.state.projectId = projectId;
					newProjectRef.current.setProjId(projectId);
					localStorage.setItem("projectId", projectId);

					showToasterMessage(response.respMessage, 'success');
					// this.newProjectRef.state.editModal = "existing";
					newProjectRef.current.changeEditModal("existing");
				} else if (response.errorMessage && response.errorMessage == 'Session expired please login again.') {
					setSessionExpiryModal(true);

					LogoutTime.resetLocalStorage()
				} else if (response && response.respMessage && response.respCode === 9001) {
					// display message
					showToasterMessage(response.respMessage, 'info');
				} else if (response && response.errorMessage) {
					// display message
					showToasterMessage(response.errorMessage, 'error');
				}
				setIsLoading(false);

			}).catch((err) => {
				return err;
			});
	}

	const getSessionExpiryModal = () => {
		return (
			<SessionExpiryModal
				SOpen={sessionExpiryModal}
			/>
		)
	}

	const renderProgressStatements = (progressBarPer) => {
		const statements = [
			{ text: 'Client files deploying started' },
			{ text: 'Client files deployed! Server files deploying started' },
			{ text: 'Files deployed in server and setup done' },
			{ text: 'Project is up and running' },
			{ text: 'Deployment completed' },
		];

		const completedIndex = Math.floor((statements.length - 1) * (progressBarPer / 100));
		return statements.map((statement, index) => ({
			...statement,
			tick: index <= completedIndex,
		}));
	}

	const toggleDialog = (dialogName, progressBarPer) => {
		const isDeployed = isProjectDeployedToServer;
		isDeployed ? setShowProgressBarDialog(false) : null;
	}

	const getchildData = (received) => {
		setCurrentUI(received);
	}

	const decideWhichUItoPreview = () => {
		let flagg = 0;
		let wantedLink = '';
		let arr = Object.keys(projectData.site);
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] == currentUI) {
				console.log(arr[i])
				flagg = 1;
				wantedLink = projectData.site[arr[i]];
			}
		}
		if (flagg != 0) {
			return wantedLink;
		}
		else {
			return null;
		}
	}

	const handlePreviewProject = () => {
		let PROJDATA = projectData;
		if (PROJDATA.pingItProjectDeployStatus == "running" && PROJDATA.pingItStatus == "up" && PROJDATA.projectStatus == "Running") {
			return false
		}
		else {
			return true;
		}
	}


	// return(
	return (<div>
		{/* <form onSubmit={handleSubmit(this.submit)}> */}

		<Loader loader={isLoading} />

		{selectedProject && selectedProject == "new" ? (
			<RSNewProject
				ref={newProjectRef}
				projectFile={projectFile}
				projectLoginSideImage={projectLoginSideImage}
				jsonUploadFile={jsonUploadFile}
				apiUrlTypes={apiUrlTypes}
				yesJsonUploaded={yesJsonUploaded}
				handleAllTabs={handleTabs}
				setLoadingStatus={setLoadingStatus}
				changeprogressWidth={changeprogressWidth}
				projectFav={projectFav}
				projectBg={projectBg}
				projectData={projectData}
				editProject={props.editProject}
				editProjectId={props.editProjectId}
				setButtonValues={setButtonValues}
				submitted={submitted}
				// loginUrl={loginUrl}
				submit={submit}
				handleyesExistingProject={handleyesExistingProject}
				getchildData={getchildData}
			/>
		) : selectedProject && selectedProject == "existing" ? (
			<RSExistingProject
				ref={existingProjectRef}
				projectFile={projectFile}
				showProgressBar={showProgressBarDialog}
				jsonUploadFile={jsonUploadFile}
				apiUrlTypes={apiUrlTypes}
				yesJsonUploaded={yesJsonUploaded}
				changeprogressWidth={changeprogressWidth}
				handleyesExistingProject={handleyesExistingProject}
			/>
		) : null}


		<Dialog
			header="Deploying Project"
			visible={showProgressBarDialog}
			onHide={() => this.toggleDialog('showProgressBarDialog', progressBarPer)}
			style={{ width: '800px', height: '200px', textAlign: 'center' }}
			breakpoints={{ '960px': '75vw', '641px': '100vw' }}
		>
			{/* value={this.state.progressBarPer} displayValueTemplate={() => `${this.state.progressBarPer}%`} */}
			<ProgressBar mode="indeterminate" style={{ height: '5px' }} />
			{renderProgressStatements(progressBarPer).map((statement, index) => (
				<div key={index} className='text-left mt-2' style={{ fontSize: '1.5rem' }}>
					{statement.tick ? (
						<i className="pi pi-check-circle" style={{ color: 'green', fontSize: '1.5rem' }}></i>
					) : (
						<i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem' }}></i>
					)}
					{statement.text}
				</div>
			))}
		</Dialog>
		<div className="text-center mt-3" style={{ margin: "auto", }}>
			<Button
				className='ms-auto mb-0 mt-1'
				color='custom'
				size={'xs'}
				onClick={(e) => saveProject(e)}
				disabled={saveButton}
			>
				{"Save project"}
			</Button>
			<Button
				className='ms-auto mb-0 mt-1  '
				size={'xs'}
				color='custom'
				disabled={downloadButton}
			>
				<a style={{ height: "30px", position: "absolute", width: "60px" }} href={downloadProjectLink ? downloadProjectLink : null} download></a>
				{"Download project"}
			</Button>
			<Button
				className='ms-auto mb-0 mt-1  '
				size={'xs'}
				onClick={() => newProjectRef.current.submit(newProjectRef.current.setType("getdata"), "clicked")}
				disabled={deployButton}
				color='custom'
			>
				{"Deploy project to server"}
			</Button>
			<Button className='ms-auto mb-0 mt-1  '
				color='custom'
				onClick={() => window.open(decideWhichUItoPreview())}
				disabled={handlePreviewProject()}
			>
				{"Preview the Project"}
			</Button>
		</div>
		<ConfirmModal
			showConfirmModal={showConfirmModal}
			confirmModalOpened={confirmModalOpened}
		/>
		<SelectModal
			selectModalShow={selectModalShow}
			handleSelectModal={handleSelectModal}
		/>
		{/* </form> */}
		{sessionExpiryModal ? getSessionExpiryModal() : null}
	</div>
	);
	// <>
	// 	<Loader loader={this.state.isLoading} />
	// </>

}

export default ClientCode;