const imagePath = '../assets/';
const configImages = {
    //defaultImg: require('../assets/img/profile/userProfile.png'),
    favImg: require('../assets/fav.ico'),
    nodeImg: require("../assets/nodejs.png"),
    reactImg: require("../assets/react.png"),
    Node: require("../assets/node.png"),
    Java: require("../assets/java.png"),
    React: require("../assets/react.png"),
    Angular: require("../assets/angular.png")
    //employeesSampleXL: require('../assets/files/employeeSample.xlsx'),
};
export default configImages;
