import React,  { Component, Fragment, useEffect, useState }from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// import { hot } from 'react-hot-loader';

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../../scss/app.scss';


import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';

import { ToastContainer, toast, TypeOptions } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

// import fontAwsome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUsers, faTachometerAlt, faStethoscope, faPaperPlane, faUserFriends,
  faPlus, faPlusCircle, faFile, faCog, faChevronDown, faEdit, faChevronLeft, faChevronRight, faTrashAlt, faThumbsUp, faShoppingCart, faComments, faFemale, faMale, faDownload,
  faMoneyBillAlt, faPrint, faCheck, faGlobeAsia, faList, faUser, faDollarSign, faCertificate, faSyncAlt, faUpload, faBookOpen,
  faBan, faCheckCircle, faAngleDown, faTimesCircle, faEyeDropper, faGripHorizontal, faCalendar, faTable, faInfoCircle, faBars, faHandPaper
  //   faUsers, faSave, faTachometerAlt, faStethoscope, faPaperPlane, faUserFriends,
  //   faPlus, faPlay, faStop, faFile, faEdit, faTrashAlt, faThumbsUp, faShoppingCart, faComments, faFemale, faMale, faDownload, faMoneyBillAlt, faGlobeAsia, faMinus,
  //   faUser, faArrowDown, faArrowUp, faAngleDown, faAngleUp, faHandPointDown, faTimesCircle, faPlusCircle, faDollarSign, faCertificate, faSyncAlt, faUpload, faBookOpen, faCheckCircle, faArrowAltCircleLeft,
} from '@fortawesome/free-solid-svg-icons'

// fontawsome icons library
library.add(

  faTachometerAlt,
  faCheck,
  faPlusCircle,
  faChevronDown,
  faList,
  faAngleDown,
  faPrint,
  faChevronLeft,
  faChevronRight,
  faGripHorizontal,
  faUsers,
  faCog,
  faStethoscope,
  faPaperPlane,
  faUserFriends,
  faPlus,
  faFile,
  faEdit,
  faTrashAlt,
  faThumbsUp,
  faShoppingCart,
  faComments,
  faDownload,
  faMoneyBillAlt,
  faGlobeAsia,
  faUser,
  faDollarSign,
  faCertificate,
  faSyncAlt,
  faUpload,
  faBookOpen, faFemale, faMale,
  faBan, faCheckCircle, faTimesCircle, faCalendar, faTable, faInfoCircle, faBars,
  faHandPaper, faEyeDropper
  // faMinus,
  // faTachometerAlt,
  // faUsers,
  // faStethoscope,
  // faPaperPlane,
  // faUserFriends,
  // faPlus,
  // faPlusCircle,
  // faFile,
  // faEdit,
  // faTrashAlt,
  // faThumbsUp,
  // faShoppingCart,
  // faComments,
  // faDownload,
  // faMoneyBillAlt,
  // faGlobeAsia,
  // faUser,
  // faDollarSign,
  // faCertificate,
  // faSyncAlt,
  // faUpload,
  // faBookOpen, faFemale, faMale,
  // faCheckCircle,
  // faTimesCircle,
  // faArrowAltCircleLeft,
  // faArrowDown,
  // faHandPointDown,
  // faAngleDown,
  // faAngleUp,
  // faArrowUp,
  // faSave,
  // faStop,
  // faPlay
)

i18next.init(i18nextConfig);


function App () {
  const [preLoader,setPreLoading] = useState(true);
  const [isPageLoaded,pageLoad] = useState(false);

  //  We are Not using this code,  commenting for now
  // useEffect(()=> {
  //   window.addEventListener('load', () => {
  //     setPreLoading(false);
  //     setTimeout(() => pageLoad(true), 500);
  //   });
  // })

  return (
    <Provider store={store}>
      <ToastContainer  position="top-right" />
      <BrowserRouter basename="/">
        <I18nextProvider i18n={i18next}>
          <ScrollToTop>
            <Fragment>
              <>
                <Router />
              </>
            </Fragment>
          </ScrollToTop>
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
}
export default App;

