import React, { useCallback,useState } from 'react';
import {useIdleTimer} from "react-idle-timer";
import SessionExpiryModal from '../../containers/CommonModals/SessionexpiryModal'

const IdleTimout = (props) => {
    const [isTimeOut, setIsTimeOut] = useState(false);
    const timeOut = 1000 * 60 * 15;    
    const handleOnIdle = useCallback( () => {
        if(!isTimeOut){
            setIsTimeOut(true);
        }       
    });
    // const handleOnAction = useCallback( () => {
    //     setIsTimeOut(false);        
    // });
    const IdleTimer = useIdleTimer({
        crossTab: true,
        onIdle: handleOnIdle,
        timeout:timeOut        
    });
    return (
        <>
        <SessionExpiryModal SOpen={isTimeOut}></SessionExpiryModal>          
        </>
    )
}

export default IdleTimout;