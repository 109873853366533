import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import GoogleButton from './GoogleButton';
import FacebookButton from './FacebookButton';
import Captcha from './Captcha';


class RegisterStaticForm extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {

        }

    }
    render() {
        return (
            <div>
                <div>
                    <div className="form-group form-group-sm">
                        <label for="exampleInputEmail1">First Name</label>
                        <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                    </div>
                    <div className="form-group form-group-sm">
                        <label for="exampleInputEmail1">Last Name</label>
                        <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />
                    </div>
                    <div className="form-group form-group-sm">
                        <label for="exampleInputEmail1">Email address</label>
                        <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                    </div>
                    <div className="form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Password" />
                    </div>
                    <div className="form-group">
                        <label for="exampleInputPassword1">Confirm Password</label>
                        <input type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Password" />
                    </div>
                    {this.state.captcha || this.props.googleCaptcha ?
                        <div className='my-2'>
                            <Captcha></Captcha>
                        </div>
                        : null
                    }                    
                    <button type="submit" className="btn btn-custom btn-sm btn-block"
                     style={{
                        backgroundColor: (this.props.buttonType === 'regular' ? this.props.buttonColour : 'transparent'),
                        color: (this.props.buttonType === 'regular' ? this.props.buttonTextColour : this.props.buttonColour),
                         borderColor: this.props.buttonColour,
                        borderRadius: (this.props.buttonRounded ? '20px' : '4px')                        
                    }}
                     >Submit</button>
                </div>
                {this.state.googleLogin || this.props.googleLogin ?
                    <div>
                        <GoogleButton></GoogleButton>

                    </div>
                    : null
                }
                {this.state.facebookLogin ?
                    <div className='mt-2'>
                        <FacebookButton></FacebookButton>
                    </div>
                    : null
                }
            </div>
        )
    }
}
export default withTranslation('common')(RegisterStaticForm);