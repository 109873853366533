import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody, Button, Col, Container, Row, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes, { func } from 'prop-types';
import { Redirect } from 'react-router';
import CountDisplay from './components/CountDisplay';
import fetch from '../../config/service';
import config from '../../config/config'
import apiCalls from '../../config/apicalls';
import Loader from '../App/Loader';
import Project from '../Cruds/Project/';
import { faPlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'primereact/dropdown';

const Dashboard =(props)=> {

  const statusList = [
      { label:'All Projects', value:'All' },
      { label: 'Running', value: 'Running' },
      { label: 'Stopped', value: 'Stopped' },
      { label: 'Developing', value: 'Developing' },   
  ];

  const [dashboardData, updateDashBoardData] = useState([]);
  const [isLoading, setIsLoading] = useState(null);
  const [expirationsList] = useState(null);
  const [meetingsData] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showProjects] = useState(false);
  const [redirectToRscode, setRedirectRs] = useState(false);
  const [redirectToProject, setRedirectProj] = useState(false);
  const [status, onStatusUpdate] = useState('All');

  const [loginData, setLoginData] = useState(null);
  const [role, setUserRole] =  useState(null);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      if (user) {
          setLoginData(user);
          setUserRole(user.role);
      }
      // getCountData();
  },[]);

    // Get dashboard counts
  const getCountData =()=> {
      let api = apiCalls.Project;
      let url = api;
      if(status && status.toLowerCase() !== 'all') { 
          // const { value } = status;    
          let filter = '?filter=' + JSON.stringify({'criteria':[{ key: 'projectStatus', value: status, type:"eq" }]});
          url += filter;
      }
      setIsLoading(true);
      fetch('GET', url).then((response) => {
        setIsLoading(false);
          if (response && response.projects && response.projects.length) {
            const projectList = response['projects'];
            updateDashBoardData(projectList);
              if(projectList.length && projectList.length >= 8) { 
                  setShowMore(true);
              } else {
                  setShowMore(false);
              }
          } else {
            updateDashBoardData([]);
          }
      }).catch((err) => {
          return err;
      });
  }

  const redirectTo = (value) => {
      if(value && typeof(value) === 'string') {
          if(value === "projects") {
              setRedirectProj(true);
              setRedirectRs(false);
          } else {
              setRedirectProj(false);
              setRedirectRs(true); 
          }
      }
  }

  useEffect(()=> {
    getCountData();
  },[status]);


  return(
      <>
          <div className="dashboard">
              <Loader loader={isLoading} />
              <Row>
                  <Col md={6}>
                      <h3 className="page-title" style={{ marginBottom: '5px' }}>Dashboard</h3>
                  </Col>
                  <Col md={6} style={{ textAlign: "right" }}>
                      <Button color='custom' className="openpro"  onClick={() => redirectTo("projects")}>{'Open Projects'}</Button>
                      <Button color='custom' className="newpro"  onClick={() => redirectTo("create_project")}>{'Project from Json'}</Button>
                      <Button color='custom' className="newpro"  onClick={() => redirectTo("create_project")}>{'New Project'}</Button>
                  </Col>
              </Row>
              <div className='d-flex align-items-center justify-content-end mb-2'>
                  <div className="block" style={{width: '15%'}}>
                          <Dropdown value={ status } onChange={(e) => {onStatusUpdate(e.value)}} options={statusList} optionLabel="label" 
                              placeholder="Select Project Status" className="w-full md:w-14rem" />
                  </div>
              </div>

              <div>
                  <CountDisplay
                      dashboardData={ dashboardData }
                      showMore={ showMore }
                      getCountData={ getCountData }
                  />
              </div>

              { redirectToProject ? <Redirect to='projects' /> : null }
              { redirectToRscode ? <Redirect to='create_project' /> : null }
          </div>  
      </>
  )

}
export default Dashboard;
