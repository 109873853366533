import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import '../../../scss/login.scss';

class Captcha extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {

        }

    }
    render() {
        return (
            <div className='d-flex captchaBox w-100'>
                { (this.props.showCheck) ?
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
                        <label className="form-check-label" for="defaultCheck1">
                        I'm not a robot 
                        </label>
                    </div>
                :<div className='ps-2'>I'm not a robot</div> }
                <div>
                    <img src="./img/captcha.jpg" alt="" />
                </div>
            </div>
        )
    }
}
export default withTranslation('common')(Captcha);