import React from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apicalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';
import { Redirect } from 'react-router';
import fetchMethodRequest from '../../../../config/service';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
// config file
export default class Projects extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      type: 'Projects',
      rowData: "",
      filterCriteria: { limit: 20, page: 1, criteria: [], sortfield: 'created', direction: 'desc' },
    };
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  //Table fields keys and values significance

  // show:true                        to display field in datatable

  // textAlign:                          decides to display field value at left,right,center,

  // width:                               width of field (100)

  // "field":                           field name,

  // header:                          heading 

  // fieldType:"Badge"                   decides format of field value

  // 'displayInSettings': true          to display field key in settings

  // filter: true,                      search filter by default  is disabled please enable in datatable.jsx

  // sortable: false,                    sort data table 

  // mobile:true                         decides field to be displayed in grid and mobile view 

  // displayInFilter: true,           decides to display field in multi filter or not,
  //                                    Note:options are needed

  //'options': [                        these options are displayed in filter and also in view
  //  { 
  // "label": "Active",                   label is choosen to display
  // "value": 'Active',                   value will be sent to server
  // 'checkIcon': false,                  decides either checkicon to be true or false by deafulr false,
  // "color": 'success'                   color of label
  // },
  // ],
  setData = (props) => {
    let rowData = {}
    if (props && props.match && props.match.params && props.match.params.id) {
      rowData["_id"] = JSON.parse(props.match.params.id)
      return rowData
    }
  }
  componentDidMount = () => {
    //Screen permisions value can be edit,view, no view
    let screenPermissions = RolePermissions.screenPermissions('Projects');
     }

  getTableFields = () => {
    let { languageData } = this.state
    let data = [
      {
        "textAlign": "center",
        "width": 47,
        "field": "multiple",
        "fieldType": 'multiple',
        "header": "",
        "label": "",
        'selectionMode': 'multiple',
        "show": true,
        "mobile": true,
        "displayInSettings": false,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "displayInSettings": true,
        "placeholder": "Search"
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 140,
        "field": "name",
        "mobile": true,
        "header": "Name",
        "label": "Name",
        "filter": true,
        "fieldType": 'Link',
        "displayInSettings": true,
        "sortable": true
      },
      // {
      //   "show": true,
      //   "textAlign": "left",
      //   "width": 140,
      //   "field": "site",
      //   "mobile": true,
      // "label": "Site",
      //   "header": "Site",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "sortable": true
      // },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "port",
        "mobile": true,
        "label": "Port",
        "header": "Port",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "projectStatus",
        "mobile": true,
        "label": "Project Status",
        "header": "Project Status",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "deployStatus",
        "label": "Deploy Status",
        "mobile": true,
        "header": "Deploy Status",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "userName",
        "label": "Created By",
        "mobile": true,
        "header": "Created By",
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 180,
        "field": "created",
        "mobile": true,
        "label": "Created",
        "header": "Created",
        'fieldType': 'Date',
        'dateFormat': 'MM-DD-YYYY',
        "displayInSettings": true,
        "filter": false,
        "sortable": true,
      },
      // {
      //   "show": true,
      //   "textAlign": "right",
      //   "width": 140,
      //   "mobile": true,
      //   "field": "phone",
      //   "header": "Phone",
      //   "filter": false,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   "options": [{ "label": "Active", "value": 'Active' }, { "label": "Pending", "value": 'Pending' }, { "label": "Inactive", "value": 'Inactive' }],
      //   "sortable": true,
      // },


      // {
      //   "show": true,
      //   textAlign: 'center',
      //   width: 80,
      //   field: 'projectStatus',
      //   "mobile": true,
      //   fieldType: 'Badge',
      //   header: 'Project Status',
      //   filter: true,
      //   "displayInSettings": true,
      //   "displayInFilter": true,
      //   sortable: false,
      //   options: [{ "label": "Run", "value": "Run", "color": "success" }, { "label": "Stop", "value": "Stop", "color": "danger" }],
      //   filterElement: [{ "label": "All", "value": null }, { "label": "Run", "value": 'Run' }, { "label": "Stop", "value": 'Stop' }],
      // },
    ]
    return data;
  };



  // Form fields key and values significance

  // required: true,                will be validated

  // type: 'dropDown',              decides which type of field we have render

  // name: 'status',                name of the field

  // label: 'Status',               label of the field

  // id: 'status',                  id of the field

  // options: [                     for type dropDown, multiSelect options are needed these options are rendered has dropdown
  // { 
  // "label": "Active",
  // "value": 'Active'
  // },
  // ],

  // placeholder: 'Status',         placeholder of the field

  // isAddFormHidden: true,         decides field to be displayed in add form are not

  // isEditFormHidden:true          decides field to be displayed in edit form are not

  getFormFields = () => {
    let { languageData } = this.state
    return ([
      {
        'show': false,
        "value": "",
        "type": "text",
        "name": "name",
        "label": "Name",
        "id": "name",
        "placeholder": "Name",
        "required": true
      },
      {
        "value": "",
        "type": "text",
        "name": "userEmail",
        "label": "Email",
        "id": "email",
        "placeholder": "Email",
        "required": true
      },
      {
        "value": "new",
        "type": "text",
        "name": "port",
        "label": "Port",
        "id": "port",
        "placeholder": "Port",
        "required": true
      },

      {
        "value": "new",
        "type": "text",
        "name": "site",
        "label": "Site",
        "id": "site",
        "placeholder": "Site",
        "required": true
      },

      {
        required: true,
        value: '',
        type: 'dropDown',
        name: 'projectStatus',
        // isMultiSelect:true,
        label: 'Project Status',
        id: 'projectStatus',
        options: [{ "label": "Run", "value": 'Run' }, { "label": "Stop", "value": 'Stop' }],
        placeholder: 'Project Status',
        // isAddFormHidden: true,
      },
    ]);
  }


  //Props passed to Datatable.jsx

  // actionsTypes [                             these actionTypes are used has action other than edit and and
  //  {
  // 'name': 'Delete',                          name of the action
  // "options": [                               options of the actions these are important 
  // { 
  // 'label': 'Delete',                         label of actionType display on screen
  // 'value': 'Delete',                         value will be sent to server
  // 'show': true,                              either these to be displayed are not
  // "multiple": true,                          options to delete multiple records
  // },
  // }
  // ]

  // Condition based actions

  // exportRequried = {true}                   Export to CSV

  // printRequried = {true}                    Print

  // addRequried = {true}                      Add

  // editRequired = {true}                     Edit

  // deleteRequired = {true}                   Delete

  // viewRequired = {true}                     View

  // settingsRequired = {true}                 Settings

  // filterRequired = {true}                   Filter

  // gridRequried = {true}                     Grid and List View

  // sample = {true}                           
  // globalSearchFieldName = 'fname'
  // globalSearch = { 'First Name'}
  // type = 'Projects'
  // routeTo = 'project' 
  // displayViewOfForm = 'screen'
  // apiUrl = { apiCalls.Project }
  // entityType = 'project'
  closeViewModal = () => {
    this.setState({
      openViewModal: false,
    })
    this.datatableref.getDataFromServer(this.state.filterCriteria, "refresh");
  }
  submit = async (item) => {
    await this.setState({
      openViewModal: true,
      rowData: [{ 'label': 'name', 'value': 'new name' }]
    })
    // let userBody = item;
    // return fetch("POST", "orders/sendEmail", userBody)
    // .then((response) => {
    //   if (response && response.respCode && response.respCode === 200) {
    //     this.datatableref.getDataFromServer(this.state.filterCriteria,"refresh");
    //     showToasterMessage(response.respMessage, "success");
    //   } else if (response && response.errorMessage) {
    //     showToasterMessage(response.errorMessage, "error");
    //   }
    //   this.setState({
    //     isLoading: false,
    //   });
    // })
    // .catch((err) => {
    //   return err;
    // });
  }
  startProject(item) {
    let userBody = {
      projectName: item[0].name,
      type: "start",
      Id: item[0]._id
    };
    return  fetchMethodRequest('POST', apiCalls.serverStatus, userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          // display message    
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          // display message
          showToasterMessage(response.errorMessage, 'error');
        }
        this.setState({ isLoading: false});

      }).catch((err) => {
        return err;
      });
    
  }
  stopProject(item) {

    let userBody = {
      projectName: item[0].name,
      type: "stop",
      Id: item[0]._id
    };
    return fetchMethodRequest('POST', apiCalls.serverStatus, userBody)
      .then((response) => {
        if (response && response.respCode && response.respCode === 200) {
          // display message
          showToasterMessage(response.respMessage, 'success');
               } else if (response && response.errorMessage) {
          // display message
          showToasterMessage(response.errorMessage, 'error');
        }
        this.setState({ isLoading: false});

      }).catch((err) => {
        return err;
      });


  }
  render() {
    return (
      <span>
        <DataTables
          {...this.props}
          onRef={(ref) => (this.datatableref = ref)}
          getTableFields={this.getTableFields}
          formFields={this.getFormFields}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
          {
            'name': 'Start',
            // 'action': this.startProject,
            "options": [
              { 'label': 'Start', 'value': 'Start', 'show': true, "multiple": true, },
            ]
          },
          {
            'name': 'Stop',
            // 'action': this.stopProject,
            "options": [
              { 'label': 'Stop', 'value': 'Stop', 'show': true, "multiple": true, },
            ]
          },
            // {
            //   'name': 'Block',
            //   "options": [
            //     { 'label': 'Block', 'value': 'Block', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'UserPassword',
            //   'action': this.submit,
            //   "options": [
            //     { 'label': 'UserPassword', 'value': 'UserPassword', 'show': true, "multiple": false, }
            //   ]
            // },
            // {
            //   'name': 'Submit',
            //   'action':this.submit,
            //   "options": [
            //     { 'label': 'Submit', 'value': 'Submit', 'show': true, "multiple": false},
            //   ]
            // }
          ]}
          exportRequried={true}
          printRequried={true}
          addRequried={false}
          editRequired={true}
          deleteRequired={true}
          viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          sample={false}
          // menuList={[
          //   {
          //     "icon": "project",
          //     "displayTitle": "Projects",
          //     "title": "Project",
          //     "route": "/project"
          //   },
          //   // {
          //   //   "icon": "project",
          //   //   "displayTitle": "Projects",
          //   //   "title": "Project",
          //   //   "route": "/project"
          //   // },
          // ]}
          globalSearchFieldName='user'
          globalSearch={'Name'}
          type='Projects'
          routeTo='projects'
          statusValue={this.props.filterValue ? this.props.filterValue : null}
          homeRouteTo="dashboard"
          homeRouteType="Dashboard"
          displayViewOfForm='screen'
          apiResponseKey='projects'
          setData={this.setData}
          rowData={this.state.rowData}
          apiUrl={apiCalls.Project}
          entityType='project'
        />
        {
          this.state.openViewModal ? (
            <ViewModal
              onRef={(ref) => (this.viewModalRef = ref)}
              type={this.state.type}
              openViewModal={this.state.openViewModal}
              displayViewOfForm={'Modal'}
              rowData={this.state.rowData}
              closeViewModal={this.closeViewModal}
            />
          ) : null
        }
      </span>
    );
  }
}