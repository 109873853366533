import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 9999,
    textTransform: 'capitalize',
  }),
  option: (provided, state) => ({
    ...provided,
    position: 'relative',
  }),
  singleValue: (provided, state) => {

    return { ...provided, };
  },
  menuPortal: (base) => ({ ...base, zIndex: 9999 }) 
}
class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ])
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  constructor(props) {
    super(props)
    this.state = {
      value: null,
      defaultValue: this.props.defaultValue ? this.props.options[0] : null,
      options: []
    }

  }
  handleChange = (selectedOption) => {
    const { onChange, value } = this.props.input;
    this.setState({ value: selectedOption });
    onChange(selectedOption.value);
  };

  render() {
    const { placeholder, options, isDisable, valueExists,
    } = this.props;
    const { name, value } = this.props.input;
    const isValue = { label: value, value: value };
    const selectValue = this.state.value ? this.state.value : isValue && isValue.value ? isValue : this.state.defaultValue ? this.state.defaultValue : null

    if (!value && !this.state.value) {
      if (this.props.defaultValue && this.state.defaultValue) {
        this.handleChange(this.state.defaultValue);
      }
    }
    return (
      <Select
        styles={customStyles}
        name={name}
        value={valueExists && valueExists.label && valueExists.value ? valueExists : selectValue ? selectValue : selectValue}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        style={isDisable ? { background: 'lightgray', border: "none" } : { background: 'white' }} {...placeholder} isDisabled={isDisable} 
        
      />
    );
  }
}

const renderSelectField = (props) => {
  const { meta: { touched, error } } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...props}
      />
      {touched && error && <span className="form__form-group-error mt-3">{"error"}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default renderSelectField;
