import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import 'primeicons/primeicons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import GoogleButton from './GoogleButton';
import FacebookButton from './FacebookButton';
import Captcha from './Captcha';
import config from '../../../config/config';

const LoginTemplate = (props) => {

    const [selectedTemplate, setSelectedTemplate] = useState(props.selectedTemplate ? props.selectedTemplate : '1');
    const [googleLogin] = useState(props.googleLogin);
    const [facebookLogin] = useState(false);
    const [captcha] = useState(props.googleCaptcha);
    const [scaleLogin, setScaleLogin] = useState(0.6);
    const [sideImage, setSideImage] = useState(props.loginSideImage)
    const [uploadImage, setUploadImage] = useState(null)

    const templates = [
        { name: 'Login 1', value: '1', img: 'screen1.png' },
        { name: 'Login 2', value: '2', img: 'screen2.png' },
        { name: 'Login 3', value: '3', img: 'screen3.png' },
        { name: 'Login 4', value: '4', img: 'screen4.png' }
        // { name: 'Login 5', value: '5', img: './img/login/log5.png' },
    ];

    const getImage = (event) => {
        let imgFile = { file: event.target.files[0], name: event.target.files[0].name }
        props.projectLoginSideImage(imgFile);
        setUploadImage(URL.createObjectURL(event.target.files[0]));
    };

    const zoomInLogin = () => {
        if (scaleLogin <= 1) {
            setScaleLogin(scaleLogin + 0.1);
        }
    };

    const zoomOutLogin = () => {
        if (scaleLogin >= 0.5) {
            setScaleLogin(scaleLogin - 0.1);
        }
    };

    const loginOptionTemplate = (option) => {
        return (
            <div className="country-item">
                <img alt={option.name} src={`${config.logoUrl}${option.img}`} className='dropdown-login-images' />
            </div>
        );
    };

    const selectedLoginTemplate = (option) => {
        return (
            <div className="country-item country-item-value">
                <img alt={option.value} src={`${config.logoUrl}${option.img}`} />
            </div>
        );
    };

    const onSelectLoginTemplate = (value) => {
        value ? props.getSelectedTemplate(value) : null;
    };

    return (
        <div className='h-100 w-100'>
            <div className='d-flex justify-content-between mb-1'>
                <div className='template-page-name h6'>
                    Login Screen
                </div>
                <div>
                    <Dropdown value={selectedTemplate} options={templates}
                        onChange={(e) => { setSelectedTemplate(e.value), onSelectLoginTemplate(e.value) }}
                        optionLabel="name" placeholder="Select Template"
                        valueTemplate={selectedLoginTemplate} itemTemplate={loginOptionTemplate} />
                </div>
            </div>

            <div className='my-2 d-flex' style={{ position: 'absolute', right: '20px' }}>

                {/* <div> KEEP, MIGHT NEED LATER AS IT IS RETAINED IN OLD VERSION OF PROJECT AS WELL!
                        <div className="field-checkbox">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="googleLogin" value="true" checked={this.state.googleLogin} onChange={e => this.setState({ googleLogin: !this.state.googleLogin })} />
                                <label className="form-check-label" for="googleLogin">Google Login</label>
                            </div>
                        </div>
                    </div>
                    <div className='ml-3'>
                        <div className="field-checkbox">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="facebookLogin" value="true" checked={this.state.facebookLogin} onChange={e => this.setState({ facebookLogin: !this.state.facebookLogin })} />
                                <label className="form-check-label" for="facebookLogin">Facebook Login</label>
                            </div>
                        </div>
                    </div>
                    <div className='ml-3'>
                        <div className="field-checkbox">
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="googleLogin" value="true" checked={this.state.captcha} onChange={e => this.setState({ captcha: !this.state.captcha })} />
                                <label className="form-check-label" for="googleLogin">Google Captcha</label>
                            </div>

                        </div>
                    </div> */}
                {(selectedTemplate === '3' || selectedTemplate === '2') ?
                    <div className='ml-1'>
                        <label htmlFor="login-img-uploader" className="custom_file_uploader mb-0" style={{ marginRight: '10px' }} >
                            < FontAwesomeIcon
                                icon={faCloudUploadAlt}
                            />  Choose Picture
                        </label>
                        <input id="login-img-uploader" type="file" accept='image/*' onChange={getImage} />
                    </div>
                    : null
                }
                <div className='ml-auto cursor-pointer'>
                    < FontAwesomeIcon
                        icon={faSearchMinus}
                        className="mr-3"
                        onClick={zoomOutLogin}
                    />
                    &nbsp;
                    <span style={{ marginRight: '5px' }}></span>
                    < FontAwesomeIcon
                        icon={faSearchPlus}
                        onClick={zoomInLogin}
                    />
                </div>
            </div>

            <div className='loginTemp' style={(props.backGroundType === 'image') ? { backgroundImage: `url(${config.logoUrl}${props.bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'auto' } : { backgroundColor: props.bgColor, overflow: 'auto' }}>
                {(props.selectedTemplate === '1' || !props.selectedTemplate) ?
                    <div className='login-wrap' style={{ transform: `scale(${scaleLogin})` }} >
                        <div className='card'>
                            <div className='card-body'>
                                <div>
                                    <div className='text-center h6 pb-2 proj-name-color'>
                                        <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                                    </div>
                                    <div className="form-group form-group-sm">
                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                        <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Password</label>
                                        <input type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Password" />
                                    </div>
                                    {captcha || props.googleCaptcha ?
                                        <div className='my-2'>
                                            <Captcha showCheck='true'></Captcha>
                                        </div>
                                        : null
                                    }
                                    <button type="submit" className="btn btn-custom btn-sm btn-block"
                                        style={{
                                            backgroundColor: (props.buttonType === 'regular' ? props.buttonColour : 'transparent'),
                                            color: (props.buttonType === 'regular' ? props.buttonTextColour : props.buttonColour),
                                            borderColor: props.buttonColour,
                                            borderRadius: (props.buttonRounded ? '20px' : '4px'),
                                            width: '100%'
                                        }}>Submit</button>
                                </div>
                                {googleLogin || googleLogin ?
                                    <div>
                                        <GoogleButton></GoogleButton>
                                    </div>
                                    : null
                                }
                                {facebookLogin ?
                                    <div className='mt-2'>
                                        <FacebookButton></FacebookButton>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '2') ?
                    <div className='login2 d-flex' style={{ transform: `scale(${scaleLogin})` }}>
                        <div className='log-img'>
                            {uploadImage ? <img src={uploadImage} alt="" className='h-100' /> : <img src={`${config.logoUrl}${sideImage}`} alt="" className='h-100' />}
                        </div>
                        <div className='p-4 h-100 d-flex align-items-center log-form' style={{ transform: `scale(0.7)` }}>
                            <div>
                                <div className='text-center h6 pb-2 proj-name-color'>
                                    <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                </div>
                                {captcha || props.googleCaptcha ?
                                    <div className='my-2'>
                                        <Captcha showCheck='true'></Captcha>
                                    </div>
                                    : null
                                }
                                <button type="submit" className="btn btn-custom btn-sm btn-block"
                                    style={{
                                        backgroundColor: (props.buttonType === 'regular' ? props.buttonColour : 'transparent'),
                                        color: (props.buttonType === 'regular' ? props.buttonTextColour : props.buttonColour),
                                        borderColor: props.buttonColour,
                                        borderRadius: (props.buttonRounded ? '20px' : '4px'),
                                        width: '100%'
                                    }}
                                >Submit</button>
                                {googleLogin || props.googleLogin ?
                                    <div>
                                        <GoogleButton></GoogleButton>
                                    </div>
                                    : null
                                }
                                {facebookLogin ?
                                    <div className='mt-2'>
                                        <FacebookButton></FacebookButton>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '3') ?
                    <div className='login2 d-flex' style={{ transform: `scale(${scaleLogin})` }}>
                        <div className='p-4 h-100 d-flex align-items-center log-form' style={{ transform: `scale(0.7)` }}>
                            <div>
                                <div className='text-center h6 pb-2 proj-name-color'>
                                    <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                                </div>
                                <div className="form-group">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                </div>
                                {captcha || props.googleCaptcha ?
                                    <div className='my-2'>
                                        <Captcha showCheck='true'></Captcha>
                                    </div>
                                    : null
                                }
                                <button type="submit" className="btn btn-custom btn-sm btn-block"
                                    style={{
                                        backgroundColor: (props.buttonType === 'regular' ? props.buttonColour : 'transparent'),
                                        color: (props.buttonType === 'regular' ? props.buttonTextColour : props.buttonColour),
                                        borderColor: props.buttonColour,
                                        borderRadius: (props.buttonRounded ? '20px' : '4px'),
                                        width: '100%'
                                    }}
                                >Submit</button>
                                {googleLogin || props.googleLogin ?
                                    <div>
                                        <GoogleButton></GoogleButton>
                                    </div>
                                    : null
                                }
                                {facebookLogin ?
                                    <div className='mt-2'>
                                        <FacebookButton></FacebookButton>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                        <div className='log-img'>
                            {uploadImage ? <img src={uploadImage} alt="" className='h-100' /> : <img src={`${config.logoUrl}${sideImage}`} alt="" className='h-100' />}
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '4') ?
                    <div className='login4 d-flex' style={{ transform: `scale(${scaleLogin})` }}>
                        <div className='logo-img d-flex flex-column justify-content-center'>
                            <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                        </div>
                        <div className='ps-5 h-100 d-flex align-items-center log-form pt-4'>
                            <div>
                                <div className="form-group form-group-sm">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                                </div>
                                <div className="form-group form-group-sm">
                                    <label htmlFor="exampleInputPassword1">Password</label>
                                    <input type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Password" />
                                </div>
                                {captcha || props.googleCaptcha ?
                                    <div className='my-2'>
                                        <Captcha showCheck='true'></Captcha>
                                    </div>
                                    : null
                                }

                                <button type="submit" className="btn btn-custom btn-sm btn-block"
                                    style={{
                                        backgroundColor: (props.buttonType === 'regular' ? props.buttonColour : 'transparent'),
                                        color: (props.buttonType === 'regular' ? props.buttonTextColour : props.buttonColour),
                                        borderColor: props.buttonColour,
                                        borderRadius: (props.buttonRounded ? '20px' : '4px'),
                                        width: '100%'
                                    }}
                                >Submit</button>
                                {googleLogin || props.googleLogin ?
                                    <div>
                                        <GoogleButton></GoogleButton>
                                    </div>
                                    : null
                                }
                                {facebookLogin ?
                                    <div className='mt-2'>
                                        <FacebookButton></FacebookButton>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '5') ?
                    <div className='login5' style={{ backgroundImage: `url("https://img.freepik.com/free-vector/gradient-network-connection-background_23-2148865392.jpg?t=st=1653310866~exp=1653311466~hmac=5d573410b2b936c61ec2eb9e227b10d011c8aed47eec6309402ddc85cf6cb4d0&w=1060")` }}>
                        <div className='h-100 d-flex justify-content-center align-items-center'>
                            <div className='card login-scale'>
                                <div className='card-body'>
                                    <form>
                                        <div className='text-center h6 pb-2 proj-name-color'>
                                            <img src='http://api.nocode2.dosystemsinc.com/image/default_project_logo.png' className='login-layout-logo'></img>
                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label htmlFor="exampleInputEmail1">Email address</label>
                                            <input type="email" className="form-control form-control-sm" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" />

                                        </div>
                                        <div className="form-group form-group-sm">
                                            <label htmlFor="exampleInputPassword1">Password</label>
                                            <input type="password" className="form-control form-control-sm" id="exampleInputPassword1" placeholder="Password" />
                                        </div>

                                        <button type="submit" className="btn btn-custom btn-sm btn-block">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        </div >
    )
}
export default LoginTemplate;