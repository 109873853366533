import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import Layout from '../../Layout';
import NoCode from '../../DisplayType/index';
import Landing from '../../Landing/LogIn'
import Register from '../../Register/index';
import RSCode from '../../RSCode/rsCode'
import RSNewProject from '../../RSCode/rsNewProject'
import CountDisplay from '../../Dashboard/index';

import ChangeRecoverPassword from '../../Account/ChangeRecoverPassword/index';
import ForgotPassword from '../../Account/ForgotPassword/index';
import ChangePassword from '../../Account/ChangePassword'
import Profile from '../../Account/Profile'

import Project from '../../Cruds/Project';

// const wrappedRoutes = () => {
//   let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
//   loginCredentials = true;
//   if (loginCredentials) {
//     return <div>
//       {/* <Layout /> */}

//       <div className="container__wrap">
//         <Route exact path="/nocode" render={props => <NoCode {...props} />} />
//         <Route exact path="/create_project" render={props => <RSCode {...props} />} />
//         {/* <Route path="/changePassword" component={ChangePassword} />
//         <Route path="/profile" component={Profile} /> */}
//         {/* <Route path="/dashboard" component={CountDisplay} /> */}
//         {/* <Route path="project/dashboard" component={CountDisplay} /> */}
//         {/* <Route path="/projects" component={Project} />
//         <Route path="/project/:id" render={props => <Project {...props} />} />
//         <Route path="/running_projects" render={props => <Project filterValue={"Run"} />} />
//         <Route path="/Stoped_projects" render={props => <Project filterValue={"Stop"} />} /> */}
//       </div>
//     </div>
//   }
//   else {
//     return <Landing />
//   }
// }
const Tables = () => {
  return ( <Switch>
      <Route path="/projects" component={Project} />
      <Route path="/project/:id" render={props => <Project {...props} />} />
      <Route path="/view_project/:id" render={props => <Project {...props} />} />
      <Route path="/edit_project/:id" render={props => <RSCode {...props} />} />
      <Route exact path="/create_project" render={props => <RSCode {...props} />} />
      <Route path="/running_projects" render={props => <Project filterValue={"Run"} />} />
      <Route path="/Stoped_projects" render={props => <Project filterValue={"Stop"} />} />
  </Switch>)
}
const Account = () => {
  return (<Switch>
    <Route path="/changepassword" component={ChangePassword} />
    <Route path="/profile" component={Profile} />
  </Switch>)
};
const wrappedRoutes2 = () => {
  let loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
  if (loginCredentials) {
    return <div>
      <Layout />
      <div>
      <Route path="/" component={wrapped} />  
      </div>  
    </div>
  }
  else {
    return <ErrorNotFound />
  }
}

const wrapped = () => {
  return (<Switch>
   <div className="container__wrap">
        <Route path="/" component={Account} />
        <Route path="/" component={Tables} />
        <Route path="/dashboard" component={CountDisplay} /> 
        {/* <Route exact path="/nocode" render={props => <NoCode {...props} />} /> */}
      
      </div>
  </Switch>)
}
const Router = () => (

  <MainWrapper>
    <main>
      <Switch>
      <Route path="/changerecoverpassword/:enEmail" render={(props) => <ChangeRecoverPassword
          {...props} />} />
        <Route exact path="/" component={Landing} />
        <Route path="/log_in" component={Landing} />
        <Route path="/logout" component={Landing} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/register" component={Register} />
        <Route path="/" component={wrappedRoutes2} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
