import React from "react";
//Components
import ServerExistingProject from "./ServerExistingProject/serverexistingproject";
import ServerNewProject from "./ServerNewProject/servernewproject";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RadioButton from '../../shared/components/form/RadioButton';
import ConfirmModal from '../ConfirmModals/ConfirmModal';
import SelectModal from '../ConfirmModals/SelectModal';

//styles
import { Row, Col, Collapse } from "reactstrap";
import { Field, reduxForm } from "redux-form";

//service.js
import fetchMethodRequest from '../../config/service';
import Loader from '../App/Loader';
import apiCalls from '../../config/apicalls';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

class ServerCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        { value: "new", label: "New" },
        { value: "existing", label: "Existing" },
      ],
      selectedProject: null,
      showProgressBar: false,
      fileName: '',
      downloadProjectLink: '',
      isLoading: false,
      yesJsonUploaded: false,
      jsonUploadFile: '',
      hideTabTwo: false,
      showConfirmModal: false,
      yesExistingProject: false,
    };
  }

  //submit form
  submit = (values) => {
    let temp = '';
    if (this.newProjectRef && this.state.selectedProject == 'new') {
      temp = this.newProjectRef.returnControllerArray();
    }
    if (this.existingProjectRef && this.state.selectedProject == 'existing') {
      temp = this.existingProjectRef.returnControllerArray();
    }
    let { selectedProject, fileName } = this.state;
    if (selectedProject && selectedProject == "new" && values) {
      values.mailServiceType =
        this &&
          this.newProjectRef &&
          this.newProjectRef.state &&
          this.newProjectRef.state.selectedMailService &&
          this.newProjectRef.state.selectedMailService.value
          ? this.newProjectRef.state.selectedMailService.value
          : "";
      if (values.mailServiceType == 1) {
        delete values.sendGridApiKey;
        delete values.sendGridMail;
      }
      if (values.mailServiceType == 2) {
        delete values.nodeMailerEmail;
        delete values.nodeMailerHost;
        delete values.nodeMailerPassword;
        delete values.nodeMailerPort;
        delete values.nodeMailerService;
      }

    } else {
      if (values['nodemailerpassword']) {
        delete values['nodemailerpassword']
      }
      if (values['emailservice']) {
        delete values['emailservice']
      }
      if (values['nodemaileremail']) {
        delete values['nodemaileremail']
      }
      if (values['projectFile'] && values['projectFile']['name']) {
        values['projectFile'] = values['projectFile']['name']
      }
      if (values.projectNameNew) {
        values.projectName = values.projectNameNew;
        delete values['projectNameNew']
      }
      if (values.mailServiceType) {
        delete values['mailServiceType']
      }
    }
    if (selectedProject) {
      values.projectType = selectedProject;
    }
    for (let j = 0; j < temp.length; j++) {
      if (temp[j]['entityValue']) {
        delete temp[j]['entityValue']
      }
      let Fields = {};
      for (let k = 0; k < temp[j].controllerFields.length; k++) {
        Object.keys(temp[j].controllerFields[k]).forEach((items, index) => {
          if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
            items.endsWith('4') || items.endsWith('5') ||
            items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
            let yesExist = items;
            yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
            temp[j].controllerFields[k][`${yesExist}`] = temp[j].controllerFields[k][items];
            delete temp[j].controllerFields[k][items];
          } else {
            delete temp[j].controllerFields[k][items];
          }
        })
      }
      for (let i = 0; i < temp[j].controllerFields.length; i++) {
        Fields[temp[j].controllerFields[i]['name']] = temp[j].controllerFields[i]['type']
      }
      delete temp[j].controllerFields;
      temp[j]['Fields'] = Fields;
      if (temp[j].methods) {
        let act = temp[j].methods;
        act.push('load');
        temp[j].methods = act;
      }
    }
    values.controllers = temp
    if (selectedProject && selectedProject == "existing" && fileName) {
      values.projectFile = fileName
    }
    let body = {
      projectName: values.projectName,
      projectType: values.projectType,
      controllers: temp,
      emailService: values.emailService,
      mailServiceType: values.mailServiceType,
    }
    if (values.mailServiceType && values.mailServiceType == 1) {
      body['nodeMailerEmail'] = values.nodeMailerEmail;
      body['nodeMailerHost'] = values.nodeMailerHost;
      body["nodeMailerPassword"] = values.nodeMailerPassword;
      body["nodeMailerPort"] = values.nodeMailerPort;
      body["nodeMailerService"] = values.nodeMailerService;
    } else if (values.mailServiceType && values.mailServiceType == 2) {
      body['sendGridApiKey'] = values.sendGridApiKey;
      body['sendGridMail'] = values.sendGridMail;
    }
    if (this.existingProjectRef && this.state.selectedProject == 'existing') {
      delete body.emailService;
      delete body.mailServiceType;
    }
    if (this.state.yesJsonUploaded) {
      delete body['controllers'];
      delete body.projectName;
      body['serverJsonFile'] = this.state.jsonUploadFile;
    }
    console.log(body)
    this.sendDataToServer(body)
  };

  //send data to server
  sendDataToServer = async (values) => {
    let submitDataValue = false;
    if (!this.state.yesJsonUploaded && values.controllers &&
      values.controllers.length > 0 && values.projectName) {
      submitDataValue = true;
    } else if (this.state.yesJsonUploaded) {
      submitDataValue = true;
    }
    if (submitDataValue) {
      let { selectedProject } = this.state;
      await this.setState({ isLoading: true })
      fetchMethodRequest('POST', apiCalls.Node, values).then(async (res) => {
        if (res && res.respCode && res.downloadLink) {
          this.setState({
            downloadProjectLink: res.downloadLink,
            jsonUploadFile: '', yesJsonUploaded: false
          })
          setTimeout(() => {
            this.setState({ hideTabTwo: false, })
          }, 500)
          if (selectedProject && selectedProject == "existing" && this.existingProjectRef) {
            await this.setState({ selectedProject: '', isLoading: false })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.existingProjectRef.emptyController();
          } else if (selectedProject && selectedProject == "new" && this.newProjectRef) {
            await this.setState({ selectedProject: '', isLoading: false })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.newProjectRef.emptyController();
          }
        } else if (res && res.errorCode && res.errorMessage) {
          showToasterMessage(res.messageType, 'error');
          await this.setState({ isLoading: false })
        } else {
          await this.setState({ isLoading: false })
        }
      })
    }
  }

  //logo
  projectFile = (file) => {
    this.setState({ showProgressBar: true })
    fetchMethodRequest('POST', 'upload', file.file, 'upload').then((res) => {
      this.setState({ showProgressBar: false })
      if (res && res.fileName) {
        this.setState({ fileName: res.fileName })
      }
    })
  }

  //project file
  jsonUploadFile = (file) => {
    fetchMethodRequest('POST', 'jsonUpload', file.file, 'upload').then(async (res) => {
      if (res && res.fileName) {
        await this.props.changeprogressWidth(60)
        await this.props.changeprogressWidth(80)
        if (this.newProjectRef) {
          this.newProjectRef.toggleUploadSample();
          if (this.existingProjectRef && this.state.selectedProject == 'existing') {
            this.existingProjectRef.toggleUploadSample();
            await this.props.changeprogressWidth(100)
          }
        } else if (this.existingProjectRef) {
          this.existingProjectRef.toggleUploadSample();
          await this.props.changeprogressWidth(100)
        }
        this.setState({
          jsonUploadFile: res.fileName,
          yesExistingProject: true,
          yesJsonUploaded: true,
        })
      }
    })
  }

  //handleSelectModal
  handleSelectModal = async (type) => {
    await this.setState({ selectedModalValue: type, selectModalShow: false })
    if (this.state.selectedProject == 'existing') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'existing', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({ selectedProject: 'new', yesExistingProject: false, yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true, })
      }
    } else if (this.state.selectedProject == 'new') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({ selectedProject: 'existing', yesExistingProject: false, yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true, })
      }
    } else {
      this.setState({ selectedProject: this.state.selectedProject, downloadProjectLink: '', hideTabTwo: true, })
    }
  }

  //handleyesExistingProject
  handleyesExistingProject = async (type) => {
    await this.setState({ yesExistingProject: type })
  }

  //handleRadioChanges
  handleRadioChanges = async (type, event) => {
    if (type == 'new') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'existing') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({ selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true, yesExistingProject: false, })
      }
    } else if (type == 'exi') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'new') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({ selectedProject: 'existing', downloadProjectLink: '', hideTabTwo: true, yesExistingProject: false, })
      }
    }
  }

  //handleProgreess
  handleProgressWidth = async () => {
    if (this.state.selectedProject) {
      await this.setState({ showConfirmModal: true })
    } else {
      await this.props.handleTabs('one')
      await this.setState({ showConfirmModal: false })
    }
  }

  //confirmModalOpened
  confirmModalOpened = async (type) => {
    if (type == 'yes') {
      await this.setState({
        selectedProject: '', yesExistingProject: false, yesJsonUploaded: false,
        hideTabTwo: false, showConfirmModal: false
      })
      await this.props.reset();
      setTimeout(() => {
        this.props.handleTabs('one')
      }, 500)
    } else if (type == 'no') {
      await this.setState({ showConfirmModal: false })
    }
  }

  render() {
    let { selectedProject, hideTabTwo, yesExistingProject } = this.state;
    const { handleSubmit } = this.props;
    return (
      this.props.hideTabOne && <form onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <h2 className='pt-3'>
          <b><span className='pr-3 '>
            < FontAwesomeIcon
              className='editIcon text-info'
              color='#ff00008c'
              icon='arrow-alt-circle-left'
              data-toggle="tool-tip"
              title="Go Back"
              onClick={() => this.handleProgressWidth()}
            />
          </span>Node Js
          {selectedProject && <span className='ps-2 drownArrowC'>
              < FontAwesomeIcon
                className='editIcon downColor'
                color='#ff00008c'
                icon={hideTabTwo ? 'angle-down' : 'angle-up'}
                data-toggle="tool-tip"
                title={hideTabTwo ? "Open" : 'Close'}
                onClick={() => { this.setState({ hideTabTwo: !this.state.hideTabTwo }) }}
              />
            </span>}
          </b>
        </h2>
        <Collapse isOpen={!hideTabTwo}>
          <label className="list_class">
            Please Select Type Of Your Project
          </label>
          <Row className="pb-3">
            <Col sm={4}>
              <Field
                id='rsnew'
                name='project'
                className="react-select"
                placeholder="Select project type"
                label="New"
                radioValue="new"
                onClick={(event) => {
                  this.handleRadioChanges('new', event)
                }}
                component={RadioButton}
                selectedProject={this.state.selectedProject}
              />
              <Field
                id='rsexi'
                name='project'
                onClick={(event) => {
                  this.handleRadioChanges('exi', event)
                }}
                className="react-select"
                placeholder="Select project type"
                label="Existing"
                radioValue='existing'
                component={RadioButton}
                selectedProject={this.state.selectedProject}
              />
            </Col>
          </Row>
          <hr />
        </Collapse>
        {selectedProject && selectedProject == "new" ? (
          <ServerNewProject
            onRef={(ref) => (this.newProjectRef = ref)}
            jsonUploadFile={this.jsonUploadFile}
            yesJsonUploaded={this.state.yesJsonUploaded}
            changeprogressWidth={this.props.changeprogressWidth}
            handleyesExistingProject={this.handleyesExistingProject}
          />
        ) : selectedProject && selectedProject == "existing" ? (
          <ServerExistingProject
            onRef={(ref) => (this.existingProjectRef = ref)}
            projectFile={this.projectFile}
            showProgressBar={this.state.showProgressBar}
            jsonUploadFile={this.jsonUploadFile}
            yesJsonUploaded={this.state.yesJsonUploaded}
            changeprogressWidth={this.props.changeprogressWidth}
            handleyesExistingProject={this.handleyesExistingProject}
          />
        ) : null}
        {selectedProject && <Row className="pt-3">
          <Col sm={12} className="d-flex justify-content-center">
            {yesExistingProject && <button type="submit" className="btn btn-success">
              Submit
              </button>}
          </Col>
        </Row>}
        {selectedProject && <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>}
        <Row className={this.state.downloadProjectLink ? "pt-3" : 'd-none'}>
          <Col sm={12}
            className="text-center"
          >
            <h4 >  You can download your Project Here <a href={this.state.downloadProjectLink} download>Download</a></h4>
          </Col>
        </Row>
        <ConfirmModal
          showConfirmModal={this.state.showConfirmModal}
          confirmModalOpened={this.confirmModalOpened}
        />
        <SelectModal
          selectModalShow={this.state.selectModalShow}
          handleSelectModal={this.handleSelectModal}
        />
      </form>
    );
  }
}
export default reduxForm({
  form: "server", // a unique identifier for this form
})(ServerCode);
