import React, { useEffect, useState } from 'react';
import { forwardRef, useRef, useImperativeHandle } from 'react';

// Config files
import config from '../../../config/config';
import apiCall from '../../../config/apicalls';
import fetchMethodRequest from '../../../config/service';
import fontsArray from '../../../config/fonts';


// Primeng & bootstrap 
import { Form, FormGroup, Row, Col, Input, Label } from 'reactstrap';
import { Button } from 'reactstrap';
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from "primereact/inputtext";


// color pallate
import { SketchPicker, BlockPicker } from "react-color";


const settingComponent = forwardRef((props, ref) => {

    // First Section
    const [projectNameValue, updateProjectName] = useState(props.projectNameValue);
    const [displayProjectLogoOrName, CheckProjLogoName] = useState(false);
    const [isGoogleLoginRequired, CheckGoogleLogin] = useState(false);
    const [isGoogleRecaptchaRequired, CheckCaptcha] = useState(false);
    const [googleClientID, setGCID] = useState(null); // Client ID for Google Login
    const [googleRecaptchaSiteKey, setGCaptcha] = useState(null); // Key for captcha


    const [projectLogo, setProjectLogo] = useState("default_project_logo.png");
    const [projectFav, setFavIcon] = useState("default_fav_icon.png");
    const [projectFile, updateProjectFile] = useState("");
    const [favicon, onSetFav] = useState("");
    const [loginSideImage, changeLoginSideImage] = useState("default_LoginSideImage.webp");
    const [selectedBackground, changeSelectedBg] = useState("colour");

    const [backgroungImage, onSetBgImage] = useState("");
    const [projectBg, onSetProjectBg] = useState("");

    const [backgroundColor, setBackgroundColor] = useState("#561ecb");
    const [templateColour, setTemplateColor] = useState("#3c6177");
    const [textColour, setTextColor] = useState("#ffffff");
    const [navColour, changeNavColor] = useState('#ffffff');
    const [navTextColour, changeNavTextColour] = useState('#3c6177');


    const [fontOptions] = useState(fontsArray);
    const [fontType, setSelectedFont] = useState({ "name": "Inherit", "code": "inherit" });

    const options = ['top', 'bottom'];
    const [paginationPosition, changePaginationPOS] = useState(options[0]);

    const [selectedFrontEnd, setSelectedFrontEnd] = useState('React');
    const [selectedBackEnd, setSelectedBackEnd] = useState('Node');
    const [buttonType, changeBtnType] = useState("regular");
    const [buttonRounded, changeBtnStyle] = useState(false);
    const [button, setButtonConfig] = useState({});
    const [buttonColour, setButtonColour] = useState("#3c6177");
    const [buttonTextColour, setButtonTextColour] = useState("#ffffff");


    const [emailService, setEmailService] = useState(false);
    const [emailServiceType, setEmailServiceType] = useState(null);

    const [sendgridApiKey, setSendgridApiKey] = useState(null);
    const [sendgridEmail, setSendgridEmail] = useState(null);
    const [nodeMailerEmail, setNodeMailerEmail] = useState(null);
    const [smtphost, setSmtpHost] = useState(null);
    const [smtpuser, setSmtpUser] = useState(null);
    const [smtppassword, setSmtpPassword] = useState(null);


    const [showControllers, updateControllers] = useState(false);
    const [projectNameError, updateProjectNameError] = useState(false);
    const [errorMessage, updateErrorMsg] = useState(null);

    const [multipleList, setMultiListOrg] = useState([]);
    const [multipleListDummy, setMultiListDummy] = useState([]);

    const op = useRef(null);
    const op2 = useRef(null);
    const op3 = useRef(null);
    const op4 = useRef(null);
    const op5 = useRef(null);
    const op6 = useRef(null);
    const op7 = useRef(null);

    const multiScreensList = props.multipleList;
    const [screensList, setScreens] = useState([]);

    useImperativeHandle(ref, () => ({
        setprojectlogos(value, type) {
            if (value && type) {
                type === 'logo' ? setProjectLogo(value) : type === 'fav' ? setFavIcon(value) :
                    type === 'bg' ? onSetProjectBg(value) : type === 'loginSideImage' ? changeLoginSideImage(value) : null;
            }
        },

        setDetails(values) {
            let font={
                "name": values.fontType,
                "code": values.fontType
            };
            setSelectedFrontEnd(values.selectedFrontEnd);
            setSelectedBackEnd(values.selectedBackEnd);
            setProjectLogo(values.projectLogo);
            setFavIcon(values.projectFav);
            changeSelectedBg(values.selectedBackground);
            onSetProjectBg(values.projectBg);
            updateProjectFile(values.projectFile);
            onSetFav(values.favicon);
            onSetBgImage(values.backgroungImage);
            changeNavColor(values.navColour);
            changeNavTextColour(values.navTextColour);
            setSelectedFont(font);
            changePaginationPOS(values.paginationPosition);
            setTemplateColor(values.color);
            setTextColor(values.textcolor);
            setBackgroundColor(values.bgColor);
            setMultiListOrg(values.multipleList);
            setMultiListDummy(values.multipleListDummy);
            setScreens(values.multipleListDummy);
            setEmailService(values.emailService);
            setEmailServiceType(values.emailServiceType);
            CheckProjLogoName(values.displayProjectLogoOrName);
            setSendgridApiKey(values.sendgridApiKey);
            setSendgridEmail(values.sendgridEmail);
            setNodeMailerEmail(values.nodeMailerEmail);
            setSmtpHost(values.smtphost);
            setSmtpUser(values.smtpuser);
            setSmtpPassword(values.smtppassword);
            updateProjectName(values.projectNameValue);
            CheckGoogleLogin(values.isGoogleLoginRequired);
            setGCID(values.googleClientID);
            setGCaptcha(values.googleRecaptchaSiteKey);
            CheckCaptcha(values.isGoogleRecaptchaRequired);
            changeLoginSideImage(values.loginSideImage);
            changeBtnType(values.buttonType);
            changeBtnStyle(values.buttonRounded);
            setButtonColour(values.buttonColour);
            setButtonTextColour(values.buttonTextColour);
        }

    }))

    // Use Effect 
    useEffect(() => {
        const button_ref = {};
        button_ref['buttonType'] = 'regular';;
        button_ref['buttonRounded'] = false;
        setButtonConfig(button_ref);
        if (/test/i.test(projectNameValue)) {
            updateErrorMsg("Unable to create a project that includes test")
            updateProjectNameError(true);

        }
    }, [props, projectNameError]);

    const setImage = (e) => {
        let value1 = props.projectBg(e);
        onSetProjectBg(value1);
        onSetBgImage(e.name);
        props.backgroundOutput({ image: value1, color: null, selectedBackground: "image" });
    }

    const setColour = async (e) => {
        props.backgroundOutput({ image: null, color: e, selectedBackground: "colour" });
    }


    let handleExistingProject1 = (inputvalue) => {
        if (!inputvalue) return;

        updateProjectName(inputvalue);
        updateControllers(true);
        props.handleExistingProject(inputvalue);

        if (/test/i.test(inputvalue)) {
            updateErrorMsg("Unable to create a project that includes test")
            updateProjectNameError(true);
            return;
        }
        else if (!/^[a-zA-Z0-9 ]{1,64}$/.test(inputvalue)) {
            updateErrorMsg('Field Name field must contain alphabets & numbers.');
            updateProjectNameError(true);
        } else {
            updateErrorMsg(null);
            updateProjectNameError(false);
        }

        let filterCriteria = { "limit": "10", "page": 1, "criteria": [{ "key": "name", "value": `${inputvalue}`, "type": "eq" }], "sortfield": "created", "direction": "desc" }

        if (props.projectId) {
            filterCriteria['criteria'].push({ "key": "_id", "value": `${props.projectId}`, "type": "ne" })
        }

        let apiUrl = `${apiCall.Project}?filter=${JSON.stringify(filterCriteria)}`;

        fetchMethodRequest('GET', apiUrl)
            .then(async (response) => {
                if (response) {
                    if (response.projects && response.projects.length > 0) {
                        updateErrorMsg(`Project Name Already Exists.`);
                        updateProjectNameError(true);
                    } else {
                        updateErrorMsg(null);
                        updateProjectNameError(false);
                    }
                }
            }).catch((err) => {
                // Loader if needed
                return err;
            });
    }

    let changeProjectFile = async (file) => {
        props.projectFile(file);
        fetchMethodRequest('POST', apiCall.ReactLogo, file.file, 'upload').then((res) => {
            if (res && res.logo) {
                setProjectLogo(res.logo);
            }
        });
    }

    const changeProjectFavFile = (file) => {
        props.favicon(file);
        fetchMethodRequest('POST', apiCall.ReactFav, file.file, 'upload').then((res) => {
            if (res && res.logo) {
                setProjectLogo(res.logo);
            }
        });
        onSetFav(file.name);
    }

    const changeProjectLoginSideImage = (value) => {
        props.projectLoginSideImage(value);
        changeLoginSideImage(value.name);
    }

    const setmultipleListDummy = (e) => {
        props.setmultipleListDummy(e.value);
        setMultiListDummy(e.value);
        setScreens(e.value);
    }

    const setButtonStyle = (value, type) => {
        let button_cop = button;
        switch (type) {
            case 'type':
                button_cop['buttonType'] = value;
                break;
            case 'rounded':
                button_cop['buttonRounded'] = value;
                break;
            case 'colour':
                button_cop['buttonColour'] = value;
                break;
            case 'text':
                button_cop['buttonTextColour'] = value;
                break;
        }
        setButtonConfig(button_cop);
    }

    const clearEmailService = (value) => {
        if (!value) {
            clearEmailServiceValues(emailServiceType);
            setEmailServiceType(null);
        }
    }
    const clearEmailServiceValues = (emailServiceType) => {
        if (emailServiceType === 'sendgrid') {
            setSendgridApiKey(null);
            props.saveServiceDetails("sendgridApiKey", null)
            setSendgridEmail(null);
            props.saveServiceDetails("sendgridEmail", null)
        } else {
            setNodeMailerEmail(null);
            props.saveServiceDetails("nodeMailerEmail", null);
            setSmtpHost(null);
            props.saveServiceDetails("smtphost", null);
            setSmtpUser(null);
            props.saveServiceDetails("smtpuser", null);
            setSmtpPassword(null);
            props.saveServiceDetails("smtppassword", null);
        }
    }



    return (
        <>
            <Form>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="project_name" hidden>Email</Label>
                            <Input

                                id="project_name"
                                name="projectName"
                                placeholder="Project Name..."
                                type="text"
                                defaultValue={projectNameValue}
                                // onBlur={(e) => handleExistingProject1(e.target.value)}
                                onChange={(e) => handleExistingProject1(e.target.value)}
                                disabled={props.activeTab !== "Admin" ? true : false}
                            />
                            <span className="p-error" style={{ fontSize: "10px" }}>{projectNameError && errorMessage}</span>

                        </FormGroup>
                    </Col>

                    <div className="d-flex align-items-center mb-2 pb-1">
                        <Checkbox inputId="check1" name={`ProjectLogoName${props.activeTab}`}
                            onChange={(e) => { CheckProjLogoName(e.checked), props.saveServiceDetails("displayProjectLogoOrName", e.checked) }}
                            checked={displayProjectLogoOrName} />
                        <label htmlFor="check1" className="ms-3">Display Project logo instead of Name</label>
                    </div>

                    <div className="d-flex align-items-center mb-2 pb-1">
                        <Checkbox inputId="check2" name={`isGoogleLoginRequired${props.activeTab}`}
                            onChange={(e) => { CheckGoogleLogin(e.checked), props.saveServiceDetails("isGoogleLoginRequired", e.checked) }}
                            checked={isGoogleLoginRequired} />
                        <label htmlFor="check2" className="ms-3">Google Login authentication</label>
                    </div>

                    {isGoogleLoginRequired &&
                        // <section className='my-2'>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="google_login_key" hidden></Label>
                                <Input
                                    id="google_login_key"
                                    name={`googleClientID${props.activeTab}`}
                                    placeholder="Google Client ID"
                                    type="text"
                                    defaultValue={googleClientID}
                                    onChange={(e) => { setGCID(e.target.value), props.saveServiceDetails("googleClientID", e.target.value) }}
                                />
                            </FormGroup>
                        </Col>
                        // </section>
                    }

                    <div className="d-flex align-items-center mb-2 pb-1">
                        <Checkbox inputId="check3" name={`isGoogleRecaptchaRequired${props.activeTab}`}
                            onChange={(e) => { CheckCaptcha(e.checked), props.saveServiceDetails("isGoogleRecaptchaRequired", e.checked) }}
                            checked={isGoogleRecaptchaRequired} />
                        <label htmlFor="check3" className="ms-3">Google Recaptcha</label>
                    </div>

                    {isGoogleRecaptchaRequired &&
                        // <section className='my-2'>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="google_captcha" hidden></Label>
                                <Input
                                    id="google_captcha"
                                    name={`googleRecaptchaSiteKey${props.activeTab}`}
                                    placeholder="Google Recaptcha Site Key"
                                    type="text"
                                    defaultValue={googleRecaptchaSiteKey}
                                    onChange={(e) => { setGCaptcha(e.target.value), props.saveServiceDetails("googleRecaptchaSiteKey", e.target.value) }}
                                />
                            </FormGroup>
                        </Col>
                    }
                </Row>
            </Form>

            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Logo & Favicon</h2>
                </div>
                <form>
                    <div className='form-group'>
                        <div className="row">
                            <div className='col-md-6 col-lg-6'>
                                <div className="btn-upload-wrapper">
                                    <div className='prime_custom_btn'>
                                        <button className='btn btn-primary full_width'>
                                            <span className="pi pi-upload me-2"></span>
                                            {projectLogo ? projectLogo : projectFile}
                                        </button>
                                        <Input
                                            id="logoupload1"
                                            name="appLogo"
                                            type="file"
                                            className="logoupload"
                                            onChange={(e) => {
                                                e.preventDefault(); const files = [...e.target.files];
                                                if (files && files.length) {
                                                    changeProjectFile({ file: files[0], name: files[0].name });
                                                }
                                            }}
                                            accept={'.jpg,.jpeg,.png'}
                                        />
                                    </div>
                                </div>
                                {projectLogo &&
                                    <div className='my-2'>
                                        <img src={`${config.logoUrl}${projectLogo}`} alt="" className='settings_img_sample' />
                                    </div>
                                }
                            </div>
                            <div className='col-md-6 col-lg-6'>
                                <div className="btn-upload-wrapper">
                                    <div className='prime_custom_btn'>
                                        <button className='btn btn-primary full_width'>
                                            <span className="pi pi-upload me-2"></span>
                                            {projectFav}
                                        </button>
                                        <Input
                                            id="logoupload2"
                                            name="faviconUpload"
                                            type="file"
                                            className="logoupload"
                                            accept={'.jpg,.jpeg,.png'}
                                            onChange={(e) => {
                                                e.preventDefault(); const files = [...e.target.files];
                                                if (files && files.length) {
                                                    changeProjectFavFile({ file: files[0], name: files[0].name });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {(projectFav) &&
                                    <img src={`${config.logoUrl}${projectFav}`} alt="" className='settings_img_sample' />
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Login Side Image</h2>
                </div>
                <form>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="btn-upload-wrapper">
                                    <div className='prime_custom_btn'>
                                        <button className='btn btn-primary full_width'>
                                            <span className="pi pi-upload me-2"></span>
                                            {loginSideImage ? loginSideImage : 'Upload Login Side Image'}
                                        </button>
                                        <Input
                                            id="logoupload3"
                                            name="loginSideImage"
                                            type="file"
                                            className="logoupload"
                                            accept={'.jpg,.jpeg,.png'}
                                            onChange={(e) => {
                                                e.preventDefault(); const files = [...e.target.files];
                                                if (files && files.length) {
                                                    changeProjectLoginSideImage({ file: files[0], name: files[0].name });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {loginSideImage &&
                                    <img src={`${config.logoUrl}${loginSideImage}`} alt="Login Side Image" className='settings_img_sample' />
                                }
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Application Background</h2>
                </div>
                <form noValidate>
                    <div className="d-flex flex-wrap gap-3 my-1">
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="appImage" name="appBackground" value="image" onChange={(e) => changeSelectedBg(e.value)} checked={selectedBackground === 'image'} />
                            <label htmlFor="appImage" className="ms-2">Image</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="appRGBA" name="appBackground" value="colour" onChange={(e) => changeSelectedBg(e.value)} checked={selectedBackground === 'colour'} />
                            <label htmlFor="appRGBA" className="ms-2">RGBA</label>
                        </div>
                    </div>
                    {selectedBackground && selectedBackground === 'image' &&
                        <div className="col-md-12">
                            <div className='btn-upload-wrapper'>
                                <div className='prime_custom_btn'>
                                    <button className='btn btn-primary full_width'>
                                        <span className="pi pi-upload me-2"></span>
                                        {projectBg ? projectBg : 'Upload background image'}
                                    </button>
                                    <Input
                                        id="logoupload4"
                                        name="backgroungImage"
                                        type="file"
                                        className="logoupload"
                                        accept={'.jpg,.jpeg,.png'}
                                        onChange={(e) => {
                                            e.preventDefault(); const files = [...e.target.files];
                                            if (files && files.length) {
                                                setImage({ file: files[0], name: files[0].name });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {projectBg &&
                                <img src={`${config.logoUrl}${projectBg}`} alt="App Background Image" className='settings_img_sample' />
                            }
                        </div>
                    }
                    {selectedBackground && selectedBackground === 'colour' &&
                        <div className="d-flex align-items-center my-3">
                            <div className='color-wrapper'>
                                <div className="color-input" style={{ backgroundColor: backgroundColor }}></div>
                                <div className="color-filler ms-2">
                                    <i className="pi pi-pencil" onClick={(e) => op7.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                                    <OverlayPanel className="colorPickers" ref={op7} showCloseIcon>
                                        <div className="content-body">
                                            <div className="picker">
                                                <div className="d-flex flex-column align-items-center">
                                                    <SketchPicker
                                                        onChange={(color) => { setBackgroundColor(color.hex); setColour(color.hex) }}
                                                        color={backgroundColor}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                </div>
                            </div>
                        </div>
                    }
                </form>
            </section>




            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Template Colour</h2>
                </div>
                <div className='color-wrapper'>
                    <div className="color-input" style={{ backgroundColor: templateColour }}></div>
                    <div className="color-filler ms-2">
                        <i className="pi pi-pencil" onClick={(e) => op6.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                        <OverlayPanel className="colorPickers" ref={op6} showCloseIcon>
                            <div className="content-body">
                                <div className="picker">
                                    <div className="d-flex flex-column align-items-center">
                                        <SketchPicker
                                            onChange={(color) => { setTemplateColor(color.hex); props.handleColorChange(color.hex, 'templateColor') }}
                                            color={templateColour}
                                        />
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                </div>
            </section>

            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Text Colour</h2>
                </div>
                <div className='color-wrapper'>
                    <div className="color-input" style={{ backgroundColor: textColour }}></div>
                    <div className="color-filler ms-2">
                        <i className="pi pi-pencil" onClick={(e) => op5.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                        <OverlayPanel className="colorPickers" ref={op5} showCloseIcon>
                            <div className="content-body">
                                <div className="picker">
                                    <div className="d-flex flex-column align-items-center">
                                        <SketchPicker
                                            onChange={(color) => { setTextColor(color.hex); props.handleColorChange(color.hex, 'textColor') }}
                                            color={textColour}
                                        />
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                </div>
            </section>

            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Navbar Colour</h2>
                </div>
                <div className='color-wrapper'>
                    <div className="color-input" style={{ backgroundColor: navColour }}></div>
                    <div className="color-filler ms-2">
                        <i className="pi pi-pencil" onClick={(e) => op4.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                        <OverlayPanel className="colorPickers" ref={op4} showCloseIcon>
                            <div className="content-body">
                                <div className="picker">
                                    <div className="d-flex flex-column align-items-center">
                                        <SketchPicker
                                            onChange={(color) => { changeNavColor(color.hex); props.handleColorChange(color.hex, 'navColour') }}
                                            color={navColour}
                                        />
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                </div>
            </section>

            <section className='mb-3'>
                <div className="col-12 mb-1">
                    <h2 className='setting_heading_l2 pb-0'>Navbar Text Colour</h2>
                </div>
                <div className='color-wrapper'>
                    <div className="color-input" style={{ backgroundColor: navTextColour }}></div>
                    <div className="color-filler ms-2">
                        <i className="pi pi-pencil" onClick={(e) => op3.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                        <OverlayPanel className="colorPickers" ref={op3} showCloseIcon>
                            <div className="content-body">
                                <div className="picker">
                                    <div className="d-flex flex-column align-items-center">
                                        <SketchPicker
                                            onChange={(color) => { changeNavTextColour(color.hex); props.handleColorChange(color.hex, 'navTextColour') }}
                                            color={navTextColour}
                                        />
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </div>
                </div>
            </section>

            <section className='mb-3'>
                <div className="col-12">
                    <h2 className='setting_heading_l2 pb-0'>Font</h2>
                </div>

                <form noValidate>
                    <div className="mb-1">
                        <span style={{ fontFamily: fontType && fontType.code ? fontType.code : 'inherit' }}>Sample font test</span>
                    </div>
                    <div className='form-group'>
                        <div className="d-flex align-items-center">
                            <Dropdown value={fontType} onChange={(e) => { setSelectedFont(e.value); props.getFontFamily(e.value) }} options={fontOptions} optionLabel="name"
                                placeholder="Select font" className="w-full" />
                        </div>
                    </div>
                </form>
            </section>

            <section className="mb-3">
                <div className="col-12">
                    <h2 className='setting_heading_l2'>Pagination</h2>
                </div>
                <form noValidate>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <SelectButton value={paginationPosition} onChange={(e) => { changePaginationPOS(e.value); props.setPagination(e.value) }} options={options} />
                        </div>
                    </div>
                </form>
            </section>

            <section className="mb-3">
                <div className="d-flex align-items-center pb-3">
                    <h2 className='setting_heading_l2 p-0'>Button Style</h2>
                    <button className='btn btn-primary mb-0 ms-3'
                        style={{
                            backgroundColor: (buttonType && buttonType === 'regular' ? buttonColour : 'transparent'),
                            color: (buttonType === 'regular' ? buttonTextColour : buttonColour),
                            borderColor: buttonColour,
                            borderRadius: (buttonRounded ? '20px' : '4px')
                        }}
                    >Preview</button>
                </div>
                <form noValidate>
                    <div className='mb-2'>
                        <strong className='text-secondary'>Type</strong>
                    </div>
                    <div className="d-flex flex-wrap gap-3 mb-2">
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="buttonTypeReg" name="buttonType" value="regular" onChange={(e) => { changeBtnType(e.value); props.updateButtonStyle('type', e.value) }} checked={buttonType === 'regular'} />
                            <label htmlFor="buttonTypeReg" className="ms-2">Regular</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="buttonTypeOutL" name="buttonType" value="outline" onChange={(e) => { changeBtnType(e.value); props.updateButtonStyle('type', e.value) }} checked={buttonType === 'outline'} />
                            <label htmlFor="buttonTypeOutL" className="ms-2">Outline</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <Checkbox inputId="btnRounded" name="buttonRounded" onChange={e => { changeBtnStyle(e.checked); props.updateButtonStyle('rounded', e.checked) }} checked={buttonRounded} />
                            <label htmlFor="btnRounded" className="ms-2">Rounded</label>
                        </div>
                    </div>
                </form>
                <div className='mb-2 d-flex flex-wrap gap-3'>
                    <div className="d-flex flex-column">
                        <strong className='text-secondary'>Colour</strong>
                        <div className='color-wrapper'>
                            <div className="color-input" style={{ backgroundColor: buttonColour }}></div>
                            <div className="color-filler ms-2">
                                <i className="pi pi-pencil" onClick={(e) => op.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                                <OverlayPanel className="colorPickers" ref={op} showCloseIcon>
                                    <div className="content-body">
                                        <div className="picker">
                                            <div className="d-flex flex-column align-items-center">
                                                <SketchPicker
                                                    onChange={(color) => { setButtonColour(color.hex); props.updateButtonStyle('colour', color.hex) }}
                                                    color={buttonColour}
                                                />
                                                {/* <div className='applyBtn'>
                                                <button className="btn btn-primary mb-0" type="btn" onClick={(e) => op.current.toggle(e)}>Apply</button>
                                            </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column">
                        <strong className='text-secondary'>Text Colour</strong>
                        <div className='color-wrapper'>
                            <div className="color-input" style={{ backgroundColor: buttonTextColour }}></div>
                            <div className="color-filler ms-2">
                                <i className="pi pi-pencil" onClick={(e) => op2.current.toggle(e)} style={{ fontSize: '1rem' }}></i>
                                <OverlayPanel className="colorPickers" ref={op2} showCloseIcon>
                                    <div className="content-body">
                                        <div className="picker">
                                            <div className="d-flex flex-column align-items-center">
                                                <SketchPicker
                                                    onChange={(color) => { setButtonTextColour(color.hex); props.updateButtonStyle('textColour', color.hex) }}
                                                    color={buttonTextColour}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className='mb-3'>
                <div className="col-12">
                    <h2 className='setting_heading_l2'>Show/Hide UI Screens</h2>
                </div>
                <form noValidate>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <MultiSelect  options={multiScreensList} value={screensList} onChange={(e) => setScreens(e.value)} optionLabel="label" 
                            placeholder="Select screens list" className="w-full" />
                        </div>
                    </div>
                </form>
            </section> */}

            <section className='mb-3'>
                <div className="form-group">
                    <div className="d-flex align-items-center">
                        <Checkbox inputId="emailservice" name="emailService" onChange={e => { setEmailService(e.checked); props.saveServiceDetails("emailService", e.checked); clearEmailService(e.checked) }} checked={emailService} />
                        <label htmlFor="emailservice" className="ms-2">Email Service Provider</label>
                    </div>
                </div>
            </section>

            <section className='mb-3'>
                {emailService &&
                    <div className="d-flex flex-wrap gap-3 mb-2">
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="nodemail" name="emailServiceType" value="nodeMailer" onChange={(e) => { clearEmailServiceValues(e.value); setEmailServiceType(e.value); props.mailFields("nodeMailer") }} checked={emailServiceType === 'nodeMailer'} />
                            <label htmlFor="nodemail" className="ms-2">Node Mailer</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <RadioButton inputId="sendgrid" name="emailServiceType" value="sendgrid" onChange={(e) => { clearEmailServiceValues(e.value); setEmailServiceType(e.value); props.mailFields("sendgrid") }} checked={emailServiceType === 'sendgrid'} />
                            <label htmlFor="sendgrid" className="ms-2">Send Grid</label>
                        </div>
                    </div>
                }
            </section>

            {emailServiceType && emailServiceType === 'sendgrid' &&
                <section className='mb-3'>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="sendgridApi">Send Grid Api Key</Label>
                            <Input
                                id="sendgridApi"
                                name="sendGridApiKey"
                                placeholder="Api Key"
                                type="text"
                                defaultValue={sendgridApiKey}
                                onChange={(e) => { setSendgridApiKey(e.target.value); props.saveServiceDetails("sendgridApiKey", e.target.value) }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="sendgridEmail">Send Grid Email</Label>
                            <Input
                                id="sendgridEmail"
                                name="sendGridEmail"
                                placeholder="Email"
                                type="email"
                                defaultValue={sendgridEmail}
                                onChange={(e) => { setSendgridEmail(e.target.value); props.saveServiceDetails("sendgridEmail", e.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                </section>
            }

            {emailServiceType && emailServiceType === 'nodeMailer' &&
                <section className='mb-3'>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="nm_email">Node mailer Email</Label>
                            <Input
                                id="nm_email"
                                name="nodeMailerEmail"
                                placeholder="Node mailer Email"
                                type="email"
                                defaultValue={nodeMailerEmail}
                                onChange={(e) => { setNodeMailerEmail(e.target.value); props.saveServiceDetails("nodeMailerEmail", e.target.value) }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="nm_host">SMTP host</Label>
                            <Input
                                id="nm_host"
                                name="smtphost"
                                placeholder="SMTP host"
                                type="text"
                                defaultValue={smtphost}
                                onChange={(e) => { setSmtpHost(e.target.value); props.saveServiceDetails("smtphost", e.target.value) }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="nm_user">SMTP user</Label>
                            <Input
                                id="nm_user"
                                name="smtpuser"
                                placeholder="SMTP user"
                                type="text"
                                defaultValue={smtpuser}
                                onChange={(e) => { setSmtpUser(e.target.value); props.saveServiceDetails("smtpuser", e.target.value) }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="nm_password">SMTP password</Label>
                            <Input
                                id="nm_password"
                                name="smtppassword"
                                placeholder="SMTP password"
                                type="password"
                                defaultValue={smtppassword}
                                onChange={(e) => { setSmtpPassword(e.target.value); props.saveServiceDetails("smtppassword", e.target.value) }}
                            />
                        </FormGroup>
                    </Col>
                </section>
            }

            <section className="mb-3">
                <div className="col-12">
                    <h2 className='setting_heading_l2'>Frontend Language</h2>
                </div>
                <form noValidate>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-wrap gap-3 mb-2">
                                <div className="d-flex align-items-center">
                                    <RadioButton inputId="frontR" name="selectedFrontEnd" value="React" onChange={(e) => { setSelectedFrontEnd(e.value); props.chooseFrontBackEndLanguage(e.value, 'front') }} checked={selectedFrontEnd === 'React'} />
                                    <label htmlFor="frontR" className="ms-2">React</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <RadioButton inputId="frontA" name="selectedFrontEnd" value="Angular" onChange={(e) => { setSelectedFrontEnd(e.value); props.chooseFrontBackEndLanguage(e.value, 'front') }} checked={selectedFrontEnd === 'Angular'} />
                                    <label htmlFor="frontA" className="ms-2">Angular</label>
                                </div>

                            </div>                        </div>
                    </div>
                </form>
            </section>

            <section className="mb-3">
                <div className="col-12">
                    <h2 className='setting_heading_l2'>Backend Language</h2>
                </div>
                <form noValidate>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-wrap gap-3 mb-2">
                                <div className="d-flex align-items-center">
                                    <RadioButton inputId="backN" name="selectedBackEnd" value="Node" onChange={(e) => { setSelectedBackEnd(e.value); props.chooseFrontBackEndLanguage(e.value, 'back') }} checked={selectedBackEnd === 'Node'} />
                                    <label htmlFor="backN" className="ms-2">Node</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <RadioButton inputId="backJ" name="selectedBackEnd" value="Java" onChange={(e) => { setSelectedBackEnd(e.value); props.chooseFrontBackEndLanguage(e.value, 'back') }} checked={selectedBackEnd === 'Java'} />
                                    <label htmlFor="backJ" className="ms-2">Java</label>
                                </div>

                            </div>                        </div>
                    </div>
                </form>
            </section>
        </>
    )
})
export default settingComponent;