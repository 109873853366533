import React, { useState, useEffect, useRef } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faImage, faTextHeight, faSlidersH, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';

// hook forms
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { classNames } from 'primereact/utils';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';


const addNewTabModal = (props)=> {
    const newTabUIForm = {
        newUIName: '',
        selectedAuthScreen: '',
        selectedScreens: ''
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register, trigger, setError, setValue
    } = useForm({ newTabUIForm });


    const [dNLInDropDownArray, setDropdownList] = useState([]);
    const [errorDisplayNewUIName, setErrDisplayName] = useState(false);
    const [errorDisplayForSelectedAuthScreen, setDisplayForAuthScreen] = useState(false);
    const [errorDisplaySelectedScreens, setErrorDisplayScreens] = useState(false);
    const [selectedAuthScreenErrorMsg, setAuthScreenErrorMsg] = useState(false);

    useEffect(()=> {
        console.log(props,"Props");
        let controllers = props.controllersJson;
        let doNotListInDropDown = props.doNotListInDropDown;
        let dNLInDropDownArray = [];
        if (doNotListInDropDown && doNotListInDropDown.length > 0 && controllers && controllers.length > 4) {
            for (let obj of controllers) {
                if (!doNotListInDropDown.includes(obj.controllerId) && !obj.isAuth && props.type == "Add") {
                    let flag = true;
                    for (let o of props.multiTabsData) {
                        if (o.name && props.newUIDetails && props.newUIDetails[o.name]) {
                            let data = props.newUIDetails[o.name]
                            flag = data.selectedAuthScreen == obj.controllerId ? false : flag;
                        }
                    }
                    if (flag) dNLInDropDownArray.push({ "label": obj.name, "value": obj.controllerId })
                }
                if (props.newUIDetails && props.newUIDetails[props.activeTab] && props.newUIDetails[props.activeTab].selectedAuthScreen
                    && props.newUIDetails[props.activeTab].selectedAuthScreen == obj.controllerId && props.type == "Edit") {
                    dNLInDropDownArray.push({ "label": obj.name, "value": obj.controllerId })
                }
            }
            setDropdownList(dNLInDropDownArray);
        }
        if (props.type == "Edit") {
            let data = props.newUIDetails[props.activeTab];
            console.log(data);
            setValue("newUIName", data.newUIName);
            setValue("selectedAuthScreen", data.selectedAuthScreen);
            setValue("selectedScreens", data.selectedScreens);
        }
    },[props]);

    const onSelectedAuthScreen = (e)=> {
        setDisplayForAuthScreen(false);
        setAuthScreenErrorMsg(false);
        for(let obj of props.controllers){
            if(obj.controllerId === e.value && obj.controllerFields && obj.controllerFields.length > 0){
				let emailIndex = obj.controllerFields.findIndex(val => val.name === "email");
				let passwordIndex = obj.controllerFields.findIndex(val => val.name === "password");
                let emailFieldObj={
                    "name": "email",
                    "type": "email",
                    "placeholder": "Email",
                    "label": "Email",
                    "id": "email",
                    "width": 150,
                    "displayinaddForm": "true",
                    "displayineditForm": "false",
                    "displayinlist": "true",
                    "profileField": "True",
                    "controllerName": null,
                    "textAlign": "Center",
                    "displayinregisterForm": "true",
                    "displayinServer": "True",
                    "serverFieldType": "String",
                    "disabled": true,
                    "required": true,
                    "show": true,
                    "globalSearchField": 'true',
                    "field": "email",
                    "header": "Email",
                    "manuallyPushed": true
                };

                let passwordFieldObj={
                    "name": "password",
                    "type": "password",
                    "placeholder": "password",
                    "label": "password",
                    "id": "password",
                    "displayinaddForm": "true",
                    "displayineditForm": "false",
                    "displayinlist": "false",
                    "profileField": "false",
                    "controllerName": null,
                    "textAlign": "Center",
                    "displayinServer": "false",
                    "serverFieldType": "String",
                    "show": true,
                    "required":true,
                    "displayInSettings": false,
                    "disabled": true,
                    "field": "password",
                    "header": "password",
                    "manuallyPushed": true
                };           

                if(emailIndex === -1 && passwordIndex != -1){
                    setAuthScreenErrorMsg(true);
                    obj.controllerFields.push(emailFieldObj);
                }
                if(emailIndex != -1 && passwordIndex === -1){
                    setAuthScreenErrorMsg(true);
                    obj.controllerFields.push(passwordFieldObj);
                }
                if(emailIndex === -1 && passwordIndex === -1){
                    setAuthScreenErrorMsg(true);
                    obj.controllerFields.push(passwordFieldObj);
                    obj.controllerFields.push(emailFieldObj);
                }
            }
        }
    }

    const onSubmit = (values) => {
        values.newUIName = values.newUIName.split(' ').join('').toString()
        let multiTabsData = props.multiTabsData;
            let error = false;
            if (!values.newUIName) {
                setErrDisplayName(true);
                error = true;
            } else {
                setErrDisplayName(false);
            }

            if (!values.selectedAuthScreen) {
                setDisplayForAuthScreen(true);
                error = true;
            } else {
                setDisplayForAuthScreen(false);
            }

            if (!values.selectedScreens || (values.selectedScreens && values.selectedScreens.length == 0)) {
                setErrorDisplayScreens(true);
                error = true;
            } else {
                setErrorDisplayScreens(false);
            }
    
            if (!error) {
                if (props && props.type == "Add") {
                    multiTabsData.push({ "name": values.newUIName })
                }
    
                props.setNewUiDetails({ newUIName: values.newUIName, 
                    selectedScreens: values.selectedScreens, 
                    selectedAuthScreen: values.selectedAuthScreen }, props.type);
                props.closetheModal("openAddNewTabModal", false);
                props.closetheModal("openEditNewTabModal", false);
            }
            reset();
        
    };
    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return(
        <>
            <Modal style={{ width: "30%" }} isOpen={props.openAddNewTabModal} centered={true}>
                <ModalHeader className='progresBarClr text-white'>
                    <span className='h5'> {props.type == "Edit" ? "Edit New UI" : "Add New UI"}  </span>
                    
                    <span className='float-right'>
                        < FontAwesomeIcon
                            className='editIcon text-white'
                            color='#ff00008c'
                            icon='times-circle'
                            data-toggle="tool-tip"
                            title="Close"
                            onClick={() => { props.closetheModal("openAddNewTabModal", false), props.closetheModal("openEditNewTabModal", false) }}
                        />
                    </span>
                </ModalHeader>
                <ModalBody>
                    <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                        <div className='form-group'>
                            <label className='form-label'>Name</label>
                            <Controller 
                                name='newUIName'
                                control={control}
                                rules={{ required: 'Field is required.' }}
                                render={({field, fieldState})=> (
                                    <>
                                        <InputText id={field.name} value={field.value} 
                                            className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} 
                                            onChange={(e) => field.onChange(e.target.value)} 
                                            disabled={props.type == 'Edit' ? true : false}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Select Login screen</label>
                            <Controller 
                                name='selectedAuthScreen'
                                control={control}
                                rules={{ required: 'Login Screen is required field.' }}
                                render={({field, fieldState})=> (
                                    
                                        <Dropdown
                                            id={field.name}
                                            value={field.value}
                                            optionLabel="label"
                                            placeholder="Select Login screen"
                                            options={dNLInDropDownArray}
                                            focusInputRef={field.ref}
                                            onChange={(e) => {field.onChange(e.value),onSelectedAuthScreen(e)} }
                                            className={classNames({ 'p-invalid': fieldState.error }, 'w-full')}
                                        />
                                    
                                )}
                            />
                            {getFormErrorMessage('selectedAuthScreen')}
                        </div>
                        <div className='form-group'>
                            <label className='form-label'>Select screens</label>
                            <Controller 
                                name='selectedScreens'
                                control={control}
                                rules={{ required: 'Field is required.' }}
                                render={({field, fieldState}) => (
                                    
                                        <MultiSelect id={field.name} name="selectedScreens" value={field.value} 
                                            options={props.multipleList} onChange={(e) => field.onChange(e.value)} 
                                            optionLabel="label" placeholder="Select screens" 
                                            className="w-full md:w-20rem" 
                                        />
                                   
                                )}
                            />
                            {getFormErrorMessage('selectedScreens')}
                        </div>
                        <div className='col-sm-12 mt-2'>
                            <ButtonToolbar className="justify-content-center ms-auto" >
                                <Button color='primary' type="button"
                                    onClick={() => { props.closetheModal("openAddNewTabModal", false), props.closetheModal("openEditNewTabModal", false) }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color='primary'
                                    type="submit"
                                >
                                    {'Save'}
                                </Button>

                            </ButtonToolbar>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </>
    )

}
export default addNewTabModal; 