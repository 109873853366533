import React from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Collapse } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

//Components
import DefaultInput from "../../../shared/components/form/DefaultInput";
import CheckBox from '../../../shared/components/form/CheckBox';
import Select from '../../../shared/components/form/Select';
import config from '../../../config/config';
import FileInput from '../../../shared/components/form/FileInput'
import MultiSelect from '../../../shared/components/form/MultiSelectDropDown';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          height: 47,
          top: -2,
        },
        "&$focused $notchedOutline": {
          borderColor: config.floatingFieldsColor,
        },
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: config.floatingFieldsColor,
          fontWeight: '500'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$contained": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        "&$marginDense": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        margin: 0,
        marginTop: 0,
      }
    },
  }
});

class ExistingProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controllers: [],
      showFilterElement: false,
      selectdFilterIndex: 0,
      controllerFields: [],
      selectedControllerIndex: 0,
      selectedFormIndex: 0,
      typeOptions: [
        { value: "text", label: "text" },
        { value: "dropDown", label: "dropDown" },
        { value: "Action", label: "Action" },
      ],
      fieldTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "dropDown", label: "dropDown" },
        { value: "date", label: "date" },
        { value: "checkbox", label: "checkbox" },
        { value: "autoComplete", label: "autoComplete" },
        { value: "multiSelect", label: "multiSelect" },
        { value: "radio", label: "radio" },
      ],
      fieldProfileTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "date", label: "date" },
        { value: "state", label: "state" },
      ],
      fieldProfileType: [
        { value: "email", label: "email" },
        { value: "fullName", label: "fullName" },
        { value: "lastName", label: "lastName" },
        { value: "dateOfBirth", label: "dateOfBirth" },
        { value: "address", label: "address" },
      ],
      actionsType: [
        { value: "add", label: "add" },
        { value: "edit", label: "edit" },
        { value: "delete", label: "delete" },
        { value: "view", label: "view" },
      ],
      selectedProfileIndex: 0,
      showProfileTypeDropdown: false,
      showControllers: false,
      apiUrlTypes: [],
      jsonDownloadLink: '#',
      sampleFileJson: 'http://nocode.api.dosystemsinc.com/json/angularJson',
      showSampleFile: false,
      filterMatchCodeOptions: [
        { value: "empty", label: "empty" },
        { value: "startsWith", label: "startsWith" },
      ],
      specificClass: [
        { value: "text-center", label: "text-center" },
        { value: "text-left", label: "text-left" },
        { value: "text-right", label: "text-right" },
      ],
      statusClass: [
        { value: "badgeClass", label: "badgeClass" },
      ],
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }

  //emptyController
  emptyController = async () => {
    await this.setState({
      controllers: [],
      controllerFields: [],
      showControllers: false,
      loginCheck: false,
      selectdFilterIndex: 0,
      selectedProfileIndex: 0,
      showProfileTypeDropdown: false,
      showFilterElement: false,
      selectedControllerIndex: 0,
      selectedFormIndex: 0,
      uploadCheck: false,
    })
  }

  //addFields
  addControllersFields = async (type, e) => {
    this.setState({ showProfileTypeDropdown: false })
    let { selectedControllerIndex, controllers } = this.state;
    let controllerData = controllers;
    if (type === 'table') {
      let controllerFields =
        controllers[selectedControllerIndex].controllerFields;
      let controllerFieldsLength = controllerFields.length;
      controllerFields.push({
        showBadge: `controller_${selectedControllerIndex +
          1}.showBadge${controllerFieldsLength + 1}`,
        specificClass: `controller_${selectedControllerIndex +
          1}.specificClass${controllerFieldsLength + 1}`,
        fieldType: `controller_${selectedControllerIndex +
          1}.fieldType${controllerFieldsLength + 1}`,
        filterMatchMode: `controller_${selectedControllerIndex +
          1}.filterMatchMode${controllerFieldsLength + 1}`,
        width: `controller_${selectedControllerIndex +
          1}.width${controllerFieldsLength + 1}`,
        field: `controller_${selectedControllerIndex +
          1}.field${controllerFieldsLength + 1}`,
        header: `controller_${selectedControllerIndex +
          1}.header${controllerFieldsLength + 1}`,
        filter: `controller_${selectedControllerIndex +
          1}.filter${controllerFieldsLength + 1}`,
        sortable: `controller_${selectedControllerIndex +
          1}.sortable${controllerFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFields = controllerFields;
      await this.setState({
        selectdFilterIndex: controllerFields.length && controllerFields.length !== 1 ?
          controllerFields.length - 1 : 0,
        controllers: controllerData,
        showFilterElement: false,
      });
    } else if (type === 'form') {
      let controllerFormFields =
        controllers[selectedControllerIndex].controllerFormFields;
      let controllerFormFieldsLength = controllerFormFields.length;
      controllerFormFields.push({
        value: `controller_${selectedControllerIndex +
          1}.value${controllerFormFieldsLength + 1}`,
        type: `controller_${selectedControllerIndex +
          1}.type${controllerFormFieldsLength + 1}`,
        name: `controller_${selectedControllerIndex +
          1}.name${controllerFormFieldsLength + 1}`,
        label: `controller_${selectedControllerIndex +
          1}.label${controllerFormFieldsLength + 1}`,
        id: `controller_${selectedControllerIndex +
          1}.id${controllerFormFieldsLength + 1}`,
        placeholder: `controller_${selectedControllerIndex +
          1}.placeholder${controllerFormFieldsLength + 1}`,
        minlength: `controller_${selectedControllerIndex +
          1}.minlength${controllerFormFieldsLength + 1}`,
        maxlength: `controller_${selectedControllerIndex +
          1}.maxlength${controllerFormFieldsLength + 1}`,
        required: `controller_${selectedControllerIndex +
          1}.required${controllerFormFieldsLength + 1}`,
        isHiddenInEdit: `controller_${selectedControllerIndex +
          1}.isHiddenInEdit${controllerFormFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFormFields = controllerFormFields;

      await this.setState({
        selectedFormIndex: controllerFormFields.length && controllerFormFields.length !== 1 ?
          controllerFormFields.length - 1 : 0,
        controllers: controllerData,
      });
    } else if (type === 'controller') {
      controllers.push({
        controllerFields: [],
        controllerFormFields: [],
      });

      await this.setState({
        controllers: controllers,
        selectedControllerIndex: controllers.length && controllers.length !== 1 ?
          controllers.length - 1 : 0,
        showProfileTypeDropdown: false,
        tabThreeActive: true,
        activeThreeTab: true,
        setMainTab: 'three'
      });
      this.props.changeprogressWidth(80)
    } else if (type === 'profile') {
      let controllerProfileFields = controllerData && controllerData.length &&
        controllerData[0]['controllerProfileFields'] ? controllerData[0]['controllerProfileFields'] : [];
      let controllerProfileFieldsLength = controllerProfileFields.length ? controllerProfileFields.length : 0;
      let tempOptions = this.state.fieldProfileType;
      let flag = false;
      for (let j = 0; j < controllerProfileFields.length; j++) {
        if (controllerProfileFields[j]["profileName"] === e) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        controllerProfileFields.push({
          value: `controllerprofile_${0 +
            1}.value${controllerProfileFieldsLength + 1}`,
          type: `controllerprofile_${0 +
            1}.type${controllerProfileFieldsLength + 1}`,
          name: `controllerprofile_${0 +
            1}.name${controllerProfileFieldsLength + 1}`,
          label: `controllerprofile_${0 +
            1}.label${controllerProfileFieldsLength + 1}`,
          id: `controllerprofile_${0 +
            1}.id${controllerProfileFieldsLength + 1}`,
          placeholder: `controllerprofile_${0 +
            1}.placeholder${controllerProfileFieldsLength + 1}`,
          required: `controllerprofile_${0 +
            1}.required${controllerProfileFieldsLength + 1}`,
          [`name${controllerProfileFieldsLength == 0 ? 0 : controllerProfileFieldsLength == 1 ? controllerProfileFieldsLength : controllerProfileFieldsLength}`]: e,
          [`profileName`]: e,
        });
        if (controllerData && controllerData.length) {
          controllerData[0]['controllerProfileFields'] = controllerProfileFields;
        } else {
          controllerData.push({ 'controllerProfileFields': '' })
          controllerData[0]['controllerProfileFields'] = controllerProfileFields;
        }
        this.setState({
          selectedProfileIndex: controllerProfileFields.length && controllerProfileFields.length !== 1 ?
            controllerProfileFields.length - 1 : 0,
          controllers: controllerData,
          showProfileTypeDropdown: false,
          fieldProfileType: tempOptions
        });
      } else {
        this.setState({
          showProfileTypeDropdown: true,
        });
      }
      this.props.changeprogressWidth(100)
    }
  };

  //addFilterElement
  addFilterElement = async (index, type) => {
    let { selectedControllerIndex, controllers } = this.state;
    let controllerData = controllers;
    if (type === 'fieldTypeOptions') {
      let filterElementFields =
        controllers[selectedControllerIndex].controllerFields[index].options ?
          controllers[selectedControllerIndex].controllerFields[index].options :
          [];
      let filterElementFieldsLength = filterElementFields.length;
      filterElementFields.push({
        label: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.label${filterElementFieldsLength + 1}`,
        value: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.value${filterElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFields[index].options = filterElementFields;
      await this.setState({ selectdFilterIndex: index, controllers: controllerData, });
    } else if (type === 'fieldTypeDisplayOptions') {
      let filterElementFields =
        controllers[selectedControllerIndex].controllerFields[index].displayOptions ?
          controllers[selectedControllerIndex].controllerFields[index].displayOptions :
          [];
      let filterElementFieldsLength = filterElementFields.length;
      filterElementFields.push({
        bgColor: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.bgColor${filterElementFieldsLength + 1}`,
        actionType: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.actionType${filterElementFieldsLength + 1}`,
        isICon: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.isICon${filterElementFieldsLength + 1}`,
        name: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.name${filterElementFieldsLength + 1}`,
        tooltip: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.tooltip${filterElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFields[index].displayOptions = filterElementFields;
      await this.setState({ selectdFilterIndex: index, controllers: controllerData, });
    } else if (type === 'options') {
      let optionsElementFields =
        controllers[selectedControllerIndex].controllerFormFields[index].options ?
          controllers[selectedControllerIndex].controllerFormFields[index].options :
          [];
      let optionsElementFieldsLength = optionsElementFields.length;
      optionsElementFields.push({
        label: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.label${optionsElementFieldsLength + 1}`,
        value: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.value${optionsElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFormFields[index].options = optionsElementFields;
      await this.setState({ selectedFormIndex: index, controllers: controllerData, });
    } else if (type === 'fieldTypeValidation') {
      let optionsElementFields =
        controllers[selectedControllerIndex].controllerFormFields[index].validations ?
          controllers[selectedControllerIndex].controllerFormFields[index].validations :
          [];
      let optionsElementFieldsLength = optionsElementFields.length;
      optionsElementFields.push({
        name: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.name${optionsElementFieldsLength + 1}`,
        message: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.message${optionsElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFormFields[index].validations = optionsElementFields;
      await this.setState({ selectedFormIndex: index, controllers: controllerData, });
    }
  }


  //returnController
  returnControllerArray() {
    return this.state.controllers;
  }

  //changeControllerName
  changeContrllerName = async (e, selectedControllerIndex) => {
    let controllers = this.state.controllers;
    let tempUrlTypes = [];
    if (e && e.target.value) {
      controllers[selectedControllerIndex].name = e.target.value
      for (let i = 0; i < controllers.length; i++) {
        Object.keys(controllers[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers[i].name, value: controllers[i].name })
          }
        }
        )
      }
      await this.setState({
        controllers: this.state.controllers,
        apiUrlTypes: tempUrlTypes
      })
    } else {
      let i = selectedControllerIndex;
      i += 1;
      controllers[selectedControllerIndex].name = ''
      await this.setState({ controllers: this.state.controllers })
    }
  }

  //handleControllerTableFields
  handleControllerFields = async (e, index, fieldName, type, fieldsType, name) => {
    let { selectedControllerIndex, controllers } = this.state;
    if (fieldsType === 'table') {
      if (type === 'checkbox') {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e;
        controllers[selectedControllerIndex]["controllerFields"][index][name] = e;
        await this.setState({ controllers: controllers })
        if (e == 'dropDown' && fieldName == `fieldType${index}`) {
          let filterElementFields =
            controllers[selectedControllerIndex].controllerFields[index].options ?
              controllers[selectedControllerIndex].controllerFields[index].options :
              [];
          let filterElementFieldsLength = filterElementFields.length;
          filterElementFields.push({
            label: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.label${filterElementFieldsLength + 1}`,
            value: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.value${filterElementFieldsLength + 1}`,
          })
          controllers[selectedControllerIndex].controllerFields[index].options = filterElementFields;
          delete controllers[selectedControllerIndex].controllerFields[index].displayOptions;
          await this.setState({ selectdFilterIndex: index, controllers: controllers, });
        } else if (e == 'Action' && fieldName == `fieldType${index}`) {
          let filterElementFields =
            controllers[selectedControllerIndex].controllerFields[index].displayOptions ?
              controllers[selectedControllerIndex].controllerFields[index].displayOptions :
              [];
          let filterElementFieldsLength = filterElementFields.length;
          filterElementFields.push({
            bgColor: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.bgColor${filterElementFieldsLength + 1}`,
            actionType: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.actionType${filterElementFieldsLength + 1}`,
            isICon: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.isICon${filterElementFieldsLength + 1}`,
            name: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.name${filterElementFieldsLength + 1}`,
            tooltip: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.tooltip${filterElementFieldsLength + 1}`,
          })
          controllers[selectedControllerIndex].controllerFields[index].displayOptions = filterElementFields;
          delete controllers[selectedControllerIndex].controllerFields[index].options
          await this.setState({ selectdFilterIndex: index, controllers: controllers, });
        } else if (e == 'text' && fieldName == `fieldType${index}`) {
          delete controllers[selectedControllerIndex].controllerFields[index].displayOptions;
          delete controllers[selectedControllerIndex].controllerFields[index].options;

          await this.setState({ selectdFilterIndex: index, controllers: controllers, });
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e.target.value;
          controllers[selectedControllerIndex]["controllerFields"][index][name] = e.target.value;
          await this.setState({ controllers: controllers })
        } else {
          controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = '';
          controllers[selectedControllerIndex]["controllerFields"][index][name] = '';
          await this.setState({ controllers: controllers })
        }
      }
    } else if (fieldsType === 'form') {
      if (type === 'checkbox') {
        if (e === 'dropDown' || e === 'radio') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e === 'autoComplete') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index]['options'];
        } else if (e === 'multiSelect') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = 'dropDown';
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`] = true;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e && `type${index}` === fieldName) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index]['options'];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e == true && fieldName == `required${index}`) {
          let filterElementFields =
            controllers[selectedControllerIndex].controllerFormFields[index].validations ?
              controllers[selectedControllerIndex].controllerFormFields[index].validations :
              [];
          let filterElementFieldsLength = filterElementFields.length;
          filterElementFields.push({
            name: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.name${filterElementFieldsLength + 1}`,
            message: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.message${filterElementFieldsLength + 1}`,
          })
          controllers[selectedControllerIndex].controllerFormFields[index].validations = filterElementFields;
        } else if (e == false && fieldName == `required${index}`) {
          delete controllers[selectedControllerIndex].controllerFormFields[index].validations;
        } else if (e) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e.target.value;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e.target.value;
        } else {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = '';
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = '';
        }
      }
      await this.setState({ controllers: controllers })
    } else if (fieldsType === 'profile') {
      if (type === 'checkbox') {
        if (e) {
          controllers[0]["controllerProfileFields"][index][fieldName] = e;
          controllers[0]["controllerProfileFields"][index][name] = e;
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[0]["controllerProfileFields"][index][fieldName] = e.target.value;
          controllers[0]["controllerProfileFields"][index][name] = e.target.value;
        } else {
          controllers[0]["controllerProfileFields"][index][fieldName] = '';
          controllers[0]["controllerProfileFields"][index][name] = '';
        }
      }
      await this.setState({ controllers: controllers })
    }
  }

  //handleactions
  handleControllerActions = async (e, fieldName) => {
    let controllers = this.state.controllers;
    if (fieldName === 'actions') {
      if (e) {
        controllers[this.state.selectedControllerIndex][fieldName] = e

      } else {
        controllers[this.state.selectedControllerIndex][fieldName] = e
      }
    } else if ('login') {
      controllers[this.state.selectedControllerIndex][fieldName] = e
    }
    await this.setState({ controllers: controllers })
  }

  //handleControllerFilterFields
  handleControllerFilterFields = async (e, index, fieldName, filterIndex, type, name, fieldsTypeValue) => {
    let controllerData = this.state.controllers;
    if (type === 'table') {
      if (fieldsTypeValue == 'fieldTypeOptions') {
        if (e && e.target.value) {
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['options'][filterIndex][fieldName] = e.target.value;
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['options'][filterIndex][name] = e.target.value;
        } else {
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['options'][filterIndex][fieldName] = '';
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['options'][filterIndex][name] = '';
        }
      } else if (fieldsTypeValue == 'fieldTypeDisplayOptions') {
        if (e && name === 'isIConValue') {
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'][filterIndex][fieldName] = e;
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'][filterIndex][name] = e;
        }
        else if (e && e.target.value) {
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'][filterIndex][fieldName] = e.target.value;
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'][filterIndex][name] = e.target.value;
        } else {
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'][filterIndex][fieldName] = '';
          controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'][filterIndex][name] = '';
        }
      }
    } else if (type === 'form') {
      if (fieldsTypeValue == 'fieldTypeValidations') {
        if (e && e.target.value) {
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['validations'][filterIndex][fieldName] = e.target.value;
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['validations'][filterIndex][name] = e.target.value;
        } else {
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['validations'][filterIndex][fieldName] = '';
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['validations'][filterIndex][name] = '';
        }
      } else {
        if (e && e.target.value) {
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][fieldName] = e.target.value;
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][name] = e.target.value;
        } else {
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][fieldName] = '';
          controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][name] = '';
        }
      }
    }
    await this.setState({ controllers: controllerData })
  }

  //removeFormFields
  removeFormFields = async (index, type) => {
    let controllers = this.state.controllers;
    if (type === 'table') {
      controllers[this.state.selectedControllerIndex]["controllerFields"].splice(index, 1);
      await this.setState({
        selectdFilterIndex:
          index > 0 ? index - 1 : 0,
      })
    } else if (type === 'form') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"].splice(index, 1);
      await this.setState({
        selectedFormIndex:
          index > 0 ? index - 1 : 0,
      })
    } else if (type === 'profile') {
      controllers[0]["controllerProfileFields"].splice(index, 1);
      await this.setState({
        showProfileTypeDropdown: false,
      })
    } else if (type === 'controller') {
      let tempUrlTypes = [];
      controllers.splice(index, 1);
      for (let i = 0; i < controllers.length; i++) {
        Object.keys(controllers[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers[i].name, value: controllers[i].name })
          }
        }
        )
      }
      if (index === 0) {
        await this.setState({
          selectedControllerIndex: 0,
          apiUrlTypes: tempUrlTypes
        })
      } else {
        await this.setState({
          selectedControllerIndex: index - 1,
          apiUrlTypes: tempUrlTypes
        })
      }
    }
    await this.setState({
      controllers: controllers,
    })
  }

  //activeTabs
  activeTabs = async (index, fieldName) => {
    let controllers = this.state.controllers;
    let valueType = controllers[this.state.selectedControllerIndex]["controllerFields"][index][fieldName] ? controllers[this.state.selectedControllerIndex]["controllerFields"][index][fieldName] : '';
    if (valueType) {
      if (valueType == false) {
        await this.setState({ showFilterElement: false, selectdFilterIndex: index })
      } else {
        await this.setState({ showFilterElement: true, selectdFilterIndex: index })
      }
    } else {
      await this.setState({ showFilterElement: false, selectdFilterIndex: index })
    }
  }

  //toggleUploadSample
  toggleUploadSample = async () => {
    await this.setState({ showSampleFile: !this.state.showSampleFile })
    if (this.props.yesJsonUploaded) {
      await this.setState({ showControllers: true })
    }
  }

  //removeFilterFields
  removeFilterFields = async (index, type, filterIndex) => {
    let controllers = this.state.controllers;
    if (type == 'tableFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFields"][index]['options'].splice(filterIndex, 1);
    } else if (type == 'formFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'].splice(filterIndex, 1);
    } else if (type == 'displayFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFields"][index]['displayOptions'].splice(filterIndex, 1);
    } else if (type == 'validFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"][index]['validations'].splice(filterIndex, 1);
    }
    await this.setState({ controllers: controllers })
  }

  //handleyesExistingProject
  handleExistingProject = (e) => {
    if (e && e.target.value) {
      this.setState({ showControllers: true })
      this.props.handleyesExistingProject(true);
    } else {
      this.setState({ showControllers: false, })
      this.props.handleyesExistingProject(false);
    }
  }

  render() {

    const {
      controllers,
      selectedControllerIndex,
      typeOptions,
      fieldTypeOptions,
      fieldProfileTypeOptions,
      fieldProfileType,
      actionsType,
      apiUrlTypes,
      showSampleFile,
      filterMatchCodeOptions,
      specificClass,
      statusClass,
    } = this.state;
    let controllerFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFields"] &&
        controllers[selectedControllerIndex]["controllerFields"].length
        ? controllers[selectedControllerIndex]["controllerFields"]
        : [];
    let controllerProfileFields =
      controllers &&
        controllers.length &&
        controllers[0] &&
        controllers[0]["controllerProfileFields"] &&
        controllers[0]["controllerProfileFields"].length
        ? controllers[0]["controllerProfileFields"]
        : [];
    let controllerFormFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFormFields"] &&
        controllers[selectedControllerIndex]["controllerFormFields"].length
        ? controllers[selectedControllerIndex]["controllerFormFields"]
        : [];
    return (
      <Row>
        <Col sm={12}>
          <div >
            <h4 className='ps-2 addIcon pb-2 float-right'>
              < FontAwesomeIcon
                className='editIcon text-info'
                color='#ff00008c'
                icon='upload'
                data-toggle="tool-tip"
                title="Upload Json File"
                onClick={this.toggleUploadSample}
              />
            </h4>
            <Collapse isOpen={showSampleFile}>

              <h5 className='pt-3 pb-3 '>Sample File <a href={this.state.sampleFileJson} download target="_blank" >
                <span className='ps-2 addIcon'>
                  < FontAwesomeIcon
                    className='editIcon text-info'
                    color='#ff00008c'
                    icon='download'
                    data-toggle="tool-tip"
                    title="Sample File"
                  />
                </span>
              </a> </h5>
              <Field
                name="jsonFile"
                label={'Upload Json File'}
                component={FileInput}
                type="text"
                placeholder="eg:var/www/html"
                className="form-control d-none"
                accept={'.json'}
                onChange={this.props.jsonUploadFile}
              />
              <h5 className=' text-secondary mt-2 mb-3'>
                Note: If you upload JSON file please fill the fields which displays below.</h5>
              <hr></hr>
            </Collapse>
            {showSampleFile && <h4 className='text-center'> OR</h4>}
          </div>
        </Col>
        <Col sm={12} >
          {/* <h2 className='pt-2'>Enter Existing Project Details here</h2> */}
          <div>
            <div>
              <div className='row pt-3'>
                {!this.props.yesJsonUploaded && <div className='col-12 col-sm-12 col-md-6 col-lg-4'>
                  <label className='labelMargin'>May I have your Project Name?</label>
                  <Field
                    name="projectName"
                    component={DefaultInput}
                    type="text"
                    placeholder="Project Name"
                    className="form-control"
                    onChange={(e) => this.handleExistingProject(e)}
                  />
                </div>}
              </div>
            </div>
          </div>
          {this.state.showControllers && !this.props.yesJsonUploaded ?
            <div className='pt-4'>
              <div className='row'>
                <div className='col-lg-4 col-md-12'>
                  <label className='labelMargin headingsFontC '>Add Controllers Data
            <span className='ps-3 addIcon'>
                      < FontAwesomeIcon
                        className='editIcon text-info'
                        color='#ff00008c'
                        icon='plus-circle'
                        data-toggle="tool-tip"
                        title="Add"
                        onClick={(e) => this.addControllersFields('controller')}
                      />
                    </span>
                  </label>
                </div>
              </div>
              <div className='row ps-2'>
                {!this.props.yesJsonUploaded && controllers && controllers.length
                  ? controllers.map((item, index) => {
                    return (
                      <div
                        className='col-12 col-sm-12 col-md-6 col-lg-4'
                        key={index}
                      >
                        <div className={selectedControllerIndex == index ?
                          'text-info  controller_label borderContollerColor'
                          : 'controller_label'}>
                          <span
                            style={{ textTransform: 'capitalize' }}
                            onClick={(e) =>
                              this.setState({
                                selectedControllerIndex: index,
                                selectdFilterIndex: 0,
                                selectedFormIndex: 0,
                              })
                            }>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                autoFocus={true}
                                margin="dense"
                                variant="outlined"
                                id="custom-css-outlined-input"
                                size='small'
                                type="text"
                                helperText={'Please Enter Singular Values, Ex:employee'}
                                className={selectedControllerIndex == index ? 'float-left text-info textFieldsWidth'
                                  : 'float-left textFieldsWidth'}
                                style={{ textTransform: 'capitalize' }}
                                name={`controller_${index + 1}.name`}
                                label={`Controller${index + 1} Name`}
                                onChange={(e) => {
                                  this.changeContrllerName(e, selectedControllerIndex)
                                }}
                                value={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                                defaultValue={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                              />
                            </MuiThemeProvider>
                            <span className='text-white'>
                              {`controller_${index + 1}.name`}
                            </span>
                          </span>
                          <span className='float-right iconSize controllerDelteIConPos'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeFormFields(index, 'controller')}
                          /></span>
                        </div>
                      </div>
                    );
                  })
                  : null}
                {!this.props.yesJsonUploaded &&
                  <Col
                    sm={12}
                    className={controllers && controllers.length ? " pt-2" : "d-none"}
                  >
                    <div className='row'>
                      <div className='col-lg-4 col-md-12'>
                        <label className='labelMargin headingsFont'>
                          {controllers && controllers.length && controllers[selectedControllerIndex].name
                            ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`} Table Fields
                  <span className='ps-3 addIcon'>
                            < FontAwesomeIcon
                              className='editIcon text-info'
                              color='#ff00008c'
                              icon='plus-circle'
                              data-toggle="tool-tip"
                              title="Add"
                              onClick={(e) => this.addControllersFields('table')}
                            />
                          </span>
                        </label>
                      </div>
                      {/* <div className='col-lg-4 col-md-12'>
                        <div>
                          <label className='mb-0'>
                            Actions
                    </label>
                          <Field
                            name={`controller_${selectedControllerIndex}.act`}
                            component={MultiSelect}
                            type="text"
                            placeholder="Actions"
                            className="form-control"
                            valueMulti={controllers && controllers.length > 0 && controllers[selectedControllerIndex]['actions'] && controllers[selectedControllerIndex]['actions']}
                            options={actionsType}
                            onChange={(e) => { e ? this.handleControllerActions(e, 'actions') : null }}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="row">
                      {controllerFieldsArray && controllerFieldsArray.length
                        ? controllerFieldsArray.map((item, index) => {
                          return (
                            <div key={index} className='col-12 col-sm-12 col-md-6 col-lg-4'>
                              <div key={index}>
                                <div className='controller_labelForFields'>
                                  <span className='float-left' style={{ textTransform: 'capitalize' }}>
                                    {item[`fieldValue`] ? item[`fieldValue`] : `Table Fields ${index + 1}`}
                                  </span>
                                  <span className='float-right iconSize' ><FontAwesomeIcon
                                    className='editIcon'
                                    color='#ff00008c'
                                    icon='trash-alt'
                                    data-toggle="tool-tip"
                                    title="Delete"
                                    onClick={() => this.removeFormFields(index, 'table')}
                                  /></span>
                                </div>
                                <div className='card cardShadow'>
                                  <div className='card-body px-3 py-2'>
                                    <div className="row" key={index}>
                                      <div className="col-12 pb-2 ">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.field}
                                            label="Field Name"
                                            variant="outlined"
                                            id="custom-css-outlined-input"
                                            size='small'
                                            defaultValue={item[`fieldValue`] ? item[`fieldValue`] : ''}
                                            value={item[`fieldValue`] ? item[`fieldValue`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `field${index}`, 'input', 'table', 'fieldValue')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12 pt-2 pb-3">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.header}
                                            label="Header Name"
                                            variant="outlined"
                                            size='small'
                                            id="custom-css-outlined-input"
                                            defaultValue={item[`headerValue`] ? item[`headerValue`] : ''}
                                            value={item[`headerValue`] ? item[`headerValue`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `header${index}`, 'input', 'table', 'headerValue')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12  dropDownPadiingAlign">
                                        <Field
                                          name={item.filterMatchMode}
                                          component={Select}
                                          type="text"
                                          placeholder="Filter Match Mode"
                                          valueExists={{ label: item[`filterMatchModeValue`], value: item[`filterMatchModeValue`] }}
                                          className="form-control"
                                          options={filterMatchCodeOptions}
                                          onChange={(e) => this.handleControllerFields(e, index, `filterMatchMode${index}`, 'checkbox', 'table', 'filterMatchModeValue')}
                                        />
                                      </div>
                                      <div className="col-12  pb-4">
                                        <Field
                                          name={item.fieldType}
                                          component={Select}
                                          type="text"
                                          placeholder="Field Type"
                                          valueExists={{ label: item[`fieldTypeValue`], value: item[`fieldTypeValue`] }}
                                          className="form-control"
                                          options={typeOptions}
                                          onChange={(e) => this.handleControllerFields(e, index, `fieldType${index}`, 'checkbox', 'table', 'fieldTypeValue')}
                                        />
                                      </div>
                                      {item.options && item.options.length > 0 && index == index ?
                                        <div>
                                          <div className="col-12  pb-2">
                                            <Field
                                              name={item.showBadge}
                                              component={CheckBox}
                                              label='Show Badge'
                                              className="form-control"
                                              type="checkbox"
                                              onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `showBadge${index}`, 'checkbox', 'table') : null }}
                                            />
                                          </div>
                                          <div className="col-12 pt-2 pb-3">
                                            <Field
                                              name={item.specificClass}
                                              component={Select}
                                              type="text"
                                              placeholder="Specific Class"
                                              valueExists={{ label: item[`specificClassValue`], value: item[`specificClassValue`] }}
                                              className="form-control"
                                              options={specificClass}
                                              onChange={(e) => this.handleControllerFields(e, index, `specificClass${index}`, 'checkbox', 'table', 'specificClassValue')}
                                            />
                                          </div>
                                          <div className='col-12'>
                                            {item.options && item.options.length > 0 ?
                                              item.options.map((itemsFilter, filterIndex) => (
                                                <div className='row' key={filterIndex}>
                                                  <div className="col-12 pb-3 ">
                                                    <MuiThemeProvider theme={theme}>
                                                      <TextField
                                                        type="text"
                                                        className='textFieldsWidth'
                                                        margin="dense"
                                                        name={itemsFilter.label}
                                                        label="Label"
                                                        variant="outlined"
                                                        size='small'
                                                        id="custom-css-outlined-input"
                                                        defaultValue={itemsFilter[`labelValue`] ? itemsFilter[`labelValue`] : ''}
                                                        value={itemsFilter[`labelValue`] ? itemsFilter[`labelValue`] : ''}
                                                        onChange={(e) => this.handleControllerFilterFields(e, index, `label${filterIndex}`, filterIndex, 'table', 'labelValue', 'fieldTypeOptions')}
                                                      />
                                                    </MuiThemeProvider>
                                                  </div>
                                                  <div className="col-12 pb-3">
                                                    <MuiThemeProvider theme={theme}>
                                                      <TextField
                                                        type="text"
                                                        className='textFieldsWidth'
                                                        margin="dense"
                                                        name={itemsFilter.value}
                                                        label="Value"
                                                        variant="outlined"
                                                        size='small'
                                                        id="custom-css-outlined-input"
                                                        defaultValue={itemsFilter[`valueValue`] ? itemsFilter[`valueValue`] : ''}
                                                        value={itemsFilter[`valueValue`] ? itemsFilter[`valueValue`] : ''}
                                                        onChange={(e) => this.handleControllerFilterFields(e, index, `value${filterIndex}`, filterIndex, 'table', 'valueValue', 'fieldTypeOptions')}
                                                      />
                                                    </MuiThemeProvider>
                                                    <span className='float-right iconSize' ><FontAwesomeIcon
                                                      className='editIconField'
                                                      color='#ff00008c'
                                                      icon='trash-alt'
                                                      data-toggle="tool-tip"
                                                      title="Delete"
                                                      onClick={() => this.removeFilterFields(index, 'tableFilter', filterIndex)}
                                                    /></span>
                                                  </div>
                                                </div>
                                              ))
                                              : null}
                                            <p
                                              onClick={(e) => this.addFilterElement(index, 'fieldTypeOptions')}
                                              className="text-primary py-2 mt-0"
                                              style={{ textDecoration: "underline", cursor: "pointer" }}
                                            >
                                              Add Filter Element Fields +
                        </p>
                                          </div>
                                        </div> : null}
                                      {item.displayOptions && item.displayOptions.length > 0 && index == index ?
                                        <div className='col-12'>
                                          {item.displayOptions && item.displayOptions.length > 0 ?
                                            item.displayOptions.map((itemsFilter, filterIndex) => (
                                              <div className='row' key={filterIndex}>
                                                <div className="col-12 pb-1 pt-3">
                                                  <Field
                                                    name={itemsFilter.isICon}
                                                    component={CheckBox}
                                                    label='Icon'
                                                    className="form-control"
                                                    type="checkbox"
                                                    onChange={(e) => { e && e.target ? this.handleControllerFilterFields(e.target.checked, index, `isICon${filterIndex}`, filterIndex, 'table', 'isIConValue', 'fieldTypeDisplayOptions') : null }}
                                                  />
                                                </div>
                                                <div className="col-12 pb-3">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsFilter.bgColor}
                                                      helperText={'Ex:primary'}
                                                      label="Background Color"
                                                      variant="outlined"
                                                      size='small'
                                                      id="custom-css-outlined-input"
                                                      defaultValue={itemsFilter[`bgColorValue`] ? itemsFilter[`bgColorValue`] : ''}
                                                      value={itemsFilter[`bgColorValue`] ? itemsFilter[`bgColorValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `bgColor${filterIndex}`, filterIndex, 'table', 'bgColorValue', 'fieldTypeDisplayOptions')}
                                                    />
                                                  </MuiThemeProvider>
                                                </div>
                                                <div className="col-12 pb-3 pt-3">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsFilter.actionType}
                                                      helperText={'Ex:Edit'}
                                                      label="Action Type"
                                                      variant="outlined"
                                                      size='small'
                                                      id="custom-css-outlined-input"
                                                      defaultValue={itemsFilter[`actionTypeValue`] ? itemsFilter[`actionTypeValue`] : ''}
                                                      value={itemsFilter[`actionTypeValue`] ? itemsFilter[`actionTypeValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `actionType${filterIndex}`, filterIndex, 'table', 'actionTypeValue', 'fieldTypeDisplayOptions')}
                                                    />
                                                  </MuiThemeProvider>
                                                </div>
                                                <div className="col-12 pb-3 pt-3">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsFilter.name}
                                                      helperText={'Ex:fa fa-edit'}
                                                      label="Icon Name"
                                                      variant="outlined"
                                                      size='small'
                                                      id="custom-css-outlined-input"
                                                      defaultValue={itemsFilter[`nameValue`] ? itemsFilter[`nameValue`] : ''}
                                                      value={itemsFilter[`nameValue`] ? itemsFilter[`nameValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `name${filterIndex}`, filterIndex, 'table', 'nameValue', 'fieldTypeDisplayOptions')}
                                                    />
                                                  </MuiThemeProvider>
                                                </div>
                                                <div className="col-12 pb-3 pt-3">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsFilter.tooltip}
                                                      label="Tooltip"
                                                      variant="outlined"
                                                      size='small'
                                                      id="custom-css-outlined-input"
                                                      defaultValue={itemsFilter[`tooltipValue`] ? itemsFilter[`tooltipValue`] : ''}
                                                      value={itemsFilter[`tooltipValue`] ? itemsFilter[`tooltipValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `tooltip${filterIndex}`, filterIndex, 'table', 'tooltipValue', 'fieldTypeDisplayOptions')}
                                                    />
                                                  </MuiThemeProvider>
                                                  <span className='float-right iconSize' ><FontAwesomeIcon
                                                    className='editIconField'
                                                    color='#ff00008c'
                                                    icon='trash-alt'
                                                    data-toggle="tool-tip"
                                                    title="Delete"
                                                    onClick={() => this.removeFilterFields(index, 'displayFilter', filterIndex)}
                                                  /></span>
                                                </div>
                                              </div>
                                            ))
                                            : null}
                                          <p
                                            onClick={(e) => this.addFilterElement(index, 'fieldTypeDisplayOptions')}
                                            className="text-primary py-2 mt-0"
                                            style={{ textDecoration: "underline", cursor: "pointer" }}
                                          >
                                            Add Filter Element Fields +
                        </p>
                                        </div> : null}
                                      {!item.options && !item.displayOptions && index == index ? <div className="col-12 ">
                                        <Field
                                          name={item.sortable}
                                          component={CheckBox}
                                          label='Hide Sort Icon'
                                          className="form-control"
                                          type="checkbox"
                                          onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `hideSortIcon${index}`, 'checkbox', 'table') : null }}
                                        />
                                      </div> : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </Col>}
                {!this.props.yesJsonUploaded && <Col
                  sm={12}
                  className={controllers && controllers.length ? "pt-3" : "d-none"}
                >
                  <label className='labelMargin headingsFont pt-1'>
                    {controllers && controllers.length && controllers[selectedControllerIndex].name
                      ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`} Form Fields
                  <span className='ps-3 addIcon'>
                      < FontAwesomeIcon
                        className='editIcon text-info'
                        color='#ff00008c'
                        icon='plus-circle'
                        data-toggle="tool-tip"
                        title="Add"
                        onClick={(e) => this.addControllersFields('form')}
                      />
                    </span>
                  </label>
                  <div className="row" >
                    {controllerFormFieldsArray && controllerFormFieldsArray.length
                      ? controllerFormFieldsArray.map((item, index) => {
                        return (
                          <div
                            className='col-12 col-sm-12 col-md-6 col-lg-4'
                            key={index}
                          >
                            <div className='controller_labelForFields '>
                              <span className='float-left' style={{ textTransform: 'capitalize' }}>
                                {item[`formName`] ? item[`formName`] : `Form Fields ${index + 1}`}
                              </span>
                              <span className='float-right iconSize'><FontAwesomeIcon
                                className='editIcon'
                                color='#ff00008c'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                onClick={() => this.removeFormFields(index, 'form')}
                              /></span>
                            </div>
                            <div className='card cardShadowForm'>
                              <div className='card-body px-3 py-2'>
                                <div className="row" key={index}>
                                  <div className="col-12 pb-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.value}
                                        label="Value"
                                        id="outlined-search"
                                        variant="outlined"
                                        size='sm'
                                        defaultValue={item[`formValue`] ? item[`formValue`] : ''}
                                        value={item[`formValue`] ? item[`formValue`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'form', 'formValue')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12 py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.name}
                                        label="Name"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='sm'
                                        defaultValue={item[`formName`] ? item[`formName`] : ''}
                                        value={item[`formName`] ? item[`formName`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'form', 'formName')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.label}
                                        label="Label"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='sm'
                                        value={item[`formLabel`] ? item[`formLabel`] : ''}
                                        defaultValue={item[`formLabel`] ? item[`formLabel`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'form', 'formLabel')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.id}
                                        label="Id"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='small'
                                        value={item[`formId`] ? item[`formId`] : ''}
                                        defaultValue={item[`formId`] ? item[`formId`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'form', 'formId')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.placeholder}
                                        label="Placeholder"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='small'
                                        value={item[`formPlaceholder`] ? item[`formPlaceholder`] : ''}
                                        defaultValue={item[`formPlaceholder`] ? item[`formPlaceholder`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'form', 'formPlaceholder')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.minlength}
                                        label="Min Length"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='small'
                                        value={item[`formMinlength`] ? item[`formMinlength`] : ''}
                                        defaultValue={item[`formMinlength`] ? item[`formMinlength`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `minlength${index}`, 'input', 'form', 'formMinlength')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.maxlength}
                                        label="Max Length"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='small'
                                        value={item[`formMaxlength`] ? item[`formMaxlength`] : ''}
                                        defaultValue={item[`formMaxlength`] ? item[`formMaxlength`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `maxlength${index}`, 'input', 'form', 'formMaxlength')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12 dropDownPadiingFields">
                                    <Field
                                      name={item.type}
                                      component={Select}
                                      type="text"
                                      placeholder="Field Type"
                                      valueExists={{ label: item[`formType`], value: item[`formType`] }}
                                      className="form-control"
                                      options={fieldTypeOptions}
                                      onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'form', 'formType')}
                                    />
                                  </div>
                                  {(item[`formType`] === 'dropDown' || item[`formType`] === 'radio'
                                    || item[`formType`] === 'multiSelect') && index == index ?
                                    <div className='col-12 pb-3'>
                                      {item.options && item.options.length > 0 ?
                                        item.options.map((itemsOptions, optionsIndex) => (
                                          <div className='row' key={optionsIndex}>
                                            <div className="col-6 pb-3 pr-lg-1">
                                              <MuiThemeProvider theme={theme}>
                                                <TextField
                                                  type="text"
                                                  className='textFieldsWidth'
                                                  margin="dense"
                                                  name={itemsOptions.label}
                                                  label="Label"
                                                  id="outlined-margin-dense"
                                                  variant="outlined"
                                                  size='small'
                                                  value={itemsOptions[`formOptionLabel`] ? itemsOptions[`formOptionLabel`] : ''}
                                                  defaultValue={itemsOptions[`formOptionLabel`] ? itemsOptions[`formOptionLabel`] : ''}
                                                  onChange={(e) => this.handleControllerFilterFields(e, index, `label${optionsIndex}`, optionsIndex, 'form', 'formOptionLabel')}
                                                />
                                              </MuiThemeProvider>
                                            </div>
                                            <div className="col-6 pb-3 ps-lg-1">
                                              <MuiThemeProvider theme={theme}>
                                                <TextField
                                                  type="text"
                                                  className='textFieldsWidth'
                                                  margin="dense"
                                                  name={itemsOptions.value}
                                                  label="Value"
                                                  id="outlined-margin-dense"
                                                  variant="outlined"
                                                  size='small'
                                                  value={itemsOptions[`formOptionValue`] ? itemsOptions[`formOptionValue`] : ''}
                                                  defaultValue={itemsOptions[`formOptionValue`] ? itemsOptions[`formOptionValue`] : ''}
                                                  onChange={(e) => this.handleControllerFilterFields(e, index, `value${optionsIndex}`, optionsIndex, 'form', 'formOptionValue')}
                                                />
                                              </MuiThemeProvider>
                                              <span className='float-right iconSize' ><FontAwesomeIcon
                                                className='editIconField'
                                                color='#ff00008c'
                                                icon='trash-alt'
                                                data-toggle="tool-tip"
                                                title="Delete"
                                                onClick={() => this.removeFilterFields(index, 'formFilter', optionsIndex)}
                                              /></span>
                                            </div>
                                          </div>
                                        ))
                                        : null}
                                      <p
                                        onClick={(e) => this.addFilterElement(index, 'options')}
                                        className="text-primary  mt-0 pt-2"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                      >
                                        Add Options Fields +
                        </p>
                                    </div> : null}
                                  {item[`formType`] === 'autoComplete' ?
                                    <div className='col-12'>
                                      <div className='row'>
                                        <div className="col-6 pb-3 pr-lg-1">
                                          <MuiThemeProvider theme={theme}>
                                            <TextField
                                              type="text"
                                              className='textFieldsWidth'
                                              margin="dense"
                                              name={`controller_${selectedControllerIndex +
                                                1}.searchF${index + 1}`}
                                              label="Search Field"
                                              id="outlined-margin-dense"
                                              variant="outlined"
                                              size='small'
                                              value={item[`searchF`] ? item[`searchF`] : ''}
                                              defaultValue={item[`searchF`] ? item[`searchF`] : ''}
                                              onChange={(e) => this.handleControllerFields(e, index, `searchField${index}`, 'input', 'form', 'searchF')}
                                            />
                                          </MuiThemeProvider>
                                        </div>
                                        <div className="col-6 pb-3 ps-lg-1">
                                          <MuiThemeProvider theme={theme}>
                                            <TextField
                                              type="text"
                                              className='textFieldsWidth'
                                              margin="dense"
                                              name={`controller_${selectedControllerIndex +
                                                1}.searchA${index + 1}`}
                                              label="Search Api"
                                              id="outlined-margin-dense"
                                              variant="outlined"
                                              size='small'
                                              value={item[`searchA`] ? item[`searchA`] : ''}
                                              defaultValue={item[`searchA`] ? item[`searchA`] : ''}
                                              onChange={(e) => this.handleControllerFields(e, index, `searchApi${index}`, 'input', 'form', 'searchA')}
                                            />
                                          </MuiThemeProvider>
                                        </div>
                                      </div>
                                    </div>
                                    : null}
                                  <div className="col-12 pt-2">
                                    <Field
                                      name={item.isHiddenInEdit}
                                      component={CheckBox}
                                      label='Editable'
                                      className="form-control"
                                      type="checkbox"
                                      onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `isHiddenInEdit${index}`, 'checkbox', 'form') : null }}
                                    />
                                  </div>
                                  <div className="col-12 pt-2">
                                    <Field
                                      name={item.required}
                                      component={CheckBox}
                                      label='Requried'
                                      className="form-control"
                                      type="checkbox"
                                      onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'form') : null }}
                                    />
                                  </div>
                                  {item.validations && item.validations.length > 0 && index == index ?
                                    <div>
                                      <div className='col-12'>
                                        {item.validations && item.validations.length > 0 ?
                                          item.validations.map((itemsFilter, filterIndex) => (
                                            <div className='row' key={filterIndex}>
                                              <div className="col-12 pb-3 ">
                                                <MuiThemeProvider theme={theme}>
                                                  <TextField
                                                    type="text"
                                                    className='textFieldsWidth'
                                                    margin="dense"
                                                    name={itemsFilter.name}
                                                    label="Name"
                                                    variant="outlined"
                                                    size='small'
                                                    id="custom-css-outlined-input"
                                                    defaultValue={itemsFilter[`nameValue`] ? itemsFilter[`nameValue`] : ''}
                                                    value={itemsFilter[`nameValue`] ? itemsFilter[`nameValue`] : ''}
                                                    onChange={(e) => this.handleControllerFilterFields(e, index, `name${filterIndex}`, filterIndex, 'form', 'nameValue', 'fieldTypeValidations')}
                                                  />
                                                </MuiThemeProvider>
                                              </div>
                                              <div className="col-12 pb-3">
                                                <MuiThemeProvider theme={theme}>
                                                  <TextField
                                                    type="text"
                                                    className='textFieldsWidth'
                                                    margin="dense"
                                                    name={itemsFilter.message}
                                                    label="Message"
                                                    variant="outlined"
                                                    size='small'
                                                    id="custom-css-outlined-input"
                                                    defaultValue={itemsFilter[`messageValue`] ? itemsFilter[`messageValue`] : ''}
                                                    value={itemsFilter[`messageValue`] ? itemsFilter[`messageValue`] : ''}
                                                    onChange={(e) => this.handleControllerFilterFields(e, index, `message${filterIndex}`, filterIndex, 'form', 'messageValue', 'fieldTypeValidations')}
                                                  />
                                                </MuiThemeProvider>
                                                <span className='float-right iconSize' ><FontAwesomeIcon
                                                  className='editIconField'
                                                  color='#ff00008c'
                                                  icon='trash-alt'
                                                  data-toggle="tool-tip"
                                                  title="Delete"
                                                  onClick={() => this.removeFilterFields(index, 'validFilter', filterIndex)}
                                                /></span>
                                              </div>
                                            </div>
                                          ))
                                          : null}
                                        <p
                                          onClick={(e) => this.addFilterElement(index, 'fieldTypeValidation')}
                                          className="text-primary py-2 mt-0"
                                          style={{ textDecoration: "underline", cursor: "pointer" }}
                                        >
                                          Add Validation Fields +
                        </p>
                                      </div>
                                    </div> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </Col>}
              </div>
            </div>
            : null}
          {this.state.showControllers && !this.props.yesJsonUploaded && <hr className='mt-4'></hr>}
        </Col>
      </Row >
    );
  }
}
export default ExistingProject;


