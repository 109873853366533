import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse, Button } from 'reactstrap';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarMenuLink from '../topbar/TopbarMenuLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faDownload, faUpload, faSave, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import config from '../../../config/config';
import RSCode from '../../NoCodeRS/index';
import logo from '../../../assets/logo.png';
//session expiry modal
import SessionExpiryModal from '../../Cruds/CommonModals/SessionexpiryModal'

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      settingsInfo: {},
      sessionExpiryModal: false,
      collapse: false,
      savebutton: true

    }
  }
  componentDidMount = async () => {
    // console.log("window.location.pathname", window.location.pathname)
    if (window.location.pathname == "/create_project") {
      await this.setState({
        savebutton: false
      })
    }
    let sessionexpired = await localStorage.getItem('sessionexpired')
    if (sessionexpired == "true") {
      await this.setState({ sessionExpiryModal: true })
    }
  }


  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };
  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    const { collapse } = this.state;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <div className="topbar_drop_profile ps-2">
              {/* <span className="topbar_button mt-2"
              //  onClick={this.toggle}
               >
                {"Nocode"}
              </span> */}
              {/* <Link className='topbar_button mt-2' to="/dashboard" >
              {"Nocode"}
            </Link> */}
              
              <Link className='logo-container'  to="/dashboard" >
                <img src={logo} className='topbar-logo'></img>
                </Link>
             
              {/* <span>
                <Collapse isOpen={collapse} className="topbar_drop_menu-wrap ps-3">
                  <div className="topbar_drop_menu" >
                    <a className="topbar__link"
                      href="/create_project"
                    // onClick={this.new}
                    >
                      <p className="topbar__link-title">{"New"}</p>
                    </a>
                    <a className="topbar__link"
                      href="/projects"
                      onClick={this.new}>
                      <p className="topbar__link-title">{"Open"}</p>
                    </a>

                  </div>
                </Collapse>
              </span> */}
            </div>
            {/* <Button
              className='ms-auto mb-0 mt-1'
              style={{ color: "white", border: "none", padding: "0px" }}
              size={'xs'}
              onClick={""}
              disabled={this.state.savebutton}
            >
              <FontAwesomeIcon
                // className='topbar_button ms-3 mt-3'
                style={{ width: "55", height: "25", color: 'blue' }}
                color="blue"
                icon={faSave}
                data-toggle="tool-tip"
                title={"save"}
              />
            </Button>
            <Button
              style={{ color: "white", border: "none", padding: "0px" }}
              className='ms-auto mb-0 mt-1'
              size={'xs'}
              onClick={""}
              disabled={true}
            >
              <FontAwesomeIcon
                // className='topbar_button ms-3 mt-3'
                style={{ width: "55", height: "25", color: 'blue' }}
                color="blue"
                icon={faDownload}
                data-toggle="tool-tip"
                title={"Download"}
              />
            </Button>
            <Button
              style={{ color: "white", border: "none", padding: "0px" }}
              className='ms-auto mb-0 mt-1'
              size={'xs'}
              onClick={""}
              disabled={true}
            >
              <FontAwesomeIcon
                // className='topbar_button ms-3 mt-3'
                style={{ width: "55", height: "25", color: 'blue' }}
                color="blue"
                icon={faCloudUploadAlt}
                data-toggle="tool-tip"
                title={"Deploy"}
              />
            </Button>
            <Button
              style={{ color: "white", border: "none", padding: "0px" }}
              className='ms-auto mb-0 mt-1'
              size={'xs'}
              onClick={""}
              disabled={true}
            >
              <FontAwesomeIcon
                // className='topbar_button ms-3 mt-3'
                style={{ width: "55", height: "25", color: 'green' }}
                color="green"
                icon={faPlay}
                data-toggle="tool-tip"
                title={"Run"}
              />
            </Button>
            <Button
              style={{ color: "white", border: "none", padding: "0px" }}
              className='ms-auto mb-0 mt-1'
              size={'xs'}
              onClick={""}
              disabled={true}
            >
              <FontAwesomeIcon
                // className='topbar_button ms-2 mt-3'
                style={{ width: "55", height: "25", color: 'red' }}
                color="red"
                icon={faStop}
                data-toggle="tool-tip"
                title={"Stop"}
              />
            </Button> */}
            {/* <Link className='topbar_button ms-3' to="/save" >
              {"Save"}
            </Link>
            <Link className='topbar_button ms-3' to="/Download_code" >
              {"Download code"}
            </Link>
            <Link className='topbar_button ms-3' to="/Deploy" >
              {"Deploy"}
            </Link>
            <Link className='topbar_button ms-3' to="/Run/Stop" >
              {"Run/Stop"}
            </Link> */}
          </div>
          <div className="topbar__right">
            <TopbarProfile />
          </div>
          {
            this.state.sessionExpiryModal ?
              <SessionExpiryModal
                SOpen={this.state.sessionExpiryModal}
              />
              : null
          }
        </div >
      </div >
    );
  }
}

export default Topbar;
