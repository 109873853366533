import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const CodeEditorOptions = (props) => {

    const [displayOptions, setDisplayOptions] = useState(false);


    return (
        <Modal isOpen={props.displayOptions} centered={true} style={{ maxWidth: 400 }}>
            <ModalHeader className='text-white' toggle={props.toggleOptions} style={{ backgroundColor: "#4ce1b6" }}>
                <span className='h5 text-white'>Select File {props.showingOptions === 'fileTypes' && <span>Type</span>}</span>
            </ModalHeader>
            <ModalBody>

                {/* Showing select file types starts */}
                {props.showingOptions === 'fileTypes' &&
                    <div className='d-flex justify-content-around p-3'>
                        <div className='select-ce-button' onClick={() => { props.onSelectType('frontend') }}>
                            Frontend
                        </div>
                        <div className='select-ce-button' onClick={() => { props.onSelectType('backend') }}>
                            Backend
                        </div>
                    </div>
                }

                {/* Showing select file types  ends */}
                {/* Showing select files  starts */}
                {props.showingOptions === 'files' &&
                    <div className='w-100'>
                        {props.files && props.files.length > 0 ?
                            props.files.map((file) =>
                                <div>
                                    <div className='select-ce-files' onClick={() => { props.onSelectFile(file) }}>
                                        {file}
                                    </div>
                                </div>
                            )
                            : null
                        }
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}
export default CodeEditorOptions;