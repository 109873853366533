import config from '../../config/config';
import configMessages from '../../config/configMessages';
import dateFormats from '../UI/FormatDate/formatDate';

const validate = (values, props) => {
  let fields = [];
  // get formFields from props and set validation  fields
  if (props.formFields) {
    fields = [];
    let formFields = props.formFields();
    formFields.forEach(formField => {
      if (formField.required) {
        fields.push(formField.name);
      }
    });
  }
  const errors = {};


  let checkRegExpFieldsArray = ['aadharcardNumber', 'pancardNumber', 'email', 'companyEmail', 'confirmPassword', 'phoneNumber', 'password', 'phone'];
   if (values.email) {
   if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values["email"])) {
      errors.email = configMessages['invalidEmail'];
    }
  }
  if (values.phone) {
    if (!/^\d{10}$/i.test(values["phone"])) {
      errors.phone = configMessages['phoneNumber'];
    }
  }
  fields.forEach((field) => {
    if (!values[field]) {
      errors[field] = configMessages.fillField;
    } else if (checkRegExpFieldsArray.includes(field)) {
      let regex = config[field + "Regex"];
      if (field == 'companyEmail') {
        regex = config['email' + "Regex"];
      }
      if (values[field] && typeof (values[field]) == 'string' && regex.test(values[field].trim()) == false) {
        if (field == 'companyEmail') {
          errors[field] = configMessages['email'];
        } else {
          errors[field] = configMessages[field];
        }
      }
    }
  });
  if(values.confirmPassword &&values.password&&values.confirmPassword !==values.password){
    errors["confirmPassword"] = "Password and Confirm password must be same";
  }
  if(values.confirmPassword &&values.newPassword&&values.confirmPassword !==values.newPassword){
    errors["confirmPassword"] = "New Password and Confirm password must be same";
  }
  let todayDate = dateFormats.formatDate(new Date(), config.dateDashFormat);
  let compare;
  if (values.dateOfBirth) {
    compare = dateFormats.datesComparisionBefore(todayDate, values.dateOfBirth);
    if (compare) {
      errors.dateOfBirth = configMessages.InvalidDate;
    }
  }

  if (values.endDate && values.startDate) {
    compare = dateFormats.datesComparisionBefore(values.endDate, values.startDate);
    if (compare) {
      errors['endDate'] = configMessages.InvalidDate;
    } else {
      errors['endDate'] = '';
    }
  }
  if (values.toDate && values.fromDate) {
    compare = dateFormats.datesComparisionBefore(values.toDate, values.fromDate);
    if (compare) {
      errors['toDate'] = configMessages.InvalidDate;
    } else {
      errors['toDate'] = '';
    }
  }
  if (values.joinDate) {
    if (values.dateOfBirth) {
      let years = dateFormats.differenceBetweenDatesInYears(values.joinDate, values.dateOfBirth);
      if (years < 15) {
        errors.joinDate = configMessages.InvalidDate;
      } else {
        errors.joinDate = '';
      }
    }
  }
  return errors;
};




export default validate;

