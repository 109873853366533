import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { InputText } from 'primereact/inputtext';

const CopyProjectModal = ({ name, selectModalShow, closeInputModal }) => {

    const [value, setValue] = useState('');

    return (
        <Modal centered={true} isOpen={selectModalShow}>
            <ModalHeader className='progresBarClr text-white' style={{ textAlign: "center" }}>
                Do you want to copy <b>"{name}"</b> project?
                <button className="lnr lnr-cross modal__close-btn fw-bolder text-white" type="button"
                    onClick={() => { closeInputModal('No'); setValue('') }} />
            </ModalHeader>
            <ModalBody>
                <Row className="pt-2">
                    <Col sm={12} className="text-center mb-3">
                        <div className='mb-3'>
                            <label className="form-group-label text-left pb-1" style={{ fontSize: '12px', width: '50%' }}>Enter a New Project Name</label>
                            <br></br>
                            <InputText value={value}
                                className={value && (!/^[a-zA-Z0-9 ]{3,64}$/.test(value)) || (/test/i.test(value)) ?
                                    "p-inputtext-lg new-project-name-danger" : "p-inputtext-lg new-project-name"}
                                onChange={(e) => setValue(e.target.value)} placeholder={name} />
                            <br></br>
                            {value && (!/^[a-zA-Z0-9 ]{3,64}$/.test(value)) ?
                                <small style={{ color: 'red' }}>New name must contain alphabets & numbers, and minimum length of 3.</small>
                                : value && (/test/i.test(value)) ?
                                    <small style={{ color: 'red' }}>Unable to create a project that includes test</small>
                                    : ''}
                        </div>
                        <button type="button" className="btn btn-success" onClick={() => { closeInputModal('No'); setValue('') }}>
                            No
                        </button>
                        <button type="button" className="btn btn-success copy-yes-btn"
                            disabled={value && (!/^[a-zA-Z0-9 ]{3,64}$/.test(value)) || (/test/i.test(value)) || value == '' ? true : false} // button will be disabled if the pattern, name have test and value is empty
                            onClick={() => closeInputModal('Yes', value)}>
                            Yes
                        </button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default CopyProjectModal;
