import React, { useEffect, useState, useRef } from "react";
//Components

import Loader from '../App/Loader';
import { Field, reduxForm } from "redux-form";
import RadioInput from "../../shared/components/form/RadioButton";

import { Redirect, Link } from 'react-router-dom';
//styles
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import socket from '../../config/Socket';

import { Row, Col, Container } from "reactstrap";
//service.js
import ServerCode from '../NoCodeServer/index';
import ClientCode from '../NoCode/index';
import RSCode from '../NoCodeRS/index';
import AngularClientCode from '../NoCodeAngular/index';
import VueClientCode from '../NoCodeVue/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faStop, faDownload, faUpload, faSave, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'

import configImages from "../../config/configImages";

import RSNewProject from './rsNewProject';

const Display=(props)=>{

	const ClientCode = useRef(null);

	const [selectedTech, setSelectedTech] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedReact, setSelectedReact] = useState(false);
    const [selectedNode, setSelectedNode] = useState(false);
    const [selectRS, setSelectRS] = useState(true);
    const [tabOneActive, setTabOneActive] = useState(true);
    const [tabTwoActive, setTabTwoActive] = useState(false);
    const [tabThreeActive, setTabThreeActive] = useState(false);
    const [activeOneTab, setActiveOneTab] = useState(true);
    const [activeTwoTab, setActiveTwoTab] = useState(false);
    const [activeThreeTab, setActiveThreeTab] = useState(false);
    const [activeFiveTab, setActiveFiveTab] = useState(false);
    const [activeFourTab, setActiveFourTab] = useState(false);
    const [setMainTab, setSetMainTab] = useState('one');
    const [hideEverything, setHideEverything] = useState(false);
    const [hideTabOne, setHideTabOne] = useState(false);
    const [hideTabTwo, setHideTabTwo] = useState(false);
    const [hideNextScreen, setHideNextScreen] = useState(false);
    const [progressWidth, setProgressWidth] = useState(0);
    const [selectedAngular, setSelectedAngular] = useState(false);
    const [selectedVue, setSelectedVue] = useState(false);
    const [showScroll, setShowScroll] = useState(false);
    const [saveButton, setSaveButton] = useState(true);
    const [downloadButton, setDownloadButton] = useState(true);
    const [deployButton, setDeployButton] = useState(true);
    const [stopButton, setStopButton] = useState(true);
    const [startButton, setStartButton] = useState(true);
    const [editProject, setEditProject] = useState(window.location.href.includes("edit"));
    const [editProjectId, setEditProjectId] = useState(window.location.href.includes("edit") && props && props.match && props.match.params && props.match.params.id ? JSON.parse(props.match.params.id) : null);

	useEffect(() => {
        const loginCredentials = localStorage.loginCredentials ? JSON.parse(localStorage.loginCredentials) : false;
        if (loginCredentials) {
            socket.emit('userConnected', {
                userId: loginCredentials._id,
            });
        }
    }, []);

	//scroll top
	const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };

	//scrolling window
	const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

	//handle tabs
    const handleTabs = async (type) => {
        if (type === 'one') {
            await setSetMainTab('one');
            await setHideTabOne(false);
            await setHideNextScreen(true);
            changeprogressWidth(0);
        } else if (type === 'two') {
            await setSetMainTab('two');
            await setActiveTwoTab(true);
            await setHideTabOne(true);
            await setHideNextScreen(false);
        } else if (type === 'three') {
            await setSetMainTab('three');
            await setActiveThreeTab(true);
        } else if (type === 'four') {
            await setSetMainTab('four');
            await setActiveFourTab(true);
        }
    };


	const changeprogressWidth = async (widthValue) => {
        let x = progressWidth;
        if (widthValue !== 110) {
            if (widthValue !== 0) {
                if (x !== 100) {
                    if (x > widthValue) {
                        x = x - 20;
                    } else if (x < widthValue) {
                        x = x + 20;
                    } else if (widthValue === 20) {
                        x = 0;
                    }
                    await setProgressWidth(x);
                } else {
                    await setProgressWidth(100);
                }
            } else {
                await setProgressWidth(0);
            }
        } else {
            await setProgressWidth(40);
        }
    };

    const yesSubmittedData = async () => {
        await setProgressWidth(20);
    };

	return(
		<>
			<div className="newproj-wrapper">
				<div className="account">
					<div className="account__wrapper">
						<div className="account__card">
							<div className="my-2 mx-4">
								<h4>
									<span className='pt-2 mb-5'>
										<b>
											<Link to='/dashboard' style={{ color: "#3c6177" }}>
												{"Dashboard"} </Link> / New Project
										</b>
									</span>
								</h4>
								
								<div className="mt-2">
									<div>
										<RSNewProject		
											ref={ClientCode}	
											hideTabOne={true}
											editProject={editProject}
											editProjectId={editProjectId}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

};

export default Display;
