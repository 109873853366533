const apiCalls = {
    "React": 'build/clientCreate',
    "ReactExist": 'build/reactExist',
    "Node": 'build/create',
    "ReactNode": 'build/srCreate',
    "ReactNodeUpdate": 'build/srUpdate',
    "Angular": 'build/angularCreate',
    "Vue": 'build/vueCreate',
    "ReactLogo": 'build/reactLogo?type=logo',
    "serverStatus": "build/serverStatus",
    "saveProject": "build/saveProject",
    "copyProject": "build/copyProject",
    "serverCreate": "build/serverCreate",
    "ReactFav": 'build/reactLogo?type=fav',
    "loginSideImage": 'build/reactLogo?type=loginSideImage',
    "ReactBg": 'build/reactLogo?type=bg',
    "AngularLogo": 'build/angularLogo?type=logo',
    "AngularFav": 'build/angularLogo?type=fav',
    "AngularBg": 'build/angularLogo?type=bg',
    "AngularLoad": 'build/angularLogo?type=load',
    "VueLogo": 'build/vueLogo?type=logo',
    "VueFav": 'build/vueLogo?type=fav',
    "VueBg": 'build/vueLogo?type=bg',
    "VueLoad": 'build/vueLogo?type=load',
    "loginUser": "auth/login",
    "User": "users",
    "Project": "projects",
    "randomString":"build/randomString",
    "crudCreate":"build/crudCreate",
    "getFile":"build/get_crud_file",
    "saveFile":"build/save_crud_file",
    "rename_crud_file" : "build/rename_crud_file"
}
export default apiCalls;
