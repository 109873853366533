import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuEditor from '../MenuEditor';
import SetMenu from '../SetMenu';

const Menu = forwardRef((props, ref) => {
    const activeTab = props.activeTab;
    const proj_data = props.newUIDetails;
    const propsList = (proj_data[activeTab].menuList || []);
    const [menuList, onUpdateMenuList] = useState(props.newUIDetails[props.activeTab].menuList);

    const [openMenuEdit, setOpenMenuEdit] = useState(false);
    const [lastControllerName, setLastController] = useState('');
    const [deleteControllerId, setDeletedControllerID] = useState(null);

    const propListObj = (proj_data[activeTab].menuListObj || {});
    const [menuListObj, onSetMenuListObj] = useState(propListObj);

    const [menuListData, setMenuListData] = useState(propsList);
    const [menuObjData, setMenuObjData] = useState(propListObj);

    useImperativeHandle(ref, () => ({
        async handleDeleteController(controllerId) {
            if (controllerId !== deleteControllerId) {
                return;
            } else {
                onUpdateMenuList(propsList)
                let position = await findMenuPosition(propsList, controllerId, 'key');
                position = position.split("-").filter((elem) => !isNaN(parseInt(elem))).map(elem => parseInt(elem));
                let menuList_ref = JSON.parse(JSON.stringify(propsList));
                let prop, menuListObj_ref = propListObj;
                if (position.length == 2) {
                    prop = menuList_ref[position[0]].submenus[position[1]].id;
                    if (menuList_ref[position[0]].submenus.length === 1) {
                        delete menuListObj_ref[menuList_ref[position[0]].id];
                        menuList_ref.splice(position[0], 1);
                    } else {
                        menuList_ref[position[0]].submenus.splice(position[1], 1);
                    }
                } else {
                    prop = menuList_ref[position[0]].id;
                    menuList_ref.splice(position[0], 1);
                }

                delete menuListObj_ref[prop];
                setMenuListData(menuList_ref);
                setMenuObjData(menuListObj_ref);
                props.setMenulistForUI(props.activeTab, menuList_ref, menuListObj_ref);
            }
        },

        async updateControllerName(controller) {
            let menuList_ref = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : props.newUIDetails[props.activeTab].menuList;
            let updatedMenu = await updateName(controller, menuList_ref);
            setMenuListData(updatedMenu);
            props.setMenulistForUI(props.activeTab, updatedMenu, menuListObj);
        },

        handleAddController(controllers_argmts) {
            if (controllers_argmts && controllers_argmts.length) {
                // let _menuList = JSON.parse(JSON.stringify(menuList));
                // let _menuList = JSON.parse(JSON.stringify(menuListData));
                let _menuList = props.newUIDetails[props.activeTab].menuList;
                let _menuListObj = menuListObj;
                if (_menuList[_menuList.length - 1].controllerId === controllers_argmts[0].controllerId) {
                    return;
                }
                for (let elem of controllers_argmts) {
                    let menuItem = {};
                    menuItem.name = (elem.name) ? elem.name.charAt(0).toUpperCase() + elem.name.slice(1) : `Page ${index}`;
                    menuItem.controllerId = (elem.controllerId) ? elem.controllerId : index;
                    let prop = _menuList.length + 1;
                    if (_menuListObj[prop]) {
                        menuItem.id = menuList.length + 1 + 'm';
                    } else {
                        menuItem.id = menuList.length + 1;
                    }
                    menuItem.displayTitle = menuItem.name.charAt(0).toUpperCase() + menuItem.name.slice(1);
                    menuItem.disableDelete = elem.disableDelete;
                    menuItem.route = '/' + menuItem.name
                    menuItem.iconType = 'letters';
                    menuItem.iconLetters = elem.name ? (elem.name).substring(0, 2) : '';
                    menuItem.iconName = null;
                    _menuListObj[menuItem.id] = { ...menuItem };
                    _menuList.push(menuItem);
                }
                setMenuListData(_menuList);
                setMenuObjData(_menuListObj);
                props.setMenulistForUI(props.activeTab, _menuList, _menuListObj);
            }
        }
    }));


    useEffect(() => {
        createMenu(props.controllers);
        setLastController((props.controllers[props.controllers.length - 1].name || ''));
    }, [props]);

    useEffect(() => {
        onUpdateMenuList(menuListData);
        onSetMenuListObj(menuObjData);
    }, [menuListData, menuObjData]);

    useEffect(() => {
        renderSetMenu();
    }, [propsList])


    const openMenuEditor = () => {
        setOpenMenuEdit(!openMenuEdit);
    }

    const setDetailsforMenuList = (_menuList, _menuListObj) => {
        setMenuListData(_menuList);
        setMenuObjData(_menuListObj);
    }

    const createMenu = async (sourceArray) => {
        let menuObj = {};
        let activeTab = props.activeTab;
        let _menuList = sourceArray.map((elem, index) => {
            let menuItem = {};
            menuItem.name = (elem.name) ? elem.name.charAt(0).toUpperCase() + elem.name.slice(1) : `Page ${index}`;
            menuItem.controllerId = (elem.controllerId) ? elem.controllerId : index;
            menuItem.id = index + 1;
            menuItem.displayTitle = menuItem.name.charAt(0).toUpperCase() + menuItem.name.slice(1);
            menuItem.disableDelete = elem.disableDelete;
            menuItem.route = '/' + menuItem.name;
            menuItem.iconType = 'letters';
            menuItem.iconLetters = elem.name ? (elem.name).substring(0, 2) : '';
            menuItem.iconName = null;
            menuObj[menuItem.id] = { ...menuItem };
            return menuItem;
        });
        if (activeTab == "Admin" && props.menuList && props.menuList.length === 0) {
            setMenuListData(_menuList);
            setMenuObjData({ ...menuObj });
            props.setMenulistForUI(activeTab, _menuList, { ...menuObj })
        }
    }


    const cntrollerUpdated = (controllers) => {
        let idArray = controllers.map((controller) => { return controller.controllerId });
        syncMenuWithControllers(idArray, menuList);
    }

    const syncMenuWithControllers = (idArray, menuListargmts) => {
        let menus = menuListargmts.filter((menu) => {
            if (menu.submenus && menu.submenus.length > 0) {
                menu.submenus = menu.submenus.filter(menu => {
                    return idArray.findIndex((controllerId) => { return controllerId === menu.controllerId }) > -1;
                })
                return menu;
            } else {
                return idArray.findIndex((controllerId) => { return controllerId === menu.controllerId }) > -1;
            }
        });
    }

    const updateName = async (controller, menuListargmts) => {
        for (let i = 0; i < menuListargmts.length; i++) {
            if (menuListargmts[i].submenus) {
                menuListargmts[i].submenus = await this.updateName(controller, menuListargmts[i].submenus);
            } else {
                if (menuListargmts[i].controllerId === controller.controllerId) {
                    menuListargmts[i].name = controller.name.charAt(0).toUpperCase() + controller.name.slice(1);
                    menuListargmts[i].displayTitle = controller.name.charAt(0).toUpperCase() + controller.name.slice(1);
                    menuListargmts[i].route = '/' + controller.name;
                    let menuListObj_ref = menuListObj;
                    menuListObj_ref[menuListargmts[i].id] = menuListargmts[i];
                    setMenuObjData(menuListObj_ref);
                    break;
                }
            }
        }

        return menuListargmts;
    }

    const setControllerIndex = (id, Edit) => {
        props.setControllerIndex(id);
    }

    const updateMenu = (menu, menuObj) => {
        setMenuListData(menu);
        setMenuObjData(menuObj);
        props.setMenulistForUI(props.activeTab, menu, menuObj)
    }

    const loadControllerData = (id) => {
        setControllerIndex(id);
        props.loadControllerData();
    }

    const deleteMenuItem = (controllerId) => {
        let index = props.controllers.findIndex((controller) => {
            return controllerId === controller.controllerId;
        });
        setDeletedControllerID(controllerId);
        props.deleteController(index, 'controller', props.controllers[index].name);
    }

    const findMenuPosition = (menuListargms, controllerId, key) => {
        for (let i = 0; i < menuListargms.length; i++) {
            if (menuListargms[i].controllerId && menuListargms[i].controllerId === controllerId) {
                return key = key + '-' + i;
            }
            if (menuListargms[i].submenus && menuListargms[i].submenus.length > 0) {
                return this.findMenuPosition(menuListargms[i].submenus, controllerId, key + '-' + i)
            }
        }

    }

    const renderSetMenu = () => {
        return (
            <SetMenu
                menuArray={props.menuList ? props.menuList : menuListData}
                newUIDetails={props.newUIDetails}
                activeTab={props.activeTab}
                setControllerIndex={setControllerIndex}
                loadControllerData={loadControllerData}
                deleteMenuItem={deleteMenuItem}
                setUIDT={props.setMenulistForUI} />
        )
    }

    return (
        <>
            <div style={{ borderBottom: "1px solid black", paddingLeft: "18px" }}>
                <label className='labelMargin conrollerFont' style={{ color: "white", fontWeight: "bolder" }}>Edit Menu
                    <span className='ps-2 addIcon'>
                        <FontAwesomeIcon
                            className='editIcon icon_Color'
                            color='#ff00008c'
                            icon='edit'
                            data-toggle="tool-tip"
                            title="Add"
                            onClick={(e) => openMenuEditor()}
                        />
                    </span>
                </label>
            </div>
            {(openMenuEdit) &&
                <MenuEditor
                    openMenuEdit={openMenuEdit}
                    openMenuEditor={openMenuEditor}
                    controllers={props.menuList ? props.menuList : menuList}
                    updateMenu={updateMenu}
                    menuListObj={props.menuListObj ? props.menuListObj : menuListObj}

                >
                </MenuEditor>
            }
            {renderSetMenu()}
        </>
    );
});
export default Menu;