import React, { useState } from 'react';


const ShowFiles = (props) => {
    const [selectedFile, setSelectedFile] = useState(props.selectedFile);
    const [showType, setShowType] = useState({ frontend: true, backend: true });

    return (
        <div>
            {Object.keys(props.menu).map((type) =>
                <div className='mb-3'>
                    <div key={type} className='cursor-pointer' onClick={() => setShowType({ ...showType, [type]: (showType[type] ? false : true) })}>
                        {showType[type] ? <i className='pi pi-angle-down mr-2' /> : <i className='pi pi-angle-right mr-2' />} <span className='text-capitalize'>{type}</span>
                    </div>
                    {showType[type] && props.menu && props.menu[type] && props.menu[type].length > 0 &&
                        <div className='ms-4'>
                            {props.menu[type].map((file) => (
                                <div key={file} className={selectedFile === file ? 'mt-1 p-1 cursor-pointer selected-file' : 'mt-1 p-1 cursor-pointer'}
                                    onClick={() => { setSelectedFile(file); props.onSelectFile(file, type) }}>
                                    <i className='pi pi-file mr-2' />{file} {props.codeModifyObj[file] && props.codeModifyObj[file] && <span>*</span>}
                                </div>
                            ))}
                        </div>
                    }
                </div>
            )}
        </div>
    )
}
export default ShowFiles;