import React, { useState, useEffect } from 'react';

import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import apiCall from '../../../config/apicalls';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import ProjectCard from './ProjectCard';

const CountDisplay = (props) => {
	const { dashboardData, showMore } = props;

	const noRecords = 'No Projects Found';

	const startProject = (item) => {
		let selectedProjectId = [];
		selectedProjectId.push(item._id);
		let userBody = {
			type: "start",
			projectIds: selectedProjectId
		};
		return fetchMethodRequest('POST', apiCall.serverStatus, userBody)
			.then((response) => {
				if (response && response.respCode) {
					showToasterMessage(response.respMessage, 'success');
					// setTimeout(() => {
					// 	window.location.reload();
					// }, 500);
				} else if (response && response.errorMessage) {
					showToasterMessage(response.errorMessage, 'error');
				}
			}).catch((err) => {
				return err;
			});
	}

	const stopProject = (item) => {
		let selectedProjectId = [];
		selectedProjectId.push(item._id);
		let userBody = {
			type: "stop",
			projectIds: selectedProjectId
		};
		return fetchMethodRequest('POST', apiCall.serverStatus, userBody)
			.then((response) => {
				if (response && response.respCode) {
					showToasterMessage(response.respMessage, 'success');
				} else if (response && response.errorMessage) {
					showToasterMessage(response.errorMessage, 'error');
				}
				// this.setState({ isLoading: false });				
				// setTimeout(() => {
				// 	window.location.reload();
				// }, 500);

			}).catch((err) => {
				return err;
			});
	}

	const copyProject = (item, value) => {
		let userBody = {
			projectId: item._id,
			projectName: value
		};
		return fetchMethodRequest('POST', apiCall.copyProject, userBody)
			.then((response) => {
				if (response && response.respCode) {
					showToasterMessage(response.respMessage, 'success');
					setTimeout(() => {
						window.location.reload();
					}, 500);
				} else if (response && response.errorMessage) {
					showToasterMessage(response.errorMessage, 'error');
				}
			}).catch((err) => {
				return err;
			});
	}

	return (
		<>
			<div className="transparant-card">
				{(dashboardData && dashboardData.length) ?
					<>
						<Row>
							{dashboardData.map((item, index) => {
								return (
									<>
										<Col md={12} xl={3} lg={6} xs={12} key={index} className="mb-3">
											<ProjectCard
												startProject={startProject}
												stopProject={stopProject}
												copyProject={copyProject}
												project={item}
												name={item.name}
												id={item._id}
												port={item.port}
												status={item.projectStatus}
												date={item.created}
											/>
										</Col>
									</>
								)
							})}
						</Row>
						{showMore &&
							<a className="float__right" href={"/projects"} style={{ marginRight: '25px', paddingTop: "0px", marginTop: "0px" }}>
								{'...more'}
							</a>
						}
					</>
					: <p className="float_center text-center">{noRecords}</p>
				}
			</div>
		</>
	)
}
export default CountDisplay;