import React, { useState, useEffect, useRef } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import { classNames } from 'primereact/utils';
// hook forms
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import configMessages from '../../config/configMessages';
import apiCalls from '../../config/apicalls';
import DefaultScreens from '../NoCode/NewProject/DefaultCrudTemplates.json';

import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';



const loginApiModal = (props) => {

    const defaultValues = {
        screenName: '',
        actions: '',
        fetchFields: '',
        isSendCustomEmail: false,
        displayActionsInTable: false,
        emailDescription: false,
        description: '',
        displayViewOfForm: 'screen',
        dateSearchRequired: false,
        searchInDateRangeField: '',
        // uiFilteringAndSorting:false, //keeping for later ui filter and sort
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register, trigger, setError, setValue
    } = useForm({ defaultValues });

    const actionsType = [
        { value: "add", label: "Add" },
        { value: "edit", label: "Edit" },
        { value: "delete", label: "Delete" },
        { value: "view", label: "View" },
        { value: "exportToCsv", label: "Export To Csv" },
        { value: "BulkUpload", label: "Bulk Upload" }
    ];
    const [def_actions, setActions] = useState(['add', 'edit', 'delete', 'view', 'exportToCsv', 'BulkUpload']);

    const [def_fetchFields, onSetFetchFields] = useState([]);


    const [searchInDateRangeFields, setDateRangeFields] = useState([]);
    const [fetchFieldsType, setFetchFieldsType] = useState([]);
    const [existingScreensList, setExistingScreens] = useState([]);

    const [createAScreenFrom, setCreateScreenFrom] = useState('');
    const [createNewScreenWithOutServer, onSetScreenWithNoServer] = useState(false);


    const [isCreateSelected, onSetCreateSelected] = useState(true);
    const [isAuth, onSetAuth] = useState(false);

    const [selectedDuplicateScreen, setDuplicateScreens] = useState(null);
    const [FEDSerrorDisplay, setFedDisplayError] = useState(false);
    const [DESerrorDisplay, setDesDisplayError] = useState(false);
    const [ActionserrorDisplay, setActionError] = useState(false);
    const [fetchFieldErrorDisplay, setFetchFieldsError] = useState(false);
    const [defaultScreenValues, setDefScreenVals] = useState([]);
    const [selectedDuplicateScreenName, setDuplicateScreenName] = useState([]);

    const [defaultFilterRequiredState, setDefaultFilterRequiredState] = useState(false);
    const [fieldsForDefaultFilter, setFieldsForDefaultFilter] = useState([]);
    const [displayLeftRight, setDisplayLeftRight] = useState(false);
    const [screenErrFlag, setScreenErrFlag] = useState(false);

    const [isScreenNameChanged, setScreenNameChanged] = useState(false);
    const [defaultScreens] = useState(DefaultScreens.controllers);
    const [duplicateScreenErrorMsg, setDupErrorMsg] = useState(false);
    const [emailDes, onCheckEmailDes] = useState(false);
    const [dateSearch, onCheckDateSearch] = useState(false);
    // const [uiFilterAndSort, onUiFilterAndSort] = useState(false); //keeping for later ui filter and sort
    const [duplicateScreenErrorMessage] = useState(false);
    const [showScreenNameErrorMessageFound, setScreenError] = useState(false);
    let def_errorMsg = 'Screen Name Already Exists.';
    const [screenNameErrorMessageFound, setErrorMsg] = useState(def_errorMsg);
    const renameCrudBody = {
        "screenName": "",
        "s_g_project_Id": "",
        "newScreenName": "",
        "service": "",
        "sourceType": ["frontend", "backend"],
        "selectedBackEnd": "Node",
        "selectedFrontEnd": "React"
    }
    const [isControllers, setIsControllers] = useState(null);
    const [parentID, setParentID] = useState(null);
    const [existingName, setExistingName] = useState(null);
    const [isSameServer, setIsSameServer] = useState(false);


    useEffect(() => {
        let searchInDateRangeFields = [];
        if (props && props.dateSearchOptions && props.dateSearchOptions.length > 0) {
            for (let obj of props.dateSearchOptions) {
                obj.serverFieldType == "Date" ? searchInDateRangeFields.push({ label: obj.field, value: obj.field }) : null
            }
        }
        setDateRangeFields(searchInDateRangeFields);

        let fetchFieldsType = [];
        let defFetchFields = [];
        if (props && props.fetchFields && props.fetchFields.length > 0) {
            for (let obj of props.fetchFields) {
                if (obj.fieldType === "relateAutoComplete" || obj.fieldType === "autoComplete" || obj.fieldType === "Text") {
                    fetchFieldsType.push({ label: obj.label, value: obj.field });
                    defFetchFields.push(obj.field);

                }
            }
        }
        setFetchFieldsType(fetchFieldsType);
        onSetFetchFields(defFetchFields);


        let existingScreensList = [];
        if (props && props.controllersList && props.controllersList.length > 0) {
            for (let obj of props.controllersList) {
                props.doNotListInDuplicateScreenDropDown.includes(obj.controllerId) ? null : obj.name ? existingScreensList.push({ label: obj.name, value: obj.controllerId }) : null;
            }
        }
        setExistingScreens(existingScreensList);

    }, [props]);


    useEffect(() => {
        if (props && props.editOpenlogInUrlModal) {
            let controllers = props.controllers;
            setIsControllers(props.controllers[props.index].controllerId)
            setParentID(props.controllers[props.index].ParentcontrollerId)
            setExistingName(props.controllers[props.index].value)
            setIsSameServer(props.controllers[props.index].createNewScreenWithOutServer)
            let getControllerValue = controllers[props.index];


            //  Appending values for edit form
            setCreateScreenFrom(getControllerValue.createAScreenFrom || "createNewScreen");
            onSetScreenWithNoServer(getControllerValue.createNewScreenWithOutServer);
            // screen name
            setValue("screenName", getControllerValue.name);
            // actions
            setValue("actions", getControllerValue.actions);
            // fetch fields
            setValue("fetchFields", getControllerValue.fetchFields);
            // custom mail
            setValue("isSendCustomEmail", getControllerValue.isSendCustomEmail);
            // display action in table
            setValue("displayActionsInTable", getControllerValue.displayActionsInTable);
            // email description
            setValue("emailDescription", getControllerValue.emailDescription);
            // description text area
            setValue("description", getControllerValue.description);
            // for filtering and sorting in the UI
            // setValue("uiFilteringAndSorting", getControllerValue.uiFilteringAndSorting) //keeping for later ui filter and sort
            setValue("displayViewOfForm", getControllerValue.displayViewOfForm);
            setValue("dateSearchRequired", getControllerValue.dateSearchRequired);
            setValue("searchInDateRangeField", getControllerValue.searchInDateRangeField);
            setValue("defaultFilterRequired", getControllerValue.defaultFilterRequired);

            if (getControllerValue.displayViewOfForm == "sideForm") {
                setDisplayLeftRight(true);
                setValue("sideFormLeftOrRight", getControllerValue.sideFormLeftOrRight);
            }
            if (getControllerValue.defaultFilterRequired && getControllerValue.defaultFilterRequired == true) {
                console.log("hereee")
                setDefaultFilterRequiredState(true);
                getCurrentControllerDropdownFields(getControllerValue);
            }

            onCheckEmailDes(getControllerValue.emailDescription);
            onSetCreateSelected(getControllerValue.actions.includes('add'));
            onSetAuth(getControllerValue.isAuth ? true : false);
            isAuth ? controllers[props.index]["loginUrl"] = true : null;


        }
    }, [props.index])


    const handleCheck = (val) => {
        setFedDisplayError(false);
        setDesDisplayError(false);
        if (val === 'fromExistingDefaultScreens') {
            setDuplicateScreens(null);
        } else if (val === 'duplicateExistingScreen') {
            setDefScreenVals([]);
        } else {
            setValue('actions', def_actions);
            setValue('fetchFields', def_fetchFields);
        }
    }

    const marginal_width = {
        "margin": "auto",
        "width": "60%",
        "padding": "1rem 0 0"
    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (values, event) => {
        console.log(values, event)
        event.preventDefault();
        event.stopPropagation();
        let error = false;
        let count = 0;
        values.createAScreenFrom = createAScreenFrom;

        if (values.defaultFilterRequired == true) {
            let cri = [];
            for (let i = 0; i < fieldsForDefaultFilter.length; i++) {
                if (fieldsForDefaultFilter[i].checked) {
                    cri.push({
                        key: fieldsForDefaultFilter[i].name,
                        value: fieldsForDefaultFilter[i].value,
                        type: "in"
                    })
                }
            }
            values.defaultCriteria = cri;
            values.criDetails = fieldsForDefaultFilter;
        }
        else {
            values.defaultFilterRequired = false;
            values.defaultCriteria = undefined
        }

        if (createAScreenFrom === "fromExistingDefaultScreens") {
            if (defaultScreenValues.length > 0) {
                // let selectedScreenArray = defaultScreens.filter((ele)=> defaultScreenValues.indexOf(ele.name) >= 0);
                props.defaultScreen(defaultScreenValues, props.index);
                props.closeInputModal(props.index, "deleteController", "empty");
                setDefScreenVals([]);
                reset();
                setFedDisplayError(false);
            } else {
                setFedDisplayError(true);
            }
        } else if (createAScreenFrom === "duplicateExistingScreen") {
            if (selectedDuplicateScreen && selectedDuplicateScreenName) {
                //KEEP
                // values.selectedDuplicateScreenName = selectedDuplicateScreenName;
                props.HandleDuplicateScreens(selectedDuplicateScreen, values.selectedDuplicateScreenName, props.index, createNewScreenWithOutServer);
                props.closeInputModal();
                reset();
            } else if (isControllers && selectedDuplicateScreenName) { // when changing any values for the existing screen
                values.actionsIndex = props.index;
                values.name = values.screenName ? values.screenName : "";
                values.ParentcontrollerId = parentID;
                values.value = existingName;
                values.createNewScreenWithOutServer = createNewScreenWithOutServer;
                props.loginApiValue(values);
                props.closeInputModal();
                reset();
            } else {
                if (!selectedDuplicateScreen) {
                    setDesDisplayError(true);
                } else {
                    setDesDisplayError(false);
                }

            }
        } else if (createAScreenFrom === "createNewScreen") {
            values.name = values.screenName ? values.screenName : "";
            let renamePayload = renameCrudBody;
            renamePayload["screenName"] = values.screenName;
            renamePayload["s_g_project_Id"] = props.s_g_project_Id;
            renamePayload["newScreenName"] = values.name;
            renamePayload["service"] = props.activeTab;


            values.actionsIndex = props.index;
            values.name = values.screenName ? values.screenName.trim() : "";
            let errorRequired = false;
            if (values.actions && values.actions <= 0) {
                errorRequired = true;
                setActionError(true);
            } else {
                errorRequired = false;
                setActionError(false);
            }
            if (values.fetchFields && values.fetchFields <= 0) {
                errorRequired = true;
                setFetchFieldsError(true);
            } else {
                errorRequired = false;
                setFetchFieldsError(false);
            }
            if (!errorRequired) {
                props.editOpenlogInUrlModal && isScreenNameChanged ? props.projectInfo("rename", 'POST', apiCalls.rename_crud_file, renamePayload) : "";
                props.loginApiValue(values);
                props.closeInputModal();
                reset();
            }
            setScreenError(false);
            if (error) {
                setScreenError(true);
            } else {
                if (values.loginUrl) {
                    values.index = props.index;
                }
                values.actionsIndex = props.index;
                values.name = values.screenName ? values.screenName.trim() : "";
                let errorRequired = false
                if (values.actions && values.actions <= 0) {
                    errorRequired = true;
                    setActionError(true);
                } else {
                    errorRequired = false;
                    setActionError(false);
                }
                if (!errorRequired) {
                    props.loginApiValue(values);
                    props.closeInputModal();
                    reset();
                }
                setScreenError(false);
            }
        }
    }


    const onSelectAction = (event) => {
        let check = event.value.includes("add");
        onSetCreateSelected(check);
    }

    const renameTheCrudFile = async (value) => {
        if (value) {
            if (value === "screenName" && props.controllers[props.index].name &&
                value != props.controllers[props.index].name) {
                setScreenNameChanged(true);
            }
        }
    }

    //KEEP
    // const changeselectedDuplicateScreens = async (val) => {
    //         if(existingScreensList.length) {
    //         for (let obj of existingScreensList) {
    //             if (obj.value === parseInt(val)) {
    //                 setDuplicateScreenName(obj.label + "Copy");
    //             }
    //         }
    //     }
    // }

    const changeSideForm = async (e) => {
        if (e == "sideForm") {
            setDisplayLeftRight(true);

        }
        if (e == "modal" || e == "screen") {
            setDisplayLeftRight(false);

        }
    }

    const getCurrentControllerDropdownFields = (cont) => {

        let cur = props.controllers[props.index];
        let fields = cur.controllerFields;
        const tempFilterFIelds = fields.filter(field => field.type === 'dropDown');
        const filteredFields = tempFilterFIelds.map((field) => {
            return { options: field.options, checked: false, value: [], name: field.name, label: field.label }
        })
        setFieldsForDefaultFilter(filteredFields);

        if (props.controllers[props.index].defaultCriteria && props.controllers[props.index].defaultCriteria.length > 0) {
            const defaultCriteria = props.controllers[props.index].defaultCriteria;
            for (let i = 0; i < filteredFields.length; i++) {
                for (let j = 0; j < defaultCriteria.length; j++) {
                    if (filteredFields[i].name == defaultCriteria[j].key) {
                        filteredFields[i].checked = true;
                        filteredFields[i].value = defaultCriteria[j].value;
                        setValue(filteredFields[i].name, true)
                        setValue(`${filteredFields[i].name}Options`, defaultCriteria[j].value)
                    }
                }
            }
        }
    }

    const handleDefaultFilterChanges = (index, type, evnt) => {
        let tempDefaultFilters = fieldsForDefaultFilter;
        if (type == "checkbox" && evnt && evnt.checked) {
            tempDefaultFilters[index].checked = evnt.checked;
            if (tempDefaultFilters[index].checked == false) {
                tempDefaultFilters[index].value = []
            }
        }
        if (type == 'multiselect' && evnt) {
            tempDefaultFilters[index].value = evnt.value;
        }
        setFieldsForDefaultFilter(tempDefaultFilters);
    }

    const changeDefaultFilterRequired = (e) => {
        setDefaultFilterRequiredState(e);
        getCurrentControllerDropdownFields();
    }

    const validateScreenName = (value) => {
        if (value.length < 3) {
            return 'Screen Name must be at least 3 characters long.';
        }

        if (value) {
            let err = false;
            let count = 0;
            let text = ['class', 'abstract', 'arguments', 'package', 'private', 'public', 'transient', 'volatile', 'protected', 'new', "test", "Test"]
            let reservedWord = text.includes(value);
            if (/test/i.test(value)) {
                return "Unable to create the screen with the test"
            }
            if (reservedWord == true) {
                return `${value} is a reserved word, Enter a different screen name`;
            }
            for (let cont of props.checkcontrollers.slice(0, -1)) {
                if (cont.name && cont.name.toLowerCase() == value.toLowerCase() && count != props.index && screenErrFlag == true) {
                    // if(cont.name && cont.name.toLowerCase() == value.toLowerCase() && count != props.index){

                    err = true;
                }
                if (cont.name && cont.name.toLowerCase() == value.toLowerCase() && count != props.index && createAScreenFrom == 'duplicateExistingScreen' && props.editOpenlogInUrlModal == false) {
                    err = true;
                }

                count++
            }
            if (err == true) {
                return 'Screen name already exists'
            }
        }

        // Return true if validation passes
        return true;

    };
    //KEEP
    // useEffect(()=> {
    //     if(selectedDuplicateScreen) {
    //         changeselectedDuplicateScreens(selectedDuplicateScreen);
    //     }
    // },[selectedDuplicateScreen])

    const dialogHeader = () => {
        return (
            <>
                <span className='h5 bold-text text-white'>{props.editOpenlogInUrlModal ? 'Edit Screen' : 'Add Screen'}</span>
                <span className='float-right'>
                    < FontAwesomeIcon
                        className='editIcon text-white modal__close-btn'
                        color='#ff00008c'
                        icon='times-circle'
                        data-toggle="tool-tip"
                        title="Close"
                        onClick={() => props.closeInputModal(props.index, "loginApimodal", props.editOpenlogInUrlModal)}
                    />
                </span>
            </>
        )
    }

    return (
        <>
            <Dialog className='screen'
                visible={props.isActionModal}
                draggable={false}
                style={{ width: "50%" }}
                header={dialogHeader}
                closeOnEscape={false}
                focusOnShow={false}
                blockScroll={false}
            >
                <ModalBody>
                    <Card className='m-0 p-0' style={{ border: "none" }}>
                        <CardBody className='m-0 p-0'>
                            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                                {(!props.editOpenlogInUrlModal) &&
                                    <>
                                        <div className='form-group pt-3'>
                                            <div className="d-flex justify-content-around">
                                                <div className="d-flex align-items-center">
                                                    <RadioButton inputId="f5" name="screenFrom" value="fromExistingDefaultScreens"
                                                        onChange={(e) => { setCreateScreenFrom(e.value), handleCheck(e.value) }} checked={createAScreenFrom === 'fromExistingDefaultScreens'} />
                                                    <label htmlFor="f5" className="ms-2 me-3">Select Default Screens</label>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <RadioButton inputId="f6" name="screenFrom" value="createNewScreen"
                                                        onChange={(e) => { setCreateScreenFrom(e.value), handleCheck(e.value) }} checked={createAScreenFrom === 'createNewScreen'} />
                                                    <label htmlFor="f6" className="ms-2 me-3">Create New Screen</label>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <RadioButton inputId="f7" name="screenFrom" value="duplicateExistingScreen"
                                                        disabled={!existingScreensList.length}
                                                        onChange={(e) => { setCreateScreenFrom(e.value), handleCheck(e.value) }}
                                                        checked={createAScreenFrom === 'duplicateExistingScreen'}
                                                    />
                                                    <label htmlFor="f7" className="ms-2 me-3">Duplicate Existing Screen</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }


                                {(!props.editOpenlogInUrlModal && createAScreenFrom === 'fromExistingDefaultScreens') &&
                                    <>
                                        <div className='marginal_width' style={marginal_width}>
                                            <div className='form-group'>
                                                <label className="form-label">Select Screens</label>
                                                <MultiSelect value={defaultScreenValues}
                                                    onChange={(e) => setDefScreenVals(e.value)}
                                                    options={defaultScreens}
                                                    optionLabel="name"
                                                    placeholder="Select The Screens"
                                                    filter
                                                    className="w-full md:w-20rem"
                                                />
                                            </div>
                                            {FEDSerrorDisplay ? <span className="form__form-group-error">{"Please select the option"}</span> : null}
                                        </div>
                                    </>
                                }

                                {(!props.editOpenlogInUrlModal && createAScreenFrom === 'duplicateExistingScreen') &&
                                    <>
                                        <div className='marginal_width' style={marginal_width}>
                                            <div className='form-group mb-2'>
                                                <label className="form-label">Select Screens</label>
                                                <Dropdown
                                                    name="selectedDuplicateScreen"
                                                    value={selectedDuplicateScreen}
                                                    onChange={(e) => { setDuplicateScreens(e.value); }}
                                                    options={existingScreensList}
                                                    optionLabel="label"
                                                    placeholder="Select The Screens"
                                                    className="w-full md:w-20rem"
                                                />
                                                {DESerrorDisplay && <span className="form__form-group-error">{"Please select the option"}</span>}
                                            </div>
                                            {selectedDuplicateScreen &&
                                                <>
                                                    <div className='form-group mb-2'>
                                                        <label className="form-label">{"Screen Name"}</label>

                                                        <Controller
                                                            name="selectedDuplicateScreenName"
                                                            control={control}
                                                            rules={{ required: 'Screen Name is required.', validate: validateScreenName }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id={field.name}
                                                                        value={field.value}
                                                                        placeholder='Name'
                                                                        className={classNames({ 'p-invalid': fieldState.error }, 'w-full')}
                                                                        onChange={(e) => {
                                                                            field.onChange(e.target.value);
                                                                        }}
                                                                    />
                                                                    {errors.selectedDuplicateScreenName && <span className="form__form-group-error mt-2">{errors.selectedDuplicateScreenName.message}</span>}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='form-group mb-2'>
                                                        <div className="d-flex align-items-center">
                                                            <Checkbox inputId="cnsapi" name="createNewScreenWithOutServer"
                                                                value={true} onChange={e => { onSetScreenWithNoServer(e.checked) }}
                                                                checked={createNewScreenWithOutServer === true} />
                                                            <label htmlFor="cnsapi" className="ms-2 me-3">Create New Screen With Existing Api</label>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                }

                                {(createAScreenFrom === 'createNewScreen' || props.editOpenlogInUrlModal) &&
                                    <>
                                        <div className='marginal_width' style={marginal_width}>
                                            <div className='form-group'>
                                                <label className='form-label'>Screen Name</label>
                                                <Controller
                                                    name="screenName"
                                                    control={control}
                                                    rules={{ required: 'Screen Name is required.', validate: validateScreenName }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id={field.name}
                                                                value={field.value}
                                                                placeholder='Name'
                                                                className={classNames({ 'p-invalid': fieldState.error }, 'w-full')}
                                                                onChange={(e) => {
                                                                    field.onChange(e.target.value); setScreenErrFlag(true);
                                                                    (props.editOpenlogInUrlModal && props.isBackendGenerated && props.isFrontEndGenerated ? renameTheCrudFile(e.target.value) : '');
                                                                    props.changeContrllerName(e, props.index);
                                                                }}
                                                                disabled={((props.editOpenlogInUrlModal && props.controllers[props.index]["disableEdit"]) || selectedDuplicateScreen || defaultScreenValues.length != 0) ? true : false}
                                                            />
                                                            {errors.screenName && <span className="form__form-group-error mt-2">{errors.screenName.message}</span>}
                                                        </>
                                                    )}
                                                />

                                            </div>
                                            <div className='form-group mb-2'>
                                                <label className='form-label'>Actions</label>
                                                <Controller
                                                    name="actions"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <MultiSelect id={field.name} name="Actions"
                                                            value={field.value}
                                                            options={actionsType}
                                                            onChange={(e) => { field.onChange(e.value), onSelectAction(e) }}
                                                            optionLabel="label" placeholder="Select Actions"
                                                            disabled={defaultScreenValues.length != 0 ? true : false} />
                                                    }
                                                />
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label className='form-label'>Select Fetch Fields</label>
                                                <Controller
                                                    name="fetchFields"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <MultiSelect id={field.name} name="fetchFields"
                                                            value={field.value}
                                                            options={fetchFieldsType}
                                                            onChange={(e) => field.onChange(e.value)}
                                                            optionLabel="label" placeholder="Select Fetch Fields"
                                                            disabled={defaultScreenValues.length != 0 ? true : false} />
                                                    }
                                                />
                                            </div>
                                            {(props && props.openlogInUrlModal && (!props.Api || isAuth)) &&
                                                <>
                                                    <div className='form-group mb-2'>
                                                        <Controller
                                                            name="loginUrl"
                                                            control={control}
                                                            render={({ field }) => (
                                                                <div className="d-flex align-items-center">
                                                                    <Checkbox
                                                                        inputId='login_api' {...field}
                                                                        inputRef={field.ref}
                                                                        value={true}
                                                                        checked={field.value === true}
                                                                        onChange={(e) => field.onChange(e.checked)}
                                                                        disabled
                                                                    />
                                                                    <label htmlFor="login_api" className="ms-2 me-3">LoginApi</label>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            <div className='form-group mb-2'>
                                                <Controller
                                                    name="isSendCustomEmail"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <div className="d-flex align-items-center">
                                                            <Checkbox inputId="scemail" {...field} inputRef={field.ref} value={true} checked={field.value === true}
                                                                onChange={(e) => field.onChange(e.checked)} />
                                                            <label htmlFor="scemail" className="ms-2 me-3">Custom email option in actions</label>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            {/* <div className='form-group mb-2'>
                                                <Controller
                                                    name="displayActionsInTable"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <div className="d-flex align-items-center">
                                                            <Checkbox inputId="disActTab" {...field} inputRef={field.ref} value={true} checked={field.value === true}
                                                                onChange={(e) => field.onChange(e.checked)} />
                                                            <label htmlFor="disActTab" className="ms-2 me-3">Display Actions In Table</label>
                                                        </div>
                                                    }
                                                />
                                            </div> */}
                                            {isCreateSelected &&

                                                <div className='form-group mb-2'>
                                                    <Controller
                                                        name="emailDescription"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <div className="d-flex align-items-center">
                                                                <Checkbox inputId="emailDes" {...field} inputRef={field.ref} value={true} checked={field.value === true}
                                                                    onChange={(e) => { field.onChange(e.checked), onCheckEmailDes(e.checked) }} />
                                                                <label htmlFor="emailDes" className="ms-2 me-3">Email Description</label>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            }

                                            {(isCreateSelected && emailDes) &&
                                                <>
                                                    <div className='form-group mb-2'>
                                                        <label htmlFor="emailDesText">Description</label>
                                                        <Controller
                                                            name="description"
                                                            control={control}
                                                            rules={{ required: 'Description is required.' }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputTextarea id="emailDesText" {...field} rows={4} cols={30}
                                                                        className={classNames('w-full')} />
                                                                    {getFormErrorMessage(field.name)}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }

                                            <div className='form-group mb-2'>
                                                <label className='form-label'>Display Actions In Table</label>
                                                <Controller
                                                    name="displayActionsInTable"
                                                    control={control}
                                                    render={({ field }) =>
                                                        <div className="d-flex align-items-center">
                                                            <RadioButton inputId="actionf5" {...field} inputRef={field.ref}
                                                                value="actions" checked={field.value === 'actions'} />
                                                            <label htmlFor="actionf5" className="ms-2 me-3">Action Icons</label>
                                                            <RadioButton inputId="inlinef5" {...field} inputRef={field.ref}
                                                                value="inline" checked={field.value === 'inline'} />
                                                            <label htmlFor="inlinef5" className="ms-2 me-3">Inline Editing</label>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div className='form-group mb-2'>
                                                <label className='form-label'>Display Fields in Modal/Screen</label>
                                                <Controller
                                                    name="displayViewOfForm"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <div className="d-flex align-items-center">
                                                                <RadioButton inputId="modalf5" {...field} inputRef={field.ref}
                                                                    value="modal" checked={field.value === 'modal'} onClick={() => changeSideForm('modal')} />
                                                                <label htmlFor="modalf5" className="ms-2 me-3">Modal</label>
                                                                <RadioButton inputId="screenf5" {...field} inputRef={field.ref}
                                                                    value="screen" checked={field.value === 'screen'} onClick={() => changeSideForm('screen')} />
                                                                <label htmlFor="screenf5" className="ms-2 me-3">Screen</label>

                                                                <RadioButton inputId="sideformf5" {...field} inputRef={field.ref}
                                                                    value="sideForm" checked={field.value === 'sideForm'} onClick={() => changeSideForm('sideForm')} />
                                                                <label htmlFor="screenf5" className="ms-2 me-3">Side Form</label>

                                                            </div>
                                                        </>
                                                    )}
                                                />
                                            </div>
                                            {displayLeftRight && displayLeftRight == true &&
                                                <div className='form-group mb-2'>
                                                    <label className='form-label'>Display Form</label>
                                                    <Controller
                                                        name="sideFormLeftOrRight"
                                                        defaultValue="right"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <>
                                                                <div className="d-flex align-items-center">
                                                                    <RadioButton inputId="leftf5" {...field} inputRef={field.ref}
                                                                        value="left" checked={field.value === 'left'} />
                                                                    <label htmlFor="modalf5" className="ms-2 me-3">Left</label>

                                                                    <RadioButton inputId="rightf5" {...field} inputRef={field.ref}
                                                                        value="right" checked={field.value === 'right'} />
                                                                    <label htmlFor="screenf5" className="ms-2 me-3">Right</label>

                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                </div>
                                            }
                                            <div className='form-group mb-2'>
                                                <Controller
                                                    name="dateSearchRequired"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <div className="d-flex align-items-center">
                                                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref}
                                                                    onChange={(e) => { field.onChange(e.checked), onCheckDateSearch(e.checked) }} />
                                                                <label htmlFor={field.name} className="ms-2 me-3">Date search required</label>
                                                            </div>

                                                        </>
                                                    )}
                                                />
                                            </div>

                                            {dateSearch &&
                                                <>
                                                    <div className='form-group mb-2'>
                                                        <Controller
                                                            name="searchInDateRangeField"
                                                            control={control}
                                                            render={({ field, fieldState }) => (
                                                                <Dropdown
                                                                    id={field.name}
                                                                    value={field.value}
                                                                    optionLabel="label"
                                                                    placeholder="Select the Search field"
                                                                    options={searchInDateRangeFields}
                                                                    focusInputRef={field.ref}
                                                                    onChange={(e) => field.onChange(e.value)}
                                                                    className={classNames({ 'p-invalid': fieldState.error }, 'w-full')}
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            {/* <div className='form-group mb-2'> //keeping for later ui filter and sort
                                                <Controller
                                                    name="uiFilteringAndSorting"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <div className="d-flex align-items-center">
                                                                <Checkbox inputId={field.name} checked={field.value} inputRef={field.ref}
                                                                    onChange={(e) => { field.onChange(e.checked), onUiFilterAndSort(e.checked) }} />
                                                                <label htmlFor={field.name} className="ms-2 me-3">UI Filter and Sort</label>
                                                            </div>

                                                        </>
                                                    )}
                                                />
                                            </div> */}

                                            {props.editOpenlogInUrlModal == true &&
                                                <div className='form-group mb-2'>
                                                    <Controller
                                                        name="defaultFilterRequired"
                                                        control={control}
                                                        render={({ field }) =>
                                                            <div className="d-flex align-items-center">
                                                                <Checkbox inputId="disActTab" {...field} inputRef={field.ref} value={true} checked={field.value === true}
                                                                    onChange={(e) => { field.onChange(e.checked); changeDefaultFilterRequired(e.checked); }}
                                                                />
                                                                <label htmlFor="disActTab" className="ms-2 me-3">Default Filter required</label>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                            }

                                            {defaultFilterRequiredState == true && props.editOpenlogInUrlModal == true &&
                                                fieldsForDefaultFilter.map((item, i) => {
                                                    return (
                                                        <>
                                                            <div className='form-group mb-2'>
                                                                <Controller
                                                                    name={item.name}
                                                                    control={control}
                                                                    render={({ field }) =>
                                                                        <div className="d-flex align-items-center">
                                                                            <Checkbox inputId={field.name} {...field} inputRef={field.ref} value={true} checked={field.value === true}
                                                                                onChange={(e) => { field.onChange(e.checked); handleDefaultFilterChanges(i, "checkbox", e) }}
                                                                            />
                                                                            <label htmlFor="disActTab" className="ms-2 me-3">{item.label}</label>
                                                                        </div>
                                                                    }
                                                                />
                                                            </div>

                                                            <div className='form-group mb-2'>
                                                                <Controller
                                                                    name={`${item.name}Options`}
                                                                    control={control}
                                                                    render={({ field }) =>
                                                                        <MultiSelect id={field.name}
                                                                            value={field.value}
                                                                            options={item.options}
                                                                            onChange={(e) => { field.onChange(e.value); handleDefaultFilterChanges(i, "multiselect", e) }}
                                                                            optionLabel="label"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                }


                                <div className='col-sm-12 mt-3'>
                                    <ButtonToolbar className="customBtns justify-content-center ms-auto" >
                                        <Button type="button" className='cancelLoginBtnStyle' outline
                                            onClick={() => props.closeInputModal(props.index, "loginApimodal", props.editOpenlogInUrlModal)}>Cancel</Button>
                                        <Button
                                            style={{ backgroundColor: "#4ce1b6" }}
                                            outline
                                            type="submit">{'Save'}</Button>

                                    </ButtonToolbar>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Dialog>
        </>
    )
}

export default loginApiModal;