import React, { useState,useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link,Navigate } from 'react-router-dom';
import { Redirect } from 'react-router';

// Config
import configMessage from '../../../config/configMessages';
import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apicalls';
import config from '../../../config/config';
import socket from '../../../config/Socket';

// Toaster message  && // Loader
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import Loader from '../../App/Loader';

// Input fields
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
// import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';

import { Button } from 'reactstrap';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
});

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)

const LogIn = () => {
  // const { register, handleSubmit, formState: { errors }, reset } = useForm({
  //   resolver: yupResolver(schema),
  // });

  useEffect( () => {
    getSettingsData();
    resetLocalStorage();
    getLoginRememberInfo();
  },[])

  const defaultValues = {
    email: '',
    password: ''
  };

  const {
  register,
  control,
  formState: { errors },
    handleSubmit,
    getValues,
    reset
} = useForm({ defaultValues });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const [remember_me, setRemember_me] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Nice to have - future consideration

  const [loginRememberInfo, setLoginRememberInfo] = useState(null);
  const [settingsData, setSettingsData] = useState(null);

  const getFormErrorMessage = (name) => {
    return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const resetLocalStorage =()=> {
    localStorage.removeItem('loginCredentials');
    localStorage.removeItem('loginBody');
    localStorage.removeItem('sessionexpired');
  }

  // remember me functionlaity
  const getLoginRememberInfo = async () => {
    let keysToRemove = ["loginCredentials", "rolePermissions", 'loginBody', 'sessionexpired'];
    for (let key of keysToRemove) {
      localStorage.removeItem(key);
    }
    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? JSON.parse(loginRememberInfo) : null;
    reset(loginRememberInfo);
    setLoginRememberInfo(loginRememberInfo);
    setRemember_me(loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false);
  }

  // onsubmit
  const onSubmitHandler = (formValue) => {
    if (formValue.email && formValue.password) {
      setRemember_me(formValue.remember_me);
      setIsLoginDisabled(true);
      setIsLoading(true);
      handleLoginUser(formValue);
    }
  };

  
  const handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: config.entityType
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));
      return fetchRequest('POST', apiCalls.loginUser, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            setIsLoading(true);
            let userDetails = response.details;
            if (userDetails && userDetails._id) {
              if (response.accessToken) {
                let tokenInfo = {
                  accessToken: response.accessToken,
                  refreshToken: response.refreshToken,
                  tokenExpires: response.tokenExpires,
                };
                userDetails = { ...userDetails, ...tokenInfo };
                // save user credentials in storage
                localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                if(localStorage.getItem('loginCredentials')) {
                  let loginCredentials = JSON.parse(localStorage.getItem('loginCredentials'));
                  socket.emit('userConnected', {
                    userId: loginCredentials._id,
                  });
                }
                localStorage.removeItem('sessionexpired');
              }
              if (userDetails && userDetails.rolePermissions) {
                let permissions = userDetails.rolePermissions;
                // save user permissions in storage
                localStorage.setItem('rolePermissions', JSON.stringify(permissions));
              }
            }
            showToasterMessage(response.respMessage, 'success');
              setIsLoginSuccess(true);
              if (remember_me) {
                var data = {
                  email: values.email,
                  password: values.password,
                  remember_me: remember_me
                }
                if (loginRememberInfo && loginRememberInfo.email &&
                  loginRememberInfo.email != values.email) {
                  localStorage.setItem('loginRememberInfo', JSON.stringify(data));
                } else if (!loginRememberInfo) {
                  localStorage.setItem('loginRememberInfo', JSON.stringify(data));
                }
              } else {
                localStorage.removeItem('loginRememberInfo');
              };
          } else if (response && response.errorMessage) {
            setTimeout(() => {
              setIsLoginDisabled(false);
              setIsLoading(false);
            }, 250);
            showToasterMessage(response.errorMessage, 'error');
          }
          // settingsAction
        }).catch((err) => {
          setIsLoginDisabled(false);
          setIsLoading(false);
        });
  }

  //  Checkbox Changes
  const onChange = (value) => {
    if (value == 'remember') {
      setRemember_me(!remember_me);
    }
  }

  const getSettingsData = () => {
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settings = response.settings;
        if(settings && settings.length) {
          let settingsData = settings[0];
          if(settingsData && settingsData["Admin"]) {
            settingsData["GoogleClientId"] = settingsData["Admin"].GoogleClientId || null;
            settingsData["GooglesecretKey"] = settingsData["Admin"].GooglesecretKey || null;
          }
          setSettingsData(settingsData);
        }
      }
    });
  }

  return(
    <div className='account_login'>    
    <div className='login-container pt-5'>      
      <div className="card shadow bg-white login-card p-3">
        <div className="card-body">
          <div className='justifyContentLogin'>
            <div className='vertical_middle'>
              <div className='text-center '>
                <h3> <b>Log Into Nocode</b> </h3>
              </div>
              <div className='pt-5'>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Loader loader={isLoading} />
                    <Controller
                      name="email"
                      control={control}
                      rules={{ required: 'Email is required.' }}
                      render={({ field, fieldState }) => (
                        <>
                          <div className='d-flex flex-column gap-2'>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.value })}>{'Email'}</label>
                            <InputText id={field.name} value={field.value}
                            className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`}  onChange={(e) => field.onChange(e.target.value)} />
                          </div>  
                          {getFormErrorMessage(field.name)}  
                        </>
                      )}
                    />

                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: 'Password is required.' }}
                      render={({field, fieldState}) => (
                        <>
                          <div className='d-flex flex-column gap-2'>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.value })}> Password </label>
                            <Password id={field.name} {...field} inputRef={field.ref}  
                            className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`}
                            toggleMask
                            feedback={false} />
                          </div>
                          {getFormErrorMessage(field.name)}
                        </>
                      )}
                    />

                    <Controller
                      name="remember_me"
                      control={control}                    
                      render={({ field, fieldState }) => (
                        <>     
                          <div className='d-flex align-items-center mb-3'>
                            <Checkbox inputId={field.name} checked={remember_me} inputRef={field.ref} onChange={(e) => { field.onChange(e.target.checked); onChange('remember')}} />
                            <label htmlFor={field.name} className='ms-1'>Remember Me</label>
                          </div>                   
                        </>
                      )}
                    />

<div className="account__btns pb-2" style={{ width: "100%" }}>
          <Button className="ms-auto" style={{ width: '100%' }}
            color="custom"
            >LOGIN</Button>
        </div>
                </form>
                { isLoginSuccess ? <Redirect to="/dashboard" /> : null}
                <div className='pb-2 text-center w-100'>                    
           <Link to="/forgot_password">Forgot password?</Link>        
        </div>
        <div className='pb-3 text-center w-100'>                    
        Dont' have an account ?&nbsp;<Link to="/register">Register</Link>       
        </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  )
};

export default LogIn;
