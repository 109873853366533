import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import fetch from '../../../config/service';
import apiCalls from '../../../config/apicalls';
// import Reload from '../../reload'
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
const noProfileImage = configImages.defaultImg;

export default class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
      userData: {}
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  // reload = () => {
  //   Reload.reload()
  // };

  componentDidMount = async () => {
    this.updateTopbarProfileData()

  }
  updateTopbarProfileData = async () => {
    let userData = await localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    // await this.setState(({
    //   userData: { "address": "Wdfasdfadsf", "updatedBy": { "employee": "5b1536660a92e74b0a141d8b" }, "ableToCreateHospital": true, "status": "Active", "active": true, "role": "SuperAdmin", "_id": "5b1536660a92e74b0a141d8b", "displayName": " Nithin", "firstName": "admin", "lastName": "admin", "email": "admin@yopmail.com", "created": "2018-06-04T12:53:58.744Z", "__v": 0, "updated": "2020-03-19T06:45:30.600Z", "dateOfBirth": "2019-08-28T09:02:30.048Z", "phone": "1234567890", "hospitalId": "5e6b089e175b1c7ec295eb0f", "accessToken": "f6d85ee2-8b55-5532-94c1-7147e14494e2", "refreshToken": "e469c6ec-4ac1-55df-a837-ca10596de005" }
    // }));
    if (userData) {
      return fetch('GET', `${apiCalls.User}/${userData._id}`)
        .then(async (response) => {
          if (response && response.details) {
            await this.setState(({
              userData: response.details
            }));
          } else if (response && response.errorMessage) {
          }
        }).catch((err) => {
          return err;
        });
    } else {
      return;
    }
  }

  render() {
    const { collapse } = this.state;
    const profileImage = this.state.userData && this.state.userData.photo ? this.state.userData.photo : null;

    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={this.toggle}>
          {/* change */}
          <img className="topbar__avatar-img"
            src={profileImage ? `${config.imgUrl}/user/${profileImage}` : noProfileImage} />
          <p className="topbar__avatar-name">{this.state.userData && this.state.userData.displayName ?
            this.state.userData.displayName : 'No Name'}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu topbarMenu">
            <div className='topBarImageAlignment'>
              <img src={profileImage ? `${config.imgUrl}/user/${profileImage}` : noProfileImage}
                className='topBarImage' />
              <div >
                {this.state.userData && this.state.userData.displayName ?
                  this.state.userData.displayName : 'No Name'}
                <div className='themeColorText'>{this.state.userData &&
                  this.state.userData.role ?
                  this.state.userData.role : ''}</div>
              </div>
            </div>

            <div className="topbar__menu-divider" />
            <div className="topbar__menu">
              <TopbarMenuLink title="My Profile" icon="user" path="/profile" toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              />
              {/* <TopbarMenuLink title="Account Settings" icon="cog" path="/settings" toggleClose={this.toggle} /> */}
              <TopbarMenuLink title="Change Password" icon="bubble" path="/changePassword" toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              />
              {/* <TopbarMenuLink title="Help" icon="phone" path="/https://api.whatsapp.com/send?phone=918107370197" toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              /> */}
              {/* <TopbarMenuLink title="Check version" icon="phone" toggleClose={this.reload}
                updateTopbarProfileData={this.updateTopbarProfileData}
              /> */}
              <div className="topbar__menu-divider" />
              <TopbarMenuLink title="Log Out" icon="exit" path="/log_in" toggleClose={this.toggle}
                updateTopbarProfileData={this.updateTopbarProfileData}
              />
            </div>
          </div>
        </Collapse>
      </div >
    );
  }
}
