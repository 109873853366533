import React, { PureComponent, useState } from 'react';
import { withTranslation } from 'react-i18next';
import RegisterStaticForm from './RegisterStaticForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import config from '../../../config/config';

const LoginTemplate = (props) => {
  
    const [scaleLogin, setScaleLogin] = useState(0.6);
    const [sideImage, setSideImage] = useState(props.loginSideImage)

    const zoomInLogin = () => {
        if (scaleLogin <= 0.9) {
            setScaleLogin(scaleLogin + 0.1)
        }
    }

    const zoomOutLogin = () => {
        if (scaleLogin >= 0.5) {
            setScaleLogin(scaleLogin - 0.1)
        }
    }

    return (
        <div className='h-100 w-100'>
            <div className='d-flex'>
                <div className='template-page-name h6'>
                    Register Screen
                </div>
                <div className='ms-auto cursor-pointer'>
                    < FontAwesomeIcon
                        icon={faSearchMinus}
                        className="mr-3"
                        onClick={zoomOutLogin}
                    />
                    &nbsp;
                    <span style={{ marginRight: '5px' }}></span>
                    < FontAwesomeIcon
                        icon={faSearchPlus}
                        onClick={zoomInLogin}
                    />
                </div>
            </div>

            <div className='regTemp' style={(props.backGroundType === 'image') ? { backgroundImage: `url(${config.logoUrl}${props.bgImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow:'auto' } : { backgroundColor: props.bgColor, overflow: 'auto' }}>
                {(props.selectedTemplate === '1' || !props.selectedTemplate) ?
                    <div className='reg-wrap' style={{ transform: `scale(${scaleLogin})` }} >
                        <div className='card'>
                            <div className='card-body'>
                                <div className='text-center h6 pb-2 proj-name-color'>
                                    <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                                </div>
                                <RegisterStaticForm
                                    buttonColour={props.buttonColour}
                                    buttonTextColour={props.buttonTextColour}
                                    buttonType={props.buttonType}
                                    buttonRounded={props.buttonRounded}
                                    googleLogin={props.googleLogin}
                                    googleCaptcha={props.googleCaptcha} />

                            </div>
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '2') ?
                    <div className='login2 d-flex' style={{ transform: `scale(${scaleLogin})` }}>
                        <div className='log-img'>
                            <img src={`${config.logoUrl}${sideImage}`} alt="" className='h-100' />
                        </div>
                        <div className='p-4 h-100 d-flex align-items-center log-form' style={{ transform: `scale(0.7)` }}>
                            <div>
                                <div className='text-center h6 pb-2 proj-name-color'>
                                    <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                                </div>
                                <RegisterStaticForm
                                    buttonColour={props.buttonColour}
                                    buttonTextColour={props.buttonTextColour}
                                    buttonType={props.buttonType}
                                    buttonRounded={props.buttonRounded}
                                    googleLogin={props.googleLogin}
                                    googleCaptcha={props.googleCaptcha} />
                            </div>
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '3') ?
                    <div className='login2 d-flex' style={{ transform: `scale(${scaleLogin})` }}>
                        <div className='p-4 h-100 d-flex align-items-center log-form' style={{ transform: `scale(0.7)` }}>
                            <div>
                                <div className='text-center h6 pb-2 proj-name-color'>
                                    <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                                </div>
                                <RegisterStaticForm
                                    buttonColour={props.buttonColour}
                                    buttonTextColour={props.buttonTextColour}
                                    buttonType={props.buttonType}
                                    buttonRounded={props.buttonRounded}
                                    googleLogin={props.googleLogin}
                                    googleCaptcha={props.googleCaptcha} />
                            </div>
                        </div>
                        <div className='log-img'>
                            <img src={`${config.logoUrl}${sideImage}`} alt="" className='h-100' />
                        </div>
                    </div>
                    : null
                }

                {(props.selectedTemplate === '4') ?
                    <div className='login4 d-flex' style={{ transform: `scale(${scaleLogin})` }}>
                        <div className='logo-img d-flex flex-column justify-content-center'>
                            <img src={`${config.logoUrl}${props.projectLogo}`} className='login-layout-logo'></img>
                        </div>
                        <div className='ps-5 h-100 d-flex align-items-center log-form'>
                            <RegisterStaticForm
                                buttonColour={props.buttonColour}
                                buttonTextColour={props.buttonTextColour}
                                buttonType={props.buttonType}
                                buttonRounded={props.buttonRounded}
                                googleLogin={props.googleLogin}
                                googleCaptcha={props.googleCaptcha} />
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    )
}
export default LoginTemplate;