import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, FormGroup, Label, Input, FormText, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowCircleUp, faArrowCircleDown, faAngleDown, faAngleUp, faLevelUpAlt, faLevelDownAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import '../../../scss/menuEdit.scss';
import '../../../scss/custom.scss';
import FaIcons from './FaIcons';

import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';

const MenuEditor = (props) => {
    const [menuList, updateState_MenuList] = useState([]);
    const [menuListObj, updateState_MenuListObj] = useState({});

    const [title, setTitle] = useState(null);
    const [route, setRoute] = useState(null);
    const [iconType, setIconType] = useState('');
    const [iconLetters, setIconLetter] = useState(null);
    const [iconName, setIconName] = useState(null);
    const [saveId, setSaveId] = useState(null); 

    useEffect(() => {
        if (localStorage.getItem('menu') && localStorage.getItem('menuObj')) {
            const _menuList = JSON.parse(localStorage.getItem('menu'));
            const _menuListObj = JSON.parse(localStorage.getItem('menuObj'));
            updateState_MenuList(_menuList);
            updateState_MenuListObj(_menuListObj);
        } else {
            updateState_MenuList(props.controllers);
            updateState_MenuListObj(props.menuListObj);

            let getMenu = props.controllers[0];
            if (typeof getMenu === 'object') {
                setTitle(getMenu.displayTitle);
                setRoute(getMenu.route);
                setIconType(getMenu.iconType);
                setIconLetter(getMenu.iconLetters);
                setIconName(getMenu.iconName);
                setSaveId(getMenu.id)
            }
        }
    }, [props]);


    // Toggle Submenus Functionality Starts 
    const toggleSubMenu = (menu) => {
        let elem = document.getElementById(`${menu.id}Submenu`);
        elem.classList.toggle('d-none');
        let downIcon = document.getElementById(`${menu.id}AngleDown`);
        downIcon.classList.toggle('d-none');
        let upIcon = document.getElementById(`${menu.id}AngleUp`);
        upIcon.classList.toggle('d-none');
    }

    const makeChild = (menu, index, array) => {
        let newArray = [...array];
        if (newArray[index - 1]) {
            if (!newArray[index - 1].submenus) {
                newArray[index - 1].submenus = [];
            }
            newArray[index - 1].submenus.push(menu);
        }
        newArray.splice(index, 1);
        updateState_MenuList(newArray);
    };

    const removeElement = (menuList, position) => {
        let _menuList = [...menuList];
        switch (position.length) {
            case 1:
                _menuList.splice(position[0], 1);
                break;
            case 2:
                _menuList[position[0]].submenus.splice(position[1], 1);
                break;
            case 3:
                _menuList[position[0]].submenus[position[1]].submenus.splice(position[2], 1);
                break;
        }
        return _menuList;
    };

    const removeAsChild = (menu) => {
        if (menu && menu.id) {
            let source = getPosition(menuList, menu.id);
            let updatedMenuList = removeElement(menuList, source);
            let target = [];
            target[0] = source[0] + 1;
            updatedMenuList = addElement(updatedMenuList, target, menu);
            updateState_MenuList(updatedMenuList);
        }
    };

    const addElement = (menuList, position, element) => {
        let _menuList = [...menuList];
        switch (position.length) {
            case 1:
                _menuList.splice(position[0], 0, element);
                break;
            case 2:
                _menuList[position[0]].submenus.splice(position[1], 0, element);
                break;
            case 3:
                _menuList[position[0]].submenus[position[1]].submenus.splice(position[2], 0, element);
                break;
        }
        return _menuList;
    };

    const getPosition = (array, id, position = []) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id.toString() === id.toString()) {
                position.push(i);
                return position;
            } else {
                if (array[i].submenus && Array.isArray(array[i].submenus)) {
                    for (let j = 0; j < (array[i].submenus).length; j++) {
                        if (array[i].submenus[j].id.toString() === id.toString()) {
                            position = [i, j];
                            return position;
                        } else {
                            if (array[i].submenus[j].submenus && Array.isArray(array[i].submenus[j].submenus)) {
                                for (let k = 0; j < array[i].submenus[j].submenus.length; k++) {
                                    if (array[i].id.toString() === id.toString()) {
                                        position = [i, j, k];
                                        return position;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    // On Click Arrows Funcionality Starts
    const onClickUpArrow = (index, id) => {
        if (index > 0) {
            let _menu = swapArray(menuList, index, index - 1, id);
            updateState_MenuList(_menu);
        }
    }

    const onClickDownArrow = (index, id, arrayLength) => {
        if (index < arrayLength) {
            let _menu = swapArray(menuList, index, index + 1, id);
            updateState_MenuList(_menu);
        }
    }

    // swapping of the screens and sub-screens
    const swapArray = (menu, fromIndex, toIndex, id) => {
        let newMenu = [...menu];
        for (let i = 0; i < newMenu.length; i++) {
            if (newMenu[i].id.toString() === id.toString()) {
                let temp = newMenu[fromIndex];
                newMenu[fromIndex] = newMenu[toIndex];
                newMenu[toIndex] = temp;
                return newMenu;
            }

            if (newMenu[i].submenus && newMenu[i].submenus.length > 0) { // swap if there is sub-menus
                newMenu[i].submenus = swapArray(newMenu[i].submenus, fromIndex, toIndex, id); 
            }
        }
        return newMenu;
    } 
    // Ends

    const displayMenuTemplate = (menuList, subMenu) => {
        return (
            menuList.map(((menu, index, array) =>
                <div key={index}>
                    <div draggable className='p-3 border cursor-pointer' id={menu.id}
                        onDragStart={(e) => { e.dataTransfer.setData('text/plain', JSON.stringify(menu)) }}
                        onDrop={(e) => { handleDrop(e) }}
                        onDragOver={(e) => { e.preventDefault() }}>
                        <div className='d-flex justify-content-between'>
                            <div>
                                {menu.iconType === 'letters' ? (
                                    <span className='text-uppercase letter-icon menu-editor'>
                                        {menu.iconLetters}
                                    </span>
                                ) : <FontAwesomeIcon className='me-2' icon={menu.iconName} /> } &nbsp;
                                <b className='text-capitalize'>{menu.displayTitle}</b>
                            </div>

                            <div>
                                {(menu.submenus && menu.submenus.length > 0) &&
                                    <span>
                                        <a onClick={() => { toggleSubMenu(menu) }} id={`${menu.id}AngleDown`} title='Open Sub' >
                                            <FontAwesomeIcon className='me-2'
                                                icon={faAngleDown}
                                            />
                                        </a>
                                        <a onClick={() => { toggleSubMenu(menu) }} id={`${menu.id}AngleUp`} className='d-none' title='Close Sub'>
                                            <FontAwesomeIcon className='me-2'
                                                icon={faAngleUp}
                                            />
                                        </a>
                                    </span>
                                }
                                {((menu && !menu.controllerId && !menu.submenus) || (menu && menu.submenus && menu.submenus.length <= 0)) &&
                                    <span>
                                        <a onClick={() => onClickDeleteMenu(menu.id)} title='Delete'>
                                            <FontAwesomeIcon className='me-2 text-danger'
                                                icon={faTrash}
                                            />
                                        </a>
                                    </span>
                                }

                                {(index > 0 && !array[index-1].controllerId && !subMenu) &&
                                    <a onClick={() => { makeChild(menu, index, array) }} title='Make Sub'>
                                        < FontAwesomeIcon className='me-2'
                                            icon={faLevelUpAlt}
                                        />
                                    </a>
                                }

                                {(subMenu) &&
                                    <a onClick={() => { removeAsChild(menu, index, array) }} title='Remove Sub'>
                                        < FontAwesomeIcon className='me-2'
                                            icon={faLevelDownAlt}
                                        />
                                    </a>
                                }

                                <a onClick={() => { onClickUpArrow(index, menu.id) }} title='Move Up'>
                                    < FontAwesomeIcon className='me-2'
                                        icon={faArrowCircleUp}
                                    />
                                </a>
                                <a onClick={() => { onClickDownArrow(index, menu.id, array.length - 1) }} title='Move Down'>
                                    < FontAwesomeIcon className='me-2'
                                        icon={faArrowCircleDown}
                                    />
                                </a>
                                <a onClick={() => { onClickEditMenu(menu.id) }} title='Delete'>
                                    < FontAwesomeIcon className='text-primary'
                                        icon={faEdit}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    {
                        (menu.submenus && menu.submenus.length > 0) &&
                        <div className='d-none ps-2 background-filter' id={`${menu.id}Submenu`}>
                            {displayMenuTemplate(menu.submenus, true)}
                        </div>
                    }
                </div>
            ))
        )
    }

    // Add Menu
    const addNewMenu = () => {
        let _menuList = [...menuList];
        let menuItem = {};
        menuItem.name = 'Menu' + (_menuList.length + 1);
        let prop = _menuList.length + 1;
        if (menuItem.id) {
            menuItem.id = _menuList.length + 1;
        }
        menuItem.showSubmenu = false;
        menuItem.displayTitle = 'Menu' + (_menuList.length + 1);
        setTitle(menuItem.displayTitle);
        menuItem.iconType = 'letters';
        setIconType(menuItem.iconType);
        menuItem.iconLetters = 'ME';
        setIconLetter(menuItem.iconLetters);
        menuItem.iconName = null;
        setIconName(menuItem.iconName);
        setSaveId(menuItem.id)
        _menuList.push(menuItem);

        updateState_MenuList(_menuList);
    }

    const getSelectedIcons = (icon) => {
        setIconName(icon.iconName);
    }

    const handleDrop = (event) => {
        event.preventDefault();
        let source = getPosition(menuList, JSON.parse(event.dataTransfer.getData('text/plain')).id);
        let target = getPosition(menuList, event.currentTarget.id);
        let updatedMenuList = removeElement(menuList, source);
        updatedMenuList = addElement(updatedMenuList, target, JSON.parse(event.dataTransfer.getData('text/plain')));
        updateState_MenuList(updatedMenuList);
    };

    // edit icon function
    const onClickEditMenu = (controllerId) => {
        let getMenu = getObjectById(menuList, controllerId)
        if (typeof getMenu === 'object') {
            setTitle(getMenu.displayTitle);
            setRoute(getMenu.route);
            setIconType(getMenu.iconType);
            setIconLetter(getMenu.iconLetters);
            setIconName(getMenu.iconName);
            setSaveId(getMenu.id)
        }
    }

    // for getting the each menu's id by clicking on the edit icon.
    const getObjectById = (menu, id) => {
        for (let item of menu) {
            if (item.id === id) {
                return item;
            }
            if (item.submenus) {
                const submenuItem = getObjectById(item.submenus, id);
                if (submenuItem) {
                    return submenuItem;
                }
            }
        }
        return null;
    };

    // delete of the menus if no child present
    const onClickDeleteMenu = (id) => {
        let getMenu = getObjectById(menuList, id)
        if(!getMenu.controllerId){
            let menu = menuList.filter(item => item.id !== id);
            updateState_MenuList(menu)
        }
    }

    // Saving Menu
    const saveMenu = () => {
        let _menuList = menuList;
        const obj = {};
        _menuList.forEach(item => {
            obj[item.id] = item;
        });
        props.updateMenu(_menuList, obj);
        props.openMenuEditor();
    }

    // save individual of adding icons or name of the screens
    const saveIndividual = (menu, id, type, letter, name, title) => {
        const updatedMenu = menu.map(item => {
            if (item.id === id) {
                let updatedItem = { ...item };
                if (type === 'icon') {  //updates the icons if present
                    updatedItem = {
                        ...updatedItem,
                        iconType: type,
                        iconName: name,
                    };
                }
                if (type === 'letters') { //updates the letters if present
                    updatedItem = {
                        ...updatedItem,
                        iconType: type,
                        iconLetters: letter,
                    };
                }
                if (title) { // for the menu changing name
                    updatedItem = {
                        ...updatedItem,
                        displayTitle: title
                    };
                }
                return updatedItem;
            }
            if (item.submenus && item.submenus.length > 0) {
                return {
                    ...item,
                    submenus: saveIndividual(item.submenus, id, type, letter, name, title)  // for the submenus
                };
            }

            return item;
        });
        updateState_MenuList(updatedMenu);
    }


    return (
        <>
            <Modal isOpen={props.openMenuEdit} centered={true} size='lg'>
                <ModalHeader className='text-white' toggle={props.openMenuEditor} style={{ backgroundColor: "#4ce1b6" }}>
                    <span className='h5 text-white'>Menu Editor</span>
                </ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className='col-md-6'>
                            {displayMenuTemplate(menuList, false)}
                        </div>
                        <div className='col-md-6'>
                            <div className='px-4'>
                                <div className='w-100 text-right'>
                                    <Button color="primary" onClick={addNewMenu}>Add Menu</Button>
                                </div>
                                { menuList.find(item => item.id === saveId && !item.controllerId) ?
                                    <Row>
                                        <FormGroup>
                                            <Label for="displayTitle">Display Title</Label>
                                            <InputText id="displayTitle" placeholder="with a placeholder"
                                                className='w-full'
                                                value={title}
                                                onChange={(e) => { setTitle(e.target.value) }} 
                                            />
                                        </FormGroup>
                                    </Row>
                                    : <strong className='h5 text-capitalize'>{title ? title : ''}<br /><br /></strong>
                                }

                                <FormGroup>
                                    <Label>Icon</Label>
                                    <div className="d-flex flex-wrap gap-3">
                                        <div className="d-flex align-items-center">
                                            <RadioButton inputId="letters" name="iconType" value="letters"
                                                onChange={(e) => { setIconType(e.value) }} checked={iconType === 'letters'}/>
                                            <label htmlFor="letters" className="ms-2">Letters</label>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <RadioButton inputId="icons" name="iconType" value="icon"
                                                onChange={(e) => { setIconType(e.value) }} checked={iconType === 'icon'}/>
                                            <label htmlFor="icons" className="ms-2">Icon</label>
                                        </div>
                                    </div>
                                </FormGroup>

                                {(iconType === 'letters') &&
                                    <FormGroup>
                                        <Input className='text-uppercase' type="text" name="letters"
                                            id="exampleEmail" placeholder="Enter Letters"
                                            value={iconLetters}
                                            onChange={(e) => { setIconLetter(e.target.value) }}
                                        />
                                    </FormGroup>
                                }

                                {(iconType === 'icon') &&
                                    <FaIcons getSelectedIcons={getSelectedIcons} />
                                }

                                <Button color="primary" onClick={() => { saveIndividual(menuList, saveId, iconType, iconLetters, iconName, title) }}>Save</Button>

                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="customBtn">
                    <Button color="primary" onClick={() => {saveMenu()}}>Confirm Changes</Button>{' '}
                    <Button color="secondary" onClick={props.openMenuEditor} className='cancelMenuBtnStyle'>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    )

}
export default MenuEditor;