import React from 'react'
import config from '../config/config'
import showToasterMessage from './../containers/UI/ToasterMessage/toasterMessage';
import SessionExpiryModal from '../containers/Cruds/CommonModals/SessionexpiryModal';

let startTime

async function logoutTime() {
  let endTime = new Date()
  // console.log("startTime", startTime, "endTime", endTime, "endTime - startTime > config.logoutTime ", endTime - startTime, endTime - startTime > config.logoutTime)
  if (startTime && (endTime - startTime > config.logoutTime)) {
    localStorage.setItem('sessionexpired', true)
    let keys = Object.keys(localStorage)
    for (let i in keys) {
      // console.log(keys[i].substr(i.length - 14))
      if (keys[i].substr(i.length - 14) === '_column_order') {
        // console.log(keys[i])
        localStorage.removeItem(keys[i])
      }
    }
    showToasterMessage('Inactive for a while, Please login again', 'error');
    window.location.reload();
  } else {
    startTime = endTime
  }
}

async function resetLocalStorage() {
  let keys = Object.keys(localStorage)
  for (let i in keys) {
    // console.log(keys[i].substr(i.length - 14))
    if (keys[i].substr(i.length - 14) === '_column_order') {
      // console.log(keys[i])
      localStorage.removeItem(keys[i])
    }
  }
}

export default {
  logoutTime,
  resetLocalStorage
};


