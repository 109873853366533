import React from "react";
//Components
import ExistingProject from "./AngularExistingProject/angularexistingproject";
import NewProject from "./AngularNewProject/angularnewproject";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RadioButton from '../../shared/components/form/RadioButton';
import Loader from '../App/Loader';
import ConfirmModal from '../ConfirmModals/ConfirmModal';
import SelectModal from '../ConfirmModals/SelectModal';

//styles
import { Row, Col, Collapse } from "reactstrap";
import { Field, reduxForm } from "redux-form";
//service.js
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apicalls';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

class AngularClientCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        { value: "new", label: "New" },
        { value: "existing", label: "Existing" },
      ],
      selectedProject: null,
      selectedTech: null,
      showProgressBar: false,
      fileName: '',
      favName: '',
      bgName: '',
      loadIg: '',
      downloadProjectLink: '',
      isLoading: false,
      yesJsonUploaded: false,
      jsonUploadFile: '',
      apiUrlTypes: [],
      hideTabTwo: false,
      showConfirmModal: false,
      yesExistingProject: false,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }
  //submit values
  submit = (values) => {

    let temp = '';
    let yesContiue = false;
    let profileFields = [];
    if (this.newProjectRef && this.state.selectedProject == 'new') {
      temp = this.newProjectRef.returnControllerArray();
    }
    if (this.existingProjectRef && this.state.selectedProject == 'existing') {
      temp = this.existingProjectRef.returnControllerArray();
    }
    if (values && values.loginApi) {
      yesContiue = true;
    } else {
      if (this.newProjectRef) {
        this.newProjectRef.showErrorForApiUrl();
      }
      if (this.existingProjectRef && this.state.selectedProject == 'existing') {
        yesContiue = true;
      }
    }
    if (yesContiue) {
      let { selectedProject, fileName } = this.state;
      if (values) {
        if (values.projectNameNew) {
          values.projectName = values.projectNameNew;
          delete values['projectNameNew']
        }
        if (values['projectFile'] && values['projectFile']['name']) {
          values['projectFile'] = values['projectFile']['name']
        }
      }
      if (selectedProject) {
        values.projectType = selectedProject;
      }
      for (let j = 0; j < temp.length; j++) {
        if (!this.state.yesJsonUploaded) {
          for (let k = 0; k < temp[j].controllerFields.length; k++) {
            Object.keys(temp[j].controllerFields[k]).forEach((items, index) => {
              if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
                items.endsWith('4') || items.endsWith('5') ||
                items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
                let yesExist = items;
                yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                temp[j].controllerFields[k][`${yesExist}`] = temp[j].controllerFields[k][items];
                delete temp[j].controllerFields[k][items];
              } else {
                if (items === 'options' && temp[j].controllerFields[k][items].length &&
                  temp[j].controllerFields[k][items].length > 0) {
                  for (let y = 0; y < temp[j].controllerFields[k][items].length; y++) {
                    Object.keys(temp[j].controllerFields[k][items][y]).forEach((itemsFilter, index) => {
                      if (itemsFilter.endsWith('0') || itemsFilter.endsWith('1') || itemsFilter.endsWith('2') || itemsFilter.endsWith('3') ||
                        itemsFilter.endsWith('4') || itemsFilter.endsWith('5') ||
                        itemsFilter.endsWith('6') || itemsFilter.endsWith('7') || itemsFilter.endsWith('8')) {
                        let yesExist = itemsFilter;
                        yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                        temp[j].controllerFields[k][items][y][`${yesExist}`] = temp[j].controllerFields[k][items][y][itemsFilter];
                        delete temp[j].controllerFields[k][items][y][itemsFilter];
                        delete temp[j].controllerFields[k]['hideSortIcon'];
                      } else {
                        delete temp[j].controllerFields[k][items][y][itemsFilter]
                      }
                    })
                  }
                  let filterTemp = temp[j].controllerFields[k]['options'];
                  filterTemp.unshift({ label: 'All', value: null })
                } else if (items === 'displayOptions' && temp[j].controllerFields[k][items].length &&
                  temp[j].controllerFields[k][items].length > 0) {
                  for (let y = 0; y < temp[j].controllerFields[k][items].length; y++) {
                    Object.keys(temp[j].controllerFields[k][items][y]).forEach((itemsFilter, index) => {
                      if (itemsFilter.endsWith('0') || itemsFilter.endsWith('1') || itemsFilter.endsWith('2') || itemsFilter.endsWith('3') ||
                        itemsFilter.endsWith('4') || itemsFilter.endsWith('5') ||
                        itemsFilter.endsWith('6') || itemsFilter.endsWith('7') || itemsFilter.endsWith('8')) {
                        let yesExist = itemsFilter;
                        yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                        temp[j].controllerFields[k][items][y][`${yesExist}`] = temp[j].controllerFields[k][items][y][itemsFilter];
                        delete temp[j].controllerFields[k][items][y][itemsFilter];
                        delete temp[j].controllerFields[k]['hideSortIcon'];
                      } else {
                        delete temp[j].controllerFields[k][items][y][itemsFilter]
                      }
                    })
                  }

                } else {
                  delete temp[j].controllerFields[k][items];
                }
              }
            })
          }
        }
        if (!this.state.yesJsonUploaded) {
          for (let m = 0; m < temp[j].controllerFormFields.length; m++) {
            Object.keys(temp[j].controllerFormFields[m]).forEach((items, index) => {
              if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
                items.endsWith('4') || items.endsWith('5') ||
                items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
                let yesExist = items;
                yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                temp[j].controllerFormFields[m][`${yesExist}`] = temp[j].controllerFormFields[m][items];
                delete temp[j].controllerFormFields[m][items];
              } else {
                if (items === 'options' && temp[j].controllerFormFields[m][items].length &&
                  temp[j].controllerFormFields[m][items].length > 0) {
                  for (let z = 0; z < temp[j].controllerFormFields[m][items].length; z++) {
                    Object.keys(temp[j].controllerFormFields[m][items][z]).forEach((itemsOption, index) => {
                      if (itemsOption.endsWith('0') || itemsOption.endsWith('1') || itemsOption.endsWith('2') || itemsOption.endsWith('3') ||
                        itemsOption.endsWith('4') || itemsOption.endsWith('5') ||
                        itemsOption.endsWith('6') || itemsOption.endsWith('7') || itemsOption.endsWith('8')) {
                        let yesExist = itemsOption;
                        yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                        temp[j].controllerFormFields[m][items][z][`${yesExist}`] = temp[j].controllerFormFields[m][items][z][itemsOption];
                        delete temp[j].controllerFormFields[m][items][z][itemsOption];
                      } else {
                        delete temp[j].controllerFormFields[m][items][z][itemsOption]
                      }
                    })
                  }
                } else if (items === 'validations' && temp[j].controllerFormFields[m][items].length &&
                  temp[j].controllerFormFields[m][items].length > 0) {
                  for (let z = 0; z < temp[j].controllerFormFields[m][items].length; z++) {
                    Object.keys(temp[j].controllerFormFields[m][items][z]).forEach((itemsOption, index) => {
                      if (itemsOption.endsWith('0') || itemsOption.endsWith('1') || itemsOption.endsWith('2') || itemsOption.endsWith('3') ||
                        itemsOption.endsWith('4') || itemsOption.endsWith('5') ||
                        itemsOption.endsWith('6') || itemsOption.endsWith('7') || itemsOption.endsWith('8')) {
                        let yesExist = itemsOption;
                        yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                        temp[j].controllerFormFields[m][items][z][`${yesExist}`] = temp[j].controllerFormFields[m][items][z][itemsOption];
                        delete temp[j].controllerFormFields[m][items][z][itemsOption];
                      } else {
                        delete temp[j].controllerFormFields[m][items][z][itemsOption]
                      }
                    })
                  }
                } else {
                  delete temp[j].controllerFormFields[m][items];
                }
              }
            })
          }
        }
      }
      let body = {
        projectName: values.projectName,
        projectType: values.projectType,
        logo: this.state.fileName,
        fav: this.state.favName,
        bg: this.state.bgName,
        load: this.state.loadIg,
        controllers: temp,
        loginApi: values.loginApi,
      }
      if (this.existingProjectRef && this.state.selectedProject == 'existing') {
        delete body.logo;
        delete body.loginEntity;
        delete body.loginApi;
        delete body.logo;
        delete body.fav;
        delete body.bg;
        delete body.load;
      }
      if (this.state.yesJsonUploaded) {
        delete body.controllers;
        delete body.projectName;
        body['angularJsonFile'] = this.state.jsonUploadFile
      }
      console.log(body)
      this.sendDataToServer(body)
    }
  };

  //send data to server
  sendDataToServer = async (values) => {
    let submitDataValue = false;
    if (!this.state.yesJsonUploaded && values.controllers.length > 0 && values.projectName) {
      submitDataValue = true;
    } else if (this.state.yesJsonUploaded) {
      submitDataValue = true;
    }
    if (submitDataValue) {
      await this.setState({ isLoading: true })
      let { selectedProject } = this.state;
      fetchMethodRequest('POST', apiCalls.Angular, values).then(async (res) => {
        if (res && res.respCode && res.downloadLink) {
          this.setState({
            downloadProjectLink: res.downloadLink, favName: '', bgName: '', loadIg: '', fileName: '',
            yesExistingProject: false,
            jsonUploadFile: '', apiUrlTypes: [], isLoading: false, yesJsonUploaded: false,
          })
          setTimeout(() => {
            this.setState({ hideTabTwo: false, })
          }, 500)
          if (selectedProject && selectedProject == "existing" && this.existingProjectRef) {
            await this.setState({ selectedProject: '' })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.existingProjectRef.emptyController();
          } else if (selectedProject && selectedProject == "new" && this.newProjectRef) {
            await this.setState({ selectedProject: '', isLoading: false })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.newProjectRef.emptyController();
          }
        } else if (res && res.errorCode && res.errorMessage) {
          showToasterMessage(res.messageType, 'error');
          await this.setState({ isLoading: false })
        } else {
          await this.setState({ isLoading: false })
        }
      })
    } else {
      await this.setState({ isLoading: false })
    }
  }

  //project file
  projectFile = (file) => {
    fetchMethodRequest('POST', apiCalls.AngularLogo, file.file, 'upload').then((res) => {
      if (res && res.logo) {
        if (this.newProjectRef) {
          this.newProjectRef.uploadExits();
        }
        this.setState({ fileName: res.logo })
      }
    })
  }

  //project fav
  projectFav = (file) => {
    fetchMethodRequest('POST', apiCalls.AngularFav, file.file, 'upload').then((res) => {
      if (res && res.fav) {
        if (this.newProjectRef) {
          this.newProjectRef.changeImgsState('favIconState');
        }
        this.setState({ favName: res.fav })
      }
    })
  }

  //project fav
  projectBg = (file) => {
    fetchMethodRequest('POST', apiCalls.AngularBg, file.file, 'upload').then((res) => {
      if (res && res.bg) {
        if (this.newProjectRef) {
          this.newProjectRef.changeImgsState('bgState');
        }
        this.setState({ bgName: res.bg })
      }
    })
  }

  //project fav
  projectLg = (file) => {
    fetchMethodRequest('POST', apiCalls.AngularLoad, file.file, 'upload').then((res) => {
      if (res && res.load) {
        if (this.newProjectRef) {
          this.newProjectRef.changeImgsState('loadStateImg');
        }
        this.setState({ loadIg: res.load })
      }
    })
  }
  //project file
  jsonUploadFile = (file) => {
    let url = 'jsonUpload?type=angular';
    fetchMethodRequest('POST', url, file.file, 'upload').then(async (res) => {
      if (res && res.fileName) {
        await this.props.changeprogressWidth(60)
        await this.props.changeprogressWidth(80)
        if (this.newProjectRef) {
          this.newProjectRef.toggleUploadSample();
          if (this.existingProjectRef && this.state.selectedProject == 'existing') {
            this.existingProjectRef.toggleUploadSample();
            await this.props.changeprogressWidth(100)
          }
        } else if (this.existingProjectRef) {
          await this.props.changeprogressWidth(100)
          this.existingProjectRef.toggleUploadSample();
        }
        this.setState({
          jsonUploadFile: res.fileName,
          yesJsonUploaded: true,
          apiUrlTypes: res.loginApi,
          yesExistingProject: true
        })
      }
    })
  }

  //handleSelectModal
  handleSelectModal = async (type) => {
    await this.setState({ selectedModalValue: type, selectModalShow: false })
    if (this.state.selectedProject == 'existing') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'existing', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({ selectedProject: 'new', favName: '', bgName: '', loadIg: '', fileName: '', yesExistingProject: false, yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true, })
      }
    } else if (this.state.selectedProject == 'new') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({ selectedProject: 'existing', favName: '', bgName: '', loadIg: '', fileName: '', yesExistingProject: false, yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true, })
      }
    } else {
      this.setState({ selectedProject: this.state.selectedProject, downloadProjectLink: '', hideTabTwo: true, })
    }
  }

  //handleyesExistingProject
  handleyesExistingProject = async (type) => {
    await this.setState({ yesExistingProject: type })
  }

  //handleRadioChanges
  handleRadioChanges = async (type, event) => {
    if (type == 'new') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'existing') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({
          yesExistingProject: false,
          selectedProject: 'new',
          downloadProjectLink: '', hideTabTwo: true,
        })
      }
    } else if (type == 'exi') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'new') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({
          yesExistingProject: false,
          selectedProject: 'existing',
          downloadProjectLink: '', hideTabTwo: true
        })
      }
    }
  }

  //handleProgreess
  handleProgressWidth = async () => {
    if (this.state.selectedProject) {
      await this.setState({ showConfirmModal: true })
    } else {
      await this.props.handleTabs('one')
      await this.setState({ showConfirmModal: false })
    }
  }

  //confirmModalOpened
  confirmModalOpened = async (type) => {
    if (type == 'yes') {
      await this.setState({
        selectedProject: '', hideTabTwo: false, showConfirmModal: false,
        yesExistingProject: false, yesJsonUploaded: false,
      })
      await this.props.reset();
      setTimeout(() => {
        this.props.handleTabs('one')
      }, 500)
    } else if (type == 'no') {
      await this.setState({ showConfirmModal: false })
    }
  }

  //handlDown Arrow
  handleDownArrow = async (event) => {
    this.setState({ hideTabTwo: !this.state.hideTabTwo })
  }

  render() {
    let { projects, selectedProject, hideTabTwo, favName, fileName, yesExistingProject, bgName, loadIg } = this.state;
    const { handleSubmit } = this.props;
    return (

      this.props.hideTabOne && <form onSubmit={handleSubmit(this.submit)}>
        <h2 className='pt-3'>
          <b><span className='pr-3 '>
            < FontAwesomeIcon
              className='editIcon text-info'
              color='#ff00008c'
              icon='arrow-alt-circle-left'
              data-toggle="tool-tip"
              title="Go Back"
              onClick={() => this.handleProgressWidth()}
            />
          </span>Angular Js
            {selectedProject && <span className='ps-2 drownArrowC'>
              < FontAwesomeIcon
                className='editIcon downColor'
                color='#ff00008c'
                icon={hideTabTwo ? 'angle-down' : 'angle-up'}
                data-toggle="tool-tip"
                title={hideTabTwo ? "Open" : 'Close'}
                onClick={(event) => this.handleDownArrow(event)}
              />
            </span>}
          </b>
        </h2>
        <Loader loader={this.state.isLoading} />
        < Collapse isOpen={!hideTabTwo}>

          < label className="list_class">
            Please Select Type Of Your Project
          </label>
          <Row className="pb-3">
            <Col sm={4}>
              <Field
                id='rsnew'
                name='project'
                className="react-select"
                placeholder="Select project type"
                label="New"
                radioValue="new"
                onClick={(event) => {
                  this.handleRadioChanges('new', event)
                }}
                component={RadioButton}
                selectedProject={this.state.selectedProject}
              />
              <Field
                id='rsexi'
                name='project'
                onClick={(event) => {
                  this.handleRadioChanges('exi', event)
                }}
                className="react-select"
                placeholder="Select project type"
                label="Existing"
                radioValue='existing'
                component={RadioButton}
                selectedProject={this.state.selectedProject}
              />
            </Col>
          </Row>
          <hr />
        </Collapse>
        {
          selectedProject && selectedProject == "new" ? (
            <NewProject
              onRef={(ref) => (this.newProjectRef = ref)}
              projectFile={this.projectFile}
              jsonUploadFile={this.jsonUploadFile}
              apiUrlTypes={this.state.apiUrlTypes}
              yesJsonUploaded={this.state.yesJsonUploaded}
              handleAllTabs={this.props.handleTabs}
              changeprogressWidth={this.props.changeprogressWidth}
              projectFav={this.projectFav}
              projectBg={this.projectBg}
              projectLg={this.projectLg}
              handleyesExistingProject={this.handleyesExistingProject}

            />
          ) : selectedProject && selectedProject == "existing" ? (
            <ExistingProject
              onRef={(ref) => (this.existingProjectRef = ref)}
              projectFile={this.projectFile}
              showProgressBar={this.state.showProgressBar}
              jsonUploadFile={this.jsonUploadFile}
              yesJsonUploaded={this.state.yesJsonUploaded}
              handleAllTabs={this.props.handleTabs}
              changeprogressWidth={this.props.changeprogressWidth}
              handleyesExistingProject={this.handleyesExistingProject}
            />
          ) : null
        }
        {
          selectedProject && <Row className="pt-4">
            <Col sm={12} className="d-flex justify-content-center">
              {selectedProject && selectedProject == 'new' && yesExistingProject &&
                favName && fileName && bgName && loadIg ? <button type="submit" className="btn btn-success">
                  Submit
              </button> : selectedProject && selectedProject == 'existing' && yesExistingProject ?
                  <button type="submit" className="btn btn-success">
                    Submit
              </button> : null}
            </Col>
          </Row>
        }
        {
          selectedProject && <Row>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>
        }
        <Row className={this.state.downloadProjectLink ? "pt-3" : 'd-none'}>
          <Col sm={12}
            className="text-center"
          >
            <h4 >  You can download your Project Here <a href={this.state.downloadProjectLink} download>Download</a></h4>
          </Col>
        </Row>
        <ConfirmModal
          showConfirmModal={this.state.showConfirmModal}
          confirmModalOpened={this.confirmModalOpened}
        />
        <SelectModal
          selectModalShow={this.state.selectModalShow}
          handleSelectModal={this.handleSelectModal}
        />
      </form >
    );
  }
}
export default reduxForm({
  form: "angularclient", // a unique identifier for this form
})(AngularClientCode);
