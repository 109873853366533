import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import { useState } from 'react';

const DeleteNewActionModal = (props) => {
    const [displayMod, setDisplayMod] = useState(props.displayNewActionDeleteModal);

    const handleNo = () => {
        props.deleteNewActionFromController('no');
    };

    const handleYes = () => {
        props.deleteNewActionFromController('yes');
    };

    return (
        
        <div>
            <Modal centered={true} isOpen={displayMod}>
                <ModalHeader className='progresBarClr text-white' style={{ textAlign: "center" }}>
                    Are you sure you want to delete ?
                </ModalHeader>
                <ModalBody>
                    <Row className="pt-4">
                        <Col sm={12} className=" text-center">
                            <button type="button" className="btn btn-success"
                                onClick={() => handleNo()}>
                                No
                            </button>
                            <button type="button" className="btn btn-success"
                                onClick={() => handleYes()}>
                                Yes
                            </button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
};
export default DeleteNewActionModal;