import React, { useState, useEffect } from 'react';

import { Button } from 'reactstrap';
// import { Badge } from 'primereact/badge';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faStop, faPlay, faCopy } from '@fortawesome/free-solid-svg-icons';
import serverCalls from '../../../config/config';
import CopyProjectModal from '../../ConfirmModals/CopyConfirmModal';
import { Badge } from "reactstrap";
import { Tag } from 'primereact/tag';
import configImages from '../../../config/configImages';


const ProjectCard=(props)=> {
    const { project } = props;

    const [copyModalOpen, setCopyModalOpen] = useState(false);

    const openCopyModal = () => {
        setCopyModalOpen(!copyModalOpen);
    };

    const closeInputModal = (action, value) => {
        if (action === 'Yes' && value != '' && value.length >2) {
            props.copyProject(project, value)
            setCopyModalOpen(false);
        } else if (action === 'No'){
            setCopyModalOpen(false);   
        }
    };


    function showDate() {
        let date = props && project ? new Date(project.created) : '';
        return (<span>{date.toLocaleString('default', { day: '2-digit', month: 'long', year: 'numeric' })}</span>);
    };

    function switchGen(val) {
        let badgeColor = '';
        switch(val) {
            case 'Stopped': 
            badgeColor = 'danger';
            break;
            case 'Running': 
            badgeColor = 'success';
            break;
            default: badgeColor= 'primary';
        }
        return badgeColor;
    }

    return(
        <div className="relative">
            <div className="card h-100 proj-card-dash">
                <img className="card-img-top p-2" 
                    src={serverCalls.logoUrl + JSON.parse(project.jsonObject).logo} 
                    alt="Project Logo" 
                />
                <div className="card-body p-3">
                    <div className='card-title mb-1'>
                        <div className="d-flex justify-content-between">
                            <h5 className='text-capitalize' style={{fontWeight:"500", letterSpacing:"0.5px", fontSize:"14px"}}>
                                <a href={`/view_project/${JSON.stringify(project._id || '')}`}> 
                                    <b>{ project.name || '' }</b>
                                </a>
                            </h5>
                                { (project && (project.projectStatus === 'Running' || project.projectStatus === 'Stopped')) &&
                                    <>
                                        <Badge color={switchGen(project.projectStatus)}>
                                        <span className="pt-2">{ project?.projectStatus }</span>
                                        </Badge>
                                        
                                    </>
                                    
                                }
                        </div>
                    </div>
                    <div className=''> Last Updated - {showDate()} </div>
                    <div className="d-flex justify-content-between pt-3">
                        <div className="d-flex gap-2">
                            <div className='proj-card-button'>
                                <Button color='custom-outline' className='mb-2 ' data-toggle="tooltip" title="Copy Project"
                                    onClick={() => openCopyModal(project)}
                                >
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                    />
                                </Button>
                                <CopyProjectModal
                                    name={project.name}
                                    selectModalShow={copyModalOpen}
                                    closeInputModal={closeInputModal}
                                />
                            </div>
                            { ( project && project.projectStatus === 'Stopped') &&
                                <>
                                <div className='proj-card-button'>
                                    <Button color='custom-outline ' className='mb-2 proj-card-button'
                                        onClick={() => props.startProject(project)} data-toggle="tooltip"  title="Run"
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlay}
                                        />
                                    </Button>
                                </div>
                                </>
                            }
                            { (project && project.projectStatus === 'Running') &&
                                <div className='proj-card-button'>
                                    <Button color='custom-outline' className='mb-2 ' data-toggle="tooltip"  title="Stop"
                                        onClick={() => props.stopProject(project)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faStop}
                                        />
                                    </Button>
                                </div>
                            }
                        </div>
                        <div>
                            {project && (project.projectStatus === 'Running' && (props.project.siteLink && typeof(project.siteLink) === "object")) &&
                                <div className='d-flex proj-card-button'>                           
                                    { Object.keys(project.siteLink).map((item) => {
                                        return item ? <span>
                                            {  
                                                project && (project.siteLink && project.siteLink[item] && 
                                                project.uiStatus && project.uiStatus[item] && project.uiStatus[item].pingItStatus
                                                && project.uiStatus[item].pingItStatus.toLowerCase() === 'up' &&
                                                project.uiStatus[item].pingItProjectDeployStatus && 
                                                project.uiStatus[item].pingItProjectDeployStatus.toLowerCase() === 'running') &&
                                                <Button color='custom-outline' className='mb-2 me-2 proj-card-button' data-toggle="tooltip" title={item + ' ' + 'Preview'}
                                                    onClick={() => window.open(project.siteLink && project.siteLink[item] ? project.siteLink[item] : null)}>
                                                    <FontAwesomeIcon  icon={faEye} />
                                                </Button>
                                            }
                                        </span> : null;
                                    })}
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        <div>
                            {project && project.selectedFrontEnd ? 
                            <LanguageBadge color={"#ffff"} img={project.selectedFrontEnd == "React" ? configImages.React : configImages.Angular} content={project.selectedFrontEnd}/>
                            :<LanguageBadge color={"#ffff"} img={configImages.React} content={"React"}/>
                            }
                        </div>
                        <div>
                            {project && project.selectedBackEnd ? 
                            <LanguageBadge color={"#ffff"} img={configImages[project.selectedBackEnd]} content={project.selectedBackEnd}/>
                            :<LanguageBadge color={"#ffff"} img={configImages.Node} content={"Node"}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="card absolute cardStyle">

            </div>
        </div>
    )
}

const LanguageBadge = ({color, img,content}) =>{

    // d-inline-flex px-2 rounded-5
    return (
        <Tag style={{backgroundColor:color}}>
            <div className="d-flex align-items-center gap-1 px-1">
                <img src={img} style={{height:"30px", width:"60px", objectFit:"contain"}}/>
                {/* <span style={{fontWeight: "600", color:"white", fontSize:"10px"}}>{content}</span> */}
            </div>
        </Tag>
    )
}
export default ProjectCard;