import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class SelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <Modal centered={true} isOpen={this.props.selectModalShow}>
                <ModalHeader className='progresBarClr text-white'>
                    Are you sure you want to select this ?
                </ModalHeader>
                <ModalBody>
                    <Row className="pt-4">
                        <Col sm={12} className=" text-center">
                            <button type="button" className="btn btn-success"
                                onClick={() => this.props.handleSelectModal('no')}>
                                No
              </button>
                            <button type="button" className="btn btn-success"
                                onClick={() => this.props.handleSelectModal('yes')}>
                                Yes
              </button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

}
export default SelectModal;