import React from "react";
//Components

import RSExistingProject from "./RSExistingProject/rsexistingproject";
import RSNewProject from "./RSNewProject/rsnewproject";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import RadioButton from '../../shared/components/form/RadioButton';
import Loader from '../App/Loader';
import ConfirmModal from '../ConfirmModals/ConfirmModal';
import SelectModal from '../ConfirmModals/SelectModal';

//styles
import { Row, Col, Collapse } from "reactstrap";
import { Field, reduxForm } from "redux-form";

//service.js
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apicalls';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

class ClientCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        { value: "new", label: "New" },
        { value: "existing", label: "Existing" },
      ],
      selectedProject: null,
      selectedTech: null,
      showProgressBar: false,
      fileName: '',
      favName: '',
      bgName: '',
      downloadProjectLink: '',
      isLoading: false,
      yesJsonUploaded: false,
      jsonUploadFile: '',
      apiUrlTypes: [],
      hideTabTwo: false,
      showConfirmModal: false,
      selectModalShow: false,
      selectedModalValue: '',
      yesExistingProject: false,
    };
  }

  //submit values
  submit = (values) => {

    let temp = '';
    let yesContiue = false;
    let profileFields = [];
    if (this.newProjectRef && this.state.selectedProject == 'new') {
      temp = this.newProjectRef.returnControllerArray();
    }
    if (this.existingProjectRef && this.state.selectedProject == 'existing') {
      temp = this.existingProjectRef.returnControllerArray();
    }
    if (values && values.loginApi) {
      yesContiue = true;
    } else {
      if (this.newProjectRef) {
        this.newProjectRef.showErrorForApiUrl();
      }
      if (this.existingProjectRef && this.state.selectedProject == 'existing') {
        yesContiue = true;
      }
    }
    let { selectedProject, fileName } = this.state;
    if (selectedProject && selectedProject == "new" && values) {
      values.mailServiceType =
        this &&
          this.newProjectRef &&
          this.newProjectRef.state &&
          this.newProjectRef.state.selectedMailService &&
          this.newProjectRef.state.selectedMailService.value
          ? this.newProjectRef.state.selectedMailService.value
          : "";
      if (values.mailServiceType == 1) {
        delete values.sendGridApiKey;
        delete values.sendGridMail;
      }
      if (values.mailServiceType == 2) {
        delete values.nodeMailerEmail;
        delete values.nodeMailerHost;
        delete values.nodeMailerPassword;
        delete values.nodeMailerPort;
        delete values.nodeMailerService;
      }

    } else {
      if (values['nodemailerpassword']) {
        delete values['nodemailerpassword']
      }
      if (values['emailservice']) {
        delete values['emailservice']
      }
      if (values['nodemaileremail']) {
        delete values['nodemaileremail']
      }
      if (values['projectFile'] && values['projectFile']['name']) {
        values['projectFile'] = values['projectFile']['name']
      }
      if (values.projectNameNew) {
        values.projectName = values.projectNameNew;
        delete values['projectNameNew']
      }
      if (values.mailServiceType) {
        delete values['mailServiceType']
      }
    }
    if (selectedProject) {
      values.projectType = selectedProject;
    }
    if (yesContiue) {
      for (let s = 0; s < temp['serverControllers'].length; s++) {
        if (temp['serverControllers'][s]['entityValue']) {
          delete temp['serverControllers'][s]['entityValue']
        }
        let Fields = {};
        for (let t = 0; t < temp['serverControllers'][s]["controllerFields"].length; t++) {
          Object.keys(temp['serverControllers'][s]["controllerFields"][t]).forEach((items, index) => {
            if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
              items.endsWith('4') || items.endsWith('5') ||
              items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
              let yesExist = items;
              yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
              temp['serverControllers'][s]["controllerFields"][t][`${yesExist}`] = temp['serverControllers'][s]["controllerFields"][t][items];
              delete temp['serverControllers'][s]["controllerFields"][t][items];
            } else {
              delete temp['serverControllers'][s]["controllerFields"][t][items];
            }
          })
        }
        for (let q = 0; q < temp['serverControllers'][s]["controllerFields"].length; q++) {
          Fields[temp['serverControllers'][s]["controllerFields"][q]['name']] = temp['serverControllers'][s]["controllerFields"][q]['type']
        }
        delete temp['serverControllers'][s]["controllerFields"];
        temp['serverControllers'][s]['Fields'] = Fields;
        if (temp['serverControllers'][s]['methods']) {
          let act = temp['serverControllers'][s]['methods'];
          act.push('load');
          temp['serverControllers'][s]['methods'] = act;
        }
      }
    }
    if (yesContiue) {
      let { selectedProject, fileName } = this.state;
      if (values) {
        if (values.projectNameNew) {
          values.projectName = values.projectNameNew;
          delete values['projectNameNew']
        }
        if (values['projectFile'] && values['projectFile']['name']) {
          values['projectFile'] = values['projectFile']['name']
        }
      }
      if (selectedProject) {
        values.projectType = selectedProject;
      }
      for (let j = 0; j < temp['reactControllers'].length; j++) {
        if (!this.state.yesJsonUploaded) {
          for (let k = 0; k < temp['reactControllers'][j]['controllerFields'].length; k++) {
            Object.keys(temp['reactControllers'][j]['controllerFields'][k]).forEach((items, index) => {
              if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
                items.endsWith('4') || items.endsWith('5') ||
                items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
                let yesExist = items;
                yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                temp['reactControllers'][j]['controllerFields'][k][`${yesExist}`] = temp['reactControllers'][j]['controllerFields'][k][items];
                delete temp['reactControllers'][j]['controllerFields'][k][items];
              } else {
                if (items === 'filterElement' && temp['reactControllers'][j]['controllerFields'][k][items].length &&
                  temp['reactControllers'][j]['controllerFields'][k][items].length > 0) {
                  for (let y = 0; y < temp['reactControllers'][j]['controllerFields'][k][items].length; y++) {
                    Object.keys(temp['reactControllers'][j]['controllerFields'][k][items][y]).forEach((itemsFilter, index) => {
                      if (itemsFilter.endsWith('0') || itemsFilter.endsWith('1') || itemsFilter.endsWith('2') || itemsFilter.endsWith('3') ||
                        itemsFilter.endsWith('4') || itemsFilter.endsWith('5') ||
                        itemsFilter.endsWith('6') || itemsFilter.endsWith('7') || itemsFilter.endsWith('8')) {
                        let yesExist = itemsFilter;
                        yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                        temp['reactControllers'][j]['controllerFields'][k][items][y][`${yesExist}`] = temp['reactControllers'][j]['controllerFields'][k][items][y][itemsFilter];
                        delete temp['reactControllers'][j]['controllerFields'][k][items][y][itemsFilter];
                      } else {
                        delete temp['reactControllers'][j]['controllerFields'][k][items][y][itemsFilter]
                      }
                    })
                  }
                  let filterTemp = temp['reactControllers'][j]['controllerFields'][k]['filterElement'];
                  filterTemp.unshift({ label: 'All', value: null })
                } else {
                  delete temp['reactControllers'][j].controllerFields[k][items];
                }
              }
            })
          }
        }
        if (!this.state.yesJsonUploaded) {
          if (temp['reactControllers'][j].actions) {
            temp['reactControllers'][j]['controllerFields'].push({
              "textAlign": "left",
              "width": 70,
              "field": "Actions",
              "header": "Actions",
              "filter": false,
              "sortable": false
            })
          }
          temp['reactControllers'][j]['controllerFields'].unshift({
            "textAlign": 'center',
            "width": 47,
            "field": 'Sno',
            "header": 'SNo',
            "filter": false,
            "sortable": false,
            "placeholder": 'Search'
          })
        }
        if (!this.state.yesJsonUploaded) {
          for (let m = 0; m < temp['reactControllers'][j].controllerFormFields.length; m++) {
            Object.keys(temp['reactControllers'][j].controllerFormFields[m]).forEach((items, index) => {
              if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
                items.endsWith('4') || items.endsWith('5') ||
                items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
                let yesExist = items;
                yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                temp['reactControllers'][j].controllerFormFields[m][`${yesExist}`] = temp['reactControllers'][j].controllerFormFields[m][items];
                delete temp['reactControllers'][j].controllerFormFields[m][items];
              } else {
                if (items === 'options' && temp['reactControllers'][j].controllerFormFields[m][items].length &&
                  temp['reactControllers'][j].controllerFormFields[m][items].length > 0) {
                  for (let z = 0; z < temp['reactControllers'][j].controllerFormFields[m][items].length; z++) {
                    Object.keys(temp['reactControllers'][j].controllerFormFields[m][items][z]).forEach((itemsOption, index) => {
                      if (itemsOption.endsWith('0') || itemsOption.endsWith('1') || itemsOption.endsWith('2') || itemsOption.endsWith('3') ||
                        itemsOption.endsWith('4') || itemsOption.endsWith('5') ||
                        itemsOption.endsWith('6') || itemsOption.endsWith('7') || itemsOption.endsWith('8')) {
                        let yesExist = itemsOption;
                        yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                        temp['reactControllers'][j].controllerFormFields[m][items][z][`${yesExist}`] = temp['reactControllers'][j].controllerFormFields[m][items][z][itemsOption];
                        delete temp['reactControllers'][j].controllerFormFields[m][items][z][itemsOption];
                      } else {
                        delete temp['reactControllers'][j].controllerFormFields[m][items][z][itemsOption]
                      }
                    })
                  }
                } else {
                  delete temp['reactControllers'][j].controllerFormFields[m][items];
                }
              }
            })
          }
        }
        if (temp['reactControllers'][j]["controllerProfileFields"] && temp['reactControllers'][j]["controllerProfileFields"].length) {
          for (let n = 0; n < temp['reactControllers'][j].controllerProfileFields.length; n++) {
            Object.keys(temp['reactControllers'][j].controllerProfileFields[n]).forEach((items, index) => {
              if (items.endsWith('0') || items.endsWith('1') || items.endsWith('2') || items.endsWith('3') ||
                items.endsWith('4') || items.endsWith('5') ||
                items.endsWith('6') || items.endsWith('7') || items.endsWith('8')) {
                let yesExist = items;
                yesExist = yesExist.replace(/0|1|2|3|4|5|6|7|8|9/g, '');
                temp['reactControllers'][j].controllerProfileFields[n][`${yesExist}`] = temp['reactControllers'][j].controllerProfileFields[n][items];
                delete temp['reactControllers'][j].controllerProfileFields[n][items];
              } else {
                delete temp['reactControllers'][j].controllerProfileFields[n][items];
              }
            })
          }
          profileFields = temp['reactControllers'][j].controllerProfileFields;
          delete temp['reactControllers'][j].controllerProfileFields;
        }
      }
      if (selectedProject && selectedProject == "existing" && fileName) {
        values.projectFile = fileName
      }

      let body = {
        projectName: values.projectName,
        projectType: values.projectType,
        logo: this.state.fileName,
        fav: this.state.favName,
        controllers: temp,
        profileFields: profileFields,
        loginApi: values.loginApi,
        emailService: values.emailService,
        mailServiceType: values.mailServiceType,
      }
      if (values.mailServiceType && values.mailServiceType == 1) {
        body['nodeMailerEmail'] = values.nodeMailerEmail;
        body['nodeMailerHost'] = values.nodeMailerHost;
        body["nodeMailerPassword"] = values.nodeMailerPassword;
        body["nodeMailerPort"] = values.nodeMailerPort;
        body["nodeMailerService"] = values.nodeMailerService;
      } else if (values.mailServiceType && values.mailServiceType == 2) {
        body['sendGridApiKey'] = values.sendGridApiKey;
        body['sendGridMail'] = values.sendGridMail;
      }
      if (this.state.yesJsonUploaded) {
        delete body['controllers'];
        delete body.projectName;
        body['jsonFile'] = this.state.jsonUploadFile;
      }
      if (this.existingProjectRef && this.state.selectedProject == 'existing') {
        delete body.logo;
        delete body.fav;
        delete body.loginApi;
        delete body.mailServiceType;
      }
      console.log(body)
      if (yesContiue) {
        this.sendDataToServer(body)
      }
    }
  };

  //send data to server
  sendDataToServer = async (values) => {
    let submitDataValue = false;
    if (!this.state.yesJsonUploaded && values.projectName) {
      submitDataValue = true;
    } else if (this.state.yesJsonUploaded) {
      submitDataValue = true;
    }
    if (submitDataValue) {
      await this.setState({ isLoading: true })
      let { selectedProject } = this.state;
      fetchMethodRequest('POST', apiCalls.ReactNode, values).then(async (res) => {
        if (res && res.respCode && res.downloadLink) {
          this.setState({
            downloadProjectLink: res.downloadLink,
            jsonUploadFile: '', apiUrlTypes: [],
            isLoading: false, yesJsonUploaded: false, fileName: '', favName: ''
          })
          setTimeout(() => {
            this.setState({ hideTabTwo: false, })
          }, 500)
          if (selectedProject && selectedProject == "existing" && this.existingProjectRef) {
            await this.setState({ selectedProject: '' })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.existingProjectRef.emptyController();
          } else if (selectedProject && selectedProject == "new" && this.newProjectRef) {
            await this.setState({ selectedProject: '', isLoading: false })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.newProjectRef.emptyController();
          }
        } else if (res && res.errorCode && res.errorMessage) {
          showToasterMessage(res.messageType, 'error');
          await this.setState({ isLoading: false })
        } else {
          await this.setState({ isLoading: false })
        }
      })
    } else {
      await this.setState({ isLoading: false })
    }
  }

  //project file
  projectFile = (file) => {
    fetchMethodRequest('POST', apiCalls.ReactLogo, file.file, 'upload').then((res) => {
      if (res && res.logo) {
        if (this.newProjectRef) {
          this.newProjectRef.uploadExits();
        }
        this.setState({ fileName: res.logo })
      }
    })
  }

  //projectFav
  projectFav = (file) => {
    fetchMethodRequest('POST', apiCalls.ReactFav, file.file, 'upload').then((res) => {
      if (res && res.fav) {
        if (this.newProjectRef) {
          this.newProjectRef.favIconSubmitted();
        }
        this.setState({ favName: res.fav })
      }
    })
  }

  //projectBg
  projectBg = (file) => {
    fetchMethodRequest('POST', 'reactLogo?type=bg', file.file, 'upload').then((res) => {
      if (res && res.bg) {
        if (this.newProjectRef) {
          this.newProjectRef.uploadExits();
        }
        this.setState({ bgName: res.bg })
      }
    })
  }

  //project file
  jsonUploadFile = (file) => {
    fetchMethodRequest('POST', 'build/jsonUpload?type=reactAndServer', file.file, 'upload').then(async (res) => {
      if (res && res.fileName) {
        await this.props.changeprogressWidth(60)
        if (this.newProjectRef) {
          this.newProjectRef.toggleUploadSample();
          if (this.existingProjectRef && this.state.selectedProject == 'existing') {
            await this.setState({ yesJsonUploaded: true, })
            this.existingProjectRef.toggleUploadSample();
            await this.props.changeprogressWidth(80)
          }
        } else if (this.existingProjectRef) {
          await this.setState({ yesJsonUploaded: true, })
          await this.props.changeprogressWidth(80)
          this.existingProjectRef.toggleUploadSample();
        }
        this.setState({
          jsonUploadFile: res.fileName,
          yesJsonUploaded: true,
          apiUrlTypes: res.loginApi,
          yesExistingProject: true
        })
      }
    })
  }

  //handleRadioChanges
  handleRadioChanges = async (type, event) => {
    if (type == 'new') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'existing') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({
          selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true,
          yesExistingProject: false,
        })
      }
    } else if (type == 'exi') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'new') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({
          selectedProject: 'existing', downloadProjectLink: '', yesExistingProject: false,
          hideTabTwo: true
        })
      }
    }
  }

  //handleSelectModal
  handleSelectModal = async (type) => {
    await this.setState({ selectedModalValue: type, selectModalShow: false })
    if (this.state.selectedProject == 'existing') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'existing', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({
          yesExistingProject: false, fileName: '', favName: '',
          selectedProject: 'new',
          yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true,
        })
      }
    } else if (this.state.selectedProject == 'new') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({
          yesExistingProject: false, fileName: '', favName: '',
          selectedProject: 'existing',
          yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true,
        })
      }
    } else {
      this.setState({ selectedProject: this.state.selectedProject, downloadProjectLink: '', hideTabTwo: true, })
    }
  }

  //handleProgreess
  handleProgressWidth = async () => {
    if (this.state.selectedProject) {
      await this.setState({ showConfirmModal: true })
    } else {
      await this.props.handleTabs('one')
      await this.setState({ showConfirmModal: false })
    }
  }

  //confirmModalOpened
  confirmModalOpened = async (type) => {
    if (type == 'yes') {
      await this.setState({
        selectedProject: '', yesExistingProject: false, yesJsonUploaded: false,
        hideTabTwo: false, showConfirmModal: false
      })
      await this.props.reset();
      setTimeout(() => {
        this.props.handleTabs('one')
      }, 500)
    } else if (type == 'no') {
      await this.setState({ showConfirmModal: false })
    }
  }

  //handleyesExistingProject
  handleyesExistingProject = async (type) => {
    await this.setState({ yesExistingProject: type })
  }

  render() {
    let { projects, selectedProject, hideTabTwo, favName, fileName, yesExistingProject } = this.state;
    const { handleSubmit } = this.props;
    return (
      this.props.hideTabOne && <form onSubmit={handleSubmit(this.submit)}>
        <h2 className='pt-3'>
          <b><span className='pr-3 '>
            < FontAwesomeIcon
              className='editIcon text-info'
              color='#ff00008c'
              icon='arrow-alt-circle-left'
              data-toggle="tool-tip"
              title="Go Back"
              onClick={() => this.handleProgressWidth()}
            />
          </span>React & Node Js
            {selectedProject && <span className='ps-2 drownArrowC'>
              < FontAwesomeIcon
                className='editIcon downColor'
                color='#ff00008c'
                icon={hideTabTwo ? 'angle-down' : 'angle-up'}
                data-toggle="tool-tip"
                title={hideTabTwo ? "Open" : 'Close'}
                onClick={() => { this.setState({ hideTabTwo: !this.state.hideTabTwo }) }}
              />
            </span>}
          </b>
        </h2>
        <Loader loader={this.state.isLoading} />
        < Collapse isOpen={!hideTabTwo}>
          <label className="list_class">
            Please Select Type Of Your Project
          </label>
          <Row className="pb-3">
            <Col sm={4}>
              <Field
                id='rsnew'
                name='project'
                className="react-select"
                placeholder="Select project type"
                label="New"
                radioValue="new"
                onClick={(event) => {
                  this.handleRadioChanges('new', event)
                }}
                component={RadioButton}
                selectedProject={this.state.selectedProject}
              />
              <Field
                id='rsexi'
                name='project'
                onClick={(event) => {
                  this.handleRadioChanges('exi', event)
                }}
                className="react-select"
                placeholder="Select project type"
                label="Existing"
                radioValue='existing'
                component={RadioButton}
                selectedProject={this.state.selectedProject}
              />
            </Col>
          </Row>
          <hr />
        </Collapse>
        {selectedProject && selectedProject == "new" ? (
          <RSNewProject
            onRef={(ref) => (this.newProjectRef = ref)}
            projectFile={this.projectFile}
            jsonUploadFile={this.jsonUploadFile}
            apiUrlTypes={this.state.apiUrlTypes}
            yesJsonUploaded={this.state.yesJsonUploaded}
            changeprogressWidth={this.props.changeprogressWidth}
            projectFav={this.projectFav}
            projectBg={this.projectBg}
            handleyesExistingProject={this.handleyesExistingProject}
          />
        ) : selectedProject && selectedProject == "existing" ? (
          <RSExistingProject
            onRef={(ref) => (this.existingProjectRef = ref)}
            projectFile={this.projectFile}
            showProgressBar={this.state.showProgressBar}
            jsonUploadFile={this.jsonUploadFile}
            apiUrlTypes={this.state.apiUrlTypes}
            yesJsonUploaded={this.state.yesJsonUploaded}
            changeprogressWidth={this.props.changeprogressWidth}
            handleyesExistingProject={this.handleyesExistingProject}
          />
        ) : null}
        {selectedProject && <Row className="pt-4">
          <Col sm={12} className="d-flex justify-content-center">
            {selectedProject && selectedProject == 'new' &&
              favName && fileName && yesExistingProject ? <button type="submit" className="btn btn-success">
              Submit
            </button> : selectedProject && selectedProject == 'existing' && yesExistingProject ?
              <button type="submit" className="btn btn-success">
                Submit
              </button> : null}
          </Col>
        </Row>}
        {selectedProject && <Row>
          <Col sm={12}>
            <hr />
          </Col>
        </Row>}
        <Row className={this.state.downloadProjectLink ? "pt-3" : 'd-none'}>
          <Col sm={12}
            className="text-center"
          >
            <h4 >  You can download your Project Here <a href={this.state.downloadProjectLink} download>Download</a></h4>
          </Col>
        </Row>
        <ConfirmModal
          showConfirmModal={this.state.showConfirmModal}
          confirmModalOpened={this.confirmModalOpened}
        />
        <SelectModal
          selectModalShow={this.state.selectModalShow}
          handleSelectModal={this.handleSelectModal}
        />
      </form>
    );
  }
}
export default reduxForm({
  form: "client", // a unique identifier for this form
})(ClientCode);
