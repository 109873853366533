/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef} from 'react';
import { Container, Row } from 'reactstrap';
import { Card, CardHeader,CardBody, Col, Button, ButtonToolbar } from 'reactstrap';

// import Profile from './components/Profile';
import { Link } from 'react-router-dom';

import { Redirect } from 'react-router';
import apiCalls from '../../../config/apicalls';
import config from '../../../config/config';
import fetch from '../../../config/service';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Hook Forms
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// primeng
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';


import { classNames } from 'primereact/utils';



const ProfilePage = (props)=> {

    const profileForm = {
        firstName: '',
        lastName: '',
        dateOfBirth: null,
        email: '',
        phone: '',
        address: '',
        photo: ''
    };
    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register, trigger, setError, setValue
    } = useForm({ profileForm });

    const [redirect] = useState(false);
    const [userId, updateUserId] = useState(null);
    const [profilePic, setProfilePic] = useState(null);

    useEffect(()=> {
        if(localStorage.getItem('loginCredentials')) {
            getUserInfo();
        }
    },[props]);

    const getUserInfo=()=> {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        updateUserId(user._id);
        let Url = `${apiCalls.User}/${user._id}`;
        fetch('GET', Url, '')
          .then(async (response) => {
            if (response && response.details) {
                let details = response.details;
              if (details && details.photo) {
                setProfilePic(details.photo);
              }
              for(const prop in response.details) {
                if (prop === 'firstName' || prop === 'lastName' || prop === 'email' || prop === 'phone' || prop === 'address' ||
                    prop === 'dateOfBirth') {
                    setValue(prop, response.details[prop]);
                }
              }
              console.log(response.details);
            }
          }).catch((err) => {
            return err;
          });
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    const onSubmit = (formValues) => {
        if (formValues) {
            delete formValues.email;
            delete formValues.password;
            let Url;
            if (localStorage.getItem('loginCredentials')) {
                let user = JSON.parse(localStorage.getItem('loginCredentials'));
                Url = `${apiCalls.User}/${user._id}`;
            }
            return fetch('PUT', Url, formValues)
                .then(async (response) => {
                    if (response && response.respCode) {
                        showToasterMessage(response.respMessage, 'success');
                        window.location.href = '/dashboard';
                        reset();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });

        } else {
            return;
        }
    };

    const onImageSelect = (event) => {
        const picture = event.files[0];
        uploadFileToServer(picture);
    };

    const uploadFileToServer = (picture)=> {
        fetchMethodRequest('POST', 'uploads?uploadPath=user', picture, 'upload').then((response)=> {
            if(response && response.respCode) {
                showToasterMessage(response.respMessage, 'success');
                setValue("photo", response.fileName);
                setProfilePic(response.fileName);
            }
        });
    }

    return(
        <>
           <Container>
                <Card>
                    <CardHeader>
                        <div className="card__title">
                            <h3 className="bold-text">Profile</h3>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>First Name</label>
                                        <Controller
                                            name='firstName'
                                            control={control}
                                            rules={{ required: 'First Name is required.' }}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} 
                                                    placeholder='Enter your Firstname'/>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>Last Name</label>
                                        <Controller
                                            name='lastName'
                                            control={control}
                                            rules={{ required: 'Last Name is required.' }}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} 
                                                    placeholder='Enter your Lastname'/>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>Date of Birth</label>
                                        <Controller
                                            name='dateOfBirth'
                                            control={control}
                                            rules={{ required: 'Date is required.' }}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <input type='date' inputId={field.name} value={field.value} onChange={field.onChange}
                                                     className={classNames({ 'p-invalid': fieldState.error }, 'p-inputtext p-component w-full')} required/>
                                                      {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>Email</label>
                                        <Controller
                                            name='email'
                                            control={control}
                                            rules={{ required: 'Email is required.' }}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <InputText id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error }, 'w-full')} onChange={(e) => field.onChange(e.target.value)} 
                                                    placeholder='Enter your Email'/>
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>Phone number</label>
                                        <Controller
                                            name='phone'
                                            control={control}
                                            rules={{ required: 'Mobile number is required.' }}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <InputNumber id={field.name} inputRef={field.ref} value={field.value} onBlur={field.onBlur} onValueChange={(e) => field.onChange(e)} useGrouping={false} inputClassName={classNames({ 'p-invalid': fieldState.error },'w-full')} />
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>Profile Pic</label>
                                        <FileUpload mode="basic" name="photo" accept="image/*" maxFileSize={1000000} 
                                            onSelect={onImageSelect}    
                                            chooseLabel={'Browse'}
                                        />
                                        { profilePic && 
                                            <>
                                            <div className='col' style={{ padding: '20px' }}>
                                                <div style={{ justifyContent: 'center' }}>
                                                    <img src={`${config.imgUrl}/user/${profilePic}`} className='detailsImgStyle' />
                                                </div>
                                            </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className='form-label'>Address</label>
                                        <Controller
                                            name='address'
                                            control={control}
                                            rules={{ required: 'Address is required.' }}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <InputTextarea id={field.name} {...field} rows={4} cols={30} className={classNames({ 'p-invalid': fieldState.error },'w-full')} />
                                                    {getFormErrorMessage(field.name)}
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end nobtnmargins'>
                                <ButtonToolbar className="form__button-toolbar">
                                    <Link to={'/dashboard'}>
                                        <Button style={{ marginLeft: '50px' }} color='primary'>Cancel</Button>
                                    </Link>
                                    <Button color="primary" type="submit">Submit</Button>
                                </ButtonToolbar>    
                            </div>
                           
                        </form>
                    </CardBody>
                </Card>
            </Container> 
        </>
    )
}
export default ProfilePage;
