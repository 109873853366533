import React, { useState, useEffect, useRef } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
// hook forms
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Dialog } from 'primereact/dialog';



const newModal =(props)=> {
    
    const defaultValues = {
        NewModelName: '',
        actions: ''
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
        register, trigger, setError, setValue
    } = useForm({ defaultValues });

    const [actionsType] =  useState(props.formFieldsArr);

    useEffect(()=> {
        if (props.isEdit) {
            let controllers = props.controllers;
            let controllerTotalfields = controllers[props.index].newModels;
            for (let controllerTotalfield of controllerTotalfields) {
                if (controllerTotalfield.newModelName == props.modelName) {
                    setValue("NewModelName", props.modelName);
                    setValue("newactions", controllerTotalfield.newModelValues);
                }
            }
        } 
    }, [props])

    const displayCancel=()=>{
        return (
            < Button type="button" style={{ backgroundColor: "#4ce1b6" }} outline
            onClick={() => props.closeInputModal()}>Cancel</Button>
          )
    }
    const displayDelete=()=>{
        return (
            < Button type="button" style={{ backgroundColor: "#4ce1b6" }} outline
            onClick={() => handleOnClickDelete()}>Delete</Button>
          )
    }
    const handleOnClickDelete=async()=>{
        await props.OpenDeleteNewActionModal();
        props.closeInputModal();
    }
    
    const onSubmit = (data) => {
        console.log(data);

        let values = {};
        values.index = props.index;
        values.actions = data.newactions;
        values.newactions = data.newactions;
        values.actionsIndex = props.index;
        values.modelName = props.modelName;
        values.NewModelName = data.NewModelName;
        values.isEdit = props.isEdit ? props.isEdit : false;

        props.loginApiValue(values);
        props.closeInputModal();

        // reset();
    };

    const dialogHeader = () => {

        return(
            <>
                <span className='h5'>New Action</span>
                <span className='float-right'>
                    <FontAwesomeIcon
                        className='editIcon text-white'
                        color='#ff00008c'
                        icon='times-circle'
                        data-toggle="tool-tip"
                        title="Close"
                        onClick={() => props.closeInputModal()}
                    />
                </span>
            </>
        )
    }

    return(
        <>
            <Dialog className='screen'
                visible={props.isOpenAddByModal}
                draggable={false}
                style={{ width: "40%" }}
                header={dialogHeader}
                closeOnEscape={false}
                focusOnShow={false}
                blockScroll={false}
              >
                <ModalBody>
                    <Card className='m-0 p-0' style={{ border: "none" }}>
                        <div className=''>
                            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                                <div className='row mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor="username" className='mb-1'>Name</label>
                                        <Controller
                                            name='NewModelName'
                                            placeholder="Name"
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <>  
                                                    <InputText id="username" value={field.value} 
                                                    className={classNames('w-full')} 
                                                    onChange={(e) => field.onChange(e.target.value)} />  
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className='form-group'>
                                        <label htmlFor="newactionfields" className='mb-1'>Add new action fields</label>
                                        <Controller
                                            name='newactions'
                                            control={control}
                                            render={({field, fieldState})=> (
                                                <>
                                                    <MultiSelect id={field.name} 
                                                    value={field.value} 
                                                    options={actionsType} onChange={(e) => field.onChange(e.value)} 
                                                    optionLabel="label" placeholder="Action Fields" 
                                                    className="w-full" />
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-sm-12 mt-2'>
                                    <ButtonToolbar className="justify-content-center ms-auto" >

                                        {props.isEdit==true ? displayDelete() : displayCancel() }

                                        <Button
                                            style={{ backgroundColor: "#4ce1b6" }}
                                            outline
                                            type="submit">Save</Button>
                                    </ButtonToolbar>
                                   
                                </div>
                            </form>
                        </div>
                    </Card>
                </ModalBody>
            </Dialog> 
        </>
    )
}
export default newModal;