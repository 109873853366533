/* eslint-disable jsx-a11y/label-has-for */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

class FileInputField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.shape({
        name: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    value: null,
  };

  render() {
    const { onChange, name, value, className, accept, label, style } = this.props;
    let displayName = value.name ?
      value.name : this.props.defaultValue ? this.props.defaultValue : name == 'projectFile' ?
        'default_project_logo.png' : name == 'loginSideImage' ?
          'default_LoginSideImage.webp' : name == 'favicon' ?
            'default_fav_icon.png' : "";
    return (
      <div >

        {/* TODO turn back label */}
        <span  >
          {label && <label className="p-button p-fileupload-choose p-component p-button-text-icon-left d-block" htmlFor={name}>
            {displayName ? <span className="p-button-text-icon-left pi pi-upload">{" " + displayName}</span> :
              <span className="p-button-text-icon-left pi pi-plus">{" " + label}</span>}</label>}

        </span>
        <input
          type="file"
          name={name}
          id={name}
          onChange={
            (e) => {
              e.preventDefault();
              // convert files to an array
              const files = [...e.target.files];
              if (files && files.length) {
                onChange({ file: files[0], name: files[0].name });
              }
            }
          }
          className={className}
          style={{ height: 39, padding: 3 }}
          accept={accept}
        />
      </div >
    );
  }
}

const renderFileInputField = (props) => {

  const { input, meta, className, accept, label, style, } = props;
  // console.log("inputtvalueeeee", input)
  return (
    <div className="form__form-group-input-wrap">
      <FileInputField {...input}
        className={className}
        style={style} label={label} accept={accept}
        value={input.value}
        defaultValue={props.defaultValue}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderFileInputField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderFileInputField.defaultProps = {
  meta: null,
};

export default renderFileInputField;
