import React from 'react';
import { Button, Input, FormGroup, Label, Col, FormFeedback, FormText } from 'reactstrap';

// config file
export default class DefaultInput extends React.Component {
    constructor(props) {
        super(props);

    }

    handleChange = (event) => {
        if (this.props.handlerFromParent) {
            this.props.handlerFromParent(event.target.value, event.target.name);
        }
    }

    render() {

        const { input, placeholder, maxLength, type, id,
            isDisable, meta: { touched, error }, className, value, newProject } = this.props;
        return (
            <div className="form__form-group-input-wrap">
                <div className="form__form-group-field">
                    {
                        this.props.newProject == "true" ?
                            <input maxLength={maxLength}
                                {...input}
                                id={id}
                                value={this.props.defaultValue}
                                style={isDisable ? { background: 'lightgray', border: "none" } : { background: 'white' }}

                                disabled={isDisable}
                                placeholder={placeholder}
                                type={type}
                                className={className}
                            /> :
                            <input maxLength={maxLength}
                                id={id}
                                value={value}
                                style={isDisable ? { background: 'lightgray', border: "none" } : { background: 'white' }}
                                {...input}
                                disabled={isDisable}
                                placeholder={placeholder}
                                type={type}
                                className={className} />
                    }

                </div>
                {touched && error && <span className="form__form-group-error">{error}</span>}
            </div>
        );
    }
}