import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'primereact/dropdown';
// import fetchMethodRequest from '../../../config/service';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import validate from '../Form/validate';
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 5,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging
    background: isDragging ? 'grey' : 'white',
    border: '1px solid white',
    borderRadius: 10,
    width: "100%",
    // styles we need to apply on draggables
    ...draggableStyle,

});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgray',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: grid,
    overflow: 'auto',
    border: '1px solid black',
    borderRadius: 10,
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
});
let id2List = {
    droppable: 'selectTableFields',
    droppable2: 'notSelectedTableFields'
};

// const { handleSubmit } = useForm();

const GroupByModal=(props)=> {
    const [ formFields, setUpdatedFormFields ] = useState([]);
    const [ addFormFields, setUpdatedAddFormFields] = useState([]);
    const [ editFormFields, setUpdatedEditFormFields] = useState([]);
    const [ changedTableFields, setChangedTableFields ] = useState('');
    const [ selectTableFields ] = useState([]);
    const [ notSelectedTableFields ] = useState([]);
    const [orderFor, setOrderFor] = useState('Table');
    const [resetOrder, setResetOrder] =useState(false);
    const [cogVal, setCogVal] = useState(null);

    const orderOptions=[
        { name: 'Table', value: 'Table'},
        { name: 'Add Form', value: 'Add Form'},
        { name: 'Edit Form', value: 'Edit Form'},
    ]

    useEffect(()=> {
        getScreenFieldsData();
        getAddFormList();
        getEditFormList();
    },[]);

    const updateState = async (val)=> {
        setOrderFor(val.value);
        setResetOrder(true);
        setCogVal(val.value);
    }

    const handleOrderForChange = (e) => {
        if(e && e.value) {
            updateState(e);
        }
  
    };

    const changeAddFormOrder = (tabFields) => {
        let fields = props.formFields;
        tabFields.forEach((tabField, i) => {
            for (let j = 0; j < fields.length; j++) {
                if (tabField.name === fields[j].name) {
                    fields[j].addFormOrder = i + 1;
            }
          }
        });
    }

    const changeEditFormOrder = (tabFields) => {
        let fields = props.formFields;
        tabFields.forEach((tabField, i) => {
            for (let j = 0; j < fields.length; j++) {
                if (tabField.name === fields[j].name) {
                    fields[j].editFormOrder = i + 1;
            }
          }
        });
    }

    // get fields
    const getScreenFieldsData =() => {
        let fields = props.formFields;
        let tempFormFields = [];
        if (fields) {
            tempFormFields = fields;
        }
        setUpdatedFormFields(tempFormFields);
        setChangedTableFields(tempFormFields);
    }

    // Triggered on Orderchange
    const orderChange = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    // on DragEnd
    const onDragEnd = async (result) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }

        if(orderFor=='Add Form'){
            const addFormFieldsValues = orderChange(addFormFields, result.source.index, result.destination.index);
            setUpdatedAddFormFields(addFormFieldsValues)
        }
        if(orderFor == 'Edit Form'){
            const editFormFieldsValues = orderChange(editFormFields, result.source.index, result.destination.index);
            setUpdatedEditFormFields(editFormFieldsValues)
        }
        if(orderFor=='Table'){
            const tableFields = orderChange(formFields, result.source.index, result.destination.index);
            setUpdatedFormFields(tableFields);
            setChangedTableFields(tableFields);
        }

        // dropped outside the list
        if (!destination) {
            return;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
    };

    const getAddFormList = () => { //for the add form fields list
        let fields = props.formFields;
        let filtArr = fields.filter(obj => obj.displayinaddForm == true );
        let finalArr = filtArr.sort((a, b) => a.addFormOrder - b.addFormOrder);
        setUpdatedAddFormFields(finalArr)
    };
    
    const getEditFormList = () => { //for the edit form fields list
        let fields = props.formFields;
        let filtArr = fields.filter(obj => obj.displayineditForm == true);  
        let finalArr = filtArr.sort((a, b) => a.editFormOrder - b.editFormOrder);
        setUpdatedEditFormFields(finalArr)
    };

    const move = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        const result = {};
        result[droppableSource.droppableId] = sourceClone;
        result[droppableDestination.droppableId] = destClone;

        return result;
    };

    // confirm button function
    const onClickConfirm =() =>{
        if (orderFor == 'Add Form') {
            changeAddFormOrder(addFormFields); // reorders the add field forms
        }
        if (orderFor == 'Edit Form') {
            changeEditFormOrder(editFormFields);// reorders the edit field forms
        }
        props.controllerFieldsFunc(formFields, 'draggableController')
    }

    return(
        <>
            <Modal 
                className="modal-dialog-centered modal-dialog--primary modal-dialog--header" 
                isOpen={props.isOpenAddByModal} style={{ maxWidth: "30%" }}>
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button"
                            onClick={() => props.closeGroupByModal('close', null)} />
                        <b className="bold-text h5  modal__title"> {'Select Fields To Show and Reorder'} </b>
                    </ModalHeader>
                    <ModalBody className='deleteModalBody'>
                    <div>
                        <Dropdown 
                            value={cogVal}  
                            style={{ width: '100%', marginBlockEnd: '20px' }}
                            onChange={(e)=>handleOrderForChange(e)} 
                            options={orderOptions} 
                            placeholder="Order for table/add-edit form" 
                            optionLabel="name" 
                           
                        />
                        </div>
                        <form noValidate>
                            <div className='row mx-0 justify-content-center mb-3'
                            >
                                <DragDropContext onDragEnd={onDragEnd} >
                                    <div className='col-sm-12' style={{ maxHeight: "450px", overflowY: "auto" }}>
                                        <Droppable droppableId="droppable" direction="vertical" >
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                    {...provided.droppableProps}
                                                >
                                                    {orderFor=='Table' && formFields && formFields.map((item, index) => {

                                                        if (item) {
                                                            return <div className='col-sm-12 px-0 pb-2' >
                                                                <Draggable key={item.field} draggableId={item.field} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <label className='col-12 mr-0 my-0 ps-2'>{item.header}</label>
                                                                        </div>
                                                                    )}

                                                                </Draggable>
                                                            </div>
                                                        }
                                                    })}
                                                {resetOrder && orderFor == 'Add Form' && addFormFields.map((item, index) => {
                                                        
                                                        if (item) {
                                                            return <div className='col-sm-12 px-0 pb-2' >
                                                                <Draggable key={item.field} draggableId={item.field} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <label className='col-12 mr-0 my-0 pl-2'>{item.header}</label>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </div>
                                                        }
                                                    })
                                                    }
                                                {resetOrder && orderFor == 'Edit Form' && editFormFields.map((item, index) => {

                                                        if (item) {
                                                            return <div className='col-sm-12 px-0 pb-2' >
                                                                <Draggable key={item.field} draggableId={item.field} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={getItemStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}
                                                                        >
                                                                            <label className='col-12 mr-0 my-0 pl-2'>{item.header}</label>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </div>
                                                        }
                                                    })
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>

                                </DragDropContext>

                            </div>
                            <div className='col-sm-12 text-center'>
                                <Button
                                    color="primary"
                                    outline
                                    type="button"
                                    onClick={() => props.closeGroupByModal('close', null)}
                                    className='deleteModalBtn marginRight mb-0'
                                >
                                    {'Cancel'}
                                </Button>
                                <Button
                                    color='primary'
                                    outline
                                    type="button"
                                    className='deleteModalBtn mb-0'
                                    onClick={ ()=> onClickConfirm() }
                                >
                                    {'Confirm'}
                                </Button>
                            </div>
                        </form>
                    </ModalBody>
            </Modal>
        </>
    )

}

export default GroupByModal;