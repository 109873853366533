import React from "react";
import configImages from "../../config/configImages";
import RegisterForm from "./components/Register";
// import LogoUI from "../LogoUI/logoUI"
const Register = () => (
 
  <div className='account_login'>    
    <div className='login-container'>      
      <div className="card shadow bg-white register-card p-3">
        <div className="card-body">
          <div className='justifyContentLogin'>
            <div className='vertical_middle'>
              <div className='text-center '>
                <h3> <b>Nocode</b> </h3>
                <h4><b>Create Account</b></h4>
              </div>
              <div className='pt-4'>
              <RegisterForm onSubmit />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default Register;
