import React, { useState, useEffect, useRef } from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';


const validationModal = (props)=> {
    const [showDialog, hideDailog] = useState(props.visible);

    const [minLengthRequired, setMinLengthRequired] = useState(false);
    const [maxLengthRequired, setMaxLengthRequired] = useState(false);    
    const [alphabetsRequired, setAlphabetsRequired] = useState(false);
    const [numbersRequired, setNumbersRequired] = useState(false);
    const [specialCharRequired, setSpecialCharRequired] = useState(false);

    const [maxLengthGreaterValidate, setMaxLengthGreaterValidate] = useState(false); 
    const [mainValidation, setMainValidation] = useState(false);

    const [minLengthText, chMinLengthText] = useState(null);
    const [maxLengthText, chMaxLengthText] = useState(null);
    const [specialCharText, chspecialCharText] = useState('');

    useEffect(()=> {
        if (props && props.type == "edit") {
            let data = props.regexData;
            if(data) {
                // Min Length
                setMinLengthRequired(data.minLengthRequired);
                chMinLengthText( data.minLengthText);
                // Max length
                setMaxLengthRequired(data.maxLengthRequired);
                chMaxLengthText(data.maxLengthText);
                // alphabets && numbers
                setAlphabetsRequired(data.alphabetsRequired);
                setNumbersRequired(data.numbersRequired);
                // Special chars
                setSpecialCharRequired(data.specialCharRequired);
                chspecialCharText(data.specialCharText);
            }
        }
    },[props]);

    const propsClose = ()=> {
        hideDailog(false);
        props.closetheModal();
    }

    const submitValidations = () => {
        if (minLengthRequired || maxLengthRequired || alphabetsRequired || numbersRequired || specialCharRequired) {
            if ((minLengthRequired == true && minLengthText == null) || (maxLengthRequired == true && maxLengthText == null) || (specialCharRequired == true && (specialCharText == null || specialCharText == ''))) {
                setMainValidation(true)
            }
            else if ((maxLengthText <= minLengthText) && maxLengthText != null) {
                setMaxLengthGreaterValidate(true)
            }
            else {
                let regex1 = "^[AlphabetsNumbersSpecialChars]{MinLenth,MaxLenth}$";
                let regex2 = "^[AlphabetsNumbersSpecialChars]$";
                let regex3 = "^.{MinLenth,MaxLenth}$";
                let regValue = "";
                if (!minLengthRequired && !maxLengthRequired) {
                    regValue = regex2;
                } else {
                    regValue = regex1;
                    regValue = minLengthRequired ? regValue.replace("MinLenth", minLengthText) : regValue.replace("MinLenth", 0);
                    regValue = maxLengthRequired ? regValue.replace("MaxLenth", maxLengthText) : regValue.replace("MaxLenth", 10000);
                }

                if (alphabetsRequired || numbersRequired || specialCharRequired) {
                    regValue = alphabetsRequired ? regValue.replace("Alphabets", "a-zA-Z") : regValue.replace("Alphabets", "");
                    regValue = numbersRequired ? regValue.replace("Numbers", "0-9") : regValue.replace("Numbers", "");
                    regValue = specialCharRequired && specialCharText ? regValue.replace("SpecialChars", specialCharText) : regValue.replace("SpecialChars", "");

                } else {
                    regValue = regex3;
                    regValue = minLengthRequired ? regValue.replace("MinLenth", minLengthText) : regValue.replace("MinLenth", 0);
                    regValue = maxLengthRequired ? regValue.replace("MaxLenth", maxLengthText) : regValue.replace("MaxLenth", 10000);
                }
                let values = {
                    "minLengthRequired": minLengthRequired,
                    "minLengthText": minLengthText,
                    "maxLengthRequired": maxLengthRequired,
                    "maxLengthText": maxLengthText,
                    "alphabetsRequired": alphabetsRequired,
                    "numbersRequired": numbersRequired,
                    "specialCharRequired": specialCharRequired,
                    "specialCharText": specialCharText
                }
                props.setRegexValue(regValue, values)
                propsClose();
            }
        }
        else {
            props.setRegexValue(null, {})
            propsClose();
        }
    }

    const removeValidation = () => {
        props.setRegexValue(null, {})
        propsClose();
    }

    return(
        <>
            <Dialog header="Field Validation" visible={showDialog} onHide={() => propsClose()}
                style={{ width: '30vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
                <form noValidate autoComplete='off'>
                    <div className='row align-items-center mb-2 mt-3'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="minlenreq" name="minLengthRequired"
                                    onChange={(e) => setMinLengthRequired(e.checked)} checked={minLengthRequired === true} />
                                    <label htmlFor="minlenreq" className="ms-2">Min Length</label>
                                </div>
                            </div>
                        </div>
                        {  minLengthRequired &&
                            <>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                    <InputNumber className="p-inputtext-sm" value={minLengthText} onChange={(e) => chMinLengthText(e.value)} min={0} placeholder='Min length' />
                                    <span className='validate-text-danger'>{mainValidation && minLengthText == null ? "Add a min length" : ''}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='row align-items-center mb-2'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="maxlenreq" name="maxLengthRequired" 
                                    onChange={(e) => setMaxLengthRequired(e.checked)} checked={maxLengthRequired === true} />
                                    <label htmlFor="maxlenreq" className="ms-2">Max Length</label>
                                </div>
                            </div>
                        </div>
                        {  maxLengthRequired &&
                            <>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                    <InputNumber className="p-inputtext-sm" value={maxLengthText} onChange={(e) => chMaxLengthText(e.value)} min={0} placeholder='Max length' />
                                    <span className='validate-text-danger'>{mainValidation && maxLengthText == null ? "Add a max length" : ''}</span>
                                    <span className='validate-text-danger'>{(maxLengthGreaterValidate && (maxLengthText <= minLengthText)) || ( maxLengthText != null &&(maxLengthText <= minLengthText))
                                        ? "max length must be greater than min length" : ''} </span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className='row align-items-center mb-2'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="alphabets" name="alphabetsRequired" 
                                    onChange={(e) => setAlphabetsRequired(e.checked)} checked={alphabetsRequired === true} />
                                    <label htmlFor="alphabets" className="ms-2">Alphabets</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center mb-2'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="numbers" name="numbersRequired" 
                                    onChange={(e) => setNumbersRequired(e.checked)} checked={numbersRequired === true} />
                                    <label htmlFor="numbers" className="ms-2">Numbers</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row align-items-center mb-2'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <div className="flex align-items-center">
                                    <Checkbox inputId="specChars" name="specialCharRequired" 
                                    onChange={(e) => setSpecialCharRequired(e.checked)} checked={specialCharRequired === true} />
                                    <label htmlFor="specChars" className="ms-2">Special Characters</label>
                                </div>
                            </div>
                        </div>
                        {  specialCharRequired &&
                            <>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                    <InputText className="p-inputtext-sm w-100" value={specialCharText} onChange={(e) => chspecialCharText(e.target.value)} placeholder='Special Characters'/>
                                    <span className='validate-text-danger'>{mainValidation && (specialCharText == null || specialCharText == '') ? "Add a special character" : ''}</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <div className='col-sm-12 mt-4'>
                            <ButtonToolbar className="justify-content-center" >
                                < Button
                                    color='primary'
                                    type="button"
                                    className='btn btn-success mb-0'
                                    onClick={() => propsClose()}
                                >
                                    Cancel
                                </Button>
                                < Button
                                    color='primary'
                                    type="button"
                                    className='btn btn-success mb-0'
                                    onClick={() => removeValidation()}
                                >
                                    Remove Validation
                                </Button>
                                <Button
                                    color='primary'
                                    className='btn btn-success mb-0'
                                    type="button"
                                    onClick={() => submitValidations()}
                                >
                                    {'Save'}
                                </Button>

                            </ButtonToolbar>
                        </div>
                </form>
            </Dialog>
        </>
    )
}
export default validationModal;