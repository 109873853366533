import React from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Components
import DefaultInput from "../../../shared/components/form/DefaultInput";
import RadioInput from "../../../shared/components/form/RadioButton";
import SelectDropdown from "../../../shared/components/form/Select";
import MultiSelect from "../../../shared/components/form/MultiSelectDropDown";
import Select from "react-select";
import { Row, Col, Collapse } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import config from '../../../config/config';
import FileInput from '../../../shared/components/form/FileInput'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          height: 47,
          top: -2,
        },
        "&$focused $notchedOutline": {
          borderColor: config.floatingFieldsColor,
        },
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: config.floatingFieldsColor,
          fontWeight: '500'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$contained": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        "&$marginDense": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        margin: 0,
        marginTop: 0,
      }
    },
  }
});

class ServerNewProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailService: "no",
      mailServices: [
        { value: 1, label: "Node Mailer" },
        { value: 2, label: "Send Grid" },
      ],
      selectedMailService: null,
      controllers: [],
      controllerFields: [],
      controllerCol: false,
      emailServiceCol: false,
      selectedControllerIndex: 0,
      selectedControllerCheckBoxIndex: -1,
      typeOptions: [
        { value: "String", label: "String" },
        { value: "Boolean", label: "Boolean" },
        { value: "Number", label: "Number" },
      ],
      actionsType: [
        { value: "get", label: "get" },
        { value: "list", label: "list" },
        { value: "update", label: "update" },
        { value: "create", label: "create" },
        { value: "remove", label: "remove" },

      ],
      showControllers: false,
      tabOneActive: true,
      tabTwoActive: false,
      tabThreeActive: false,
      activeOneTab: true,
      activeTwoTab: false,
      activeThreeTab: false,
      setMainTab: 'one',
      sampleFileJson: 'http://nocode.api.dosystemsinc.com/json/serverJson',
      showSampleFile: false,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }

  //empty controller
  emptyController = async () => {
    await this.setState({
      controllers: [],
      controllerFields: [],
      emailService: '',
    })
  }

  //handle select
  handleSelect = (selectedMailService) => {
    this.setState({
      selectedMailService: selectedMailService,
      emailServiceCol: true, controllerCol: true
    });
  };

  //handle select
  handleSelectWithUpload = async (selectedMailService) => {
    this.setState({
      selectedMailService: selectedMailService,
      emailServiceCol: true, controllerCol: true,
      tabThreeActive: true,
      activeThreeTab: true, setMainTab: 'three'
    });
    await this.props.changeprogressWidth(100)
  }

  //add controllers
  addControllers = async (type) => {
    if (type === 'controller') {
      let controllers = this.state.controllers;
      controllers.push({
        controllerFields: [],
      });
      await this.setState({
        controllers: controllers,
        emailServiceCol: false,
        controllerCol: true,
        tabOneActive: false,
        tabTwoActive: true,
        activeTwoTab: true,
        setMainTab: 'two',
        activeOneTab: false,
        selectedControllerIndex: controllers.length && controllers.length !== 1 ?
          controllers.length - 1 : 0
      });
      await this.props.changeprogressWidth(60)
    } else if (type === 'fields') {
      let { selectedControllerIndex, controllers } = this.state;
      let controllerData = controllers;
      let controllerFields =
        controllers[selectedControllerIndex].controllerFields;
      let controllerFieldsLength = controllerFields.length;
      controllerFields.push({
        fieldName: `controller_${selectedControllerIndex +
          1}.fieldName${controllerFieldsLength + 1}`,
        type: `controller_${selectedControllerIndex +
          1}.type${controllerFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFields = controllerFields;
      this.setState({
        controllers: controllerData,
        tabThreeActive: true,
        tabTwoActive: true,
        activeThreeTab: true,
        setMainTab: 'three'
      });
      await this.props.changeprogressWidth(80)
    }
  };

  //change controller name
  changeContrllerName = async (e, selectedControllerIndex) => {
    let controllers = this.state.controllers;
    if (e && e.target.value) {
      controllers[selectedControllerIndex].name = e.target.value
      await this.setState({ controllers: this.state.controllers })
    } else {
      let i = selectedControllerIndex;
      i += 1;
      controllers[selectedControllerIndex].name = ''
      await this.setState({ controllers: this.state.controllers })
    }
  }

  //remove controller
  removeController = async (index, type) => {
    if (type === 'controller') {
      let controllers = this.state.controllers;
      controllers.splice(index, 1);
      if (index === 0) {
        await this.setState({
          controllers: controllers,
          selectedControllerIndex: 0
        })
      } else {
        await this.setState({
          controllers: controllers,
          selectedControllerIndex: index - 1
        })
      }
    } else if (type === 'fields') {
      let controllers = this.state.controllers;
      controllers[this.state.selectedControllerIndex]["controllerFields"].splice(index, 1);
      await this.setState({
        controllers: controllers,
      })
    }
  }

  //handleControllerTableFields
  handleControllerFields = async (e, index, fieldName, type, name) => {
    let { selectedControllerIndex, controllers } = this.state;
    if (type === 'radio') {
      if (e == false) {
        delete controllers[selectedControllerIndex]['entity'];
        delete controllers[selectedControllerIndex]['entityValue'];
      }
      controllers[selectedControllerIndex][fieldName] = e;
    } else if (type === 'entity') {
      if (e && e.target.value) {
        controllers[selectedControllerIndex][fieldName] = e.target.value;
        controllers[selectedControllerIndex][name] = e.target.value;
      } else {
        controllers[selectedControllerIndex][fieldName] = '';
        controllers[selectedControllerIndex][name] = '';
      }
    } else if (type === 'input') {
      if (e && e.target.value) {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e.target.value;
        controllers[selectedControllerIndex]["controllerFields"][index][name] = e.target.value;
      } else {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = '';
        controllers[selectedControllerIndex]["controllerFields"][index][name] = '';
      }
    } else if (type === 'checkbox') {
      controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e;
      controllers[selectedControllerIndex]["controllerFields"][index][name] = e;
    }
    await this.setState({ controllers: controllers })
  }

  //returnController
  returnControllerArray() {
    return this.state.controllers;
  }

  //handleactions
  handleControllerActions = async (e, fieldName) => {
    let controllers = this.state.controllers;
    if (fieldName === 'methods') {
      if (e) {
        controllers[this.state.selectedControllerIndex][fieldName] = e

      } else {
        controllers[this.state.selectedControllerIndex][fieldName] = e
      }
    }
    await this.setState({ controllers: controllers })
  }


  handleTabs = async (type) => {
    if (type === 'one') {
      await this.setState({
        setMainTab: 'one',
        tabOneActive: true,
        tabTwoActive: false,
        tabThreeActive: false
      })
    } else if (type === 'two') {
      await this.setState({
        setMainTab: 'two',
        tabOneActive: false,
        tabTwoActive: true,
        tabThreeActive: false
      })
    } else if (type === 'three') {
      await this.setState({
        setMainTab: 'three',
        tabOneActive: false,
        tabTwoActive: true,
        tabThreeActive: true
      })
    }
  }

  //handle showControlers 
  handleShowControllers = async () => {
    await this.setState({ showControllers: true })
  }
  //toggleUploadSample
  toggleUploadSample = async () => {
    await this.setState({ showSampleFile: !this.state.showSampleFile })
    if (this.props.yesJsonUploaded) {
      this.handleShowControllers();
    }
  }

  //handleyesExistingProject
  handleExistingProject = (e) => {
    if (e && e.target.value) {
      this.setState({ showControllers: true })
      this.props.handleyesExistingProject(true);
    } else {
      this.setState({ showControllers: false })
      this.props.handleyesExistingProject(false);
    }
  }

  render() {

    const {
      emailService,
      emailServiceCol,
      mailServices,
      selectedMailService,
      controllers,
      controllerCol,
      selectedControllerIndex,
      typeOptions,
      actionsType,
      tabOneActive,
      tabTwoActive,
      tabThreeActive,
      activeOneTab,
      activeThreeTab,
      activeTwoTab,
      setMainTab,
      showSampleFile,
    } = this.state;
    let controllerFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFields"] &&
        controllers[selectedControllerIndex]["controllerFields"].length
        ? controllers[selectedControllerIndex]["controllerFields"]
        : [];
    return (
      <Row>
        <Col sm={12}>
          <div>
            <h4 className='ps-2 addIcon pb-2 float-right'>
              < FontAwesomeIcon
                className='editIcon text-info'
                color='#ff00008c'
                icon='upload'
                data-toggle="tool-tip"
                title="Upload Json File"
                onClick={this.toggleUploadSample}
              />
            </h4>
            <Collapse isOpen={showSampleFile}>

              <h5 className='pt-3 pb-3'>Sample File <a href={this.state.sampleFileJson} download target="_blank" >
                <span className='ps-2 addIcon'>
                  < FontAwesomeIcon
                    className='editIcon text-info'
                    color='#ff00008c'
                    icon='download'
                    data-toggle="tool-tip"
                    title="Sample File"
                  />
                </span>
              </a> </h5>
              <Field
                name="sererJsonFile"
                component={FileInput}
                label={'Upload Json File'}
                type="text"
                placeholder="eg:var/www/html"
                className="form-control d-none"
                accept={'.json'}
                onChange={this.props.jsonUploadFile}
              />
              <h5 className='text-secondary mb-3 mt-2'>
                Note: If you upload JSON file please fill the fields which displays below.</h5>
              <hr></hr>
            </Collapse>
            {showSampleFile && <h4 className='text-center'> OR</h4>}
          </div>
          <div className='py-3'>
            <div className='col-12  col-lg-12 d-flex tabCenter'>
              <div className={setMainTab == 'one' ? 'tabRootFirst' : 'setMainTabFirst'} role='button' onClick={() => this.handleTabs('one')}>
                <h4 className='text-white'>
                  1
                    </h4>
              </div>
              <div className="progress progressCustom">
                <div className="progress-bar progresBarClr" role="progressbar"
                  style={this.state.showControllers ? { width: '100%' } : { width: '0' }}
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className={this.state.showControllers &&
                activeTwoTab ? (setMainTab == 'two' ? 'tabRootFirst' : 'setMainTabFirst') : ' bg-inactiveTab'} role='button'
                onClick={() => this.state.showControllers && activeTwoTab && this.handleTabs('two')}>
                <h4 className='text-white'>
                  2
                    </h4>
              </div>
              <div className="progress progressCustom">
                <div className="progress-bar progresBarClr" role="progressbar"
                  style={
                    this.state.showControllers &&
                      activeThreeTab ? { width: '100%' } : { width: '0' }}
                  aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div className={this.state.showControllers &&
                activeThreeTab ? (setMainTab == 'three' ? 'tabRootFirst' : 'setMainTabFirst') : ' bg-inactiveTab'} role='button'
                onClick={() => this.state.showControllers && activeThreeTab && this.handleTabs('three')}>
                <h4 className='text-white'>
                  3
                    </h4>
              </div>
            </div>
          </div>
        </Col>
        <Col sm={12}>
          {tabOneActive && !this.props.yesJsonUploaded && <div className='pt-3'>
            <label>May I have your Project Name?</label>
            <div>
              <div className='row '>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  <Field
                    name="projectName"
                    component={DefaultInput}
                    type="text"
                    placeholder="Project Name"
                    className="form-control"
                    onChange={(e) => this.handleExistingProject(e)}
                  />
                </div>
              </div>
            </div>
          </div>}
          <div>
            {this.state.showControllers && !this.props.yesJsonUploaded && (tabOneActive || tabTwoActive) &&
              <div className='d-lg-flex d-md-flex d-sm-block d-block pt-3'>
                <label className='labelMargin headingsFontC '>Add Controllers Data
               <span className='ps-3 addIcon'>
                    < FontAwesomeIcon
                      className='editIcon text-info'
                      color='#ff00008c'
                      icon='plus-circle'
                      data-toggle="tool-tip"
                      title="Add"
                      onClick={(e) => this.addControllers('controller')}
                    />
                  </span>
                </label>
              </div>}
            {tabTwoActive &&
              <div className='row ps-2'>
                {!this.props.yesJsonUploaded && controllers && controllers.length
                  ? controllers.map((item, index) => {
                    return (
                      <div
                        className={selectedControllerIndex == index ?
                          'controller_labelS text-info col-lg-4 col-md-6 col-sm-12 col-12'
                          : 'controller_labelS col-lg-4 col-md-6 col-sm-12 col-12 '}
                        key={index}
                      >
                        <div className={selectedControllerIndex == index ?
                          'text-info  controller_labelS borderContollerColor'
                          : 'controller_labelS'}>
                          <span
                            style={{ textTransform: 'capitalize' }}
                            onClick={(e) =>
                              this.setState({
                                selectedControllerIndex: index,
                                controllerCol: true,
                                emailServiceCol: false
                              })
                            }>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                autoFocus={true}
                                margin="dense"
                                variant="outlined"
                                id="custom-css-outlined-input"
                                size='small'
                                type="text"
                                helperText={'Please Enter Singular Values, Ex:employee'}
                                className={selectedControllerIndex == index ? 'float-left text-info textFieldsWidth'
                                  : 'float-left textFieldsWidth'}
                                style={{ textTransform: 'capitalize' }}
                                name={`controller_${index + 1}.name`}
                                label={`Controller${index + 1} Name`}
                                onChange={(e) => {
                                  this.changeContrllerName(e, selectedControllerIndex)
                                }}
                                value={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                                defaultValue={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                              />
                            </MuiThemeProvider>
                            <span className='text-white'>
                              {`controller_${index + 1}.name`}
                            </span>
                          </span>
                          <span className='float-right iconSize controllerDelteIConPosNode'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeController(index, 'controller')}
                          /></span>
                        </div>
                      </div>
                    );
                  })
                  : null}
              </div>}
          </div>

        </Col>
        {!this.props.yesJsonUploaded && this.state.showControllers && <Col
          sm={{ size: 12 }}
          className={controllers && controllers.length && controllerCol ? "" : "d-none"}
        >
          <h3 className='fieldsHeading pt-3'>
            <span className='float-left' style={{ textTransform: 'capitalize' }}>
              {controllers && controllers.length && controllers[selectedControllerIndex][`name`] ? controllers[selectedControllerIndex][`name`] : `
              Controller${selectedControllerIndex + 1}`} Fields
            </span>
          </h3>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-4 pt-3 ps-3'>
              <div className="form__form-group">
                <Field
                  name={`controller_${selectedControllerIndex}.act`}
                  component={MultiSelect}
                  type="text"
                  placeholder="Actions"
                  className="form-control"
                  valueMulti={controllers && controllers.length > 0 && controllers[selectedControllerIndex]['methods'] && controllers[selectedControllerIndex]['methods']}
                  options={actionsType}
                  onChange={(e) => { e ? this.handleControllerActions(e, 'methods') : null }}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <label>Authentication Required</label>
              <div>
                <Field
                  name={`controller_${selectedControllerIndex + 1}.isAuth`}
                  component={RadioInput}
                  label={"Yes"}
                  radioValue={true}
                  onClick={(e) => this.handleControllerFields(true, selectedControllerIndex, `isAuth`, 'radio', "isAuthValue")}
                />
                <Field
                  name={`controller_${selectedControllerIndex + 1}.isAuth`}
                  component={RadioInput}
                  label={"No"}
                  radioValue={false}
                  onClick={(e) => this.handleControllerFields(false, selectedControllerIndex, `isAuth`, 'radio', "isAuthValue")}
                />
              </div>
            </div>
          </div>
          <div className='d-lg-flex d-md-flex d-sm-block d-block '>
            <label className='labelMargin headingsFontC '>Add New Fields
            <span className='ps-3 addIcon'>
                < FontAwesomeIcon
                  className='editIcon text-info'
                  color='#ff00008c'
                  icon='plus-circle'
                  data-toggle="tool-tip"
                  title="Add"
                  onClick={(e) => this.addControllers('fields')}
                />
              </span>
            </label>
          </div>
          <div className="row pt-3">
            {controllerFieldsArray && controllerFieldsArray.length
              ? controllerFieldsArray.map((item, index) => {
                return (
                  <div key={index} className='col-lg-4 col-md-6 col-sm-12'>
                    <div className="pb-3">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          type="text"
                          className='textFieldsWidth'
                          margin="dense"
                          name={item.fieldName}
                          label="Field Name"
                          variant="outlined"
                          id="custom-css-outlined-input"
                          size='small'
                          defaultValue={item[`fieldvalue`] ? item[`fieldvalue`] : ''}
                          value={item[`fieldvalue`] ? item[`fieldvalue`] : ''}
                          onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', "fieldvalue")}
                        />
                      </MuiThemeProvider>
                    </div>
                    <div className=" pb-3 pt-3">
                      <Field
                        name={item.type}
                        component={SelectDropdown}
                        type="text"
                        placeholder="Field Type"
                        className="form-control"
                        options={typeOptions}
                        valueExists={{ label: item[`fieldType`], value: item[`fieldType`] }}
                        onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', "fieldType")}
                      />
                      {controllerFieldsArray && controllerFieldsArray.length > 0 ? <span>
                        <FontAwesomeIcon
                          className='editIconField'
                          color='#ff00008c'
                          icon='trash-alt'
                          data-toggle="tool-tip"
                          title="Delete"
                          onClick={() => this.removeController(index, 'fields')}
                        />
                      </span> : null}
                    </div>
                  </div>
                );
              })
              : null}
          </div>
          {tabThreeActive && !this.props.yesJsonUploaded ? <div className='py-3 ps-2'>
            <label>Do you like to have Email Service?</label>
            <div>
              <Field
                name="emailService"
                component={RadioInput}
                label={"Yes"}
                radioValue={"yes"}
                onChange={(e) => this.setState({ emailService: e })}
              />
              <Field
                name="emailService"
                component={RadioInput}
                label={"No"}
                radioValue={"no"}
                onClick={() => this.props.changeprogressWidth(100)}
                onChange={(e) => this.setState({
                  emailService: e,
                  emailServiceCol: false,
                  controllerCol: true,
                  selectedMailService: '',
                })}
              />
            </div>
          </div> : null}
          {emailService == "yes" ? (
            <div className='py-3 ps-2'>
              <label>Which type of Mail Service Do you want</label>
              <div>
                <div className='row'>
                  <div className='col-sm-12 col-md-6 col-lg-4'>
                    <Select
                      value={selectedMailService}
                      onChange={this.handleSelect}
                      options={mailServices}
                      className="react-select"
                      placeholder="Mail Service"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </Col>}
        {this.props.yesJsonUploaded && this.state.showControllers &&
          <Col sm={12}>
            <div className='py-3 ps-2'>
              <label>Do you like to have Email Service?</label>
              <div>
                <Field
                  name="emailService"
                  component={RadioInput}
                  label={"Yes"}
                  radioValue={"yes"}
                  onChange={(e) => this.setState({
                    emailService: e,
                    activeTwoTab: true, setMainTab: 'two',
                  })}
                />
                <Field
                  name="emailService"
                  component={RadioInput}
                  label={"No"}
                  radioValue={"no"}
                  onClick={() => this.props.changeprogressWidth(100)}
                  onChange={(e) => this.setState({
                    emailService: e,
                    emailServiceCol: false,
                    controllerCol: true,
                    selectedMailService: '',
                    activeTwoTab: true, setMainTab: 'two', tabThreeActive: true,
                    activeThreeTab: true, setMainTab: 'three'
                  })}
                />
              </div>
            </div>
            {emailService == "yes" ? (
              <div className='py-3 ps-2'>
                <label>Which type of Mail Service Do you want</label>
                <div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-4'>
                      <Select
                        value={selectedMailService}
                        onChange={this.handleSelectWithUpload}
                        options={mailServices}
                        className="react-select"
                        placeholder="Mail Service"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </Col>
        }
        <Col
          sm={12} md={6} lg={4}
          className={
            selectedMailService &&
              selectedMailService.value == 1 &&
              emailService == "yes"
              ? ""
              : "d-none"
          }
        >

          <h3 className='py-3'>Mail Service Details</h3>
          <div>

            <label>Your Node Mailer Service</label>
            <div>
              <Field
                name="nodeMailerService"
                component={DefaultInput}
                type="text"
                placeholder="Mail Service"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Host</label>
            <div>
              <Field
                name="nodeMailerHost"
                component={DefaultInput}
                type="text"
                placeholder="Host"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Port</label>
            <div>
              <Field
                name="nodeMailerPort"
                component={DefaultInput}
                type="text"
                placeholder="Port"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Email</label>
            <div>
              <Field
                name="nodeMailerEmail"
                component={DefaultInput}
                type="text"
                placeholder="Mail"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label className='labelMargin'>Your Node Mailer Password</label>
            <div>
              <Field
                name="nodeMailerPassword"
                component={DefaultInput}
                type="password"
                placeholder="Password"
                className="form-control"
              />
            </div>
          </div>
        </Col>
        <Col
          sm={12} md={6} lg={4}
          className={
            selectedMailService &&
              selectedMailService.value == 2 && emailServiceCol &&
              emailService == "yes"
              ? ""
              : "d-none"
          }
        >
          <h3 className='pt-3'>Send Grid Details</h3>
          <div>
            <label>Your Send Grid Email</label>
            <div>
              <Field
                name="sendGridMail"
                component={DefaultInput}
                type="text"
                placeholder="Mail Service"
                className="form-control"
              />
            </div>
          </div>
          <div>
            <label>Your Send Grid API key</label>
            <div>
              <Field
                name="sendGridApiKey"
                component={DefaultInput}
                type="text"
                placeholder="Host"
                className="form-control"
              />
            </div>
          </div>
        </Col>
      </Row >
    );
  }
}

export default ServerNewProject;
