const validate = (values) => {
  const errors = {};
  let fields = ['firstName', "name", "newUIName","uniqueIdLength", "selectedAuthScreen", "screenName", "selectedDuplicateScreen", "createAScreenFrom", "selectedDuplicateScreenName", "NewModelName", "NewScreenWithOutServerName", "width", "controllerName", "controllerId", "displayFields", "populteFields", "searchField", "dateFormat", "fieldName", "description", 'ifsc', 'lastName', 'dateOfBirth', 'joinDate', 'phoneNumber', 'skypeId', 'bitbuketId', 'laptopSNO', 'pancardNumber', 'aadharcardNumber', 'phone', 'email','addFormOrder','editFormOrder'];

  fields.forEach((field) => {
    if (!values[field]) {
      let fieldName = capitalize(field);
      errors[field] = `${fieldName} field shouldn’t be empty`;
    }
  });

  if (!values.addFormOrder) {
    errors['addFormOrder'] = `Add Form Order field shouldn’t be empty`;
  } else if (values.addFormOrder < 1) {
    errors['addFormOrder'] = `Order must be greater than or equal to 1`;
  }

  if (!values.editFormOrder) {
    errors['editFormOrder'] = `Edit Form Order field shouldn’t be empty`;
  } else if (values.editFormOrder < 1) {
    errors['editFormOrder'] = `Order must be greater than or equal to 1`;
  }

  if (!values.email) {
    errors.email = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.derivedValue) {
    errors.derivedValue = 'field shouldn’t be empty';
  } else if (!/^[A-Za-z0-9\+\-\*\/\(\)\<\>\ ]*$/.test(values.derivedValue)) {
    errors.derivedValue = 'Only +,-,*,/,(,),<,> is allowed';
  }


  if (!values.ifsc) {
    errors.ifsc = 'IFSC shouldn’t be empty';
  } else if (!/^[A-Za-z]{4}\d{7}$/i.test(values.ifsc)) {
    errors.ifsc = 'Invalid Ifsc Code ';
  }
  if (!values.fieldName) {
    errors["fieldName"] = `Field Name field shouldn’t be empty`;
  } else if (!/^[a-zA-Z0-9_]{1,64}$/.test(values.fieldName)) {
    errors["fieldName"] = `Field Name field must contain alphabets ,number and _`;
  }

  if (!values.width) {
    errors["width"] = `Width field shouldn’t be empty`;
  } else if (!/^[0-9%px]{1,6}$/.test(values.width)) {
    errors["width"] = `Width field must contain numbers followed by "px" or "%"`;
  }

  if (!values.NewScreenWithOutServerName) {
    errors["NewScreenWithOutServerName"] = `Field Name field shouldn’t be empty`;
  } else if (!/^[a-zA-Z0-9]{1,64}$/.test(values.NewScreenWithOutServerName)) {
    errors["NewScreenWithOutServerName"] = `New Screen Name field must contain alphabets & numbers`;
  }

  if (!values.screenName) {
    errors["screenName"] = `Field Name field shouldn’t be empty`;
  } else if (!/^[a-zA-Z0-9 ]{1,64}$/.test(values.screenName)) {
    errors["screenName"] = `Field Name field must contain alphabets & numbers`;
  }
  if (!values.selectedDuplicateScreenName) {
    errors["selectedDuplicateScreenName"] = `Field Name field shouldn’t be empty`;
  } else if (!/^[a-zA-Z0-9]{1,64}$/.test(values.selectedDuplicateScreenName)) {
    errors["selectedDuplicateScreenName"] = `Field Name field must contain alphabets & numbers`;
  }

  if (!values.NewModelName) {
    errors["NewModelName"] = `Field Name field shouldn’t be empty`;
  } else if (!/^[a-zA-Z]{1,64}$/.test(values.NewModelName)) {
    errors["NewModelName"] = `Field Name field must contain alphabets`;
  }

  if (!values.phone) {
    errors.phone = 'Please Enter the Valid Phone Number';
  } else if (!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(values.phone)) {
    errors.phone = 'Invalid Phone Number';
  }
  if (!values.companyEmail) {
    errors.companyEmail = 'Email field shouldn’t be empty';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.companyEmail = 'Invalid email address';
  }
  var passwordRegexp = /^(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/;
  if (!values.password) {
    errors.password = 'Password field shouldn’t be empty';
  }
  else if (!passwordRegexp.test(values.password)) {
    errors.password = 'Your password must contain 3 of the following: one upper case, one lower case, one numeric, or one special character.';
  }

  if (!values.select) {
    errors.select = 'Please select the option';
  }
  return errors;
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export default validate;