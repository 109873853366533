import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import '../../../scss/login.scss';

class GoogleButton extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {

        }

    }
    render() {
        return (
            <div className='d-flex google-button justify-content-center'>
                <div className='pr-2'>
                <img class="icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
                </div>
                <div className='g-text'>
                     Login With Google</div>
            </div>
        )
    }
}
export default withTranslation('common')(GoogleButton);