import React from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Collapse } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

//Components
import DefaultInput from "../../../shared/components/form/DefaultInput";
import CheckBox from '../../../shared/components/form/CheckBox';
import Select from '../../../shared/components/form/Select';
import config from '../../../config/config';
import FileInput from '../../../shared/components/form/FileInput'
import MultiSelect from '../../../shared/components/form/MultiSelectDropDown';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          height: 47,
          top: -2,
        },
        "&$focused $notchedOutline": {
          borderColor: config.floatingFieldsColor,
        },
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: config.floatingFieldsColor,
          fontWeight: '500'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$contained": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        "&$marginDense": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        margin: 0,
        marginTop: 0,
      }
    },
  }
});

class ExistingProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controllers: [],
      showFilterElement: false,
      selectdFilterIndex: 0,
      controllerFields: [],
      selectedControllerIndex: 0,
      selectedFormIndex: 0,
      typeOptions: [
        { value: "center", label: "center" },
        { value: "left", label: "left" },
        { value: "right", label: "right" },
      ],
      fieldTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "dropDown", label: "dropDown" },
        { value: "date", label: "date" },
        { value: "checkbox", label: "checkbox" },
        { value: "autoComplete", label: "autoComplete" },
        { value: "multiSelect", label: "multiSelect" },
        { value: "radio", label: "radio" },
      ],
      fieldProfileTypeOptions: [
        { value: "text", label: "text" },
        { value: "password", label: "password" },
        { value: "email", label: "email" },
        { value: "textarea", label: "textarea" },
        { value: "date", label: "date" },
        { value: "state", label: "state" },
      ],
      fieldProfileType: [
        { value: "email", label: "email" },
        { value: "fullName", label: "fullName" },
        { value: "lastName", label: "lastName" },
        { value: "dateOfBirth", label: "dateOfBirth" },
        { value: "address", label: "address" },
      ],
      actionsType: [
        { value: "add", label: "add" },
        { value: "edit", label: "edit" },
        { value: "delete", label: "delete" },
        { value: "view", label: "view" },
      ],
      selectedProfileIndex: 0,
      showProfileTypeDropdown: false,
      showControllers: false,
      apiUrlTypes: [],
      jsonDownloadLink: '#',
      sampleFileJson: 'http://nocode.api.dosystemsinc.com/json/reactJson',
      showSampleFile: false,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }

  //emptyController
  emptyController = async () => {
    await this.setState({
      controllers: [],
      controllerFields: [],
      showControllers: false,
      loginCheck: false,
      selectdFilterIndex: 0,
      selectedProfileIndex: 0,
      showProfileTypeDropdown: false,
      showFilterElement: false,
      selectedControllerIndex: 0,
      selectedFormIndex: 0,
      uploadCheck: false,
    })
  }

  //addFields
  addControllersFields = async (type, e) => {
    this.setState({ showProfileTypeDropdown: false })
    let { selectedControllerIndex, controllers } = this.state;
    let controllerData = controllers;
    if (type === 'table') {
      let controllerFields =
        controllers[selectedControllerIndex].controllerFields;
      let controllerFieldsLength = controllerFields.length;
      controllerFields.push({
        textAlign: `controller_${selectedControllerIndex +
          1}.textAlign${controllerFieldsLength + 1}`,
        width: `controller_${selectedControllerIndex +
          1}.width${controllerFieldsLength + 1}`,
        field: `controller_${selectedControllerIndex +
          1}.field${controllerFieldsLength + 1}`,
        header: `controller_${selectedControllerIndex +
          1}.header${controllerFieldsLength + 1}`,
        filter: `controller_${selectedControllerIndex +
          1}.filter${controllerFieldsLength + 1}`,
        sortable: `controller_${selectedControllerIndex +
          1}.sortable${controllerFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFields = controllerFields;
      await this.setState({
        selectdFilterIndex: controllerFields.length && controllerFields.length !== 1 ?
          controllerFields.length - 1 : 0,
        controllers: controllerData,
        showFilterElement: false,
      });
    } else if (type === 'form') {
      let controllerFormFields =
        controllers[selectedControllerIndex].controllerFormFields;
      let controllerFormFieldsLength = controllerFormFields.length;
      controllerFormFields.push({
        value: `controller_${selectedControllerIndex +
          1}.value${controllerFormFieldsLength + 1}`,
        type: `controller_${selectedControllerIndex +
          1}.type${controllerFormFieldsLength + 1}`,
        name: `controller_${selectedControllerIndex +
          1}.name${controllerFormFieldsLength + 1}`,
        label: `controller_${selectedControllerIndex +
          1}.label${controllerFormFieldsLength + 1}`,
        id: `controller_${selectedControllerIndex +
          1}.id${controllerFormFieldsLength + 1}`,
        placeholder: `controller_${selectedControllerIndex +
          1}.placeholder${controllerFormFieldsLength + 1}`,
        required: `controller_${selectedControllerIndex +
          1}.required${controllerFormFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFormFields = controllerFormFields;

      await this.setState({
        selectedFormIndex: controllerFormFields.length && controllerFormFields.length !== 1 ?
          controllerFormFields.length - 1 : 0,
        controllers: controllerData,
      });
    } else if (type === 'controller') {
      controllers.push({
        controllerFields: [],
        controllerFormFields: [],
      });

      await this.setState({
        controllers: controllers,
        selectedControllerIndex: controllers.length && controllers.length !== 1 ?
          controllers.length - 1 : 0,
        showProfileTypeDropdown: false,
      });
      await this.props.changeprogressWidth(60);
    } else if (type === 'profile') {
      let controllerProfileFields = controllerData && controllerData.length &&
        controllerData[0]['controllerProfileFields'] ? controllerData[0]['controllerProfileFields'] : [];
      let controllerProfileFieldsLength = controllerProfileFields.length ? controllerProfileFields.length : 0;
      let tempOptions = this.state.fieldProfileType;
      let flag = false;
      for (let j = 0; j < controllerProfileFields.length; j++) {
        if (controllerProfileFields[j]["profileName"] === e) {
          flag = true;
          break;
        }
      }
      if (!flag) {
        controllerProfileFields.push({
          value: `controllerprofile_${0 +
            1}.value${controllerProfileFieldsLength + 1}`,
          type: `controllerprofile_${0 +
            1}.type${controllerProfileFieldsLength + 1}`,
          name: `controllerprofile_${0 +
            1}.name${controllerProfileFieldsLength + 1}`,
          label: `controllerprofile_${0 +
            1}.label${controllerProfileFieldsLength + 1}`,
          id: `controllerprofile_${0 +
            1}.id${controllerProfileFieldsLength + 1}`,
          placeholder: `controllerprofile_${0 +
            1}.placeholder${controllerProfileFieldsLength + 1}`,
          required: `controllerprofile_${0 +
            1}.required${controllerProfileFieldsLength + 1}`,
          [`name${controllerProfileFieldsLength == 0 ? 0 : controllerProfileFieldsLength == 1 ? controllerProfileFieldsLength : controllerProfileFieldsLength}`]: e,
          [`profileName`]: e,
        });
        if (controllerData && controllerData.length) {
          controllerData[0]['controllerProfileFields'] = controllerProfileFields;
        } else {
          controllerData.push({ 'controllerProfileFields': '' })
          controllerData[0]['controllerProfileFields'] = controllerProfileFields;
        }
        this.setState({
          selectedProfileIndex: controllerProfileFields.length && controllerProfileFields.length !== 1 ?
            controllerProfileFields.length - 1 : 0,
          controllers: controllerData,
          showProfileTypeDropdown: false,
          fieldProfileType: tempOptions
        });
      } else {
        this.setState({
          showProfileTypeDropdown: true,
        });
      }
      await this.props.changeprogressWidth(80);
      await this.props.changeprogressWidth(100);
    }
  };

  //addFilterElement
  addFilterElement = async (index, type) => {
    let { selectedControllerIndex, controllers } = this.state;
    let controllerData = controllers;
    if (type === 'filter') {
      let filterElementFields =
        controllers[selectedControllerIndex].controllerFields[index].filterElement ?
          controllers[selectedControllerIndex].controllerFields[index].filterElement :
          [];
      let filterElementFieldsLength = filterElementFields.length;
      filterElementFields.push({
        label: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.label${filterElementFieldsLength + 1}`,
        value: `controller_${filterElementFieldsLength +
          1}${selectedControllerIndex}${index}.value${filterElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFields[index].filterElement = filterElementFields;
      await this.setState({ selectdFilterIndex: index, controllers: controllerData, });
    } else if (type === 'options') {
      let optionsElementFields =
        controllers[selectedControllerIndex].controllerFormFields[index].options ?
          controllers[selectedControllerIndex].controllerFormFields[index].options :
          [];
      let optionsElementFieldsLength = optionsElementFields.length;
      optionsElementFields.push({
        label: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.label${optionsElementFieldsLength + 1}`,
        value: `controllerform_${optionsElementFieldsLength +
          1}${selectedControllerIndex}${index}.value${optionsElementFieldsLength + 1}`,
      })
      controllerData[selectedControllerIndex].controllerFormFields[index].options = optionsElementFields;
      await this.setState({ selectedFormIndex: index, controllers: controllerData, });
    }
  }

  //returnController
  returnControllerArray() {
    return this.state.controllers;
  }

  //changeControllerName
  changeContrllerName = async (e, selectedControllerIndex) => {
    let controllers = this.state.controllers;
    let tempUrlTypes = [];
    if (e && e.target.value) {
      controllers[selectedControllerIndex].name = e.target.value
      for (let i = 0; i < controllers.length; i++) {
        Object.keys(controllers[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers[i].name, value: controllers[i].name })
          }
        }
        )
      }
      await this.setState({
        controllers: this.state.controllers,
        apiUrlTypes: tempUrlTypes
      })
    } else {
      let i = selectedControllerIndex;
      i += 1;
      controllers[selectedControllerIndex].name = ''
      await this.setState({ controllers: this.state.controllers })
    }
  }

  //handleControllerTableFields
  handleControllerFields = async (e, index, fieldName, type, fieldsType, name) => {
    let { selectedControllerIndex, controllers } = this.state;
    if (fieldsType === 'table') {
      if (type === 'checkbox') {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e;
        controllers[selectedControllerIndex]["controllerFields"][index][name] = e;
        await this.setState({ controllers: controllers })
        if (e == true && fieldName == `filter${index}`) {
          let filterElementFields =
            controllers[selectedControllerIndex].controllerFields[index].filterElement ?
              controllers[selectedControllerIndex].controllerFields[index].filterElement :
              [];
          let filterElementFieldsLength = filterElementFields.length;
          filterElementFields.push({
            label: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.label${filterElementFieldsLength + 1}`,
            value: `controller_${filterElementFieldsLength +
              1}${selectedControllerIndex}${index}.value${filterElementFieldsLength + 1}`,
          })
          controllers[selectedControllerIndex].controllerFields[index].filterElement = filterElementFields;
          await this.setState({ selectdFilterIndex: index, controllers: controllers, });
        } else if (e == false && fieldName == `filter${index}`) {
          controllers[selectedControllerIndex]["controllerFields"][index]["filterElement"] = []
          await this.setState({ showFilterElement: false, controllers: controllers })
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e.target.value;
          controllers[selectedControllerIndex]["controllerFields"][index][name] = e.target.value;
          await this.setState({ controllers: controllers })
        } else {
          controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = '';
          controllers[selectedControllerIndex]["controllerFields"][index][name] = '';
          await this.setState({ controllers: controllers })
        }
      }
    } else if (fieldsType === 'form') {
      if (type === 'checkbox') {
        if (e === 'dropDown' || e === 'radio') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e === 'autoComplete') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index]['options'];
        } else if (e === 'multiSelect') {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = 'dropDown';
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`] = true;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e && `type${index}` === fieldName) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
          delete controllers[selectedControllerIndex]["controllerFormFields"][index]['options'];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`isMultiSelect${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi${index}`]
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchApi`];
          delete controllers[selectedControllerIndex]["controllerFormFields"][index][`searchField`];
        } else if (e) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e;
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = e.target.value;
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = e.target.value;
        } else {
          controllers[selectedControllerIndex]["controllerFormFields"][index][fieldName] = '';
          controllers[selectedControllerIndex]["controllerFormFields"][index][name] = '';

        }
      }
      await this.setState({ controllers: controllers })
    } else if (fieldsType === 'profile') {
      if (type === 'checkbox') {
        if (e) {
          controllers[0]["controllerProfileFields"][index][fieldName] = e;
          controllers[0]["controllerProfileFields"][index][name] = e;
        }
      } else if (type === 'input') {
        if (e && e.target.value) {
          controllers[0]["controllerProfileFields"][index][fieldName] = e.target.value;
          controllers[0]["controllerProfileFields"][index][name] = e.target.value;
        } else {
          controllers[0]["controllerProfileFields"][index][fieldName] = '';
          controllers[0]["controllerProfileFields"][index][name] = '';
        }
      }
      await this.setState({ controllers: controllers })
    }
  }

  //handleactions
  handleControllerActions = async (e, fieldName) => {
    let controllers = this.state.controllers;
    if (fieldName === 'actions') {
      if (e) {
        controllers[this.state.selectedControllerIndex][fieldName] = e

      } else {
        controllers[this.state.selectedControllerIndex][fieldName] = e
      }
    } else if ('login') {
      controllers[this.state.selectedControllerIndex][fieldName] = e
    }
    await this.setState({ controllers: controllers })
  }

  //handleControllerFilterFields
  handleControllerFilterFields = async (e, index, fieldName, filterIndex, type, name) => {
    let controllerData = this.state.controllers;
    if (type === 'table') {
      if (e && e.target.value) {
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][fieldName] = e.target.value;
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][name] = e.target.value;
      } else {
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][fieldName] = '';
        controllerData[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'][filterIndex][name] = '';
      }
    } else if (type === 'form') {
      if (e && e.target.value) {
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][fieldName] = e.target.value;
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][name] = e.target.value;
      } else {
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][fieldName] = '';
        controllerData[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'][filterIndex][name] = '';
      }
    }
    await this.setState({ controllers: controllerData })
  }

  //removeFormFields
  removeFormFields = async (index, type) => {
    let controllers = this.state.controllers;
    if (type === 'table') {
      controllers[this.state.selectedControllerIndex]["controllerFields"].splice(index, 1);
      await this.setState({
        selectdFilterIndex:
          index > 0 ? index - 1 : 0,
      })
    } else if (type === 'form') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"].splice(index, 1);
      await this.setState({
        selectedFormIndex:
          index > 0 ? index - 1 : 0,
      })
    } else if (type === 'profile') {
      controllers[0]["controllerProfileFields"].splice(index, 1);
      await this.setState({
        showProfileTypeDropdown: false,
      })
    } else if (type === 'controller') {
      let tempUrlTypes = [];
      controllers.splice(index, 1);
      for (let i = 0; i < controllers.length; i++) {
        Object.keys(controllers[i]).forEach((item, index) => {
          if (item === 'name') {
            tempUrlTypes.push({ label: controllers[i].name, value: controllers[i].name })
          }
        }
        )
      }
      if (index === 0) {
        await this.setState({
          selectedControllerIndex: 0,
          apiUrlTypes: tempUrlTypes
        })
      } else {
        await this.setState({
          selectedControllerIndex: index - 1,
          apiUrlTypes: tempUrlTypes
        })
      }
    }
    await this.setState({
      controllers: controllers,
    })
  }

  //activeTabs
  activeTabs = async (index, fieldName) => {
    let controllers = this.state.controllers;
    let valueType = controllers[this.state.selectedControllerIndex]["controllerFields"][index][fieldName] ? controllers[this.state.selectedControllerIndex]["controllerFields"][index][fieldName] : '';
    if (valueType) {
      if (valueType == false) {
        await this.setState({ showFilterElement: false, selectdFilterIndex: index })
      } else {
        await this.setState({ showFilterElement: true, selectdFilterIndex: index })
      }
    } else {
      await this.setState({ showFilterElement: false, selectdFilterIndex: index })
    }
  }

  //toggleUploadSample
  toggleUploadSample = async () => {
    await this.setState({ showSampleFile: !this.state.showSampleFile })
    if (this.props.yesJsonUploaded) {
      await this.setState({ showControllers: true })
    }
  }

  //removeFilterFields
  removeFilterFields = async (index, type, filterIndex) => {
    let controllers = this.state.controllers;
    if (type == 'tableFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFields"][index]['filterElement'].splice(filterIndex, 1);
    } else if (type == 'formFilter') {
      controllers[this.state.selectedControllerIndex]["controllerFormFields"][index]['options'].splice(filterIndex, 1);
    }
    await this.setState({ controllers: controllers })
  }

  //handleyesExistingProject
  handleExistingProject = (e) => {
    if (e && e.target.value) {
      this.setState({ showControllers: true })
      this.props.handleyesExistingProject(true);
    } else {
      this.setState({ showControllers: false })
      this.props.handleyesExistingProject(false);
    }
  }

  render() {

    const {
      controllers,
      selectedControllerIndex,
      typeOptions,
      fieldTypeOptions,
      fieldProfileTypeOptions,
      fieldProfileType,
      actionsType,
      apiUrlTypes,
      showSampleFile,
    } = this.state;
    let controllerFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFields"] &&
        controllers[selectedControllerIndex]["controllerFields"].length
        ? controllers[selectedControllerIndex]["controllerFields"]
        : [];
    let controllerProfileFields =
      controllers &&
        controllers.length &&
        controllers[0] &&
        controllers[0]["controllerProfileFields"] &&
        controllers[0]["controllerProfileFields"].length
        ? controllers[0]["controllerProfileFields"]
        : [];
    let controllerFormFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFormFields"] &&
        controllers[selectedControllerIndex]["controllerFormFields"].length
        ? controllers[selectedControllerIndex]["controllerFormFields"]
        : [];
    return (
      <Row>
        <Col sm={12}>
          <div>
            <h4 className='ps-2 addIcon float-right' >
              < FontAwesomeIcon
                className='editIcon text-info'
                color='#ff00008c'
                icon='upload'
                data-toggle="tool-tip"
                title="Upload Json File"
                onClick={this.toggleUploadSample}
              />
            </h4>
            <Collapse isOpen={showSampleFile}>
              <h5 className='pt-3 pb-3 '>Sample File <a href={this.state.sampleFileJson} download target="_blank" >
                <span className='ps-2 addIcon'>
                  < FontAwesomeIcon
                    className='editIcon text-info'
                    color='#ff00008c'
                    icon='download'
                    data-toggle="tool-tip"
                    title="Sample File"
                  />
                </span>
              </a> </h5>
              {/* <label className='labelMargin'>Upload Existing Project Details JSON File
            </label> */}
              <Field
                name="jsonFile"
                label={'Upload Json File'}
                component={FileInput}
                type="text"
                placeholder="eg:var/www/html"
                className="form-control d-none"
                accept={'.json'}
                onChange={this.props.jsonUploadFile}
              />
              <h5 className=' text-secondary mt-2 mb-3'>
                Note: If you upload JSON file please fill the fields which displays below.</h5>
              {/* <hr></hr> */}
            </Collapse>
            {showSampleFile && <h4 className='text-center'> OR</h4>}
          </div>
        </Col>
        <Col sm={12} >
          {/* <h2 className='pt-2'>Enter Existing Project Details here</h2> */}
          <div>
            <div>
              <div className='row pt-3'>
                {/* {!this.props.yesJsonUploaded && <div className='col-12 col-sm-12 col-md-6 col-lg-4'> */}
                {!this.props.yesJsonUploaded && <div className='col-sm-12'>
                  <label className='labelMargin'>May I have your Project Name?</label>
                  <Field
                    name="projectNameNew"
                    component={DefaultInput}
                    type="text"
                    placeholder="Project Name"
                    className="form-control"
                    onChange={(e) => this.handleExistingProject(e)}
                  />
                </div>}
              </div>
            </div>
          </div>
          {this.state.showControllers && !this.props.yesJsonUploaded ?
            <div className='pt-4'>
              <div className='row'>
                {/* <div className='col-lg-4 col-md-12'> */}
                <div className='col-sm-12'>
                  <label className='labelMargin headingsFontC'>Add Controllers Data
                    <span className='ps-3 addIcon'>
                      < FontAwesomeIcon
                        className='editIcon text-info'
                        color='#ff00008c'
                        icon='plus-circle'
                        data-toggle="tool-tip"
                        title="Add"
                        onClick={(e) => this.addControllersFields('controller')}
                      />
                    </span>
                  </label>

                </div>
              </div>
              <div className='row ps-2'>
                {!this.props.yesJsonUploaded && controllers && controllers.length
                  ? controllers.map((item, index) => {
                    return (
                      <div
                        className='col-12 col-sm-12 col-md-6 col-lg-4'
                        key={index}
                      >
                        <div className={selectedControllerIndex == index ?
                          'text-info  controller_label borderContollerColor'
                          : 'controller_label'}>
                          <span
                            style={{ textTransform: 'capitalize' }}
                            onClick={(e) =>
                              this.setState({
                                selectedControllerIndex: index,
                                selectdFilterIndex: 0,
                                selectedFormIndex: 0,
                              })
                            }>
                            <MuiThemeProvider theme={theme}>
                              <TextField
                                autoFocus={true}
                                margin="dense"
                                variant="outlined"
                                id="custom-css-outlined-input"
                                size='small'
                                type="text"
                                helperText={'Please Enter Singular Values, Ex:employee'}
                                className={selectedControllerIndex == index ? 'float-left text-info textFieldsWidth'
                                  : 'float-left textFieldsWidth'}
                                style={{ textTransform: 'capitalize' }}
                                name={`controller_${index + 1}.name`}
                                label={`Controller${index + 1} Name`}
                                onChange={(e) => {
                                  this.changeContrllerName(e, selectedControllerIndex)
                                }}
                                value={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                                defaultValue={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                              />
                            </MuiThemeProvider>
                            <span className='text-white'>
                              {`controller_${index + 1}.name`}
                            </span>
                          </span>
                          <span className='float-right iconSize controllerDelteIConPos'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeFormFields(index, 'controller')}
                          /></span>
                        </div>
                      </div>
                    );
                  })
                  : null}
                {!this.props.yesJsonUploaded &&
                  <Col
                    sm={12}
                    className={controllers && controllers.length ? " pt-2" : "d-none"}
                  >
                    <div className='row'>
                      <div className='col-lg-4 col-md-12'>
                        <label className='labelMargin headingsFont'>
                          {controllers && controllers.length && controllers[selectedControllerIndex].name
                            ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`} Table Fields
                          <span className='ps-3 addIcon'>
                            < FontAwesomeIcon
                              className='editIcon text-info'
                              color='#ff00008c'
                              icon='plus-circle'
                              data-toggle="tool-tip"
                              title="Add"
                              onClick={(e) => this.addControllersFields('table')}
                            />
                          </span>
                        </label>
                      </div>
                      <div className='col-lg-4 col-md-12'>
                        <div>
                          <label className='mb-0'>
                            Actions
                          </label>
                          <Field
                            name={`controller_${selectedControllerIndex}.act`}
                            component={MultiSelect}
                            type="text"
                            placeholder="Actions"
                            className="form-control"
                            valueMulti={controllers && controllers.length > 0 && controllers[selectedControllerIndex]['actions'] && controllers[selectedControllerIndex]['actions']}
                            options={actionsType}
                            onChange={(e) => { e ? this.handleControllerActions(e, 'actions') : null }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {controllerFieldsArray && controllerFieldsArray.length
                        ? controllerFieldsArray.map((item, index) => {
                          return (
                            <div key={index} className='col-12 col-sm-12 col-md-6 col-lg-4'>
                              <div key={index}>
                                <div className='controller_labelForFields'>
                                  <span className='float-left' style={{ textTransform: 'capitalize' }}>
                                    {item[`fieldValue`] ? item[`fieldValue`] : `Table Fields ${index + 1}`}
                                  </span>
                                  <span className='float-right iconSize' ><FontAwesomeIcon
                                    className='editIcon'
                                    color='#ff00008c'
                                    icon='trash-alt'
                                    data-toggle="tool-tip"
                                    title="Delete"
                                    onClick={() => this.removeFormFields(index, 'table')}
                                  /></span>
                                </div>
                                <div className='card cardShadow'>
                                  <div className='card-body px-3 py-2'>
                                    <div className="row" key={index}>
                                      <div className="col-12 pb-2 ">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.field}
                                            label="Field Name"
                                            variant="outlined"
                                            id="custom-css-outlined-input"
                                            size='small'
                                            defaultValue={item[`fieldValue`] ? item[`fieldValue`] : ''}
                                            value={item[`fieldValue`] ? item[`fieldValue`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `field${index}`, 'input', 'table', 'fieldValue')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12 pt-2 pb-3">
                                        <MuiThemeProvider theme={theme}>
                                          <TextField
                                            type="text"
                                            className='textFieldsWidth'
                                            margin="dense"
                                            name={item.header}
                                            label="Header Name"
                                            variant="outlined"
                                            size='small'
                                            id="custom-css-outlined-input"
                                            defaultValue={item[`headerValue`] ? item[`headerValue`] : ''}
                                            value={item[`headerValue`] ? item[`headerValue`] : ''}
                                            onChange={(e) => this.handleControllerFields(e, index, `header${index}`, 'input', 'table', 'headerValue')}
                                          />
                                        </MuiThemeProvider>
                                      </div>
                                      <div className="col-12 dropDownPadiingAlign ">
                                        <Field
                                          name={item.textAlign}
                                          component={Select}
                                          type="text"
                                          placeholder="Text Align"
                                          valueExists={{ label: item[`textAlignValue`], value: item[`textAlignValue`] }}
                                          className="form-control"
                                          options={typeOptions}
                                          onChange={(e) => this.handleControllerFields(e, index, `textAlign${index}`, 'checkbox', 'table', 'textAlignValue')}
                                        />
                                      </div>
                                      <div className="col-12 pb-2">
                                        <Field
                                          name={item.filter}
                                          component={CheckBox}
                                          label='Filter'
                                          className="form-control"
                                          type="checkbox"
                                          onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `filter${index}`, 'checkbox', 'table') : null }}
                                        />
                                      </div>
                                      {item.filterElement && item.filterElement.length > 0 && index == index ?
                                        <div className='col-12'>
                                          {item.filterElement && item.filterElement.length > 0 ?
                                            item.filterElement.map((itemsFilter, filterIndex) => (
                                              <div className='row' key={filterIndex}>
                                                <div className="col-6 pb-3 pr-lg-1">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsFilter.label}
                                                      label="Label"
                                                      variant="outlined"
                                                      size='small'
                                                      id="custom-css-outlined-input"
                                                      defaultValue={itemsFilter[`labelValue`] ? itemsFilter[`labelValue`] : ''}
                                                      value={itemsFilter[`labelValue`] ? itemsFilter[`labelValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `label${filterIndex}`, filterIndex, 'table', 'labelValue')}
                                                    />
                                                  </MuiThemeProvider>
                                                </div>
                                                <div className="col-6 pb-3 ps-lg-1">
                                                  <MuiThemeProvider theme={theme}>
                                                    <TextField
                                                      type="text"
                                                      className='textFieldsWidth'
                                                      margin="dense"
                                                      name={itemsFilter.label}
                                                      label="Value"
                                                      variant="outlined"
                                                      size='small'
                                                      id="custom-css-outlined-input"
                                                      defaultValue={itemsFilter[`valueValue`] ? itemsFilter[`valueValue`] : ''}
                                                      value={itemsFilter[`valueValue`] ? itemsFilter[`valueValue`] : ''}
                                                      onChange={(e) => this.handleControllerFilterFields(e, index, `value${filterIndex}`, filterIndex, 'table', 'valueValue')}
                                                    />
                                                  </MuiThemeProvider>
                                                  <span className='float-right iconSize' ><FontAwesomeIcon
                                                    className='editIconField'
                                                    color='#ff00008c'
                                                    icon='trash-alt'
                                                    data-toggle="tool-tip"
                                                    title="Delete"
                                                    onClick={() => this.removeFilterFields(index, 'tableFilter', filterIndex)}
                                                  /></span>
                                                </div>
                                              </div>
                                            ))
                                            : null}
                                          <p
                                            onClick={(e) => this.addFilterElement(index, 'filter')}
                                            className="text-primary py-2 mt-0"
                                            style={{ textDecoration: "underline", cursor: "pointer" }}
                                          >
                                            Add Filter Element Fields +
                                          </p>
                                        </div> : null}
                                      <div className="col-12 ">
                                        <Field
                                          name={item.sortable}
                                          component={CheckBox}
                                          label='Sortable'
                                          className="form-control"
                                          type="checkbox"
                                          onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `sortable${index}`, 'checkbox', 'table') : null }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </Col>}
                {!this.props.yesJsonUploaded && <Col
                  sm={12}
                  className={controllers && controllers.length ? "pt-3" : "d-none"}
                >
                  <label className='labelMargin headingsFont pt-1'>
                    {controllers && controllers.length && controllers[selectedControllerIndex].name
                      ? controllers[selectedControllerIndex].name : `Controller ${selectedControllerIndex + 1}`} Form Fields
                    <span className='ps-3 addIcon'>
                      < FontAwesomeIcon
                        className='editIcon text-info'
                        color='#ff00008c'
                        icon='plus-circle'
                        data-toggle="tool-tip"
                        title="Add"
                        onClick={(e) => this.addControllersFields('form')}
                      />
                    </span>
                  </label>
                  <div className="row" >
                    {controllerFormFieldsArray && controllerFormFieldsArray.length
                      ? controllerFormFieldsArray.map((item, index) => {
                        return (
                          <div
                            className='col-12 col-sm-12 col-md-6 col-lg-4'
                            key={index}
                          >
                            <div className='controller_labelForFields '>
                              <span className='float-left' style={{ textTransform: 'capitalize' }}>
                                {item[`formName`] ? item[`formName`] : `Form Fields ${index + 1}`}
                              </span>
                              <span className='float-right iconSize'><FontAwesomeIcon
                                className='editIcon'
                                color='#ff00008c'
                                icon='trash-alt'
                                data-toggle="tool-tip"
                                title="Delete"
                                onClick={() => this.removeFormFields(index, 'form')}
                              /></span>
                            </div>
                            <div className='card cardShadowForm'>
                              <div className='card-body px-3 py-2'>
                                <div className="row" key={index}>
                                  <div className="col-12 pb-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.value}
                                        label="Value"
                                        id="outlined-search"
                                        variant="outlined"
                                        size='sm'
                                        defaultValue={item[`formValue`] ? item[`formValue`] : ''}
                                        value={item[`formValue`] ? item[`formValue`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'form', 'formValue')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12 py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.name}
                                        label="Name"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='sm'
                                        defaultValue={item[`formName`] ? item[`formName`] : ''}
                                        value={item[`formName`] ? item[`formName`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'form', 'formName')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.label}
                                        label="Label"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='sm'
                                        value={item[`formLabel`] ? item[`formLabel`] : ''}
                                        defaultValue={item[`formLabel`] ? item[`formLabel`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'form', 'formLabel')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.id}
                                        label="Id"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='small'
                                        value={item[`formId`] ? item[`formId`] : ''}
                                        defaultValue={item[`formId`] ? item[`formId`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'form', 'formId')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12  py-2">
                                    <MuiThemeProvider theme={theme}>
                                      <TextField
                                        type="text"
                                        className='textFieldsWidth'
                                        margin="dense"
                                        name={item.placeholder}
                                        label="Placeholder"
                                        id="outlined-margin-dense"
                                        variant="outlined"
                                        size='small'
                                        value={item[`formPlaceholder`] ? item[`formPlaceholder`] : ''}
                                        defaultValue={item[`formPlaceholder`] ? item[`formPlaceholder`] : ''}
                                        onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'form', 'formPlaceholder')}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                  <div className="col-12 dropDownPadiingFields">
                                    <Field
                                      name={item.type}
                                      component={Select}
                                      type="text"
                                      placeholder="Field Type"
                                      valueExists={{ label: item[`formType`], value: item[`formType`] }}
                                      className="form-control"
                                      options={fieldTypeOptions}
                                      onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'form', 'formType')}
                                    />
                                  </div>
                                  {(item[`formType`] === 'dropDown' || item[`formType`] === 'radio'
                                    || item[`formType`] === 'multiSelect') && index == index ?
                                    <div className='col-12 pb-3'>
                                      {item.options && item.options.length > 0 ?
                                        item.options.map((itemsOptions, optionsIndex) => (
                                          <div className='row' key={optionsIndex}>
                                            <div className="col-6 pb-3 pr-lg-1">
                                              <MuiThemeProvider theme={theme}>
                                                <TextField
                                                  type="text"
                                                  className='textFieldsWidth'
                                                  margin="dense"
                                                  name={itemsOptions.label}
                                                  label="Label"
                                                  id="outlined-margin-dense"
                                                  variant="outlined"
                                                  size='small'
                                                  value={itemsOptions[`formOptionLabel`] ? itemsOptions[`formOptionLabel`] : ''}
                                                  defaultValue={itemsOptions[`formOptionLabel`] ? itemsOptions[`formOptionLabel`] : ''}
                                                  onChange={(e) => this.handleControllerFilterFields(e, index, `label${optionsIndex}`, optionsIndex, 'form', 'formOptionLabel')}
                                                />
                                              </MuiThemeProvider>
                                            </div>
                                            <div className="col-6 pb-3 ps-lg-1">
                                              <MuiThemeProvider theme={theme}>
                                                <TextField
                                                  type="text"
                                                  className='textFieldsWidth'
                                                  margin="dense"
                                                  name={itemsOptions.value}
                                                  label="Value"
                                                  id="outlined-margin-dense"
                                                  variant="outlined"
                                                  size='small'
                                                  value={itemsOptions[`formOptionValue`] ? itemsOptions[`formOptionValue`] : ''}
                                                  defaultValue={itemsOptions[`formOptionValue`] ? itemsOptions[`formOptionValue`] : ''}
                                                  onChange={(e) => this.handleControllerFilterFields(e, index, `value${optionsIndex}`, optionsIndex, 'form', 'formOptionValue')}
                                                />
                                              </MuiThemeProvider>
                                              <span className='float-right iconSize' ><FontAwesomeIcon
                                                className='editIconField'
                                                color='#ff00008c'
                                                icon='trash-alt'
                                                data-toggle="tool-tip"
                                                title="Delete"
                                                onClick={() => this.removeFilterFields(index, 'formFilter', optionsIndex)}
                                              /></span>
                                            </div>
                                          </div>
                                        ))
                                        : null}
                                      <p
                                        onClick={(e) => this.addFilterElement(index, 'options')}
                                        className="text-primary  mt-0 pt-2"
                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                      >
                                        Add Options Fields +
                                      </p>
                                    </div> : null}
                                  {item[`formType`] === 'autoComplete' ?
                                    <div className='col-12'>
                                      <div className='row'>
                                        <div className="col-6 pb-3 pr-lg-1">
                                          <MuiThemeProvider theme={theme}>
                                            <TextField
                                              type="text"
                                              className='textFieldsWidth'
                                              margin="dense"
                                              name={`controller_${selectedControllerIndex +
                                                1}.searchF${index + 1}`}
                                              label="Search Field"
                                              id="outlined-margin-dense"
                                              variant="outlined"
                                              size='small'
                                              value={item[`searchF`] ? item[`searchF`] : ''}
                                              defaultValue={item[`searchF`] ? item[`searchF`] : ''}
                                              onChange={(e) => this.handleControllerFields(e, index, `searchField${index}`, 'input', 'form', 'searchF')}
                                            />
                                          </MuiThemeProvider>
                                        </div>
                                        <div className="col-6 pb-3 ps-lg-1">
                                          <MuiThemeProvider theme={theme}>
                                            <TextField
                                              type="text"
                                              className='textFieldsWidth'
                                              margin="dense"
                                              name={`controller_${selectedControllerIndex +
                                                1}.searchA${index + 1}`}
                                              label="Search Api"
                                              id="outlined-margin-dense"
                                              variant="outlined"
                                              size='small'
                                              value={item[`searchA`] ? item[`searchA`] : ''}
                                              defaultValue={item[`searchA`] ? item[`searchA`] : ''}
                                              onChange={(e) => this.handleControllerFields(e, index, `searchApi${index}`, 'input', 'form', 'searchA')}
                                            />
                                          </MuiThemeProvider>
                                        </div>
                                      </div>
                                    </div>
                                    : null}
                                  <div className="col-12 pt-2">
                                    <Field
                                      name={item.required}
                                      component={CheckBox}
                                      label='Requried'
                                      className="form-control"
                                      type="checkbox"
                                      onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'form') : null }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </Col>}
              </div>
            </div>
            : null}
          {this.state.showControllers && <hr className='mt-4'></hr>}
        </Col>
        {
          this.state.showControllers && !this.props.yesJsonUploaded &&
          <Col
            sm={12}
            className={controllers && controllers.length ? " pt-2" : "d-none"}
          >
            <label className=' headingsFontC pt-2 mb-0'> Profile Fields
            </label>
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 ps-0 dropDownPadiing">
              <Field
                name='profile'
                component={Select}
                label='Field Type'
                type="text"
                placeholder="Field Type"
                className="form-control"
                options={fieldProfileType}
                onChange={(e) => this.addControllersFields('profile', e)}
              />
            </div>
            <div style={{ height: 13 }}>
              {this.state.showProfileTypeDropdown ? <span className="form__form-group-error">Already exists please select another one.</span> : null}
            </div>
            <div className="row" >
              {
                controllerProfileFields && controllerProfileFields.length
                  ? controllerProfileFields.map((item, index) => {
                    return (
                      <div
                        className='col-12 col-sm-12 col-md-6 col-lg-4'
                        key={index}
                      >
                        <div className='controller_labelForFields '>
                          <span className='float-left' style={{ textTransform: 'capitalize' }}>
                            {item[`profileName`] ? item[`profileName`] : `Profile Fields ${index + 1}`}
                          </span>
                          <span className='float-right iconSize'><FontAwesomeIcon
                            className='editIcon'
                            color='#ff00008c'
                            icon='trash-alt'
                            data-toggle="tool-tip"
                            title="Delete"
                            onClick={() => this.removeFormFields(index, 'profile')}
                          /></span>
                        </div>
                        <div className='card cardShadowForm'>
                          <div className='card-body px-3 py-2'>
                            <div className="row" key={index}>
                              <div className="col-12 pb-2 ">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.value}
                                    label="Value"
                                    id="outlined-search"
                                    variant="outlined"
                                    size='sm'
                                    defaultValue={item[`profileValue`] ? item[`profileValue`] : ''}
                                    value={item[`profileValue`] ? item[`profileValue`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'profile', 'profileValue')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.name}
                                    label="Name"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='sm'
                                    defaultValue={item[`profileName`] ? item[`profileName`] : ''}
                                    value={item[`profileName`] ? item[`profileName`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'profile', 'profileName')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    name={item.label}
                                    margin="dense"
                                    label="Label"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='sm'
                                    value={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                    defaultValue={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'profile', 'profileLabel')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 py-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    name={item.id}
                                    margin="dense"
                                    label="Id"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='small'
                                    value={item[`profileId`] ? item[`profileId`] : ''}
                                    defaultValue={item[`profileId`] ? item[`profileId`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'profile', 'profileId')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 pb-3 pt-2">
                                <MuiThemeProvider theme={theme}>
                                  <TextField
                                    type="text"
                                    className='textFieldsWidth'
                                    margin="dense"
                                    name={item.placeholder}
                                    label="Placeholder"
                                    id="outlined-margin-dense"
                                    variant="outlined"
                                    size='small'
                                    value={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                    defaultValue={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                    onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'profile', 'profilePlaceholder')}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div className="col-12 dropDownPadiing">
                                <Field
                                  name={item.type}
                                  component={Select}
                                  type="text"
                                  placeholder="Field Type"
                                  valueExists={{ label: item[`profileType`], value: item[`profileType`] }}
                                  className="form-control"
                                  options={fieldProfileTypeOptions}
                                  onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'profile', 'profileType')}
                                />
                              </div>
                              <div className="col-12 pt-2">
                                <Field
                                  name={item.required}
                                  component={CheckBox}
                                  label='Requried'
                                  className="form-control"
                                  type="checkbox"
                                  onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'profile') : null }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  : null}
            </div>
          </Col>
        }
        {
          this.state.showControllers && this.props.yesJsonUploaded ?
            <Col
              sm={12}
            >
              <label className=' headingsFontC pt-2 mb-0'> Profile Fields
              </label>
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 ps-0 dropDownPadiing">
                <Field
                  name='profile'
                  component={Select}
                  label='Field Type'
                  type="text"
                  placeholder="Field Type"
                  className="form-control"
                  options={fieldProfileType}
                  onChange={(e) => this.addControllersFields('profile', e)}
                />
              </div>
              <div style={{ height: 13 }}>
                {this.state.showProfileTypeDropdown ? <span className="form__form-group-error">Already exists please select another one.</span> : null}
              </div>
              <div className="row" >
                {
                  controllerProfileFields && controllerProfileFields.length
                    ? controllerProfileFields.map((item, index) => {
                      return (
                        <div
                          className='col-12 col-sm-12 col-md-6 col-lg-4'
                          key={index}
                        >
                          <div className='controller_labelForFields '>
                            <span className='float-left' style={{ textTransform: 'capitalize' }}>
                              {item[`profileName`] ? item[`profileName`] : `Profile Fields ${index + 1}`}
                            </span>
                            <span className='float-right iconSize'><FontAwesomeIcon
                              className='editIcon'
                              color='#ff00008c'
                              icon='trash-alt'
                              data-toggle="tool-tip"
                              title="Delete"
                              onClick={() => this.removeFormFields(index, 'profile')}
                            /></span>
                          </div>
                          <div className='card cardShadowForm'>
                            <div className='card-body px-3 py-2'>
                              <div className="row" key={index}>
                                <div className="col-12 pb-2 ">
                                  <MuiThemeProvider theme={theme}>
                                    <TextField
                                      type="text"
                                      className='textFieldsWidth'
                                      margin="dense"
                                      name={item.value}
                                      label="Value"
                                      id="outlined-search"
                                      variant="outlined"
                                      size='sm'
                                      defaultValue={item[`profileValue`] ? item[`profileValue`] : ''}
                                      value={item[`profileValue`] ? item[`profileValue`] : ''}
                                      onChange={(e) => this.handleControllerFields(e, index, `value${index}`, 'input', 'profile', 'profileValue')}
                                    />
                                  </MuiThemeProvider>
                                </div>
                                <div className="col-12 py-2">
                                  <MuiThemeProvider theme={theme}>
                                    <TextField
                                      type="text"
                                      className='textFieldsWidth'
                                      margin="dense"
                                      name={item.name}
                                      label="Name"
                                      id="outlined-margin-dense"
                                      variant="outlined"
                                      size='sm'
                                      defaultValue={item[`profileName`] ? item[`profileName`] : ''}
                                      value={item[`profileName`] ? item[`profileName`] : ''}
                                      onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', 'profile', 'profileName')}
                                    />
                                  </MuiThemeProvider>
                                </div>
                                <div className="col-12 py-2">
                                  <MuiThemeProvider theme={theme}>
                                    <TextField
                                      type="text"
                                      className='textFieldsWidth'
                                      name={item.label}
                                      margin="dense"
                                      label="Label"
                                      id="outlined-margin-dense"
                                      variant="outlined"
                                      size='sm'
                                      value={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                      defaultValue={item[`profileLabel`] ? item[`profileLabel`] : ''}
                                      onChange={(e) => this.handleControllerFields(e, index, `label${index}`, 'input', 'profile', 'profileLabel')}
                                    />
                                  </MuiThemeProvider>
                                </div>
                                <div className="col-12 py-2">
                                  <MuiThemeProvider theme={theme}>
                                    <TextField
                                      type="text"
                                      className='textFieldsWidth'
                                      name={item.id}
                                      margin="dense"
                                      label="Id"
                                      id="outlined-margin-dense"
                                      variant="outlined"
                                      size='small'
                                      value={item[`profileId`] ? item[`profileId`] : ''}
                                      defaultValue={item[`profileId`] ? item[`profileId`] : ''}
                                      onChange={(e) => this.handleControllerFields(e, index, `id${index}`, 'input', 'profile', 'profileId')}
                                    />
                                  </MuiThemeProvider>
                                </div>
                                <div className="col-12 pb-3 pt-2">
                                  <MuiThemeProvider theme={theme}>
                                    <TextField
                                      type="text"
                                      className='textFieldsWidth'
                                      margin="dense"
                                      name={item.placeholder}
                                      label="Placeholder"
                                      id="outlined-margin-dense"
                                      variant="outlined"
                                      size='small'
                                      value={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                      defaultValue={item[`profilePlaceholder`] ? item[`profilePlaceholder`] : ''}
                                      onChange={(e) => this.handleControllerFields(e, index, `placeholder${index}`, 'input', 'profile', 'profilePlaceholder')}
                                    />
                                  </MuiThemeProvider>
                                </div>
                                <div className="col-12 dropDownPadiing">
                                  <Field
                                    name={item.type}
                                    component={Select}
                                    type="text"
                                    placeholder="Field Type"
                                    valueExists={{ label: item[`profileType`], value: item[`profileType`] }}
                                    className="form-control"
                                    options={fieldProfileTypeOptions}
                                    onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', 'profile', 'profileType')}
                                  />
                                </div>
                                <div className="col-12 pt-2">
                                  <Field
                                    name={item.required}
                                    component={CheckBox}
                                    label='Requried'
                                    className="form-control"
                                    type="checkbox"
                                    onChange={(e) => { e && e.target ? this.handleControllerFields(e.target.checked, index, `required${index}`, 'checkbox', 'profile') : null }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : null}
              </div>
            </Col> : null
        }
      </Row >
    );
  }
}
export default ExistingProject;


