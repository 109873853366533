import React, { useEffect, useState } from 'react';
import { Link,Navigate } from 'react-router-dom';

// Config
import configMessage from '../../../config/configMessages';
import fetchRequest from '../../../config/service';
import apiCalls from '../../../config/apicalls';
import config from '../../../config/config';

// Toaster message && // Loader
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import Loader from '../../App/Loader';

// Input fields
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';
import { Button } from 'reactstrap';

import { useForm, Controller } from 'react-hook-form';


import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Redirect } from 'react-router-dom';



let newpassval = value => (value ? config.passwordRegex.test(value) ? undefined : 'Not a secure paswword, hint:User1234$' : configMessage.fillField)
const newpassvalLength = value => (value ? value.length >= 8 ? undefined : 'Password length should be atleast 8' : configMessage.fillField)

const required = value => (value || typeof value === 'string' ? undefined : configMessage.fillField)
const normalizePhone = (value) => {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`
  }
  return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
const Register = (props) => {

  const [isLoginDisabled, setIsLoginDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [isNotChecked, setIsNotChecked] = useState(false);
  const [loginRole, setLoginRole] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [settingsData, setSettingsData] = useState({});
  const [userDetails, setUserDetails] = useState({});

  useEffect( () => {
    // getSettingsData();
  },[]);

  const formSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string()
      .required("Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(16, "Password cannot exceed more than 16 characters"),
    confirmPassword: yup.string()
      .required("Confirm Password is required")
      .min(4, "Password length should be at least 4 characters")
      .max(16, "Password cannot exceed more than 16 characters")
      .oneOf([yup.ref("password")], "Passwords do not match")
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    control,
    watch,
    getValues
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });



  const submit = (values) => {
    values.status = "Active"
    values.role = "Admin";
    // if (this.state.is_agreed) {
    return fetchRequest('POST', apiCalls.User, values)
      .then(async (response) => {
        if (response && response.respCode && response.respCode == 204) {
          setIsLoginSuccess(true);
          setIsNotChecked(false);
          let userDetails = response.details
          if (response.accessToken) {
            let tokenInfo = {
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
            };
            userDetails = { ...userDetails, ...tokenInfo };
            // save user credentials in storage
            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
          }
          showToasterMessage(response.respMessage, 'success');
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      })
    // } else if (!this.state.is_agreed) {
    //   this.setState({
    //     isNotChecked: true
    //   })
    // }
  }

  const getFormErrorMessage = (name) => {
    return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
  };

  // I DONT KNOW WHETHER THIS FUCNITON IS NEEDED
  const getSettingsData = () => {
    fetchRequest('GET', `settings`).then(async (response) => {
      if (response && response.respCode) {
        let settings = response.settings;
        if(settings && settings.length) {
          let settingsData = settings[0];
          if(settingsData && settingsData["Admin"]) {
            settingsData["GoogleClientId"] = settingsData["Admin"].GoogleClientId || null;
            settingsData["GooglesecretKey"] = settingsData["Admin"].GooglesecretKey || null;
          }
          setSettingsData(settingsData);
        }
      }
    });
  }

  return (
        <>
           <form className="form" autoComplete={"off"} onSubmit={handleSubmit(submit)}>
              <Loader loader={isLoading}/>
              <div className='row'>
                <div className='col-sm-12 col-md-6'>
                <div className='d-flex flex-column gap-2'>
                <div className="form__form-group-label text-normal">First Name</div>
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText id={field.name} value={field.value} className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`} onChange={(e) => field.onChange(e.target.value)} />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  /> 
              </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                <div className='d-flex flex-column gap-2'>
                <div className="form__form-group-label text-normal">Last Name</div>
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText id={field.name} value={field.value} className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`} onChange={(e) => field.onChange(e.target.value)} />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  /> 
              </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                <div className='d-flex flex-column gap-2'>
                <div className="form__form-group-label text-normal">Email</div>
                <Controller
                    name="email"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText id={field.name} value={field.value} className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`} onChange={(e) => field.onChange(e.target.value)} />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  /> 
              </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                <div className='d-flex flex-column gap-2'>
                <div className="form__form-group-label text-normal">Phone</div>
                <Controller
                    name="phone"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputText id={field.name} value={field.value} className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`} onChange={(e) => field.onChange(e.target.value)} />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  /> 
              </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                <div className='d-flex flex-column gap-2'>
                <div className="form__form-group-label text-normal">Password</div>
                <Controller
                    name="password"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Password id={field.name} {...field} inputRef={field.ref} className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`}
                        onChange={(e) => field.onChange(e.target.value)} feedback={false} />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  /> 
              </div>
                </div>
                <div className='col-sm-12 col-md-6'>
                <div className='d-flex flex-column gap-2'>
                <div className="form__form-group-label text-normal">Confirm Password</div>
                <Controller
                    name="confirmPassword"
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Password id={field.name} {...field} inputRef={field.ref} className={`wid_100 ${classNames({ 'p-invalid': fieldState.error })}`}
                        onChange={(e) => field.onChange(e.target.value)} feedback={false} />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  /> 
              </div>
                </div>

                <div className='col-sm-12 col-md-6 mb-2'>
                    <Button className="mt-2 mb-0" color="custom" disabled={isLoginDisabled} style={{ width: '100%' }}>
                       Register
                    </Button>
                  </div>
                  <div className='col-sm-12 col-md-6 lineheight-48 text-center'>
                    <p> Already Have An Account ? <Link to="/log_in">Login Here</Link></p>
                  </div>
              </div>

              {isLoginSuccess ? <Redirect to="/log_in" /> : null}
           </form>
        </>
  );
}

export default Register;
