import React from "react";
import { Field } from "redux-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Components
import DefaultInput from "../../../shared/components/form/DefaultInput";
import RadioInput from "../../../shared/components/form/RadioButton";
import SelectDropdown from "../../../shared/components/form/Select";
import MultiSelect from "../../../shared/components/form/MultiSelectDropDown";
import { Row, Col, Collapse } from "reactstrap";
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import config from '../../../config/config';
import FileInput from '../../../shared/components/form/FileInput'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          height: 47,
          top: -2,
        },
        "&$focused $notchedOutline": {
          borderColor: config.floatingFieldsColor,
        },
      }
    },
    MuiInputLabel: {
      root: {
        "&$focused": {
          color: config.floatingFieldsColor,
          fontWeight: '500'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        "&$contained": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        "&$marginDense": {
          color: config.helperTextColor,
          margin: 0,
          marginTop: 0,
        },
        margin: 0,
        marginTop: 0,
      }
    },
  }
});

class ServerExistingProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailService: "no",
      mailServices: [
        { value: 1, label: "Node Mailer" },
        { value: 2, label: "Send Grid" },
      ],
      selectedMailService: null,
      controllers: [],
      controllerFields: [],
      controllerCol: false,
      emailServiceCol: false,
      selectedControllerIndex: 0,
      selectedControllerCheckBoxIndex: -1,
      typeOptions: [
        { value: "String", label: "String" },
        { value: "Boolean", label: "Boolean" },
        { value: "Number", label: "Number" },
      ],
      actionsType: [
        { value: "get", label: "get" },
        { value: "list", label: "list" },
        { value: "update", label: "update" },
        { value: "create", label: "create" },
        { value: "remove", label: "remove" },
      ],
      showControllers: false,
      sampleFileJson: 'http://nocode.api.dosystemsinc.com/json/serverJson',
      showSampleFile: false,
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }

  //empty controller
  emptyController = async () => {
    await this.setState({
      controllers: [],
      controllerFields: [],
      emailService: '',
    })
  }

  //add controllers
  addControllers = async (type) => {
    if (type === 'controller') {
      let controllers = this.state.controllers;
      controllers.push({
        controllerFields: [],
      });
      await this.setState({
        controllers: controllers,
        emailServiceCol: false,
        controllerCol: true,
        selectedControllerIndex: controllers.length && controllers.length !== 1 ?
          controllers.length - 1 : 0
      });
      await this.props.changeprogressWidth(60)
      await this.props.changeprogressWidth(80)
    } else if (type === 'fields') {
      let { selectedControllerIndex, controllers } = this.state;
      let controllerData = controllers;
      let controllerFields =
        controllers[selectedControllerIndex].controllerFields;
      let controllerFieldsLength = controllerFields.length;
      controllerFields.push({
        fieldName: `controller_${selectedControllerIndex +
          1}.fieldName${controllerFieldsLength + 1}`,
        type: `controller_${selectedControllerIndex +
          1}.type${controllerFieldsLength + 1}`,
      });
      controllerData[selectedControllerIndex].controllerFields = controllerFields;
      this.setState({ controllers: controllerData });
      await this.props.changeprogressWidth(100)
    }
  };

  //change controllers name
  changeContrllerName = async (e, selectedControllerIndex) => {
    let controllers = this.state.controllers;
    if (e && e.target.value) {
      controllers[selectedControllerIndex].name = e.target.value
      await this.setState({ controllers: this.state.controllers })
    } else {
      let i = selectedControllerIndex;
      i += 1;
      controllers[selectedControllerIndex].name = ''
      await this.setState({ controllers: this.state.controllers })
    }
  }

  //remove controller
  removeController = async (index, type) => {
    if (type === 'controller') {
      let controllers = this.state.controllers;
      controllers.splice(index, 1);
      if (index === 0) {
        await this.setState({
          controllers: controllers,
          selectedControllerIndex: 0
        })
      } else {
        await this.setState({
          controllers: controllers,
          selectedControllerIndex: index - 1
        })
      }
    } else if (type === 'fields') {
      let controllers = this.state.controllers;
      controllers[this.state.selectedControllerIndex]["controllerFields"].splice(index, 1);
      await this.setState({
        controllers: controllers,
      })
    }
  }

  //handleControllerTableFields
  handleControllerFields = async (e, index, fieldName, type, name) => {
    let { selectedControllerIndex, controllers } = this.state;
    if (type === 'radio') {
      if (e == false) {
        delete controllers[selectedControllerIndex]['entity'];
        delete controllers[selectedControllerIndex]['entityValue'];
      }
      controllers[selectedControllerIndex][fieldName] = e;
    } else if (type === 'entity') {
      if (e && e.target.value) {
        controllers[selectedControllerIndex][fieldName] = e.target.value;
        controllers[selectedControllerIndex][name] = e.target.value;
      } else {
        controllers[selectedControllerIndex][fieldName] = '';
        controllers[selectedControllerIndex][name] = '';
      }
    } else if (type === 'input') {
      if (e && e.target.value) {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e.target.value;
        controllers[selectedControllerIndex]["controllerFields"][index][name] = e.target.value;
      } else {
        controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = '';
        controllers[selectedControllerIndex]["controllerFields"][index][name] = '';
      }
    } else if (type === 'checkbox') {
      controllers[selectedControllerIndex]["controllerFields"][index][fieldName] = e;
      controllers[selectedControllerIndex]["controllerFields"][index][name] = e;
    }
    await this.setState({ controllers: controllers })
  }

  //returnController
  returnControllerArray() {
    return this.state.controllers;
  }

  //handleactions
  handleControllerActions = async (e, fieldName) => {
    let controllers = this.state.controllers;
    if (fieldName === 'methods') {
      if (e) {
        controllers[this.state.selectedControllerIndex][fieldName] = e
      } else {
        controllers[this.state.selectedControllerIndex][fieldName] = e
      }
    }
    await this.setState({ controllers: controllers })
  }

  //toggleUploadSample
  toggleUploadSample = async () => {
    await this.setState({ showSampleFile: !this.state.showSampleFile })
    if (this.props.yesJsonUploaded) {
      this.handleShowControllers();
    }
  }

  //handle showControlers 
  handleShowControllers = async () => {
    await this.setState({ showControllers: true })
  }

  //handleyesExistingProject
  handleExistingProject = (e) => {
    if (e && e.target.value) {
      this.setState({ showControllers: true })
      this.props.handleyesExistingProject(true);
    } else {
      this.setState({ showControllers: false })
      this.props.handleyesExistingProject(false);
    }
  }

  render() {

    const {
      controllers,
      controllerCol,
      selectedControllerIndex,
      typeOptions,
      actionsType,
      showSampleFile,
    } = this.state;
    let controllerFieldsArray =
      controllers &&
        controllers.length &&
        controllers[selectedControllerIndex] &&
        controllers[selectedControllerIndex]["controllerFields"] &&
        controllers[selectedControllerIndex]["controllerFields"].length
        ? controllers[selectedControllerIndex]["controllerFields"]
        : [];
    return (
      <Row>
        <Col sm={12}>
          <div >
            <h4 className='ps-2 addIcon pb-2 float-right'>
              < FontAwesomeIcon
                className='editIcon text-info'
                color='#ff00008c'
                icon='upload'
                data-toggle="tool-tip"
                title="Upload Json File"
                onClick={this.toggleUploadSample}
              />
            </h4>
            <Collapse isOpen={showSampleFile}>
              <h5 className='pt-3 pb-3'>Sample File <a href={this.state.sampleFileJson} download target="_blank" >
                <span className='ps-2 addIcon'>
                  < FontAwesomeIcon
                    className='editIcon text-info'
                    color='#ff00008c'
                    icon='download'
                    data-toggle="tool-tip"
                    title="Sample File"
                  />
                </span>
              </a> </h5>
              <Field
                name="sererJsonFile"
                label={'Upload Json File'}
                component={FileInput}
                type="text"
                placeholder="eg:var/www/html"
                className="form-control d-none"
                accept={'.json'}
                onChange={this.props.jsonUploadFile}
              />
              <h5 className='text-secondary mt-2 mb-3'>
                Note: If you upload JSON file please fill the fields which displays below.</h5>
              <hr></hr>
            </Collapse>
            {showSampleFile && <h4 className='text-center  '> OR</h4>}
          </div>
        </Col>
        <Col sm={12}>
          {!this.props.yesJsonUploaded && <div className='pt-3'>
            <label>May I have your Project Name?</label>
            <div>
              <div className='row '>
                <div className='col-sm-12 col-md-6 col-lg-4'>
                  <Field
                    name="projectNameNew"
                    component={DefaultInput}
                    type="text"
                    placeholder="Project Name"
                    className="form-control"
                    onChange={(e) => this.handleExistingProject(e)}
                  />
                </div>
              </div>
            </div>
          </div>}
          <div>
            {this.state.showControllers && !this.props.yesJsonUploaded &&
              <div className='d-lg-flex d-md-flex d-sm-block d-block pt-3'>
                <label className='labelMargin headingsFontC '>Add Controllers Data
            <span className='ps-3 addIcon'>
                    < FontAwesomeIcon
                      className='editIcon text-info'
                      color='#ff00008c'
                      icon='plus-circle'
                      data-toggle="tool-tip"
                      title="Add"
                      onClick={(e) => this.addControllers('controller')}
                    />
                  </span>
                </label>
              </div>}
            <div className='row ps-2'>
              {!this.props.yesJsonUploaded && controllers && controllers.length
                ? controllers.map((item, index) => {
                  return (
                    <div
                      className={selectedControllerIndex == index ?
                        'controller_labelS text-info col-lg-4 col-md-6 col-sm-12 col-12'
                        : 'controller_labelS col-lg-4 col-md-6 col-sm-12 col-12 '}
                      key={index}
                    >
                      <div className={selectedControllerIndex == index ?
                        'text-info  controller_labelS borderContollerColor'
                        : 'controller_labelS'}>
                        <span
                          style={{ textTransform: 'capitalize' }}
                          onClick={(e) =>
                            this.setState({
                              selectedControllerIndex: index,
                              controllerCol: true,
                              emailServiceCol: false
                            })
                          }>
                          <MuiThemeProvider theme={theme}>
                            <TextField
                              autoFocus={true}
                              margin="dense"
                              variant="outlined"
                              id="custom-css-outlined-input"
                              size='small'
                              type="text"
                              helperText={'Please Enter Singular Values, Ex:employee'}
                              className={selectedControllerIndex == index ? 'float-left text-info textFieldsWidth'
                                : 'float-left textFieldsWidth'}
                              style={{ textTransform: 'capitalize' }}
                              name={`controller_${index + 1}.name`}
                              label={`Controller${index + 1} Name`}
                              onChange={(e) => {
                                this.changeContrllerName(e, selectedControllerIndex)
                              }}
                              value={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                              defaultValue={controllers && controllers.length > 0 && controllers[index]["name"] ? controllers[index]["name"] : ''}
                            />
                          </MuiThemeProvider>
                          <span className='text-white'>
                            {`controller_${index + 1}.name`}
                          </span>
                        </span>
                        <span className='float-right iconSize controllerDelteIConPosNode'><FontAwesomeIcon
                          className='editIcon'
                          color='#ff00008c'
                          icon='trash-alt'
                          data-toggle="tool-tip"
                          title="Delete"
                          onClick={() => this.removeController(index, 'controller')}
                        /></span>
                      </div>
                    </div>
                  );
                })
                : null}
            </div>
          </div>
        </Col>
        {!this.props.yesJsonUploaded && <Col
          sm={{ size: 12 }}
          className={controllers && controllers.length && controllerCol ? "" : "d-none"}
        >
          <h3 className='fieldsHeading pt-3'>
            <span className='float-left' style={{ textTransform: 'capitalize' }}>
              {controllers && controllers.length && controllers[selectedControllerIndex][`name`] ? controllers[selectedControllerIndex][`name`] : `
              Controller${selectedControllerIndex + 1}`} Fields
            </span>
          </h3>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-4 pt-3 ps-3'>
              <div className="form__form-group">
                <Field
                  name={`controller_${selectedControllerIndex}.act`}
                  component={MultiSelect}
                  type="text"
                  placeholder="Actions"
                  className="form-control"
                  valueMulti={controllers && controllers.length > 0 && controllers[selectedControllerIndex]['methods'] && controllers[selectedControllerIndex]['methods']}
                  options={actionsType}
                  onChange={(e) => { e ? this.handleControllerActions(e, 'methods') : null }}
                />
              </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-4'>
              <label>Authentication Required</label>
              <div>
                <Field
                  name={`controller_${selectedControllerIndex + 1}.isAuth`}
                  component={RadioInput}
                  label={"Yes"}
                  radioValue={true}
                  onClick={(e) => this.handleControllerFields(true, selectedControllerIndex, `isAuth`, 'radio', "isAuthValue")}
                />
                <Field
                  name={`controller_${selectedControllerIndex + 1}.isAuth`}
                  component={RadioInput}
                  label={"No"}
                  radioValue={false}
                  onClick={(e) => this.handleControllerFields(false, selectedControllerIndex, `isAuth`, 'radio', "isAuthValue")}
                />
              </div>
            </div>
          </div>
          <div className='d-lg-flex d-md-flex d-sm-block d-block'>
            <label className='labelMargin headingsFontC '>Add New Fields
            <span className='ps-3 addIcon'>
                < FontAwesomeIcon
                  className='editIcon text-info'
                  color='#ff00008c'
                  icon='plus-circle'
                  data-toggle="tool-tip"
                  title="Add"
                  onClick={(e) => this.addControllers('fields')}
                />
              </span>
            </label>
          </div>
          <div className="row pt-3 ps-2" >
            {controllerFieldsArray && controllerFieldsArray.length
              ? controllerFieldsArray.map((item, index) => {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-4" >
                    <div className=" pb-3">
                      <MuiThemeProvider theme={theme}>
                        <TextField
                          type="text"
                          className='textFieldsWidth'
                          margin="dense"
                          name={item.fieldName}
                          label="Field Name"
                          variant="outlined"
                          id="custom-css-outlined-input"
                          size='small'
                          defaultValue={item[`fieldvalue`] ? item[`fieldvalue`] : ''}
                          value={item[`fieldvalue`] ? item[`fieldvalue`] : ''}
                          onChange={(e) => this.handleControllerFields(e, index, `name${index}`, 'input', "fieldvalue")}
                        />
                      </MuiThemeProvider>
                    </div>
                    <div className=" pb-3 pt-3">
                      <Field
                        name={item.type}
                        component={SelectDropdown}
                        type="text"
                        placeholder="Field Type"
                        className="form-control"
                        options={typeOptions}
                        valueExists={{ label: item[`fieldType`], value: item[`fieldType`] }}
                        onChange={(e) => this.handleControllerFields(e, index, `type${index}`, 'checkbox', "fieldType")}
                      />
                      {controllerFieldsArray && controllerFieldsArray.length > 0 ? <span>
                        <FontAwesomeIcon
                          className='editIconField'
                          color='#ff00008c'
                          icon='trash-alt'
                          data-toggle="tool-tip"
                          title="Delete"
                          onClick={() => this.removeController(index, 'fields')}
                        />
                      </span> : null}
                    </div>
                  </div>
                );
              })
              : null}
          </div>

        </Col>}
      </Row >
    );
  }
}

export default ServerExistingProject;
