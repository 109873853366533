import React from 'react';
import Editor from "@monaco-editor/react";
import ShowFiles from './ShowFiles';

const CodeEditor = (props) => {

    const handleEditorChange = (value) => {
        props.onCodeChange(value);
    };


    return (
        <div className='h-100'>
            <div className='row h-100'>
                <div className='col-2 overflow-auto h-100'>
                    <ShowFiles
                        menu={props.sideMenu}
                        onSelectFile={props.onSelectFile}
                        selectedFile={props.initSelectedFile}
                        fileType={props.fileType}
                        codeModifyObj={props.codeModifyObj}
                    />
                </div>
                <div className='col-10 h-100'>
                    <Editor
                        defaultLanguage="javascript"
                        value={JSON.parse(props.code)}
                        onChange={handleEditorChange}
                    />
                </div>
            </div>
        </div>
    )
}
export default CodeEditor;