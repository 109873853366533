import React from 'react'

class RangeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderValue: 10,
        }
    }

    //handle slider
    sliderChange = async (value) => {
        if (value) {
            await this.setState({ sliderValue: value })
            this.props.onRangeChange(value)
        }
    }

    render() {
        const {
            labelName, input, rangeValue
        } = this.props;
        let name = input.name
        return (
            <div>
                <div>
                    <label htmlFor={name} className="form-label m-0">{labelName} : {rangeValue ? rangeValue : this.state.sliderValue}</label>
                </div>
                <input
                    {...input}
                    name={name}
                    type="range"
                    className="slider"
                    id={name}
                    min="10"
                    max="100"
                    step="1"
                    onChange={(e) => this.sliderChange(e.target.value)}
                    value={rangeValue ? rangeValue : this.state.sliderValue}

                />
            </div>
        )
    }
}
export default RangeSlider;