// import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPencilAlt,faAngleDown,faAngleUp,faTimes } from '@fortawesome/free-solid-svg-icons';
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { fas } from '@fortawesome/free-solid-svg-icons';

// // This exports the whole icon packs for  Solid.
// library.add(fas)



// class SetMenu extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             menuList: [],
//         //    menuList : this.props.menuArray,    prev
//            showMenu : false,
//            showEdit:{},
//            clickedSubMenu:{}
//         };        
//     }

//     //prev
//     componentDidMount = () => {
//         console.log("SET MENU MOUNT PROPS",this.props)
//         this.setState({menuList:this.props.menuArray})
//     }
//     componentDidUpdate(prevProps) {
//         if (this.props.menuArray !== prevProps.menuArray) {
//           console.log("SET MENU UPDATE PROPS", this.props);
//           this.setState({ menuList: this.props.menuArray });
//         }
//       }

//     handleMosueOver = async (id) => {       
//         let tempObj = {[id]:true};
//         await this.setState({showEdit:tempObj});        
//     }

//     toggleSubMenu = (menu,activeTab) => {       
//         let clickedSubMenu = this.state.clickedSubMenu;
//         if(clickedSubMenu[`${activeTab}${menu.id}DisplaySubmenu`]) {            
//             clickedSubMenu[`${activeTab}${menu.id}DisplaySubmenu`] = false;
//         }else {
//             clickedSubMenu[`${activeTab}${menu.id}DisplaySubmenu`] = true;
//         }
//         this.setState({clickedSubMenu:clickedSubMenu});
//     }


//     displayMenu = (menu) => {
//         let activeTab = this.props.activeTab;
//         return (
//             menu.map((menuItem) => {
//                 // console.log("menuItem",menuItem)
//                 return (  
//                 <li className='menu-li'>
//                     <div className='menu-item text-capitalize' >
//                         <div className='d-flex justify-content-between w-100' 
//                             onMouseLeave={() => this.setState({showEdit:{}})}>
//                             <div >
//                                 <a className='cursor-pointer'
//                                     onClick={() => { this.props.setControllerIndex(menuItem.controllerId) }}
//                                     onMouseOver={() => { this.handleMosueOver(menuItem.id) }}
//                                     >

//                                         {menuItem.iconType && menuItem.iconType === 'icon' && menuItem.iconName ?
//                                             <FontAwesomeIcon  icon={["fa", menuItem.iconName]} /> :
//                                             null
//                                         } 
//                                         {menuItem.iconType && menuItem.iconType === 'letters' && menuItem.iconLetters   ?
//                                             <span className='letter-icon'>{menuItem.iconLetters}</span> : null
//                                         } 
//                                         {
//                                             !menuItem.iconLetters && !menuItem.iconName ?
//                                              <FontAwesomeIcon icon={["fa", "user"]} /> : null
//                                         }

//                                  <span className='ms-2 text-capitalize'>&#8203;{menuItem.displayTitle}</span> 
//                                 </a>

//                                 { (this.state.showEdit[menuItem.id] && menuItem.controllerId) ?
//                                     <span>
//                                         <FontAwesomeIcon
//                                             style={{ fontSize: 15, padding: 10, padding: 2 }}
//                                             className="mr-2"
//                                             color={'white'}
//                                             icon={faPencilAlt}
//                                             data-toggle="tool-tip"
//                                             onClick={() => this.props.loadControllerData(menuItem.controllerId)}
//                                             title="Edit"

//                                         />
//                                     </span>
//                                 : null }
//                             </div>
//                             <div>
//                             {
//                                     (menuItem.submenus) ?
//                                         <span className='cursor-pointer'>
//                                                 {this.state.clickedSubMenu[`${activeTab}${menuItem.id}DisplaySubmenu`] ?
//                                                     <a onClick={() => { this.toggleSubMenu(menuItem, activeTab) }} id={`${activeTab}${menuItem.id}DisplayAngleUp`} >
//                                                         < FontAwesomeIcon className='mr-2'
//                                                             icon={faAngleUp}
//                                                         />
//                                                     </a> :
//                                                     <a onClick={() => { this.toggleSubMenu(menuItem, activeTab) }} id={`${activeTab}${menuItem.id}DisplayAngleDown`} >
//                                                         < FontAwesomeIcon className='mr-2'
//                                                             icon={faAngleDown}
//                                                         />

//                                                     </a>

//                                                 }
//                                         </span>
//                                     : null
//                                 }

//                                     {
//                                         (!menuItem.disableDelete && menuItem.controllerId &&this.props.activeTab==="Admin") ?
//                                             <div className="mx-2">
//                                                 <FontAwesomeIcon
//                                                 className='editIcon'
//                                                 color={'#ff00008c'}
//                                                 icon={faTimes}
//                                                 style={{ width: 15, height: 15, }}
//                                                 data-toggle="tool-tip"
//                                                 title="Delete Controller"
//                                                 onClick={()=>{this.props.deleteMenuItem(menuItem.controllerId)}}
//                                               />
//                                             </div>
//                                             : null
//                                     }
//                             </div>


//                         </div>

//                     </div>
//                         {(menuItem.submenus && menuItem.submenus.length > 0 && this.state.clickedSubMenu[`${activeTab}${menuItem.id}DisplaySubmenu`] && this.state.clickedSubMenu[`${activeTab}${menuItem.id}DisplaySubmenu`] === true)  ?

//                             <ul className='nav flex-column ps-2 background-filter' id={`${activeTab}${menuItem.id}DisplaySubmenu`}>
//                                 {this.displayMenu(menuItem.submenus)}
//                             </ul> 



//                     : null }
//                 </li>
//                 )
//             })
//         )
//     }


//     render() {       
//         return (
//             <div>
//                 <ul className='nav flex-column'>
//                     {this.displayMenu(this.props.menuArray)}
//                 </ul>

//             </div>
//         )
//     }

// }

// export default SetMenu;
//KEEP THE OLD CLASS COMPONENTS CODE FOR REFERENCE





import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faAngleDown, faAngleUp, faTimes } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

// This exports the whole icon packs for  Solid.
library.add(fas);

const SetMenu = (props) => {
  const [menuList, setMenuList] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showEdit, setShowEdit] = useState({});
  const [clickedSubMenu, setClickedSubMenu] = useState({});

  useEffect(() => {
    setMenuList(props.newUIDetails['Admin']['menuList']);
  }, [props.newUIDetails, props.setUIDT]);

  const handleMosueOver = async (id) => {
    let tempObj = { [id]: true };
    await setShowEdit(tempObj);
  };

  const toggleSubMenu = (menu, activeTab) => {
    let updatedClickedSubMenu = { ...clickedSubMenu };
    if (updatedClickedSubMenu[`${activeTab}${menu.id}DisplaySubmenu`]) {
      updatedClickedSubMenu[`${activeTab}${menu.id}DisplaySubmenu`] = false;
    } else {
      updatedClickedSubMenu[`${activeTab}${menu.id}DisplaySubmenu`] = true;
    }
    setClickedSubMenu(updatedClickedSubMenu);
  };

  const displayMenu = (menu) => {
    let activeTab = props.activeTab;
    return menu.map((menuItem) => (
      <li className='menu-li' key={menuItem.controllerId}>
        <div className='menu-item text-capitalize'>
          <div className='d-flex justify-content-between w-100' onMouseLeave={() => setShowEdit({})}>
            <div>
              <a
                className='cursor-pointer'
                onClick={() => props.setControllerIndex(menuItem.controllerId)}
                onMouseOver={() => handleMosueOver(menuItem.controllerId)}
              >
                {menuItem.iconType && menuItem.iconType === 'icon' && menuItem.iconName ? (
                  <FontAwesomeIcon icon={['fa', menuItem.iconName]} />
                ) : null}
                {menuItem.iconType && menuItem.iconType === 'letters' && menuItem.iconLetters ? (
                  <span className='letter-icon'>{menuItem.iconLetters}</span>
                ) : null}
                {!menuItem.iconLetters && !menuItem.iconName ? <FontAwesomeIcon icon={['fa', 'user']} /> : null}
                <span className='ms-2 text-capitalize'>&#8203;{menuItem.displayTitle}</span>
              </a>
              {showEdit[menuItem.controllerId] && menuItem.controllerId ? (
                <span>
                  <FontAwesomeIcon
                    style={{ fontSize: 15, padding: 10, padding: 2 }}
                    className='mr-2'
                    color={'white'}
                    icon={faPencilAlt}
                    data-toggle='tool-tip'
                    onClick={() => props.loadControllerData(menuItem.controllerId)}
                    title='Edit'
                  />
                </span>
              ) : null}
            </div>
            <div>
              {menuItem.submenus ? (
                <span className='cursor-pointer'>
                  {clickedSubMenu[`${activeTab}${menuItem.id}DisplaySubmenu`] ? (
                    <a onClick={() => toggleSubMenu(menuItem, activeTab)} id={`${activeTab}${menuItem.id}DisplayAngleUp`}>
                      <FontAwesomeIcon className='mr-2' icon={faAngleUp} />
                    </a>
                  ) : (
                    <a onClick={() => toggleSubMenu(menuItem, activeTab)} id={`${activeTab}${menuItem.id}DisplayAngleDown`}>
                      <FontAwesomeIcon className='mr-2' icon={faAngleDown} />
                    </a>
                  )}
                </span>
              ) : null}

              {!menuItem.disableDelete && menuItem.controllerId && props.activeTab === 'Admin' ? (
                <div className='mx-2'>
                  <FontAwesomeIcon
                    className='editIcon'
                    color={'#ff00008c'}
                    icon={faTimes}
                    style={{ width: 15, height: 15 }}
                    data-toggle='tool-tip'
                    title='Delete Controller'
                    onClick={() => props.deleteMenuItem(menuItem.controllerId)}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {menuItem.submenus && menuItem.submenus.length > 0 && clickedSubMenu[`${activeTab}${menuItem.id}DisplaySubmenu`] === true ? (
          <ul className='nav flex-column ps-2 background-filter' id={`${activeTab}${menuItem.id}DisplaySubmenu`}>
            {displayMenu(menuItem.submenus)}
          </ul>
        ) : null}
      </li>
    ));
  };
  return (
    <div>
      <ul className='nav flex-column'>{displayMenu(props.newUIDetails['Admin']['menuList'])}</ul>
    </div>
  );
};

export default SetMenu;
