
let fontsArray = [
    {
        "name": "Inherit",
        "code": "inherit"
    },
    {
        "name": "Arial",
        "code": "Arial"
    },
    {
        "name": "Arial Black",
        "code": "Arial Black"
    },
    {
        "name": "Verdana",
        "code": "Verdana"
    },
    {
        "name": "Tahoma",
        "code": "Tahoma"
    },
    {
        "name": "Trebuchet MS",
        "code": "Trebuchet MS"
    },
    {
        "name": "Impact",
        "code": "Impact"
    },
    {
        "name": "Times New Roman",
        "code": "Times New Roman"
    },
    {
        "name": "Didot",
        "code": "Didot"
    },
    {
        "name": "Georgia",
        "code": "Georgia"
    },
    {
        "name": "American Typewriter",
        "code": "American Typewriter"
    },
    {
        "name": "Andalé Mono",
        "code": "Andalé Mono"
    },
    {
        "name": "Courier",
        "code": "Courier"
    },
    {
        "name": "Lucida Console",
        "code": "Lucida Console"
    },
    {
        "name": "Monaco,monospace",
        "code": "Monaco,monospace"
    },
    {
        "name": "Bradley Hand,cursive",
        "code": "Bradley Hand,cursive"
    },
    {
        "name": "Brush Script MT,cursive",
        "code": "Brush Script MT,cursive"
    },
    {
        "name": "Luminari,fantasy",
        "code": "Luminari,fantasy"
    },
    {
        "name": "Comic Sans MS,cursive",
        "code": "Comic Sans MS,cursive"
    }
];
export default fontsArray;
    
