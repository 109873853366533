
const configMessages = {
    invalidEmail: 'Please enter valid email address',
    email: 'Please enter email',
    fillField: 'Please fill above field',
    aadharNumber: 'Please enter valid Aadhar number',
    panCardNumber: 'Please enter valid Pan Card Number',
    phoneNumber: 'please enter valid phone number',
    InvalidDate: 'Invalid Date',
    passwordMatchValidation:"Passoword and Confirm password must be same",

};
export default configMessages;
