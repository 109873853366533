import React from "react";
//Components

import Loader from '../App/Loader';
import { Field, reduxForm } from "redux-form";
import RadioInput from "../../shared/components/form/RadioButton";

import { Redirect, Link } from 'react-router-dom';
//styles
import { Button, Card, CardBody, CardHeader } from 'reactstrap';

import { Row, Col, Container } from "reactstrap";
//service.js
import ServerCode from '../NoCodeServer/index';
import ClientCode from '../NoCode/index';
import RSCode from '../NoCodeRS/index';
import AngularClientCode from '../NoCodeAngular/index';
import VueClientCode from '../NoCodeVue/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import configImages from "../../config/configImages";

class Display extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTech: null,
            isLoading: false,
            selectedReact: false,
            selectedNode: false,
            selectRS: false,
            tabOneActive: true,
            tabTwoActive: false,
            tabThreeActive: false,
            activeOneTab: true,
            activeTwoTab: false,
            activeThreeTab: false,
            activeFiveTab: false,
            activeFourTab: false,
            setMainTab: 'one',
            hideEverything: false,
            hideTabOne: false,
            hideTabTwo: false,
            hideNextScreen: false,
            progressWidth: 0,
            selectedAngular: false,
            selectedVue: false,
            showScroll: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.checkScrollTop);
    }

    //scroll top
    checkScrollTop = () => {
        if (!this.state.showScroll && window.pageYOffset > 400) {
            this.setState({ showScroll: true })
        } else if (this.state.showScroll && window.pageYOffset <= 400) {
            this.setState({ showScroll: false })
        }
    };

    //scrolling window
    scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //handleTabs
    handleTabs = async (type) => {
        console.log("type>?", type)
        if (type === 'one') {
            await this.setState({
                setMainTab: 'one',
                hideTabOne: false,
                hideNextScreen: true,
            })
            this.changeprogressWidth(0)
        } else if (type === 'two') {
            await this.setState({
                setMainTab: 'two',
                activeTwoTab: true,
                hideTabOne: true,
                hideNextScreen: false,
            })

        } else if (type === 'three') {
            await this.setState({ setMainTab: 'three', activeThreeTab: true })
        } else if (type === 'four') {
            await this.setState({ setMainTab: 'four', activeFourTab: true })
        }
    }

    //changeprogressWidth
    changeprogressWidth = async (widthValue) => {
        console.log("changeprogressWidth>?", widthValue)
        let x = this.state.progressWidth;
        if (widthValue != 110) {
            if (widthValue != 0) {
                if (x != 100) {
                    if (x > widthValue) {
                        x = x - 20;
                    } else if (x < widthValue) {
                        x = x + 20
                    } else if (widthValue == 20) {
                        x = 0;
                    }
                    await this.setState({ progressWidth: x })
                } else {
                    await this.setState({ progressWidth: 100 })
                }
            } else {
                await this.setState({ progressWidth: 0 })
            }
        } else {
            await this.setState({ progressWidth: 40 })
        }
    }

    //yesSubmittedData
    yesSubmittedData = async () => {
        console.log("yesSubmittedData>?")
        await this.setState({ progressWidth: 20 })
    }

    render() {
        const {
            progressWidth, showScroll
        } = this.state;
        return (
            <div>
                <div className="progress progressCustomTop sticky-top">
                    <div className="progress-bar progresBarClr" role="progressbar"
                        style={{ width: `${progressWidth}%` }}
                        aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                {/* <Container> */}
                <div className="account">
                    <div className="account__wrapper">
                        <div className="account__card">
                            <div className="m-4">
                                <div>
                                    <div>
                                        <h4><span className='postionRelative pt-2'>
                                            <b>
                                                <Link to='/dashboard'>
                                                    {"Dashboard"} </Link>
                                            </b>
                                        </span></h4>
                                        {/* <Card> */}
                                        <FontAwesomeIcon
                                            icon='arrow-up'
                                            color='#ffff'
                                            className='scrollTop'
                                            onClick={this.scrollTop}
                                            style={{
                                                height: 42,
                                                width: 42,
                                                padding: 11,
                                                display: showScroll ? 'flex' : 'none'
                                            }}
                                        />
                                        <Loader loader={this.state.isLoading} />
                                        {!this.state.hideTabOne && <h2 className='pt-3' style={{ textAlign: 'center' }}>
                                            <img
                                                src={configImages.favImg}
                                                alt="image"
                                                style={{ width: "80px", height: "80px" }} />                            </h2>}
                                        {!this.state.hideTabOne && <h2 className='pt-3' style={{ textAlign: 'center' }}>
                                            <b>NOCODE</b>
                                        </h2>}
                                        {!this.state.hideTabOne && <label className="list_class" style={{ display: "flex" }}>
                                            <span >  Please Select Type Of Your Technology</span>
                                        </label>}
                                        {!this.state.hideTabOne &&
                                            <Row className="pb-3">
                                                <Col sm={12} className='d-lg-flex justify-content-center'>
                                                    <div className='pr-3'>
                                                        <Field
                                                            name='react'
                                                            component={RadioInput}
                                                            className="form-control"
                                                            type="checkbox"
                                                            label={"React Js"}
                                                            radioValue='react'
                                                            onClick={(e) => e && e.target ?
                                                                this.setState({
                                                                    selectedAngular: false,
                                                                    selectedVue: false,
                                                                    selectedReact: true,
                                                                    selectedNode: false, selectRS: false,
                                                                    activeTwoTab: true,
                                                                    hideTabOne: true,
                                                                    progressWidth: this.state.progressWidth + 20,
                                                                }) :
                                                                this.setState({
                                                                    selectedVue: false,
                                                                    selectedAngular: false,
                                                                    selectedReact: false,
                                                                    selectedNode: false,
                                                                    selectRS: false,
                                                                    activeTwoTab: false,
                                                                    hideTabOne: false,
                                                                    progressWidth: this.state.progressWidth + 20
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <Field
                                                        name='react'
                                                        component={RadioInput}
                                                        className="form-control"
                                                        type="checkbox"
                                                        label={"Node Js"}
                                                        radioValue='node'
                                                        onClick={(e) => e && e.target ?
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectedAngular: false,
                                                                selectedNode: true,
                                                                selectRS: false,
                                                                selectedReact: false,
                                                                activeTwoTab: true,
                                                                hideTabOne: true,
                                                                progressWidth: this.state.progressWidth + 20
                                                            }) :
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectedNode: false,
                                                                selectedAngular: false,
                                                                selectedReact: false,
                                                                selectRS: false,
                                                                activeTwoTab: false,
                                                                hideTabOne: false,
                                                                progressWidth: this.state.progressWidth + 20
                                                            })
                                                        }
                                                    />
                                                    <Field
                                                        name='react'
                                                        component={RadioInput}
                                                        className="form-control"
                                                        type="checkbox"
                                                        label={"React & Node Js"}
                                                        radioValue='rs'
                                                        onClick={(e) => e && e.target ?
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectRS: true,
                                                                selectedNode: false,
                                                                selectedReact: false,
                                                                activeTwoTab: true,
                                                                hideTabOne: true,
                                                                selectedAngular: false,
                                                                progressWidth: this.state.progressWidth + 20
                                                            }) :
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectedNode: false,
                                                                selectedReact: false,
                                                                selectRS: false,
                                                                activeTwoTab: false,
                                                                hideTabOne: false,
                                                                selectedAngular: false,
                                                                progressWidth: this.state.progressWidth + 20
                                                            })
                                                        }
                                                    />
                                                    <Field
                                                        name='react'
                                                        component={RadioInput}
                                                        className="form-control"
                                                        type="checkbox"
                                                        label={"Angular Js"}
                                                        radioValue='an'
                                                        onClick={(e) => e && e.target ?
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectRS: false,
                                                                selectedNode: false,
                                                                selectedReact: false,
                                                                activeTwoTab: true,
                                                                hideTabOne: true,
                                                                selectedAngular: true,
                                                                progressWidth: this.state.progressWidth + 20
                                                            }) :
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectedNode: false,
                                                                selectedReact: false,
                                                                selectRS: false,
                                                                activeTwoTab: false,
                                                                selectedAngular: false,
                                                                hideTabOne: false,
                                                                progressWidth: this.state.progressWidth + 20
                                                            })
                                                        }
                                                    />
                                                    <Field
                                                        name='react'
                                                        component={RadioInput}
                                                        className="form-control"
                                                        type="checkbox"
                                                        label={"Vue Js"}
                                                        radioValue='vue'
                                                        onClick={(e) => e && e.target ?
                                                            this.setState({
                                                                selectedVue: true,
                                                                selectRS: false,
                                                                selectedNode: false,
                                                                selectedReact: false,
                                                                activeTwoTab: true,
                                                                hideTabOne: true,
                                                                selectedAngular: false,
                                                                progressWidth: this.state.progressWidth + 20
                                                            }) :
                                                            this.setState({
                                                                selectedVue: false,
                                                                selectedNode: false,
                                                                selectedReact: false,
                                                                selectRS: false,
                                                                activeTwoTab: false,
                                                                selectedAngular: false,
                                                                hideTabOne: false,
                                                                progressWidth: this.state.progressWidth + 20
                                                            })
                                                        }
                                                    />
                                                </Col>
                                            </Row>}
                                        {/* {!this.state.hideTabOne && <hr />} */}
                                        {!this.state.hideTabOne}
                                        {this.state.selectedReact ?
                                            <ClientCode
                                                technology={this.state.selectedTech}
                                                handleTabs={this.handleTabs}
                                                hideTabOne={this.state.hideTabOne}
                                                onRef={(ref) => (this.clientCode = ref)}
                                                changeprogressWidth={this.changeprogressWidth}
                                                yesSubmittedData={this.yesSubmittedData}
                                            /> : null}
                                        {this.state.selectedNode ?
                                            <ServerCode
                                                technology={this.state.selectedTech}
                                                handleTabs={this.handleTabs}
                                                hideTabOne={this.state.hideTabOne}
                                                onRef={(ref) => (this.serverCode = ref)}
                                                changeprogressWidth={this.changeprogressWidth}
                                                yesSubmittedData={this.yesSubmittedData}
                                            /> : null}
                                        {this.state.selectRS ?
                                            <RSCode
                                                handleTabs={this.handleTabs}
                                                hideTabOne={this.state.hideTabOne}
                                                changeprogressWidth={this.changeprogressWidth}
                                                yesSubmittedData={this.yesSubmittedData}
                                            />
                                            : null}
                                        {this.state.selectedAngular ?
                                            <AngularClientCode
                                                handleTabs={this.handleTabs}
                                                hideTabOne={this.state.hideTabOne}
                                                changeprogressWidth={this.changeprogressWidth}
                                                yesSubmittedData={this.yesSubmittedData}
                                            /> : null
                                        }
                                        {this.state.selectedVue ?
                                            <VueClientCode
                                                selectedVue={this.state.selectedVue}
                                                handleTabs={this.handleTabs}
                                                hideTabOne={this.state.hideTabOne}
                                                changeprogressWidth={this.changeprogressWidth}
                                                yesSubmittedData={this.yesSubmittedData}
                                            /> : null
                                        }
                                        {/* </Card> */}
                                        {/* </Container> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default reduxForm({
    form: "display", // a unique identifier for this form
})(Display);
