import React from 'react'
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        // console.log("bnvhgfh", this.props)
        return (
            // <Modal centered={true} isOpen={this.props.openInputModal}>
            <Modal centered={true} isOpen={this.props.showConfirmModal}>
                <ModalHeader className='progresBarClr text-white'>
                    Data entered will be cleared.<span className='float-right'>
                        < FontAwesomeIcon
                            className='editIcon text-white'
                            color='#ff00008c'
                            icon='times-circle'
                            data-toggle="tool-tip"
                            title="Close"
                            onClick={() => this.props.confirmModalOpened('no')}
                        />
                    </span>
                </ModalHeader>
                <ModalBody>
                    <Row className="pt-4">
                        <Col sm={12} className=" text-center">
                            <button type="button" className="btn btn-success"
                                onClick={() => this.props.confirmModalOpened('yes')}>
                                Confirm
                            </button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }

}
export default ConfirmModal;