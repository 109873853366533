import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faImage, faTextHeight, faSlidersH, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Button, Modal, ModalHeader, ModalBody,
    Card, CardBody, ButtonToolbar, ButtonGroup
} from 'reactstrap';
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { load as loadAccount } from "../../redux/reducers/commonReducer";
import DefaultInput from "../../shared/components/form/DefaultInput";
import DefaultTextArea from "../../shared/components/form/DefaultTextArea";
import CheckBox from '../../shared/components/form/CheckBox';
import Select from "../../shared/components/form/Select"
import ColorPicker from '../../shared/components/colorPicker'
import FileInput from '../../shared/components/form/FileInput'
import configMessages from '../../config/configMessages';
import validate from '../Form/validate';
//import MultiSelect from '../../shared/components/form/MultiSelect';
const required = value => {
    (value || typeof value === 'string' ? undefined : configMessages.fillField)
};
class backgroundColorModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionsType: [
                { value: "add", label: "add" },
                { value: "edit", label: "edit" },
                { value: "delete", label: "delete" },
                { value: "view", label: "view" },
                { value: "exportToCsv", label: "export To Csv" },
                { value: "BulkUpload", label: "Bulk Upload" },

            ],
            actions: [],
            name: "",
            isCreateSelcted: false,
            emailDescriptionValue: false,
            descriptionValue: "",
            isAuth: false,
            errorDisplay: false,
            backgroundImage: false,
            backgroundColor: false,
            color: null,
            image: null
        }
    }
    componentDidMount = async () => {
        // if (this.props.editOpenlogInUrlModal) {
        //     let controllers = this.props.controllers
        //     await this.setState({
        //         actions: controllers[this.props.index].actions,
        //         name: controllers[this.props.index].name,
        //         isCreateSelcted: controllers && controllers[this.props.index] && controllers[this.props.index].actions && controllers[this.props.index].actions.includes("add") ? controllers[this.props.index].actions.includes("add") : false,
        //         isAuth: controllers[this.props.index].isAuth ? true : false,
        //         emailDescriptionValue: controllers[this.props.index].emailDescription ? true : false
        //     })
        //     this.state.isAuth ? controllers[this.props.index]["loginUrl"] = true : null
        //     this.props.load(controllers[this.props.index])
        // } else {
        //     this.props.load({})
        // }
    }

    submit = (values) => {
        this.props.output({ image: this.state.image, color: this.state.color })
        this.props.closeInputModal()
        this.props.reset()
    }
    colorchange = (e) => {
        console.log("222222222222222222222222222222222222222", e)
    }
    setImage = async (e) => {
        let value1 = await this.props.projectBg(e)
        await this.setState({ image: value1, color: null })
    }
    render() {
        const { handleSubmit, controllers, index } = this.props;

        console.log(this.props.openlogInUrlModal, this.props.editOpenlogInUrlModal, "propsssssssssss", this.props)
        return (
            <Modal style={{ width: "40%" }}
                isOpen={this.props.openlogInUrlModal}
            >
                <ModalHeader className='progresBarClr text-white'>
                    Background Modal<span className='float-right'>
                        < FontAwesomeIcon
                            className='editIcon text-white'
                            color='#ff00008c'
                            icon='times-circle'
                            data-toggle="tool-tip"
                            title="Close"
                            onClick={() => this.props.closeInputModal(this.props.index, "backgroundColormodal", this.props.editOpenlogInUrlModal)}
                        />
                    </span>
                </ModalHeader>
                <ModalBody>

                    <form
                        onSubmit={handleSubmit(this.submit)}
                        autoComplete={'off'}
                    >
                        Select background type
                        <div className="row form col-sm-12" style={{ "justifyContent": "center" }}   >
                            <div className="col-sm-4">
                                <h3>
                                    {this.state.backgroundImage ? 'Selected' : 'Choose Image'}
                                </h3>
                                <FontAwesomeIcon
                                    style={{ 'font-size': '-webkit-xxx-large' }}
                                    color={this.state.backgroundImage ? '#4ce1b6' : '#7db7ff'}
                                    icon={faImage}
                                    data-toggle="tool-tip"
                                    onClick={() => { this.setState({ backgroundImage: true, backgroundColor: false }) }}
                                />
                            </div>
                            <div className="col-sm-4">
                                {this.state.backgroundImage ?
                                    <FontAwesomeIcon
                                        style={{ 'font-size': '-webkit-xxx-large' }}
                                        color={this.state.backgroundImage ? '#4ce1b6' : 'grey'}
                                        icon={faArrowLeft}
                                        data-toggle="tool-tip"
                                    /> :
                                    <FontAwesomeIcon
                                        style={{ 'font-size': '-webkit-xxx-large' }}
                                        color={this.state.backgroundColor ? '#4ce1b6' : 'grey'}
                                        icon={faArrowRight}
                                        data-toggle="tool-tip"
                                    />}
                            </div>
                            <div className="col-sm-4">
                                <h3>
                                    {this.state.backgroundColor ? 'Selected' : 'Choose Color'}
                                </h3>
                                <FontAwesomeIcon
                                    style={{ 'font-size': '-webkit-xxx-large' }}
                                    color={this.state.backgroundColor ? '#4ce1b6' : '#7db7ff'}
                                    icon={faPalette}
                                    data-toggle="tool-tip"
                                    onClick={() => { this.setState({ backgroundImage: false, backgroundColor: true }) }}
                                />
                            </div>
                        </div>
                        <div className="row form col-sm-12" style={{ "justifyContent": "center" }}>
                            {this.state.backgroundImage ?
                                <Field
                                    name="backgroungImage"
                                    label={'Upload Background Image'}
                                    component={FileInput}
                                    type="text"
                                    placeholder="eg:var/www/html"
                                    className="form-control  d-none"
                                    value={{ name: this.state.backgroungImage }}
                                    onChange={(e) => this.setImage(e)}
                                    accept={'.jpg,.jpeg,.png'}
                                />
                                : null}
                            {console.log("111111111111111111111111111111111111111111111111111111", this.state.backgroundColor)}
                            {this.state.backgroundColor ?
                                <Field
                                    style={{ backgroundColor: "white" }}
                                    name="backgroundColor"
                                    color={this.state.color ? this.state.color : this.state.textcolor}
                                    onChange={(e) => {
                                        // this.colorchange(e)
                                        // console.log("backgroundColorbackgroundColor", e)
                                        this.setState({ color: e.hex, image: null })
                                    }
                                    }
                                    component={ColorPicker}
                                    value={this.state.color ? this.state.color : this.state.textcolor}
                                /> : null}
                        </div>
                        {console.log("this.state.color", this.state.color, this.state.image)}
                        <div className='col-sm-12 mt-2'>
                            <ButtonToolbar className="justify-content-center ms-auto" >
                                {/* Cancel */}
                                < Button
                                    type="button"
                                    style={{ backgroundColor: "#4ce1b6" }}
                                    onClick={() => this.props.closeInputModal(this.props.index, "backgroundColormodal", this.props.editOpenlogInUrlModal)}
                                >
                                    Cancel
                                </Button>
                                {/* Save */}
                                <Button
                                    disabled={this.state.image || this.state.color ? false : true}
                                    style={{ backgroundColor: "#4ce1b6" }}
                                    outline
                                    type="submit"
                                >
                                    {'Save'}
                                </Button>

                            </ButtonToolbar>
                        </div>
                    </form>
                </ModalBody>
            </Modal >
        )
    }

}

backgroundColorModal = reduxForm({
    form: "loginapi_form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(backgroundColorModal);

// You have to connect() to any reducers that you wish to connect to yourself
backgroundColorModal = connect(
    (state) => ({
        initialValues: state.commonData.data, // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(backgroundColorModal);
export default backgroundColorModal;