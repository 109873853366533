import React from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';

const ConfirmModal = ({ index, name, selectModalShow, closeInputModal }) => {

    return (
        <Modal centered={true} isOpen={selectModalShow}>
            <ModalHeader className='progresBarClr text-white' style={{ textAlign: "center" }}>
                Are you sure you want to Delete {name ? name : `Controller ${index + 1}`}?
            </ModalHeader>
            <ModalBody>
                <Row className="pt-4">
                    <Col sm={12} className=" text-center">
                        <button type="button" className="btn btn-success" onClick={() => closeInputModal()}>
                            No
                        </button>
                        <button type="button" className="btn btn-success" onClick={() => closeInputModal(index, 'deleteController')}>
                            Yes
                        </button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ConfirmModal;
