import React from "react";
//Components
import ExistingProject from "./ExistingProject/existingproject";
import NewProject from "./NewProject/newproject";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RadioButton from '../../shared/components/form/RadioButton';
import Loader from '../App/Loader';
import ConfirmModal from '../ConfirmModals/ConfirmModal';
import SelectModal from '../ConfirmModals/SelectModal';
//styles
import { Row, Col, Collapse } from "reactstrap";
import { Field, reduxForm } from "redux-form";
//service.js
import fetchMethodRequest from '../../config/service';
import apiCalls from '../../config/apicalls';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

class ClientCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [
        { value: "new", label: "New" },
        { value: "existing", label: "Existing" },
      ],
      selectedProject: null,
      selectedTech: null,
      showProgressBar: false,
      fileName: '',
      favName: '',
      bgName: '',
      downloadProjectLink: '',
      isLoading: false,
      yesJsonUploaded: false,
      jsonUploadFile: '',
      apiUrlTypes: [],
      hideTabTwo: false,
      showConfirmModal: false,
      selectModalShow: false,
      selectedModalValue: '',
      yesExistingProject: false,
      loginApi: ''
    };
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillMount() {
    if (this.props.onRef) {
      this.props.onRef({});
    }
  }
  loginUrl = async (values) => {
    console.log(values)
    this.setState({
      loginUrl: values.loginApi,
      projectName: values.projectName,
      templateColor: values.color
    })
  }

  //submit values
  submit = (values) => {
    console.log("???????????????????????????????????????????")
    //this.loginUrl()
    let temp = '';
    let yesContiue = false;
    let profileFields = [];
    let selectedControllers = this.newProjectRef.returnSelectedControllers();
    console.log("selectedControllers", selectedControllers)
    console.log("temp")
    if (this.newProjectRef && this.state.selectedProject == 'new') {
      temp = this.newProjectRef.returnControllerArray();
      console.log(temp)
    }
    if (this.existingProjectRef && this.state.selectedProject == 'existing') {
      temp = this.existingProjectRef.returnControllerArray();

    }
    if (values && values.loginApi) {
      yesContiue = true;
    } else {
      if (this.newProjectRef) {
        this.newProjectRef.showErrorForApiUrl();
      }
      if (this.existingProjectRef && this.state.selectedProject == 'existing') {
        yesContiue = true;
      }
    }
    if (yesContiue) {
      let { selectedProject, fileName } = this.state;
      if (values) {
        if (values.projectNameNew) {
          values.projectName = values.projectNameNew;
          delete values['projectNameNew']
        }
        if (values['projectFile'] && values['projectFile']['name']) {
          values['projectFile'] = values['projectFile']['name']
        }
      }
      if (temp) {
        console.log("Temper11111  ", temp)
        for (let j = 0; j < temp.length; j++) {
          if (selectedControllers.includes(temp[j].name)) {
            if (temp[j].actions) {
              temp[j]['controllerFields'].push({
                "textAlign": "left",
                "width": 70,
                "field": "Actions",
                "header": "Actions",
                "filter": false,
                "sortable": false
              })
            }
            temp[j]['controllerFields'].unshift({
              "textAlign": 'center',
              "width": 47,
              "field": 'Sno',
              "header": 'SNo',
              "filter": false,
              "sortable": false,
              "placeholder": 'Search'
            })
          } else {
            temp.splice(j, 1)
          }
        }
      }

      if (selectedProject) {
        values.projectType = selectedProject;
      }
      console.log("body", temp)
      // this.loginUrl()
      let body = {
        projectName: values.projectName,
        color: values.color,
        projectType: values.projectType,
        logo: this.state.fileName,
        fav: this.state.favName,
        controllers: temp,
        loginApi: values.loginApi,
        color: values.color
        //profileFields: profileFields,
      }
      body.jsonObject = body
      console.log("body", body)
      if (this.existingProjectRef && this.state.selectedProject == 'existing') {
        delete body.logo;
        delete body.fav;
        delete body.loginApi;
      }
      if (this.state.yesJsonUploaded) {
        delete body.controllers;
        delete body.projectName;
        body['reaactJsonFile'] = this.state.jsonUploadFile
      }
      console.log(body)
      this.sendDataToServer(body)
    }
  };

  //send data to server
  sendDataToServer = async (values) => {
    let submitDataValue = false;
    if (!this.state.yesJsonUploaded && values.controllers.length > 0 && values.projectName) {
      submitDataValue = true;
    } else if (this.state.yesJsonUploaded) {
      submitDataValue = true;
    }
    if (submitDataValue) {
      await this.setState({ isLoading: true })
      let { selectedProject } = this.state;
      let url = selectedProject == "existing" ? apiCalls.ReactExist : apiCalls.React;
      fetchMethodRequest('POST', url, values).then(async (res) => {
        if (res && res.respCode && res.downloadLink) {
          this.setState({
            downloadProjectLink: res.downloadLink, favName: '', bgName: '', fileName: '',
            jsonUploadFile: '', apiUrlTypes: [], isLoading: false, yesJsonUploaded: false,
          })
          setTimeout(() => {
            this.setState({ hideTabTwo: false, })
          }, 500)
          if (selectedProject && selectedProject == "existing" && this.existingProjectRef) {
            await this.setState({
              selectedProject: '',
            })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.existingProjectRef.emptyController();
          } else if (selectedProject && selectedProject == "new" && this.newProjectRef) {
            await this.setState({ selectedProject: '', isLoading: false })
            this.props.reset();
            await this.props.yesSubmittedData()
            this.newProjectRef.emptyController();
          }
        } else if (res && res.errorCode && res.errorMessage) {
          showToasterMessage(res.messageType, 'error');
          await this.setState({ isLoading: false })
        } else {
          await this.setState({ isLoading: false })
        }
      })
    } else {
      await this.setState({ isLoading: false })
    }
  }

  //handleSelectModal
  handleSelectModal = async (type) => {
    await this.setState({ selectedModalValue: type, selectModalShow: false })
    if (this.state.selectedProject == 'existing') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'existing', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({ selectedProject: 'new', fileName: '', favName: '', yesExistingProject: false, yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true, })
      }
    } else if (this.state.selectedProject == 'new') {
      let res = this.state.selectedModalValue;
      if (res == 'no') {
        this.setState({ selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true, })
      } else {
        await this.props.reset();
        await this.props.changeprogressWidth(110)
        this.setState({ selectedProject: 'existing', fileName: '', favName: '', yesExistingProject: false, yesJsonUploaded: false, downloadProjectLink: '', hideTabTwo: true, })
      }
    } else {
      this.setState({ selectedProject: this.state.selectedProject, downloadProjectLink: '', hideTabTwo: true, })
    }
  }

  //project file
  projectFile = (file) => {
    fetchMethodRequest('POST', apiCalls.ReactLogo, file.file, 'upload').then((res) => {
      if (res && res.logo) {
        if (this.newProjectRef) {
          this.newProjectRef.uploadExits();
        }
        this.setState({ fileName: res.logo })
      }
    })
  }

  //projectFav
  projectFav = (file) => {
    fetchMethodRequest('POST', apiCalls.ReactFav, file.file, 'upload').then((res) => {
      if (res && res.fav) {
        if (this.newProjectRef) {
          this.newProjectRef.favIconSubmitted();
        }
        this.setState({ favName: res.fav })
      }
    })
  }

  //projectBg
  projectBg = (file) => {
    fetchMethodRequest('POST', 'reactLogo?type=bg', file.file, 'upload').then((res) => {
      if (res && res.bg) {
        if (this.newProjectRef) {
          this.newProjectRef.uploadExits();
        }
        this.setState({ bgName: res.bg })
      }
    })
  }

  //project file
  jsonUploadFile = (file) => {
    fetchMethodRequest('POST', 'jsonUpload?type=react', file.file, 'upload').then(async (res) => {
      if (res && res.fileName) {
        await this.props.changeprogressWidth(60)
        if (this.newProjectRef) {
          this.newProjectRef.toggleUploadSample();
          if (this.existingProjectRef && this.state.selectedProject == 'existing') {
            await this.props.changeprogressWidth(80)
            await this.props.changeprogressWidth(100)
            this.existingProjectRef.toggleUploadSample();
          }
        } else if (this.existingProjectRef) {
          await this.props.changeprogressWidth(80)
          await this.props.changeprogressWidth(100)
          this.existingProjectRef.toggleUploadSample();
        }
        this.setState({
          jsonUploadFile: res.fileName,
          yesJsonUploaded: true,
          apiUrlTypes: res.loginApi,
          yesExistingProject: true
        })
      }
    })
  }

  //handleRadioChanges
  handleRadioChanges = async (type, event) => {
    if (type == 'new') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'existing') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({ selectedProject: 'new', downloadProjectLink: '', hideTabTwo: true, yesExistingProject: false, })
      }
    } else if (type == 'exi') {
      if (!this.state.yesJsonUploaded) {
        this.props.changeprogressWidth(40)
      }
      if (this.state.selectedProject == 'new') {
        this.setState({ selectModalShow: true })
      } else {
        this.setState({ selectedProject: 'existing', downloadProjectLink: '', hideTabTwo: true, yesExistingProject: false, })
      }
    }
  }

  //handleyesExistingProject
  handleyesExistingProject = async (type) => {
    await this.setState({ yesExistingProject: type })
  }

  //handleProgreess
  handleProgressWidth = async () => {

    if (this.state.selectedProject) {
      console.log("SDFSDFS")
      await this.setState({ showConfirmModal: true })
    } else {
      await this.props.handleTabs('one')
      await this.setState({ showConfirmModal: false })
    }
  }

  //confirmModalOpened
  confirmModalOpened = async (type) => {
    if (type == 'yes') {
      await this.setState({
        selectedProject: '', hideTabTwo: false,
        showConfirmModal: false, yesExistingProject: false, yesJsonUploaded: false,
        fileName: '', favName: '',
      })
      await this.props.reset();
      setTimeout(() => {
        this.props.handleTabs('one')
      }, 500)
    } else if (type == 'no') {
      await this.setState({ showConfirmModal: false })
    }
  }

  render() {
    // console.log("this.props ", this.props)
    let { projects, selectedProject, hideTabTwo, favName, bgName, fileName, yesExistingProject, } = this.state;
    const { handleSubmit } = this.props;
    return (
      this.props.hideTabOne && <>
        <form >
          <Row>
            <span className='pr-3 pt-4'>
              < FontAwesomeIcon
                className='editIcon text-info'
                color='#ff00008c'
                style={{ fontSize: '20px' }}
                icon='arrow-alt-circle-left'
                data-toggle="tool-tip"
                title="Go Back"
                onClick={() => this.handleProgressWidth()}
              />
            </span>
            <h2 className='pt-3' >
              <b><span>React Js</span>
                {selectedProject && <span className='ps-2 drownArrowC'>
                  <FontAwesomeIcon
                    className='editIcon downColor'
                    color='#ff00008c'
                    icon={hideTabTwo ? 'angle-down' : 'angle-up'}
                    data-toggle="tool-tip"
                    title={hideTabTwo ? "Open" : 'Close'}
                    onClick={() => { this.setState({ hideTabTwo: !this.state.hideTabTwo }) }}
                  />
                </span>}
              </b>
            </h2>
          </Row>
          <Loader loader={this.state.isLoading} />
          <Collapse isOpen={!hideTabTwo} style={{ textAlign: 'center' }}>
            < label className="list_class">
              Please Select Type Of Your Project
            </label>

            <Row className="pb-3">
              {/* <Col sm={4}> */}
              <Col sm={12}>
                <Field
                  id='rsnew'
                  name='project'
                  className="react-select"
                  placeholder="Select project type"
                  label="New"
                  radioValue="new"
                  onClick={(event) => {
                    this.handleRadioChanges('new', event)
                  }}
                  component={RadioButton}
                  selectedProject={this.state.selectedProject}
                />
                <Field
                  id='rsexi'
                  name='project'
                  onClick={(event) => {
                    this.handleRadioChanges('exi', event)
                  }}
                  className="react-select"
                  placeholder="Select project type"
                  label="Existing"
                  radioValue='existing'
                  component={RadioButton}
                  selectedProject={this.state.selectedProject}
                />
              </Col>

            </Row>

            {/* <hr /> */}
          </Collapse>
        </form >
        {
          selectedProject && selectedProject == "new" ? (
            <NewProject
              onRef={(ref) => (this.newProjectRef = ref)}
              projectFile={this.projectFile}
              jsonUploadFile={this.jsonUploadFile}
              apiUrlTypes={this.state.apiUrlTypes}
              yesJsonUploaded={this.state.yesJsonUploaded}
              handleAllTabs={this.props.handleTabs}
              changeprogressWidth={this.props.changeprogressWidth}
              projectFav={this.projectFav}
              projectBg={this.projectBg}
              //loginUrl={this.loginUrl}
              submit={this.submit}
              handleyesExistingProject={this.handleyesExistingProject}

            />
          ) : selectedProject && selectedProject == "existing" ? (
            <ExistingProject
              onRef={(ref) => (this.existingProjectRef = ref)}
              projectFile={this.projectFile}
              showProgressBar={this.state.showProgressBar}
              jsonUploadFile={this.jsonUploadFile}
              yesJsonUploaded={this.state.yesJsonUploaded}
              handleAllTabs={this.props.handleTabs}
              changeprogressWidth={this.props.changeprogressWidth}
              handleyesExistingProject={this.handleyesExistingProject}
            />
          ) : null
        }
        {/* {
          selectedProject && <Row className="pt-4">
            <Col sm={12} className="d-flex justify-content-center">
              {selectedProject && selectedProject == 'new' && yesExistingProject &&
                favName && fileName ? <button type="button" className="btn btn-success" onClick={this.submit}>
                Submit
              </button> :
                <button type="button" className="btn btn-success" onClick={this.submit}>
                  Submit
                </button>}
            </Col>
          </Row>
        } */}
        {
          selectedProject && <Row>
            {/* <Col sm={12}>
              <hr />
            </Col> */}
          </Row>
        }
        <Row className={this.state.downloadProjectLink ? "pt-3" : 'd-none'}>
          <Col sm={12}
            className="text-center"
          >
            <h4 >  You can download your Project Here <a href={this.state.downloadProjectLink} download>Download</a></h4>
          </Col>
        </Row>
        <ConfirmModal
          showConfirmModal={this.state.showConfirmModal}
          confirmModalOpened={this.confirmModalOpened}
        />
        <SelectModal
          selectModalShow={this.state.selectModalShow}
          handleSelectModal={this.handleSelectModal}
        />

      </>
    );
  }
}
export default reduxForm({
  form: "client", // a unique identifier for this form
})(ClientCode);
