import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Project from './components/Project';

const project = (props, { t }) => (
  <Container>
    <Project  {...props} />
  </Container>
);
project.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(project);
